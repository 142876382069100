import {Component, Inject, OnInit} from '@angular/core';
import {Folder, Mutation} from '../../../types';
import {Clipboard} from '@angular/cdk/clipboard';
import {Apollo} from 'apollo-angular';
import {CREATE_UPLOAD_TICKET} from '../../../queries';
import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {environment} from '../../../../environments/environment';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {DoButtonComponent} from '../../do-button/do-button.component';
import {ClTabsModule} from '../../cl-tabs/cl-tabs.module';
import {FormsModule} from '@angular/forms';
import {MessageFieldComponent} from '../../do-button/message-field.component';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-generate-upload-link-dialog',
  templateUrl: './generate-upload-link-dialog.component.html',
  styleUrls: ['./generate-upload-link-dialog.component.scss'],
  imports: [
    DoButtonComponent,
    MessageFieldComponent,
    ClTabsModule,
    FormsModule,
    CommonModule
  ]
})
export class GenerateUploadLinkComponent implements OnInit {
  message: string;
  feedback: string;
  folder: Folder;
  ticket: {
    directory: string;
    url: string;
    embedurl: SafeResourceUrl;
    expiration: string;
    token: string;
  } = null;
  uploaderUrl = environment.uploaderurl;

  protected readonly encodeURIComponent = encodeURIComponent;

  constructor(private apollo: Apollo,
              public dialogRef: DialogRef<GenerateUploadLinkComponent>,
              private clipboard: Clipboard,
              private domSanitizer: DomSanitizer,
              @Inject(DIALOG_DATA) public data: { folder: Folder }) {
    this.folder = this.data.folder;
    const components = this.data.folder.id.split('/')
    components.pop()
    this.message = components.pop()
  }

  genLink(button: DoButtonComponent) {
    this.feedback = undefined;

    this.apollo.mutate<Mutation>({
      mutation: CREATE_UPLOAD_TICKET,
      variables: {
        folder_id: this.folder.id,
        message: this.message
      },
      fetchPolicy: 'network-only'
    }).subscribe(d => {
      button.done('Upload link generated.')
      this.ticket = {
        directory: encodeURIComponent(d.data.createUploadTicket.directory),
        url: d.data.createUploadTicket.url.toString(),
        embedurl: this.domSanitizer.bypassSecurityTrustResourceUrl(d.data.createUploadTicket.url.toString() + '?embed'),
        expiration: d.data.createUploadTicket.expiration,
        token: d.data.createUploadTicket.token
      }
    });
  }

  copyClipboard() {
    this.clipboard.copy(this.ticket.url.toString())
  }

  ngOnInit() {
  }
}
