<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">Move</div>

    <div class="modal-body cl-form">
      <p>You are about to move the following files from ({{ src_basefolder }}):</p>
      <table class="cl-table-data cl-table-data-light">
        <thead>
        <tr>
          <th class="cl-table-head cl-p-tiny ">
            Type
          </th>
          <th class="cl-table-head cl-p-tiny ">
            File or folder name
          </th>
          <th class="cl-table-head cl-p-tiny ">
            <div>
              Last Modified
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let file of (files$ | async)">
          <td>
            <i class="fas fa-file" *ngIf="file.__typename === 'File'"></i>
            <i class="fas fa-folder" *ngIf="file.__typename === 'Folder'"></i>
          </td>
          <td>
            {{ file.name }}
          </td>
          <td>
            {{ file?.last_modified | date: 'd MMM y,&nbsp;HH:mm' }}
          </td>
        </tbody>
      </table>

      <label for="dest_prefix" class="form-label">destination folder</label>
      <input type="text" id="dest_prefix" style="width: 100%" [(ngModel)]="dst_folder" pattern=".+/"
             #destbrefix="ngModel">
      <p class="invalid-feedback" *ngIf="destbrefix.errors?.pattern">
        The brefix has to end with a slash
      </p>

      <p class="alert cl-alert-info" role="alert"> This dialog will close automatically once the move is complete, but
        you can close it manually without interrupting the process.</p>
    </div>
    <div class="modal-footer">
    <span class="modal-footer-left cl-spacing-box cl-spacing-none">
                    <button type="button" class="btn btn-sm cl-btn-outline-text"
                            (click)="close()">
                        <i class="fas fa-angle-left"></i><span>Close</span>
                    </button></span>
      <span class="modal-footer-center">
        <div style="margin-top: -8px;">
          <app-message-field #moveMsg/>
        </div>
      </span>
      <span class="modal-footer-right">
                    <app-do-button type="button" [messageField]="moveMsg" (actionTriggered)="move($event);">
                        <span>Move files</span><i class="fas fa-angle-right"></i>
                    </app-do-button>
      </span>
    </div>

  </div>
</div>

