import {Component, Inject, OnInit} from '@angular/core';
import {
  AssociateSoftwareTokenCommand,
  CognitoIdentityProviderClient,
  SetUserMFAPreferenceCommand,
  VerifySoftwareTokenCommand,
  VerifySoftwareTokenResponseType
} from '@aws-sdk/client-cognito-identity-provider';
import {environment} from '../../../../environments/environment';

import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {QRCodeComponent} from 'angularx-qrcode';
import {FormsModule} from '@angular/forms';

@Component({
    selector: 'app-mfasetup',
    templateUrl: './mfasetup.component.html',
    styleUrls: ['./mfasetup.component.scss'],
  imports: [QRCodeComponent, FormsModule]
})
export class MFASetupComponent implements OnInit {
  client: CognitoIdentityProviderClient;
  code: string
  secret: string = null

  constructor(
    public dialogRef: DialogRef<{ message: string, severity: string }>,
    @Inject(DIALOG_DATA) public data: { access_token: string, email: string }) {
  }

  ngOnInit(): void {
    this.client = new CognitoIdentityProviderClient({
      region: environment.region,
      credentials: {
        accessKeyId: 'a',
        secretAccessKey: 'b',
      },
    });
    const disableMFA = new SetUserMFAPreferenceCommand({
      AccessToken: this.data.access_token,
      SoftwareTokenMfaSettings: {
        Enabled: false,
        PreferredMfa: false
      }
    })

    this.client.send(disableMFA).then(v => {
    })
    const command = new AssociateSoftwareTokenCommand({
      AccessToken: this.data.access_token
    });
    this.client.send(command).then(value => {
      this.secret = value.SecretCode
    })
  }

  verifyCode() {

    const command = new VerifySoftwareTokenCommand({
      AccessToken: this.data.access_token,
      UserCode: this.code
    })

    this.client.send(command).then(
      value => {
        if (value.Status === VerifySoftwareTokenResponseType.SUCCESS) {
          const command2 = new SetUserMFAPreferenceCommand({
            AccessToken: this.data.access_token,
            SoftwareTokenMfaSettings: {
              Enabled: true,
              PreferredMfa: true
            }
          })
          this.client.send(command2).then(value1 => {
              this.dialogRef.close({
                  'message': 'MFA set up successfully.',
                  'severity': 'cl-alert-info'
                }
              )

            }
          ).catch(reason => {
            this.dialogRef.close({
                'message': 'MFA was set up but could not be activated.',
                'severity': 'cl-alert-danger'
              }
            )
          })

        } else {
          this.dialogRef.close({
            'message': 'MFA was not set up correctly. Any previous MFAs are now disassociated.',
            'severity': 'cl-alert-danger'
          })
          const command2 = new SetUserMFAPreferenceCommand({
            AccessToken: this.data.access_token,
            SoftwareTokenMfaSettings: {
              Enabled: false,
              PreferredMfa: false
            }
          })
          this.client.send(command2)
        }
      }
    ).catch(e => {
        this.dialogRef.close({
          'message': 'MFA was not set up correctly. Any previous MFAs are now disassociated.',
          'severity': 'cl-alert-danger'
        })

        const command2 = new SetUserMFAPreferenceCommand({
          AccessToken: this.data.access_token,
        })

        this.client.send(command2)
      }
    )
  }

}
