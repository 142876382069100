<div class="modal-dialog container">
  <div class="modal-content">
    <div class="modal-header">Create directories</div>

    <div class="modal-body cl-form">
      <label for="dirs" class="form-label">Create multiple directories by entering one directory per line.</label>
      <textarea id="dirs" [(ngModel)]="new_dir_name" cdkAutosizeMaxRows="5"
                cdkAutosizeMinRows="2"
                cdkTextareaAutosize></textarea>
    </div>
    <div class="modal-footer">
      <span class="modal-footer-left">
                    <button type="button" class="btn btn-sm cl-btn-outline-text"
                            (click)="dialogRef.close()">
                        <i class="fas fa-angle-left"></i><span>Cancel</span>
                    </button></span>
      <span class="modal-footer-center"></span>
      <span class="modal-footer-right">
                    <button type="button" class="btn btn-sm  cl-btn-outline-primary " [class.cl-btn-spinner]="mkdiring"
                            (click)="mkdir();"
                            [disabled]="!buttonEnabled"
                    >
                        <span>Create directories</span><i class="fas fa-angle-right"></i>
                    </button></span>
    </div>
  </div>
</div>
