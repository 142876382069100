import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'remove'
})
export class RemovePipe implements PipeTransform {
  transform(value: string, removeValue: string): any {
    return value.replace(removeValue, '');
  }
}
