import {Component, OnInit, signal} from '@angular/core';
import {authConfigAbove, authConfigCastlabs, authConfigPortal, authConfigTouch} from './authConfig';
import {OAuthService} from 'angular-oauth2-oidc';
import {Router, RouterOutlet} from '@angular/router';
import {PortalIdpService} from './services/portal-idp.service';
import {cleanLocalStorage} from './components/utils';
import {LoginPageComponent} from './components/login-page.component';
import {CommonModule} from '@angular/common';
import {ContextService} from './services/context.service';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  imports: [RouterOutlet, LoginPageComponent, CommonModule],
})
export class AppComponent implements OnInit {
  loggedIn = signal(false);
  loggingIn = signal(true);

  constructor(
    public oAuthService: OAuthService,
    router: Router,
    private portalIdp: PortalIdpService,
    private contextService: ContextService
    /* Technically not needed here but it's loaded early enough  */
  ) {
    oAuthService.setStorage(localStorage)
    // Configure OAuth based on the hostname
    if (window.location.hostname.startsWith('above')) {
      oAuthService.configure(authConfigAbove);
      this.portalIdp.setIdpName('above');
    } else if (window.location.hostname.startsWith('touch')) {
      oAuthService.configure(authConfigTouch);
      this.portalIdp.setIdpName('touch');
    } else if (window.location.hostname.startsWith('platform')) {
      oAuthService.configure(authConfigCastlabs);
      this.portalIdp.setIdpName('castlabs');
    } else {
      oAuthService.configure(authConfigPortal);
      this.portalIdp.setIdpName('portal');
    }
    oAuthService.loadDiscoveryDocumentAndTryLogin().then(
      () => {
        this.loggedIn.set(oAuthService.hasValidAccessToken())
        this.loggingIn.set(false)
        if (oAuthService.hasValidAccessToken()) {
          if (this.oAuthService.state) {
            console.log('Navigate back to URL before login');
            router.navigateByUrl(this.oAuthService.state, { replaceUrl: true });
          }
        } else if (localStorage.getItem('post-logout-path')) {
          router.navigateByUrl(localStorage.getItem('post-logout-path'), { replaceUrl: true });
          localStorage.removeItem('post-logout-path')
        }
      }
    );
    oAuthService.events.subscribe(() => {
      this.loggedIn.set(this.oAuthService.hasValidAccessToken())
    })
    oAuthService.setupAutomaticSilentRefresh()
  }

  ngOnInit(): void {
  }

  cleanLocalStorage() {
    cleanLocalStorage();
  }
}
