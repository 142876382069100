import { setCredentialFeature } from "@aws-sdk/core/client";
import { doesIdentityRequireRefresh, isIdentityExpired, memoizeIdentityProvider, normalizeProvider } from "@smithy/core";
import { SignatureV4 } from "@smithy/signature-v4";
export const resolveAwsSdkSigV4Config = config => {
  let isUserSupplied = false;
  let credentialsProvider;
  if (config.credentials) {
    isUserSupplied = true;
    credentialsProvider = memoizeIdentityProvider(config.credentials, isIdentityExpired, doesIdentityRequireRefresh);
  }
  if (!credentialsProvider) {
    if (config.credentialDefaultProvider) {
      credentialsProvider = normalizeProvider(config.credentialDefaultProvider(Object.assign({}, config, {
        parentClientConfig: config
      })));
    } else {
      credentialsProvider = async () => {
        throw new Error("`credentials` is missing");
      };
    }
  }
  const boundCredentialsProvider = async () => credentialsProvider({
    callerClientConfig: config
  });
  const {
    signingEscapePath = true,
    systemClockOffset = config.systemClockOffset || 0,
    sha256
  } = config;
  let signer;
  if (config.signer) {
    signer = normalizeProvider(config.signer);
  } else if (config.regionInfoProvider) {
    signer = () => normalizeProvider(config.region)().then(async region => [(await config.regionInfoProvider(region, {
      useFipsEndpoint: await config.useFipsEndpoint(),
      useDualstackEndpoint: await config.useDualstackEndpoint()
    })) || {}, region]).then(([regionInfo, region]) => {
      const {
        signingRegion,
        signingService
      } = regionInfo;
      config.signingRegion = config.signingRegion || signingRegion || region;
      config.signingName = config.signingName || signingService || config.serviceId;
      const params = {
        ...config,
        credentials: boundCredentialsProvider,
        region: config.signingRegion,
        service: config.signingName,
        sha256,
        uriEscapePath: signingEscapePath
      };
      const SignerCtor = config.signerConstructor || SignatureV4;
      return new SignerCtor(params);
    });
  } else {
    signer = async authScheme => {
      authScheme = Object.assign({}, {
        name: "sigv4",
        signingName: config.signingName || config.defaultSigningName,
        signingRegion: await normalizeProvider(config.region)(),
        properties: {}
      }, authScheme);
      const signingRegion = authScheme.signingRegion;
      const signingService = authScheme.signingName;
      config.signingRegion = config.signingRegion || signingRegion;
      config.signingName = config.signingName || signingService || config.serviceId;
      const params = {
        ...config,
        credentials: boundCredentialsProvider,
        region: config.signingRegion,
        service: config.signingName,
        sha256,
        uriEscapePath: signingEscapePath
      };
      const SignerCtor = config.signerConstructor || SignatureV4;
      return new SignerCtor(params);
    };
  }
  return {
    ...config,
    systemClockOffset,
    signingEscapePath,
    credentials: isUserSupplied ? async () => boundCredentialsProvider().then(creds => setCredentialFeature(creds, "CREDENTIALS_CODE", "e")) : boundCredentialsProvider,
    signer
  };
};
export const resolveAWSSDKSigV4Config = resolveAwsSdkSigV4Config;