import { resolveAwsSdkSigV4Config } from "@aws-sdk/core";
import { getSmithyContext, normalizeProvider } from "@smithy/util-middleware";
export const defaultCognitoIdentityProviderHttpAuthSchemeParametersProvider = async (config, context, input) => {
  return {
    operation: getSmithyContext(context).operation,
    region: (await normalizeProvider(config.region)()) || (() => {
      throw new Error("expected `region` to be configured for `aws.auth#sigv4`");
    })()
  };
};
function createAwsAuthSigv4HttpAuthOption(authParameters) {
  return {
    schemeId: "aws.auth#sigv4",
    signingProperties: {
      name: "cognito-idp",
      region: authParameters.region
    },
    propertiesExtractor: (config, context) => ({
      signingProperties: {
        config,
        context
      }
    })
  };
}
function createSmithyApiNoAuthHttpAuthOption(authParameters) {
  return {
    schemeId: "smithy.api#noAuth"
  };
}
export const defaultCognitoIdentityProviderHttpAuthSchemeProvider = authParameters => {
  const options = [];
  switch (authParameters.operation) {
    case "AssociateSoftwareToken":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "ChangePassword":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "CompleteWebAuthnRegistration":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "ConfirmDevice":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "ConfirmForgotPassword":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "ConfirmSignUp":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "DeleteUser":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "DeleteUserAttributes":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "DeleteWebAuthnCredential":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "ForgetDevice":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "ForgotPassword":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "GetDevice":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "GetUser":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "GetUserAttributeVerificationCode":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "GetUserAuthFactors":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "GlobalSignOut":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "InitiateAuth":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "ListDevices":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "ListWebAuthnCredentials":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "ResendConfirmationCode":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "RespondToAuthChallenge":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "RevokeToken":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "SetUserMFAPreference":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "SetUserSettings":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "SignUp":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "StartWebAuthnRegistration":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "UpdateAuthEventFeedback":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "UpdateDeviceStatus":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "UpdateUserAttributes":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "VerifySoftwareToken":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    case "VerifyUserAttribute":
      {
        options.push(createSmithyApiNoAuthHttpAuthOption(authParameters));
        break;
      }
    default:
      {
        options.push(createAwsAuthSigv4HttpAuthOption(authParameters));
      }
  }
  return options;
};
export const resolveHttpAuthSchemeConfig = config => {
  const config_0 = resolveAwsSdkSigV4Config(config);
  return {
    ...config_0
  };
};