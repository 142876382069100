import {Component, Input, OnChanges, OnInit, Signal, SimpleChanges} from '@angular/core';
import {SideNavService} from '../../services/side-nav.service';
import {environment} from '../../../environments/environment';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {OrgShortenerPipe} from '../../pipes/org-shortener-pipe';
import {AsyncPipe, CommonModule, SlicePipe} from '@angular/common';
import {RouterModule} from '@angular/router';


export type BreadCrumbs = BreadCrumb[]

@Component({
    selector: 'app-logo-area',
    templateUrl: './logo-area.component.html',
    styleUrls: ['./logo-area.component.scss'],
  imports: [OrgShortenerPipe, SlicePipe, AsyncPipe, RouterModule, CommonModule]
})
export class LogoAreaComponent {

  @Input()
  breadcrumbs: BreadCrumbs;

  isSmall: Observable<boolean>;

  env = environment.graphql.split('.')[1].replace('content-', '').replace('content', 'production');

  constructor(public sidenav: SideNavService, public breakpointObserver: BreakpointObserver) {
     this.isSmall = this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).pipe(map(a => a.matches))
  }


}

export class BreadCrumb {
  commands: any[] | string | null | undefined;
  display: string
}
