import {Component, computed, effect, Input, OnInit, signal, Signal} from '@angular/core';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {BehaviorSubject, combineLatest, Observable, of, switchMap} from 'rxjs';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';
import {Airline, Po, Query} from '../../types-workflow';
import {catchError, debounceTime, filter, map, shareReplay, startWith, tap} from 'rxjs/operators';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {FILTER_DEBOUNCE_TIME, FilterSectionComponent} from '../filter-section/filter-section.component';
import {BreadCrumb, LogoAreaComponent} from '../logo-area/logo-area.component';
import {Organization} from '../../types';
import {HeadlineSectionComponent} from '../headline-section/headline-section.component';
import {AsyncPipe, CommonModule, DatePipe} from '@angular/common';
import {OrgShortenerPipe} from '../../pipes/org-shortener-pipe';
import {ContextService} from '../../services/context.service';
import {toObservable, toSignal} from '@angular/core/rxjs-interop';
import {LIST_POS} from '../../queries';
import {clpp} from '@castlabs/prestoplay';
import error = clpp.log.error;



class UIPo {
  airline: string;
  po_name: string;
  date_created?: string;
  date_due?: string;
  target_system?: string;
}


@Component({
  selector: 'app-list-pos',
  templateUrl: './list-pos.component.html',
  styleUrls: ['./list-pos.component.css'],
  imports: [
    HeadlineSectionComponent,
    LogoAreaComponent,
    FilterSectionComponent,
    ReactiveFormsModule,
    RouterModule,
    OrgShortenerPipe,
    DatePipe,
    CommonModule
  ]
})
export class ListPosComponent {
  poprefix$: Observable<string>;

  organization: Signal<Organization>;
  airlines: Signal<Airline[]>;
  airline: Signal<Airline>;


  pos$: Signal<UIPo[]>;
  allpos$: Signal<UIPo[]>;
  loading = signal(true);
  breadCrumbs: Signal<BreadCrumb[]>;

  filters: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private apollo: Apollo,
    contextService: ContextService,
    fb: FormBuilder) {
    this.filters = fb.group({
      t: new FormControl('')
    })
    this.organization = contextService.organization
    this.airline = contextService.airline
    this.airlines = contextService.airlines
    this.breadCrumbs = computed(() => [{
        display: 'Content Portal',
        commands: ['/o', this.organization()?.id],
      },
        {
          display: 'Workflows',
          commands: ['/o', this.organization()?.id, 'workflows'],
        },
        {
          display: this.airline()?.airline_name,
          commands: ['/o', this.organization()?.id, 'workflows', this.airline()?.iata_code],
        }]
    );
    this.poprefix$ = route.params.pipe(map(p => p['poprefix'] || ''));

    this.allpos$ = toSignal(combineLatest([toObservable(this.airline), toObservable(this.organization), this.poprefix$]).pipe(
      switchMap(([airline, organization, poprefix]) => {
        if (!airline || !organization) {
          this.loading.set(false)
          return of([]); // Emit an empty array if either is missing
        }
        this.loading.set(true);
        return this.apollo.use('workflow').query<Query>({
          query: LIST_POS(organization.id),
          errorPolicy: 'ignore',
          fetchPolicy: 'network-only',
          variables: {airline: airline.iata_code}
        }).pipe(
          map(d => {
            if (!d.data) {
              return [];
            }
            const pos: UIPo[] = [];
            console.log(poprefix)
            for (const po of d.data.list_POs.pos) {
              if (!poprefix || po.po_name.startsWith(poprefix)) {
                pos.push(po);
              }
            }
            return pos;
          }),
          map(pos => {
            const uiPos: UIPo[] = [];
            for (const po of pos) {
              if (po.po_name.includes('/') && !poprefix) {
                const [prefix] = po.po_name.split('/');
                const existingPo = uiPos.find(_po => _po.po_name === `${prefix}/`);

                if (existingPo) {
                  const targetSystems = existingPo.target_system ? existingPo.target_system.split(',') : [];
                  if (!targetSystems.includes(po.target_system)) {
                    targetSystems.push(po.target_system);
                    existingPo.target_system = targetSystems.join(',');
                  }
                } else {
                  uiPos.push({
                    airline: po.airline,
                    po_name: `${prefix}/`,
                    date_due: null,
                    date_created: po.date_created,
                    target_system: po.target_system
                  });
                }
              } else {
                uiPos.push({
                  airline: po.airline,
                  po_name: po.po_name,
                  date_due: po.date_due,
                  date_created: po.date_created,
                  target_system: po.target_system
                });
              }
            }

            uiPos.sort((a, b) => (a.date_created < b.date_created ? 1 : a.date_created > b.date_created ? -1 : 0));
            this.loading.set(false);
            return uiPos;
          })
        )
      })
    ))


    const filterSignal = toSignal(
      this.filters.valueChanges.pipe(debounceTime(FILTER_DEBOUNCE_TIME), startWith(this.filters.value))
    );
    this.pos$ = computed(() => {
        const allpos = this.allpos$();
        if (allpos) {
          return this.allpos$().filter(po => {
            return po.po_name.toLowerCase().includes((filterSignal()['t'] || '').toLowerCase()) ||
              po.target_system.toLowerCase().includes((filterSignal()['t'] || '').toLowerCase())
          })
        } else {
          return []
        }
      }
    )
  }

}
