import {Directive, ElementRef, forwardRef, HostListener} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Directive({
    selector: '[commaSeparated]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CommaSeparatedInputDirective),
            multi: true,
        },
    ]
})
export class CommaSeparatedInputDirective {
  private onChange: (value: any) => void;
  private onTouched: () => void;


  constructor(private el: ElementRef) {

  }
  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    this.onChange(value.trim().split(',').map(s => s.trim()).filter(s => s !== '').map(s => Number.parseInt(s, 10)));
  }

  @HostListener('blur')
  onBlur() {
    this.onTouched();
  }

  writeValue(value: any): void {
    if (value) {
      value = Array.isArray(value) ? value.join(', ') : value;
      this.el.nativeElement.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

}
