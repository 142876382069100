import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {ca_payload_sizes, densities, sd_payload_sizes, strengths} from '../constants';
import {CommonModule} from '@angular/common';

function generateRandomBase64(lengthInBits) {
  const numBytes = lengthInBits / 8; // Konvertieren von Bits in Bytes
  const randomBytes = new Uint8Array(numBytes);
  window.crypto.getRandomValues(randomBytes); // Zufällige Bytes erzeugen
  const base64String = btoa(String.fromCharCode.apply(null, randomBytes)); // In Base64 umwandeln
  return base64String;
}

@Component({
    selector: 'app-vod-default-format-specific',
    templateUrl: './vod-default-format-specific.component.html',
    styleUrls: ['./vod-default-format-specific.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => VodDefaultFormatSpecificComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: VodDefaultFormatSpecificComponent,
            multi: true
        }
    ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
  ]
})
export class VodDefaultFormatSpecificComponent implements OnInit, ControlValueAccessor {
  static DEFAULTS = {
    watermark: null,
    encrypt: true,
    thumbnail: true,
    sd_strength: strengths[3].id,
    sd_superpixel_density: 50,
    sd_pixel_density: 50,
    sd_payload_size: sd_payload_sizes[1].id,
    sd_wm_id: Math.floor(Math.random() * (sd_payload_sizes[1].max + 1)),
    ab_shared_session_key: generateRandomBase64(512),
    ab_payload_size: ca_payload_sizes[2].id,
  };

  @Input()
  parentForm: FormGroup<any>;

  form: FormGroup;
  strengths = strengths;
  densities = densities;
  sd_payload_sizes = sd_payload_sizes;
  ca_payload_sizes = ca_payload_sizes;

  protected readonly environment = environment;

  changed: any = () => {
  };
  touched: any = () => {
  };

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      watermark: null,
      encrypt: true,
      thumbnail: true,
      sd_strength: strengths[3].id,
      sd_superpixel_density: 50,
      sd_pixel_density: 50,
      sd_payload_size: sd_payload_sizes[1].id,
      ab_payload_size: ca_payload_sizes[2].id,
      ab_shared_session_key: [
        generateRandomBase64(512),
        Validators.pattern(/^[A-Za-z0-9+/]{86}={0,2}$/)
      ],
      sd_wm_id: [
        Math.floor(Math.random() * (sd_payload_sizes[1].max + 1)),
        Validators.compose([Validators.min(0), Validators.max(sd_payload_sizes[1].max), Validators.required])]
    })
    this.form.valueChanges.subscribe((val) => {
      this.touched();
      this.changed(val)
      if (this.form.get('watermark').value === 'stardust/sf') {
        this.form.controls['sd_wm_id'].clearValidators()
        const nuMax = sd_payload_sizes.find(pls => pls.id === this.form.controls['sd_payload_size'].value).max
        this.form.controls['sd_wm_id'].setValidators([Validators.min(0), Validators.max(nuMax), Validators.required])
      }
      this.form.controls['sd_wm_id'].updateValueAndValidity({emitEvent: false})
    });
  }

  ngOnInit(): void {
  }

  writeValue(value: any): void {
    if (value) {
      this.form.patchValue(value, {emitEvent: false});
    }
  }

  registerOnChange(fn: any): void {
    this.changed = fn;
  }

  registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  setDisabledState ?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  validate({value}: FormControl) {
    const isNotValid = !this.form.valid
    return isNotValid && {
      invalid: true
    }
  }


}
