import {Pipe, PipeTransform} from '@angular/core';
import {PoItem} from '../types-workflow';
import {environment} from '../../environments/environment';

@Pipe({
    name: 'vodDemoPlayer'
})
export class VodDemoPlayerPipe implements PipeTransform {

  transform(value: PoItem, drmtoday_merchant: string): unknown {

      const template = {
        'source': {
          'url': 'https://vod-sannies.cp.castlabs.com/1o1media/VOD-1o1media_2023-08-15/tearsofsteel/dash.mpd',
          'type': 'application/dash+xml',
          'drmProtected': true
        },
        'autoplay': true,
        'manifest': {},
        'abr': {
          'restrictions': {}
        },
        'drm': {
          'env': 'DRMtoday',
          'customData': {
            'userId': 'rental1',
            'sessionId': '',
            'assetId': 'tearsofsteel',
            'merchant': '',
            'fairPlayContentIdStrategy': 'full-skd',
            'headers': {},
            'deviceId': '',
            'httpHeaderNames': {
              'DeviceId': 'DeviceId',
              'Authenticator': 'Authenticator'
            }
          },
          'emeApi': ''
        },
        'restrictions': {},
        'textstyle': {
          'fontFamily': '\'Roboto\', sans-serif',
          'fontColor': 'white',
          'backgroundColor': 'rgba(0, 0, 0, 0.75)'
        }
      }

      template.drm.customData.merchant = drmtoday_merchant;
      template.drm.customData.assetId = value.po_item_id + '_' + value.workflow_process.id;
      template.source.url = `https://vod${environment.env_name === 'prod' ? '' : '-' + environment.env_name}.cp.castlabs.com/${value.customer}/${value.po.po_name}/${value.po_item_id}/dash.mpd`
      // console.log(`https://demo.castlabs.com/#/player/config?cfg=${encodeURIComponent(btoa(JSON.stringify(template)))}`)
      return `https://demo.castlabs.com/#/player/config?cfg=${encodeURIComponent(btoa(JSON.stringify(template)))}`

  }

}

