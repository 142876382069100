import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TooltipDirective} from '../../../tooltip.directive';


@Component({
    selector: 'app-asset-icon',
    templateUrl: './asset-icon.component.html',
    styleUrls: ['./asset-icon.component.scss'],
  imports: [
    CommonModule, TooltipDirective
  ]
})
export class AssetIconComponent implements OnInit {

  @Input()
  thing: any;

  constructor() { }

  ngOnInit(): void {
  }

}
