export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * The AWSDateTime scalar type provided by AWS AppSync, represents a valid extended ISO 8601 DateTime string. In other
   * words, this scalar type accepts datetime strings of the form YYYY-MM-DDThh:mm:ss.SSSZ. The scalar can also accept
   * "negative years" of the form -YYYY which correspond to years before 0000. For example, "-2017-01-01T00:00Z" and
   * "-9999-01-01T00:00Z" are both valid datetime strings. The field after the two digit seconds field is a nanoseconds f
   * ield. It can accept between 1 and 9 digits. So, for example, "1970-01-01T12:00:00.2Z", "1970-01-01T12:00:00.277Z" and
   * "1970-01-01T12:00:00.123456789Z" are all valid datetime strings. The seconds and nanoseconds fields are optional
   * (the seconds field must be specified if the nanoseconds field is to be used). The time zone offset is compulsory for
   * this scalar. The time zone offset must either be Z (representing the UTC time zone) or be in the format ±hh:mm:ss.
   * The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard.
   */
  AWSDateTime: { input: any; output: any; }
  AWSEmail: { input: any; output: any; }
  /**
   * The AWSJSON scalar type provided by AWS AppSync, represents a JSON string that complies with RFC 8259. Maps like
   * "{\"upvotes\": 10}", lists like "[1,2,3]", and scalar values like "\"AWSJSON example string\"", "1", and "true"
   * are accepted as valid JSON and will automatically be parsed and loaded in the resolver mapping templates as Maps,
   * Lists, or Scalar values rather than as the literal input strings. Invalid JSON strings like "{a: 1}", "{'a': 1}"
   * and "Unquoted string" will throw GraphQL validation errors.
   */
  AWSJSON: { input: any; output: any; }
  AWSPhone: { input: any; output: any; }
  /**
   * The AWSURL scalar type provided by AWS AppSync, represents a valid URL string (Ex: https://www.amazon.com/). The URL
   * may use any scheme and may also be a local URL (Ex: http://localhost/). URLs without schemes like "amazon.com" or
   * "www.amazon.com" are considered invalid. URLs which contain double slashes (two consecutive forward slashes) in
   * their path are also considered invalid.
   */
  AWSURL: { input: any; output: any; }
};

/**
 * Representation of an airline with its code, name, and possessive form.
 * Even though the term airline is used, it also represents content recipients other than airlines.
 */
export type Airline = {
  __typename?: 'Airline';
  /** Name of the airline as displayed in the user interface. */
  airline_name?: Maybe<Scalars['String']['output']>;
  /** Possessive form of the airline name, such as "Austrian Airlines'" or "Aeroflot's". */
  airline_possessive?: Maybe<Scalars['String']['output']>;
  /** Unique identifier for the airline  / content recipient, such as the IATA 2-letter code. */
  iata_code?: Maybe<Scalars['String']['output']>;
  /** DRMtoday organization used for previewing content. */
  preview_organization?: Maybe<Scalars['String']['output']>;
  /** DRMtoday organization used for controlling access to the production content. */
  prod_organization?: Maybe<Scalars['String']['output']>;
};

/** A list of airlines visible to the requesting user. */
export type AirlinesPayload = {
  __typename?: 'AirlinesPayload';
  /** list of airlines */
  airlines: Array<Airline>;
};

export enum CodecType {
  /** Audio track. */
  Audio = 'audio',
  /** Closed caption track. */
  Closedcaption = 'closedcaption',
  /** Image track/file. */
  Image = 'image',
  /** Subtitle track. */
  Subtitle = 'subtitle',
  /** Video track. */
  Video = 'video'
}

export type DownloadPayload = {
  __typename?: 'DownloadPayload';
  /** A short-lived HTTPS link to download an `Encode` */
  link?: Maybe<Scalars['String']['output']>;
};

/** Represents a file produced as part of a workflow result. */
export type Encode = {
  __typename?: 'Encode';
  /** Unique identifier for the file, in the format of s3://bucket/path/filename.mp4. */
  id?: Maybe<Scalars['ID']['output']>;
  /** Last modification date of the file in ISO 8601 date string format. */
  last_modified?: Maybe<Scalars['String']['output']>;
  /** Human-readable name of the file, typically the last part of the file's ID. */
  name?: Maybe<Scalars['String']['output']>;
  /** Size of the file in bytes. */
  size?: Maybe<Scalars['Float']['output']>;
};

export type FilterAwsDateTimeInput = {
  between?: InputMaybe<Array<Scalars['AWSDateTime']['input']>>;
  gt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  lt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

/** Used to filter items based on the value of a string property. */
export type FilterStringInput = {
  /** The result set will only include items whose property value matches the filter value. */
  eq?: InputMaybe<Scalars['String']['input']>;
};

export type ListPOsInput = {
  filter?: InputMaybe<PoFilter>;
};

export type Mutation = {
  __typename?: 'Mutation';
  deleteEncodesOfPoItem?: Maybe<PoItem>;
  /** Publish given PO items and finalize PO. */
  publish: WorkflowProcessPayload;
  /** @deprecated Field no longer supported */
  publishPo: WorkflowProcessPayload;
  /** Publish a single PO item. */
  publishPoItem: WorkflowProcessPayload;
  /** Registers a webhook to the process. The webhook will be called when the process reaches a terminal state. */
  registerWebhook: WorkflowProcessPayload;
  /** Restart a PO item that has failed or needs to be re-run for other reasons. */
  restartPoItem: WorkflowProcessPayload;
  start_workflow_above_default: WorkflowProcessPayload;
  start_workflow_aerq_default: WorkflowProcessPayload;
  /** Start a default content delivery workflow from an external ID. */
  start_workflow_default: WorkflowProcessPayload;
  start_workflow_lsy_order: WorkflowProcessPayload;
  /** Start a VOD workflow that emits a number if individualized, watermarked streams. */
  start_workflow_vod_batch: WorkflowProcessPayload;
  /** Start a VOD workflow that emits a single stream. */
  start_workflow_vod_default: WorkflowProcessPayload;
};


export type MutationDeleteEncodesOfPoItemArgs = {
  input: PoItemInput;
};


export type MutationPublishArgs = {
  input?: InputMaybe<PoInput>;
};


export type MutationPublishPoArgs = {
  input: PoItemInput;
};


export type MutationPublishPoItemArgs = {
  input: PoItemInput;
};


export type MutationRegisterWebhookArgs = {
  input: RegisterWebhookInput;
};


export type MutationRestartPoItemArgs = {
  input: PoItemInput;
};


export type MutationStart_Workflow_Above_DefaultArgs = {
  input: StartWorkflowAboveDefaultInput;
};


export type MutationStart_Workflow_Aerq_DefaultArgs = {
  input: StartWorkflowAerqDefaultInput;
};


export type MutationStart_Workflow_DefaultArgs = {
  input: StartWorkflowDefaultInput;
};


export type MutationStart_Workflow_Lsy_OrderArgs = {
  input: StartWorkflowLsyInput;
};


export type MutationStart_Workflow_Vod_BatchArgs = {
  input: StartWorkflowVodInput;
};


export type MutationStart_Workflow_Vod_DefaultArgs = {
  input: StartWorkflowVodInput;
};

export type Po = {
  __typename?: 'PO';
  airline: Scalars['String']['output'];
  date_created?: Maybe<Scalars['AWSDateTime']['output']>;
  date_due?: Maybe<Scalars['String']['output']>;
  finalized_state?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  po_name: Scalars['ID']['output'];
  poitems: Array<PoItem>;
  target_system?: Maybe<Scalars['String']['output']>;
};


export type PoPoitemsArgs = {
  po_item_id?: InputMaybe<Scalars['String']['input']>;
};

/** Input type for filtering Purchase Orders (POs) */
export type PoFilter = {
  /** Filter POs based on their airline name. */
  airline?: InputMaybe<FilterStringInput>;
  /** Filter POs based on their creation date. */
  date_created?: InputMaybe<FilterAwsDateTimeInput>;
  /** Filter POs based on their name. */
  po_name?: InputMaybe<FilterStringInput>;
};

export type PoInput = {
  /** name of the PO, consisting of the organization followed by an underscore and a custom name. */
  po_name: Scalars['String']['input'];
  /** An array of PO Item IDs that is to be published before the finalization. */
  publish_po_item_ids: Array<Scalars['String']['input']>;
  /** Whether or not to execute the workflow and format specific finalization of the PO. */
  skip_finalize: Scalars['Boolean']['input'];
};

/** A single item in a Purchase Order (PO). */
export type PoItem = {
  __typename?: 'POItem';
  /** Aspect ratio: 4:3, 16:9, or pass-through (default). */
  aspect_ratio?: Maybe<Scalars['String']['output']>;
  checkpoint_content_complete?: Maybe<Scalars['Boolean']['output']>;
  checkpoint_content_uploaded?: Maybe<Scalars['Boolean']['output']>;
  checkpoint_encodes_done?: Maybe<Scalars['Boolean']['output']>;
  checkpoint_metadata_available?: Maybe<Scalars['Boolean']['output']>;
  /** Content identifier of the source. */
  content_identifier: Scalars['String']['output'];
  /** Customer ID associated with the billing for this PO item. */
  customer?: Maybe<Scalars['String']['output']>;
  /** Array of files produced to fulfill the PO item. */
  encodes: Array<Encode>;
  /** Output filename used to start the workflow, with varying meanings depending on workflow and output format. */
  filename: Scalars['String']['output'];
  format_specific_data?: Maybe<Scalars['AWSJSON']['output']>;
  format_specific_data_json?: Maybe<Scalars['AWSJSON']['output']>;
  /** Unique identifier for the PO item, consisting of the po name and po_item_id. */
  id?: Maybe<Scalars['ID']['output']>;
  /** Internal: S3 bucket/prefix of the media source location. */
  input_brefix: Scalars['String']['output'];
  metadata_message?: Maybe<Scalars['String']['output']>;
  metadata_source?: Maybe<Scalars['String']['output']>;
  /** Internal: S3 bucket/prefix of the target location. */
  output_brefix: Scalars['String']['output'];
  /** The PO that this PO item belongs to. */
  po?: Maybe<Po>;
  /** Destination for final delivery of the PO files. */
  po_destination?: Maybe<Scalars['String']['output']>;
  /** Identifier for the PO item within the PO. */
  po_item_id: Scalars['String']['output'];
  /** Information about the encode's preview, if available. */
  preview?: Maybe<PoItemPreview>;
  /** Feedback about the process that published the output files. */
  publish_process?: Maybe<WorkflowProcessPayload>;
  /** Array of requested tracks, with the source property populated when a suitable source track is identified. */
  tracks: Array<Track>;
  /** @deprecated use vtk_jobs to cater for the possibility of multiple VTK jobs */
  vtk_job?: Maybe<VtkJob>;
  vtk_jobs: Array<VtkJob>;
  /** Identifier of the selected encoding template. */
  vtk_template?: Maybe<Scalars['String']['output']>;
  /** Indicator of whether to apply a forensic watermark according to APEX specification. */
  watermark?: Maybe<Scalars['Boolean']['output']>;
  /** Type of workflow: above_default, aerq_default. */
  workflow: Scalars['String']['output'];
  /** Feedback about the process that generated the output files. */
  workflow_process?: Maybe<WorkflowProcessPayload>;
  wrap_up_workflow?: Maybe<Scalars['String']['output']>;
};

export type PoItemInput = {
  /** Identifier for the PO item within the PO. */
  po_item_id: Scalars['String']['input'];
  /** Name of the PO, consisting of the organization followed by an underscore and a custom name. */
  po_name: Scalars['String']['input'];
};

export type PoItemPayload = {
  __typename?: 'POItemPayload';
  poitem?: Maybe<PoItem>;
};

/** Preview information for a Purchase Order (PO) item. */
export type PoItemPreview = {
  __typename?: 'POItemPreview';
  /** Session-specific token that must be included in all segment requests for generating a session-specific forensic watermark. */
  ab_token?: Maybe<Scalars['String']['output']>;
  /** Access token for authorization to access encrypted DASH or HLS files and manifests. */
  access_token?: Maybe<Scalars['String']['output']>;
  /** Last modified date of the DASH manifest. [Informational]. */
  dash_manifest_last_modified?: Maybe<Scalars['AWSDateTime']['output']>;
  /** URL to the Dynamic Adaptive Streaming over HTTP (DASH) manifest, if available. */
  dash_manifest_url?: Maybe<Scalars['AWSURL']['output']>;
  /** Short-lived DRMtoday upfront authentication token. */
  drmtoday_token?: Maybe<Scalars['String']['output']>;
  /** Last modified date of the HLS master playlist. [Informational]. */
  hls_manifest_last_modified?: Maybe<Scalars['AWSDateTime']['output']>;
  /** URL to the HTTP Live Streaming (HLS) master playlist, if available. */
  hls_manifest_url?: Maybe<Scalars['AWSURL']['output']>;
};

export type POsPayload = {
  __typename?: 'POsPayload';
  pos: Array<Po>;
};

/** Input type used to update a process subscription. */
export type ProcessInput = {
  /** Type of action being performed by the process. */
  action?: InputMaybe<Scalars['String']['input']>;
  /** Optional additional information related to the action performed by the process. Typically set only when the process is in a terminal state. */
  data?: InputMaybe<Scalars['String']['input']>;
  /** Unique identifier for the process. */
  id: Scalars['ID']['input'];
  /** Input that was used to starrt the process. */
  input?: InputMaybe<Scalars['String']['input']>;
  /** Progress message to provide updates on the process status to the client. */
  message?: InputMaybe<Scalars['String']['input']>;
  /** Current state of the process. */
  state: ProcessStateEnum;
};

export enum ProcessStateEnum {
  Aborted = 'ABORTED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Success = 'SUCCESS'
}

export type Query = {
  __typename?: 'Query';
  /** Retrieves a list of airlines suitable for building a user interface. */
  airlines?: Maybe<AirlinesPayload>;
  /** Retrieves a list of POs filtered by airline, name, and/or date. */
  list_POs: POsPayload;
  /** Retrieves the status information of a long-running process. */
  process?: Maybe<WorkflowProcessPayload>;
};


export type QueryList_POsArgs = {
  input: ListPOsInput;
};


export type QueryProcessArgs = {
  id: Scalars['ID']['input'];
};

/**
 * Input for registering a webhook to a process.
 *
 * When a process reaches a terminal state, the webhook will be called with a POST request containing the following JSON payload:
 *
 *     {
 *       "process_id": "process_id",
 *       "state": "state",
 *       "data": "data",
 *       "message": "message",
 *       "action": "action"
 *     }
 */
export type RegisterWebhookInput = {
  /** Unique identifier of the process to attach to. */
  process_id: Scalars['ID']['input'];
  /** URL to call when the process reaches a terminal state. */
  webhook_url: Scalars['AWSURL']['input'];
};

export type Source = {
  __typename?: 'Source';
  /** Type of the selected source track (audio, video, closed_caption, subtitle, or image). Depending on the workflow, closed captioning may be delivered instead of subtitles. */
  codec_type: Scalars['String']['output'];
  /** Index of the selected source track. */
  index: Scalars['Int']['output'];
  /** Object key of the selected source file, excluding the bucket. */
  key: Scalars['String']['output'];
  /** Language of the selected track. Depending on the workflow, similar languages may be delivered, such as fr-CA for fr, or es-419 for es-ES. */
  lang?: Maybe<Scalars['String']['output']>;
};

export type StartWorkflowAboveDefaultInput = {
  /** Aspect ratio: 4:3, 16:9, or pass-through (default). */
  aspect_ratio?: InputMaybe<Scalars['String']['input']>;
  /** Publishes the PO item automatically after successful completion of the workflow when set to true. */
  auto_publish?: InputMaybe<Scalars['Boolean']['input']>;
  /** List of email addresses to notify about success or failure of the workflow. */
  email_notification?: InputMaybe<Array<Scalars['AWSEmail']['input']>>;
  /** Output filename used to start the workflow, with varying meanings depending on the workflow and output format. */
  filename: Scalars['String']['input'];
  /** VTK template-specific data structure. */
  format_specific_data?: InputMaybe<Scalars['AWSJSON']['input']>;
  /** S3 bucket/prefix of the media source location. */
  input_brefix: Scalars['String']['input'];
  /** Destination for final delivery of the PO files. */
  po_destination?: InputMaybe<Scalars['String']['input']>;
  /** Identifier for the PO item within the PO. */
  po_item_id: Scalars['String']['input'];
  /** Name of the PO, consisting of the target organization followed by an underscore and a custom name. Please use a basic character set. */
  po_name: Scalars['String']['input'];
  /** Array of tracks to deliver. */
  tracks: Array<TrackInput>;
  /** Identifier of the encoding template to be used in this PO. */
  vtk_template: Scalars['String']['input'];
  /** Indicator of whether to apply a visual watermark according to APEX specification. */
  watermark: Scalars['Boolean']['input'];
};

export type StartWorkflowAerqDefaultInput = {
  aerq_airline: Scalars['String']['input'];
  aerq_project?: InputMaybe<Scalars['String']['input']>;
  aerq_version: Scalars['String']['input'];
  filename: Scalars['String']['input'];
  format_specific_data?: InputMaybe<Scalars['AWSJSON']['input']>;
  input_brefix: Scalars['String']['input'];
  po_destination?: InputMaybe<Scalars['String']['input']>;
  po_item_id: Scalars['String']['input'];
  po_name: Scalars['String']['input'];
  tracks: Array<TrackInput>;
  vtk_template: Scalars['String']['input'];
};

export type StartWorkflowDefaultInput = {
  /** Aspect ratio: 4:3, 16:9, or pass-through (default). */
  aspect_ratio?: InputMaybe<Scalars['String']['input']>;
  /** Publishes the PO item automatically after successful completion of the workflow when set to true. */
  auto_publish?: InputMaybe<Scalars['Boolean']['input']>;
  /** Identifier of the source content. This is the ID of the content that is to be used as the source for the workflow. */
  content_identifier: Scalars['String']['input'];
  /** List of email addresses to notify about success or failure of the workflow. */
  email_notification?: InputMaybe<Array<Scalars['AWSEmail']['input']>>;
  /** Output filename used to start the workflow, with varying meanings depending on the workflow and output format. */
  filename: Scalars['String']['input'];
  /** VTK template-specific data structure. */
  format_specific_data?: InputMaybe<Scalars['AWSJSON']['input']>;
  /** This is the URL of the metadata source that is to be used for the workflow. */
  metadata_source?: InputMaybe<Scalars['String']['input']>;
  /** Destination for final delivery of the PO files. */
  po_destination?: InputMaybe<Scalars['String']['input']>;
  /** Identifier for the PO item within the PO. */
  po_item_id: Scalars['String']['input'];
  /** Name of the PO, consisting of the airline's 2-letter IATA code followed by an underscore and a custom name. Please use a basic character set. */
  po_name: Scalars['String']['input'];
  /** Array of tracks to deliver. */
  tracks: Array<TrackInput>;
  /** Identifier of the encoding template to be used in this PO. */
  vtk_template: Scalars['String']['input'];
  /** Indicator of whether to apply a visual watermark according to APEX specification. */
  watermark: Scalars['Boolean']['input'];
  /** This is the URL of the metadata source that is to be used for the workflow. */
  wrap_up_workflow?: InputMaybe<Scalars['String']['input']>;
};

export type StartWorkflowLsyInput = {
  aspect_ratio?: InputMaybe<Scalars['String']['input']>;
  email_notification?: InputMaybe<Array<Scalars['AWSEmail']['input']>>;
  filename: Scalars['String']['input'];
  format_specific_data?: InputMaybe<Scalars['AWSJSON']['input']>;
  /** S3 bucket/prefix of the media source location. */
  input_brefix: Scalars['String']['input'];
  po_destination?: InputMaybe<Scalars['String']['input']>;
  po_item_id: Scalars['String']['input'];
  po_name: Scalars['String']['input'];
  tracks: Array<TrackInput>;
  vtk_template: Scalars['String']['input'];
  /** Indicator of whether to apply a watermark. */
  watermark: Scalars['Boolean']['input'];
};

/** Input for the VOD workflow. */
export type StartWorkflowVodInput = {
  /** Publishes the PO item automatically after successful completion of the workflow when set to true. */
  auto_publish: Scalars['Boolean']['input'];
  /** List of email addresses to notify about success or failure of the workflow. */
  email_notification?: InputMaybe<Array<Scalars['AWSEmail']['input']>>;
  /**
   * VTK template-specific data structure.
   * This JSON structure is specific to the VTK template used. The following keys are supported:
   *
   * when `"watermark":  "contentarmor/ab"` (vod-default workflow)
   *
   *     {
   *       "watermark":  "contentarmor/ab",
   *       "ab_shared_session_key": "[512 Bit base 64 encoded key]",
   *       "ab_payload_size": "4" | "8" |  "16" | "24" | "32",
   *     }
   *
   * when `"watermark":  "stardust/sf"` (vod-default & vod-batch workflow)
   *
   *
   *     {
   *       "watermark":  "stardust/sf",
   *       "sd_strength": "8",
   *       "sd_payload_size":  "8" | "13" |  "16" | "24" | "32",
   *       "sd_superpixel_density": [10 - 100],
   *       "sd_pixel_density": [10 -100],
   *       "sd_watermark":  1 <= x <= 2^sd_payload_size,  # for vod-default workflow
   *       "sd_watermarks":  [1 <= x <= 2^sd_payload_size, ..., 1 <= x <= 2^sd_payload_size], # for vod-batch workflow
   *     }
   *
   * when `"watermark": "contentarmor/embed"` (vod-batch workflow)
   *
   *     {
   *       "watermark": "contentarmor/embed",
   *       "ca_payload_size": "4" | "8" |  "16" | "24" | "32",
   *       "ca_watermarks": [1 <= x <= 2^sd_payload_size, ..., 1 <= x <= 2^sd_payload_size],
   *     }
   *
   *
   * General options
   *
   *     "encrypt": true | false,
   *
   * encrypt and register DRM keys with DRMtoday yes/no
   *
   *     "thumbnail": true | false,
   *
   * generate thumbnails yes/no
   */
  format_specific_data?: InputMaybe<Scalars['AWSJSON']['input']>;
  /** source bucket/prefix of the media source location. */
  input_brefix: Scalars['String']['input'];
  /** Destination for final delivery of the PO files. 'vod' is the only supported value at this time. */
  po_destination: Scalars['String']['input'];
  /** Identifier for the PO item within the PO. */
  po_item_id: Scalars['String']['input'];
  /** Name of the PO, prefixed with [your_org_name]-... . Please stick to a basic character set. */
  po_name: Scalars['String']['input'];
  /** VTK template to use for encode and encryption. 'cmaf-abr' is the only supported value at this time. */
  vtk_template: Scalars['String']['input'];
};

export type Track = {
  __typename?: 'Track';
  /** Type of the requested track. Either video, audio, subtitle, closedcaption, or image. */
  codec_type: Scalars['String']['output'];
  /** Language of the requested track. */
  lang?: Maybe<Scalars['String']['output']>;
  /** Messages generated for this specific track during workflow execution. */
  messages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Actual source file with some additional information as determined during workflow execution. */
  source?: Maybe<Source>;
};

export type TrackInput = {
  codec_type: CodecType;
  /** `filename` is required for and used only by image tracks. */
  filename?: InputMaybe<Scalars['String']['input']>;
  /** `lang` is required for audio/subtitle/closedcaption tracks. */
  lang?: InputMaybe<Scalars['String']['input']>;
};

export type VtkJob = {
  __typename?: 'VtkJob';
  /** Creation date of the VTK job in ISO 8601 date string format. */
  created?: Maybe<Scalars['AWSDateTime']['output']>;
  /** Unique identifier for the VTK job. */
  id?: Maybe<Scalars['String']['output']>;
  /** Job Bundles are used for grouping jobs together. All jobs in a PO will have the PO's name as the job bundle. */
  job_bundle?: Maybe<Scalars['String']['output']>;
  /** Modified date of the VTK job in ISO 8601 date string format. */
  modified?: Maybe<Scalars['AWSDateTime']['output']>;
  organization_id?: Maybe<Scalars['String']['output']>;
  status_text: Scalars['String']['output'];
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type WorkflowProcessPayload = {
  __typename?: 'WorkflowProcessPayload';
  /** The type of action being performed by the process. */
  action?: Maybe<Scalars['String']['output']>;
  /** Additional information related to the action performed by the process. This field is typically only set when the process is in a terminal state. */
  data?: Maybe<Scalars['String']['output']>;
  /** The date and time the process ended. */
  end_date?: Maybe<Scalars['AWSDateTime']['output']>;
  /** A unique identifier for the process. */
  id: Scalars['ID']['output'];
  /** Input that was used to starrt the process. */
  input?: Maybe<Scalars['String']['output']>;
  /** A message indicating the progress of the process. This can be used to provide updates on the process' status to the client. */
  message?: Maybe<Scalars['String']['output']>;
  /** The date and time the process was created. */
  start_date?: Maybe<Scalars['AWSDateTime']['output']>;
  /** The current state of the process. */
  state: ProcessStateEnum;
};
