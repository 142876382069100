import {Component, computed, effect, Signal} from '@angular/core';
import {RouterModule} from '@angular/router';
import {filter, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {Folder, Query} from '../../../types';
import {Apollo} from 'apollo-angular';
import {GET_ROOTS} from '../../../queries';
import {LogoAreaComponent} from '../../logo-area/logo-area.component';
import {HeadlineSectionComponent} from '../../headline-section/headline-section.component';
import {AsyncPipe, CommonModule} from '@angular/common';
import {FileFolderLinkPipe} from '../../file-folder-link.pipe';
import {ContextService} from '../../../services/context.service';
import {toObservable, toSignal} from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-home-component',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.scss'],
  imports: [
    LogoAreaComponent,
    HeadlineSectionComponent,
    RouterModule,
    FileFolderLinkPipe,
    CommonModule
  ]
})
export class HomeComponent {

  loading = true;
  roots: Signal<Folder[]>;

  constructor(private apollo: Apollo,
              private organizationService: ContextService) {
    const org = this.organizationService.organization
    this.roots = toSignal(toObservable(org).pipe(
      filter(_org => !!_org),
      tap((_org) => { this.loading = true; console.log('org: ' , _org) }),
      switchMap((_org) => this.apollo.query<Query>({query: GET_ROOTS(_org.id), variables: {orgId: _org.id}})),
      map(res => [...res.data.roots].sort((a, b) => a.name.localeCompare(b.name))),
      tap(() => this.loading = false)),
    )
    effect(() => {
      console.log('Ensuring data is accessed:', this.roots());
    });
  }
}
