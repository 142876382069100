import {computed, Injectable, Injector, Signal, signal} from '@angular/core';
import {Organization, Policy, Query as RepositoryQuery} from '../types';
import {Airline, Query as WorkflowQuery} from '../types-workflow';
import {ActivatedRouteSnapshot, NavigationEnd, NavigationSkipped, Router} from '@angular/router';
import {Apollo} from 'apollo-angular';
import {GET_AIRLINES, GET_POLICIES} from '../queries';
import {HttpHeaders} from '@angular/common/http';
import {distinctUntilChanged, filter, map, tap} from 'rxjs/operators';
import {combineLatest, switchMap} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContextService {
  public organizationId = signal<string | null>(null);
  public organization = signal<Organization | null>(null);
  public airlines = signal<Airline[]>([]);
  public airline = signal<Airline | null>(null);
  public policies = signal<Policy[]>(null);
  public licensedProducts = signal<string[]>(null);

  public isAllowed = (action: string, resource: string): Signal<boolean> => {
    return computed(() => {
      const policies = this.policies();

      return policies.some(policy =>
        policy.statements.some(statement => {
          if (!statement.actions.includes(action)) {
            return false;
          }

          if (resource === null) {
            return true;
          }

          const isResourceAllowed = statement.resources.some(statementResource =>
            this.fnMatch(statementResource, resource)
          );

          if (isResourceAllowed && statement.conditions && statement.conditions.length > 0) {
            console.log('Conditions not implemented yet');
          }

          return isResourceAllowed;
        })
      );
    });
  };


  public isAllowedAnywhere(action: string): Signal<boolean> {
    return computed(() => {
      const policies = this.policies();
      return policies.some(
        policy => policy.statements.some(statement => {
            return statement.actions.includes(action);
          }
        )
      )
    });
  }

  constructor(private injector: Injector, private router: Router) {
    console.log('ContextService constructor');
    router.events.pipe(
      filter(event => event instanceof NavigationEnd || event instanceof NavigationSkipped),
      map(() => this.router.routerState.snapshot),
      map((rss) => [
        this.extract(rss.root, 'organization_id'),
        this.extract(rss.root, 'airline')
      ]),
      distinctUntilChanged(([prevOrg, prevAirline], [curOrg, curAirline]) =>
        prevOrg === curOrg && prevAirline === curAirline
      ),
      tap(([organizationId, airlineId]) => console.log('ContextService', organizationId, airlineId) ),
      switchMap(([organizationId, airlineId]) => {
        this.organizationId.set(organizationId);
        console.log('organizationId, airlineId', organizationId, airlineId);
        const apollo = this.injector.get(Apollo);

        if (organizationId === null) {
          this.organization.set(null);
          this.policies.set(null);
          this.licensedProducts.set(null);
          this.airlines.set(null);
          return [];
        }

        return combineLatest([
          apollo.query<RepositoryQuery>({
            query: GET_POLICIES(organizationId),
            context: {
              headers: new HttpHeaders({
                'X-Castlabs-Organization': organizationId
              })
            }
          }),
          apollo.use('workflow').query<WorkflowQuery>({
            query: GET_AIRLINES(organizationId),
            context: {
              headers: new HttpHeaders({
                'X-Castlabs-Organization': organizationId
              })
            }
          })
        ]).pipe(
          map(([policiesRes, airlinesRes]) => {
            console.log('updating organization & airlines');
            this.organization.set(policiesRes.data.policies.organization);
            this.policies.set(policiesRes.data.policies.policies);
            this.licensedProducts.set(policiesRes.data.policies.licensedServices);
            this.airlines.set([...airlinesRes.data.airlines.airlines].sort((a, b) => a.airline_name.localeCompare(b.airline_name)));

            if (airlineId !== null) {
              const airline = airlinesRes.data.airlines.airlines.find(_airline => _airline.iata_code === airlineId);
              this.airline.set(airline || null);
            } else {
              this.airline.set(null);
            }
          })
        );
      })
    ).subscribe();
  }

  private extract(snapshot: ActivatedRouteSnapshot, param: string): string | null {
    // Walk the route tree to find `organization_id`
    let route: ActivatedRouteSnapshot | null = snapshot;
    while (route) {
      const orgId = route.paramMap.get(param);
      if (orgId) {
        return orgId; // Found it!
      }
      route = route.firstChild; // Move deeper in the route tree
    }
    return null; // Not found
  }


  fnMatch(pattern: string, str: string): boolean {
    // Escape special characters, then replace wildcards with RegEx equivalents
    const regexPattern = pattern
      .replace(/[.+^${}()|[\]\\]/g, '\\$&') // Escape special characters
      .replace(/\*/g, '.*') // Replace * with .*
      .replace(/\?/g, '.'); // Replace ? with .

    const regex = new RegExp(`^${regexPattern}$`);

    return regex.test(str);
  }
}
