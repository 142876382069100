import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';
import {PortalIdpService} from '../services/portal-idp.service';
import {environment} from '../../environments/environment';
import {CommonModule} from '@angular/common';
import {FooterComponent} from './footer/footer.component';
import {IsAllowedPipe} from '../pipes/has-action-pipe';

@Component({
  selector: 'login-page',
  templateUrl: 'login-page.component.html',
  styleUrls: ['login-page.component.scss'],
  imports: [CommonModule, FooterComponent]
})
export class LoginPageComponent {
  idp: string
  basehost = environment.production ? '.content.castlabs.com' : '.content-stag.castlabs.com';

  constructor(public oAuthService: OAuthService, public router: Router, portalIdp: PortalIdpService) {
    this.idp = portalIdp.getIdpName()
  }

  login() {
    this.oAuthService.initLoginFlow(this.router.url, {});
  }

  protected readonly window = window;
  protected readonly location = location;
}
