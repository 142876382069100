import { SENSITIVE_STRING } from "@smithy/smithy-client";
import { CognitoIdentityProviderServiceException as __BaseException } from "./CognitoIdentityProviderServiceException";
import { AttributeTypeFilterSensitiveLog, AuthenticationResultTypeFilterSensitiveLog, RiskConfigurationTypeFilterSensitiveLog, UICustomizationTypeFilterSensitiveLog, UserPoolClientTypeFilterSensitiveLog, UserTypeFilterSensitiveLog } from "./models_0";
export class UnauthorizedException extends __BaseException {
  name = "UnauthorizedException";
  $fault = "client";
  constructor(opts) {
    super({
      name: "UnauthorizedException",
      $fault: "client",
      ...opts
    });
    Object.setPrototypeOf(this, UnauthorizedException.prototype);
  }
}
export class UnsupportedOperationException extends __BaseException {
  name = "UnsupportedOperationException";
  $fault = "client";
  constructor(opts) {
    super({
      name: "UnsupportedOperationException",
      $fault: "client",
      ...opts
    });
    Object.setPrototypeOf(this, UnsupportedOperationException.prototype);
  }
}
export class UnsupportedTokenTypeException extends __BaseException {
  name = "UnsupportedTokenTypeException";
  $fault = "client";
  constructor(opts) {
    super({
      name: "UnsupportedTokenTypeException",
      $fault: "client",
      ...opts
    });
    Object.setPrototypeOf(this, UnsupportedTokenTypeException.prototype);
  }
}
export class WebAuthnConfigurationMissingException extends __BaseException {
  name = "WebAuthnConfigurationMissingException";
  $fault = "client";
  constructor(opts) {
    super({
      name: "WebAuthnConfigurationMissingException",
      $fault: "client",
      ...opts
    });
    Object.setPrototypeOf(this, WebAuthnConfigurationMissingException.prototype);
  }
}
export class EnableSoftwareTokenMFAException extends __BaseException {
  name = "EnableSoftwareTokenMFAException";
  $fault = "client";
  constructor(opts) {
    super({
      name: "EnableSoftwareTokenMFAException",
      $fault: "client",
      ...opts
    });
    Object.setPrototypeOf(this, EnableSoftwareTokenMFAException.prototype);
  }
}
export const VerifySoftwareTokenResponseType = {
  ERROR: "ERROR",
  SUCCESS: "SUCCESS"
};
export const GlobalSignOutRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.AccessToken && {
    AccessToken: SENSITIVE_STRING
  })
});
export const InitiateAuthRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.AuthParameters && {
    AuthParameters: SENSITIVE_STRING
  }),
  ...(obj.ClientId && {
    ClientId: SENSITIVE_STRING
  }),
  ...(obj.UserContextData && {
    UserContextData: SENSITIVE_STRING
  }),
  ...(obj.Session && {
    Session: SENSITIVE_STRING
  })
});
export const InitiateAuthResponseFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.Session && {
    Session: SENSITIVE_STRING
  }),
  ...(obj.AuthenticationResult && {
    AuthenticationResult: AuthenticationResultTypeFilterSensitiveLog(obj.AuthenticationResult)
  })
});
export const ListDevicesRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.AccessToken && {
    AccessToken: SENSITIVE_STRING
  })
});
export const ListDevicesResponseFilterSensitiveLog = obj => ({
  ...obj
});
export const UserPoolClientDescriptionFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.ClientId && {
    ClientId: SENSITIVE_STRING
  })
});
export const ListUserPoolClientsResponseFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.UserPoolClients && {
    UserPoolClients: obj.UserPoolClients.map(item => UserPoolClientDescriptionFilterSensitiveLog(item))
  })
});
export const ListUsersResponseFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.Users && {
    Users: obj.Users.map(item => UserTypeFilterSensitiveLog(item))
  })
});
export const ListUsersInGroupResponseFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.Users && {
    Users: obj.Users.map(item => UserTypeFilterSensitiveLog(item))
  })
});
export const ListWebAuthnCredentialsRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.AccessToken && {
    AccessToken: SENSITIVE_STRING
  })
});
export const ResendConfirmationCodeRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.ClientId && {
    ClientId: SENSITIVE_STRING
  }),
  ...(obj.SecretHash && {
    SecretHash: SENSITIVE_STRING
  }),
  ...(obj.UserContextData && {
    UserContextData: SENSITIVE_STRING
  }),
  ...(obj.Username && {
    Username: SENSITIVE_STRING
  })
});
export const RespondToAuthChallengeRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.ClientId && {
    ClientId: SENSITIVE_STRING
  }),
  ...(obj.Session && {
    Session: SENSITIVE_STRING
  }),
  ...(obj.ChallengeResponses && {
    ChallengeResponses: SENSITIVE_STRING
  }),
  ...(obj.UserContextData && {
    UserContextData: SENSITIVE_STRING
  })
});
export const RespondToAuthChallengeResponseFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.Session && {
    Session: SENSITIVE_STRING
  }),
  ...(obj.AuthenticationResult && {
    AuthenticationResult: AuthenticationResultTypeFilterSensitiveLog(obj.AuthenticationResult)
  })
});
export const RevokeTokenRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.Token && {
    Token: SENSITIVE_STRING
  }),
  ...(obj.ClientId && {
    ClientId: SENSITIVE_STRING
  }),
  ...(obj.ClientSecret && {
    ClientSecret: SENSITIVE_STRING
  })
});
export const SetRiskConfigurationRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.ClientId && {
    ClientId: SENSITIVE_STRING
  })
});
export const SetRiskConfigurationResponseFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.RiskConfiguration && {
    RiskConfiguration: RiskConfigurationTypeFilterSensitiveLog(obj.RiskConfiguration)
  })
});
export const SetUICustomizationRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.ClientId && {
    ClientId: SENSITIVE_STRING
  })
});
export const SetUICustomizationResponseFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.UICustomization && {
    UICustomization: UICustomizationTypeFilterSensitiveLog(obj.UICustomization)
  })
});
export const SetUserMFAPreferenceRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.AccessToken && {
    AccessToken: SENSITIVE_STRING
  })
});
export const SetUserSettingsRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.AccessToken && {
    AccessToken: SENSITIVE_STRING
  })
});
export const SignUpRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.ClientId && {
    ClientId: SENSITIVE_STRING
  }),
  ...(obj.SecretHash && {
    SecretHash: SENSITIVE_STRING
  }),
  ...(obj.Username && {
    Username: SENSITIVE_STRING
  }),
  ...(obj.Password && {
    Password: SENSITIVE_STRING
  }),
  ...(obj.UserAttributes && {
    UserAttributes: obj.UserAttributes.map(item => AttributeTypeFilterSensitiveLog(item))
  }),
  ...(obj.ValidationData && {
    ValidationData: obj.ValidationData.map(item => AttributeTypeFilterSensitiveLog(item))
  }),
  ...(obj.UserContextData && {
    UserContextData: SENSITIVE_STRING
  })
});
export const SignUpResponseFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.Session && {
    Session: SENSITIVE_STRING
  })
});
export const StartWebAuthnRegistrationRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.AccessToken && {
    AccessToken: SENSITIVE_STRING
  })
});
export const UpdateAuthEventFeedbackRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.Username && {
    Username: SENSITIVE_STRING
  }),
  ...(obj.FeedbackToken && {
    FeedbackToken: SENSITIVE_STRING
  })
});
export const UpdateDeviceStatusRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.AccessToken && {
    AccessToken: SENSITIVE_STRING
  })
});
export const UpdateUserAttributesRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.UserAttributes && {
    UserAttributes: obj.UserAttributes.map(item => AttributeTypeFilterSensitiveLog(item))
  }),
  ...(obj.AccessToken && {
    AccessToken: SENSITIVE_STRING
  })
});
export const UpdateUserPoolClientRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.ClientId && {
    ClientId: SENSITIVE_STRING
  })
});
export const UpdateUserPoolClientResponseFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.UserPoolClient && {
    UserPoolClient: UserPoolClientTypeFilterSensitiveLog(obj.UserPoolClient)
  })
});
export const VerifySoftwareTokenRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.AccessToken && {
    AccessToken: SENSITIVE_STRING
  }),
  ...(obj.Session && {
    Session: SENSITIVE_STRING
  }),
  ...(obj.UserCode && {
    UserCode: SENSITIVE_STRING
  })
});
export const VerifySoftwareTokenResponseFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.Session && {
    Session: SENSITIVE_STRING
  })
});
export const VerifyUserAttributeRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.AccessToken && {
    AccessToken: SENSITIVE_STRING
  })
});