import {ChangeDetectorRef, Component, computed, effect, signal, Signal, WritableSignal} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {filter, map, shareReplay, skipWhile, tap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {ActivatedRoute, Router} from '@angular/router';
import {Folder, Organization, Query} from '../../../types';
import {FILE_FOLDER_LIST_WITH_ARCHIVE} from '../../../queries';
import {BannerService} from '../../../banner/banner.service';
import {BreadCrumb, BreadCrumbs, LogoAreaComponent} from '../../logo-area/logo-area.component';
import {ContextService} from '../../../services/context.service';
import {PoMetaService} from '../../../services/po-meta.service';
import {HeadlineSectionComponent} from '../../headline-section/headline-section.component';
import {AsyncPipe, CommonModule} from '@angular/common';
import {FolderListingComponent} from '../folder-listing/folder-listing.component';
import {FolderAssetViewComponent} from '../folder-asset-view/folder-asset-view.component';
import {ClTabsModule} from '../../cl-tabs/cl-tabs.module';
import {AboveDefaultPoComponent} from '../../submit-po-2024/above-default-po/above-default-po.component';
import {VodDefaultPoComponent} from '../../submit-po-2024/vod-default-po/vod-default-po.component';
import {VodWatermarkPoComponent} from '../../submit-po-2024/vod-watermark-po/vod-watermark-po.component';
import {IsAllowedPipe} from '../../../pipes/has-action-pipe';
import {Airline} from '../../../types-workflow';
import {Observable, switchMap} from 'rxjs';


@Component({
  selector: 'app-folder',
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.scss'],
  imports: [
    LogoAreaComponent,
    HeadlineSectionComponent,
    FolderListingComponent,
    CommonModule,
    FolderAssetViewComponent,
    ClTabsModule,
    AboveDefaultPoComponent,
    VodDefaultPoComponent,
    VodWatermarkPoComponent,
    IsAllowedPipe
  ]
})
export class FolderComponent {
  organization: Signal<Organization>
  airlines: Signal<Airline[]>
  allowPoSubmit: Signal<boolean>;
  folder: Signal<Folder>;
  breadcrumbs: Signal<BreadCrumbs>;
  assetpath: Signal<string>;
  view: WritableSignal<string> = signal<string>('listing');
  refreshing = signal<boolean>(false);

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public banner: BannerService,
    private apollo: Apollo,
    public poMetaService: PoMetaService,
    public organizationService: ContextService) {

    this.organization = this.organizationService.organization
    this.airlines = this.organizationService.airlines


    this.assetpath = toSignal(this.route.paramMap.pipe(
      map(pm => {
        return pm.get('assetpath') || '';
      }))
    )

    this.route.queryParamMap.pipe(
      map(params => params.get('view') || '')
    ).subscribe(view => this.view.set(view));

    this.allowPoSubmit = computed(() => {
      const a = this.organizationService.isAllowedAnywhere('contentplatform:workflow:Mutation/StartWorkflowDefault')();
      const b = this.organizationService.isAllowedAnywhere('contentplatform:workflow:Mutation/StartWorkflowVodDefault')();
      const c = this.organizationService.isAllowedAnywhere('contentplatform:workflow:Mutation/StartWorkflowVodBatch')();

      return !!a || !!b || !!c;
    });

    this.folder = toSignal(this.route.paramMap.pipe(
      map(pm => pm.get('assetpath') || ''),
      filter(brefix => !!brefix), // Ensure brefix is valid
      tap(() => this.refreshing.set(true)),
      switchMap(brefix =>
        this.apollo.watchQuery<Query>({
          query: FILE_FOLDER_LIST_WITH_ARCHIVE,
          variables: {id: `s3://${brefix}/`, show_deleted: true},
          nextFetchPolicy: 'cache-and-network'
        }).valueChanges.pipe(
          map(res => res.data.folder)
        )
      ),
      tap((folder) => {
        this.refreshing.set(false)
        if (folder && this.view() === '') {
          if (
            folder.folders.length === 0 &&
            folder.files.length < 100 &&
            folder.files.length > 0 &&
            folder.files.some(f => f.tracks && f.tracks.length > 0)
          ) {
            this.view.set('asset');
          } else {
            this.view.set('listing');
          }
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {view: this.view()},
            queryParamsHandling: 'merge',
            replaceUrl: true
          });
        }
      }),
      shareReplay(1) // Ensures no unnecessary re-subscriptions
    ));


    this.breadcrumbs = computed(() => {
      const folder = this.folder();
      const org = this.organization();

      if (!org || !folder || !folder.parents) {
        return [];
      }

      const crumbs: BreadCrumb[] = [
        {'commands': `/o/${org.id}/files`, 'display': 'Assets'} as BreadCrumb
      ];

      crumbs.push(...folder.parents.map(f => ({
        'commands': `/o/${org.id}/files/browse/${f.id.replace('s3://', '')}`,
        'display': f.name
      })));

      crumbs.push({
        'commands': `/o/${org.id}/files/browse/${folder.id.replace('s3://', '')}`,
        'display': folder.name
      });

      return crumbs;
    });

  }

}
