<app-logo-area [breadcrumbs]="[{'commands': undefined, 'display': 'Assets'}]"></app-logo-area>
<main>
  <app-headline-section [title]="'Assets'"></app-headline-section>
  <section class="cl-section-regular cl-form">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <table style="table-layout: fixed" class="cl-table-data">
            <colgroup>
              <col span="1" style="width: 40px">
              <col span="1" style="width: 100%">
            </colgroup>

            <thead>
            <tr>

              <th class="" style="margin-top: -4px; margin-bottom: -4px">
              </th>
              <th class="" style="margin-top: -4px; margin-bottom: -4px">
                Bucket Name
              </th>

            </tr>
            </thead>
            <tbody *ngIf="loading; else bodyWithData">
            <tr class="cl-tr-no-data" *ngIf="loading">
              <td colspan="100" style="text-align: center"> Loading Storage Roots...<i class="fas fa-spinner fa-spin"></i></td>
            </tr>
            </tbody>

            <ng-template #bodyWithData>
              <tbody>
              <tr *ngFor="let it of roots()">
                <td>
                  <div style="margin-top: -8px; margin-bottom: -12px;">
                    <i class="fas fa-hdd"></i>

                  </div>
                </td>
                <td>
                  <div style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                    <a [routerLink]="it | link"
                       (click)="$event.stopPropagation()">
                      {{ it.name }}
                    </a>
                  </div>
                </td>

              </tr>
              </tbody>
            </ng-template>
          </table>
        </div>
      </div>


    </div>
  </section>
</main>
