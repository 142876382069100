import {Component, computed, Input, OnInit, Signal} from '@angular/core';

import {BreadCrumb, LogoAreaComponent} from '../logo-area/logo-area.component';
import {Airline} from '../../types-workflow';
import {Organization} from '../../types';
import {HeadlineSectionComponent} from '../headline-section/headline-section.component';
import {AirlineSelectComponent} from '../airline-select/airline-select.component';
import {ContextService} from '../../services/context.service';

@Component({
  selector: 'app-workflows',
  templateUrl: './workflows.component.html',
  styleUrls: ['./workflows.component.scss'],
  providers: [],
  imports: [LogoAreaComponent, HeadlineSectionComponent, AirlineSelectComponent]
})
export class WorkflowsComponent {

  breadCrumbs: Signal<BreadCrumb[]>;

  constructor(private contextService: ContextService) {
    this.breadCrumbs = computed(() => [
        {display: 'Content Portal', commands: ['/o', this.contextService.organization()?.id]},
        {display: this.contextService.organization()?.name, commands: ['/o', this.contextService.organization()?.id]},
        {display: 'Workflows', commands: ['/o', this.contextService.organization()?.id, 'workflows']},
      ]
    )
  }
}
