import {Component, OnInit, Pipe, PipeTransform, signal, Signal, TemplateRef, TrackByFunction, ViewChild} from '@angular/core';
import {ActivatedRoute, RouterModule} from '@angular/router';
import {catchError, distinctUntilChanged, filter, map, mergeMap, shareReplay, tap} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, forkJoin, Observable, of, switchMap} from 'rxjs'
import {Apollo} from 'apollo-angular';
import {Airline, Po, PoItem, Query as WorkflowQuery} from '../../types-workflow';
import {CREATE_UPLOAD_TICKET, PO_ITEM_LIST_FULL} from '../../queries';
import {FinalizePoDialogComponent} from './finalize-po-dialog/finalize-po-dialog.component';
import {Mutation, Organization} from '../../types';

import {BreadCrumbs, LogoAreaComponent} from '../logo-area/logo-area.component';
import {Dialog} from '@angular/cdk/dialog';
import {SelectionModel} from '@angular/cdk/collections';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {ContextService} from '../../services/context.service';
import {IsAllowedPipe} from '../../pipes/has-action-pipe';
import {HeadlineSectionComponent} from '../headline-section/headline-section.component';
import {FilterSectionComponent} from '../filter-section/filter-section.component';
import {AsyncPipe, CommonModule} from '@angular/common';
import {PoItemLineComponent} from './po-item-line/po-item-line.component';
import {PoItemDetailsComponent} from './po-item-details/po-item-details.component';
import {toObservable} from '@angular/core/rxjs-interop';

interface Upload {
  url: string;
  text: string;
}


@Component({
  selector: 'app-list-po-items2',
  templateUrl: './list-po-items2.component.html',
  styleUrls: ['./list-po-items2.component.scss'],
  imports: [
    IsAllowedPipe,
    ReactiveFormsModule,
    HeadlineSectionComponent,
    LogoAreaComponent,
    FilterSectionComponent,
    RouterModule,
    AsyncPipe,
    PoItemLineComponent,
    PoItemDetailsComponent,
    CommonModule]
})
export class ListPoItems2Component implements OnInit {
  opened: SelectionModel<PoItem> = new SelectionModel<PoItem>(false, [], true, ((a, b) => a.po_item_id === b.po_item_id))


  airline: Signal<Airline>;
  organization: Signal<Organization>;

  @ViewChild('toolbaractions', {read: TemplateRef})
  public toolbaractions: TemplateRef<any>;

  filters: FormGroup = new FormGroup({
    t: new FormControl<string>(''),
    e: new FormControl<string>(null),
    p: new FormControl<string>(null),
  });


  menu = new BehaviorSubject<TemplateRef<any>>(null);
  uploads: Upload[] = [];
  iata_code$: Observable<string>;
  po_id$: Observable<string>;
  po_name: string;

  po$: Observable<Po>;
  poitems$: Observable<PoItem[]>;
  poitems_filtered$: Observable<PoItem[]>;
  loading = signal(true);

  breadCrumbs: Observable<BreadCrumbs>;
  hasMetadata$: Observable<boolean>;
  prefix$: Observable<string>

  constructor(private route: ActivatedRoute,
              private organizationService: ContextService,
              protected dialog: Dialog,
              private apollo: Apollo) {
    this.organization = this.organizationService.organization;
    this.airline = this.organizationService.airline;
    this.prefix$ = route.queryParamMap.pipe(
      map(params => params.get('prefix')),
    )


    this.po_id$ = this.route.params.pipe(
      map(params => params['po']),
      distinctUntilChanged(),
      tap(po_name => {
        this.po_name = po_name
      }),
      shareReplay()
    );
    this.iata_code$ = this.route.params.pipe(
      map(params => params['airline']),
      distinctUntilChanged()
    )
    const appSyncRes$ =
    combineLatest([this.po_id$, toObservable(this.organization).pipe(filter(o => !!o))]).pipe(
      tap(() => {
        console.log('something changed!');
        this.loading.set(true)
      }),
      switchMap(([po_name, org]) => {
        return this.apollo.use('workflow').watchQuery<WorkflowQuery>({
          query: PO_ITEM_LIST_FULL(org.id),
          nextFetchPolicy: 'cache-and-network',
          pollInterval: 10000,
          errorPolicy: 'ignore',
          variables: {
            'po_name': po_name
          }
        }).valueChanges
      }),
      tap(() => {
        console.log('something changed and I\'m done loading');
        this.loading.set(false)
      }),
      shareReplay()
    )

    this.po$ = appSyncRes$.pipe(
      map(wfq => wfq.data?.list_POs.pos[0])
    )

    this.poitems$ = appSyncRes$.pipe(
      map(wfq => {
        const data = wfq.data
        if (!data?.list_POs?.pos[0]) {
          return []
        }
        const p = [...data.list_POs.pos[0].poitems]
        p.sort((a, b) => {
          return a.po_item_id > b.po_item_id ? 1 : -1
        })
        return p
      }),
      tap(() => this.loading.set(false)),
    )
    this.hasMetadata$ = this.poitems$.pipe(
      map(poItems => poItems.some(poItem => poItem.checkpoint_metadata_available != null))
    );
    this.poitems_filtered$ = combineLatest([this.poitems$, this.filters.valueChanges]).pipe(
      map(([poitems, filters]) => poitems.filter(po => {
        return (
            po.po_item_id.toLowerCase().includes((filters['t'] || '').toLowerCase()) ||
            po.filename.toLowerCase().includes((filters['t'] || '').toLowerCase()))
          && ((filters['e'] || '') === '' || (filters['e'] || '') === po.workflow_process.state)
          && ((filters['p'] || '') === '' || (filters['p'] || '') === po.publish_process.state)
      }))
    )
  }

  poItemTrackBy: TrackByFunction<PoItem> = (index: number, poitem: PoItem) => {
    return poitem.id
  }

  genUploads() {
    this.poitems$.pipe(
      switchMap(poitems => {
        const uploadObservables = poitems.map(poitem => {

              if (this.organizationService.isAllowed(
                'contentplatform:repository:Mutation/CreateUploadTicket',
                `contentplatform:folder:input.folder_id/${poitem.input_brefix}`
              )()) {
                return this.apollo.mutate<Mutation>({
                  mutation: CREATE_UPLOAD_TICKET,
                  variables: {
                    folder_id: `s3://${poitem.input_brefix}`,
                    message: poitem.po_item_id
                  },
                  fetchPolicy: 'network-only'
                }).pipe(
                  map(d => ({
                    url: d.data.createUploadTicket.url,
                    text: poitem.po_item_id
                  }))
                );
              } else {
                return of({
                  url: null,
                  text: `You are not allowed to upload to ${poitem.input_brefix}`
                });
              }
            }
          )
        ;

        return forkJoin(uploadObservables);
      })
    ).subscribe(u => this.uploads = u);
  }

  ngOnInit() {

    this.breadCrumbs = this.po_id$.pipe(
      map(po => {
          return [
            {
              display: 'Content Portal',
              commands: ['/o', this.organization()?.id],
            },
            {
              display: 'Workflows',
              commands: ['/o', this.organization()?.id, 'workflows'],
            },
            {
              display: this.airline()?.airline_name,
              commands: ['/o', this.organization()?.id, 'workflows', this.airline()?.iata_code],
            },
            {
              display: po,
              commands: ['/o', this.organization()?.id, 'workflows', this.airline()?.iata_code, po],
            }
          ];
        }
      ));
  }

  openDialog(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }

  publish() {
    this.dialog.open(FinalizePoDialogComponent, {
      data: {po_name: this.po_name}
    })
  }

}
