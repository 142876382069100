import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-headline-section',
  templateUrl: './headline-section.component.html',
  styleUrls: ['./headline-section.component.scss'],
  imports: [CommonModule]
})
export class HeadlineSectionComponent implements OnInit {

  @Input()
  title: string

  @Input()
  menu: TemplateRef<any>;

  constructor() {
  }

  ngOnInit(): void {
  }

}
