import {Component, computed, effect, OnDestroy, OnInit, signal, Signal, TemplateRef} from '@angular/core';
import {File, Folder, Mutation, Organization, ProcessStateEnum, Query, Tag, Track, TrackType} from '../../../types';
import {Apollo, gql} from 'apollo-angular';
import {Constants} from '../../../globals';
import {distinctUntilChanged, filter, map, skipWhile, switchMap, take, tap} from 'rxjs/operators';
import {Observable, Subject, Subscription} from 'rxjs';
import {BannerService} from '../../../banner/banner.service';
import {FILE_DETAILS, GET_PARENTS, START_GENERATE_STILLS} from '../../../queries';
import {ActivatedRoute, RouterModule} from '@angular/router';
import {FileFolderLinkPipe} from '../../file-folder-link.pipe';
import {BreadCrumb, LogoAreaComponent} from '../../logo-area/logo-area.component';
import {Dialog} from '@angular/cdk/dialog';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryModule, NgxGalleryOptions} from '@vinlos/ngx-gallery';
import {ContextService} from '../../../services/context.service';
import {ProcessService} from '../../../services/process.service';
import {DoButtonComponent} from '../../do-button/do-button.component';
import {S3LinkComponent} from '../../s3-link/s3-link.component';
import {MessageFieldComponent} from '../../do-button/message-field.component';
import {AssetIconComponent} from '../file-icon/asset-icon.component';
import {HeadlineSectionComponent} from '../../headline-section/headline-section.component';
import {CommonModule, JsonPipe} from '@angular/common';
import {toSignal} from '@angular/core/rxjs-interop';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IsAllowedPipe} from '../../../pipes/has-action-pipe';


@Component({
  selector: 'app-file-details',
  templateUrl: './file-details.component.html',
  styleUrls: ['./file-details.component.scss'],
  imports: [
    S3LinkComponent,
    MessageFieldComponent,
    DoButtonComponent,
    AssetIconComponent,
    FileFolderLinkPipe,
    RouterModule,
    HeadlineSectionComponent,
    LogoAreaComponent,
    ReactiveFormsModule,
    FormsModule,
    IsAllowedPipe,
    JsonPipe,
    NgxGalleryModule,
    CommonModule
  ]
})
export class FileDetailsComponent {
  TRACKTYPES = TrackType
  loading = signal(true);

  file: Signal<File>;
  folder: Signal<Folder>;
  organization: Signal<Organization>
  breadcrumps: Signal<BreadCrumb[]>;

  languages = Constants.languages.slice();

  ffprobe: Array<any>;
  warnings: Array<any>;
  extra_info: any;
  mediainfo: string;
  mediainfo_json: Array<any>;
  tracks: Track[];
  tags: Tag[];

  glacier: boolean;
  stills: NgxGalleryImage[] = [];
  has_video: boolean;

  private assetPath$: Observable<string>;

  galleryOptions: NgxGalleryOptions[] = [
    {
      width: '100%',
      thumbnailsColumns: 6,
      height: '800px',
      imageAnimation: NgxGalleryAnimation.Fade
    },
    {
      breakpoint: 992,
      height: '600px',
    },
    {
      breakpoint: 768,
      height: '400px',
    }

  ];

  constructor(
    private apollo: Apollo,
    private route: ActivatedRoute,
    protected dialog: Dialog,
    private contextService: ContextService,
    private banner: BannerService,
    private processService: ProcessService
  ) {
    this.organization = contextService.organization
    this.assetPath$ = this.route.paramMap.pipe(
      map(pm => pm.get('assetpath') || ''),
      distinctUntilChanged(),
      tap(() => this.loading.set(true))
    )
    this.folder = toSignal(this.assetPath$.pipe(
      filter(assetPath => !!assetPath),
      switchMap(assetPath => this.apollo.watchQuery<Query>({
        query: GET_PARENTS,
        variables: {
          id: `s3://${assetPath.split('/').slice(0, -1).join('/')}/`
        }
      }).valueChanges.pipe(
        map(res => res.data.folder)
      ))
    ))
    this.breadcrumps = computed(() => {
      const fs = this.folder()
      return (fs && fs.parents.length > 1) ? fs.parents.map(f => {
        return {
          'commands': new FileFolderLinkPipe(this.contextService).transform(f),
          'display': f.name
        }
      }) : [];
    })

    this.file = toSignal(
      this.assetPath$.pipe(
        filter(assetPath => !!assetPath),
        switchMap(assetPath => this.apollo.watchQuery<Query>({
          query: FILE_DETAILS,
          variables: {
            id: `s3://${assetPath}`
          },
          errorPolicy: 'all',
          nextFetchPolicy: 'cache-only',
          useInitialLoading: false
        }).valueChanges.pipe(
          skipWhile((response) => response.data?.file === null),
          map(response => {
            const f = Object.assign({}, response.data.file);
            this.loading.set(false)
            if (response.errors) {
              this.banner.open('Error retrieving metadata. Data might not be complete.', ['Close'])
              for (const err of response.errors) {
                if (err.path[0] === 'file' && err.path[1] === 'tags') {
                  f.tags = []
                }
                if (err.path[0] === 'file' && err.path[1] === 'tracks') {
                  f.tracks = []
                }
                if (err.path[0] === 'file' && err.path[1] === 'mediainfo') {
                  f.mediainfo = ''
                }
                if (err.path[0] === 'file' && err.path[1] === 'mediainfo_json') {
                  f.mediainfo_json = [JSON.stringify({'message': 'no mediainfo data'})]
                }
                if (err.path[0] === 'file' && err.path[1] === 'ffprobe') {
                  f.ffprobe = [JSON.stringify({'message': 'no ffprobe data'})]
                }
              }
            }
            return f;
          })
        )))
    )

    effect(() => {
      const file = this.file();
      if (file.ffprobe !== null) {
        this.ffprobe = file.ffprobe.map(e => JSON.parse(e))
      } else {
        this.ffprobe = [{'message': 'no ffprobe data'}]
      }
      if (file.warnings !== null) {
        this.warnings = file.warnings.map(e => JSON.parse(e))
      } else {
        this.warnings = []
      }
      if (file.extra_info !== null) {
        this.extra_info = JSON.parse(file.extra_info)
      } else {
        this.extra_info = []
      }
      if (file.mediainfo_json !== null) {
        this.mediainfo_json = file.mediainfo_json.map(e => JSON.parse(e))
      } else {
        this.mediainfo_json = [{'message': 'no mediainfo data'}]
      }
      this.mediainfo = file.mediainfo
      this.has_video = file.tracks !== null ? file.tracks.filter(t => t.track_type === TrackType.Video).length > 0 : false;
      this.stills = file.stills.map((still, i) => {
        return {
          small: still.url,
          big: still.url,
          medium: still.url,
        }
      }).slice(0, 100)
      this.glacier = file.archived;
      if (file.tags !== null) {
        this.tags = file.tags;
        if (this.tags == null || this.tags.length === 0) {
          this.tags = []
        }
        this.tags = this.tags.map(t => {
          return {'key': t.key, 'value': t.value}
        });
      }
      if (file.tracks !== null) {
        for (const track of file.tracks) {
          if (this.languages.filter(a => a.id === track.language).length === 0) {
            this.languages = [].concat(...this.languages, {'id': track.language, 'name': track.language})
          }
        }

        this.tracks = file.tracks.map((v) => {
          return {
            'index': v.index,
            'enabled': v.enabled,
            'language': v.language,
            'track_type': v.track_type
          }
        });
      } else {
        this.tracks = []
      }

    })

  }

  refreshTracks(button: DoButtonComponent) {
    this.apollo.mutate<Mutation>({
      mutation: gql`mutation RefreshTracks($id: ID!) {
        refreshTracks(input: {file_id: $id, force: true}) {
          file {
            id
            tracks {
              index
              enabled
              language
              track_type
            }
          }
        }
      }
      `,
      variables: {
        id: this.file().id
      },
      fetchPolicy: 'network-only'
    }).subscribe({
      next: res => {
        button.done('Track data reset.')
      },
      error: err => {
        button.done('Reset failed: ' + err.message, {severity: 'cl-alert-danger'})
      }
    });
  }

  onAddTag(event: Event) {
    this.tags.push({
      key: '',
      value: ''
    })
  }

  save(button: DoButtonComponent) {
    const tag_inputs = [];
    for (const tag of this.tags) {
      if (tag.key !== null && tag.key !== '') {
        let item = ''
        item += '{\n';
        item += `  key: "${tag.key}"`
        item += `  value:" ${tag.value}"`
        item += '}\n'
        tag_inputs.push(item)
      }
    }

    const track_input = [];
    for (const track of this.tracks) {
      let item = ''
      item += '{\n';
      item += `  index: ${track.index}`
      item += `  track_type: ${track.track_type}`
      item += `  language: "${track.language}"`
      item += `  enabled: ${track.enabled}`
      item += '}\n'
      track_input.push(item)
    }
    this.apollo.mutate<Mutation>({
      mutation: gql`mutation UpdateFileDetails($id: ID!) {
        updateFile(input: {
          file_id: $id
          tags: [${tag_inputs.join(',\n')}
          ]
          tracks: [${track_input.join(',\n')}
          ]
        }) {
          file {
            id
            tags {
              key
              value
            }
            tracks {
              index
              enabled
              language
              track_type
            }
          }
        }
      }
      `,
      variables: {
        id: this.file().id,
      }
    }).subscribe({
      next: res => {
        button.done('Changes saved')
        const file = res.data.updateFile.file;
        this.tracks = file.tracks;
        this.tags = res.data.updateFile.file.tags;
      },
      error: err => {
        button.done('Workflow metadata was not updated: ' + err.message, {severity: 'cl-alert-danger'})
      }
    })

  }


  refresh_ffprobe(button: DoButtonComponent) {
    this.apollo.mutate<Mutation>({
      mutation: gql`mutation Refresh_ffprobe($id: ID!) {
        refreshFFProbe(input: {file_id: $id, force: true}) {
          file {
            id
            ffprobe
          }
        }
      }
      `,
      fetchPolicy: 'network-only',
      variables: {
        id: this.file().id
      }
    }).subscribe({
      next: res => {
        button.done('FFprobe refreshed')
      },
      error: err => {
        button.done('FFprobe refreshed', {severity: 'cl-alert-danger'})
      }
    })

  }

  refresh_basic_qc(button: DoButtonComponent) {

    this.apollo.mutate<Mutation>({
      mutation: gql`mutation RefreshBasicQc($id: ID!) {
        refreshBasicQc(input: {file_id: $id, force: true}) {
          file {
            id
            warnings
          }
        }
      }
      `,
      fetchPolicy: 'network-only',
      variables: {
        id: this.file().id
      }
    }).subscribe({
      next: () => button.done('Basic QC updated'),
      error: err => button.done('Basic QC was not updated: ' + err.message, {
        severity: 'cl-alert-danger'
      })
    })
  }

  refresh_extra_info(button: DoButtonComponent) {
    this.apollo.mutate<Mutation>({
      mutation: gql`mutation RefreshExtra($id: ID!) {
        refreshExtra(input: {file_id: $id, force: true}) {
          file {
            id
            extra_info
          }
        }
      }
      `,
      fetchPolicy: 'network-only',
      variables: {
        id: this.file().id
      }
    }).subscribe({
      next: () => button.done('Extra info updated'),
      error: err => button.done('Extra info was not updated: ' + err.message, {severity: 'cl-alert-danger'})
    })
  }

  openDialog(dialog: TemplateRef<any>) {
    this.dialog.open(dialog)
  }

  refresh_mediainfo(button: DoButtonComponent) {
    this.apollo.mutate<Mutation>({
      mutation: gql`mutation refreshMediaInfo($id: ID!) {
        refreshMediaInfo(input: {file_id: $id, force: true}) {
          file {
            id
            mediainfo_json
            mediainfo
          }
        }
      }
      `,
      variables: {
        id: this.file().id
      },
      fetchPolicy: 'network-only'
    }).subscribe(
      {
        next: () => {
          button.done('MediaInfo updated')
        },
        error: err => {
          this.dialog.closeAll();
          button.done('MediaInfo was not updated: ' + err.message, {severity: 'cl-alert-danger'})
        }
      })
  }

  generate_stills(button: DoButtonComponent) {
    this.apollo.mutate<Mutation>({
      mutation: START_GENERATE_STILLS,
      variables: {
        file_id: this.file().id
      }
    }).pipe(
      switchMap(d => this.processService.observeProcess(d.data.generate_stills, {
        successMessage: `Still generation for ${this.file.name} succeeded.`,
        failureMessage: `Still generation for ${this.file.name} failed.`
      })),
      skipWhile(process => {
        return process.state === ProcessStateEnum.InProgress
      }),
      map(() => this.apollo.query<Query>({
        query: FILE_DETAILS,
        variables: {
          id: this.folder().id
        },
        errorPolicy: 'all',
        fetchPolicy: 'network-only'
      }))
    ).subscribe({
        complete: () => {
          button.done()
        }
      }
    );
  }

  copy_ffprobe(button: DoButtonComponent) {
    navigator.clipboard.writeText(JSON.stringify(this.ffprobe))
    button.done('Copied')
  }

  copy_warnings(button: DoButtonComponent) {
    navigator.clipboard.writeText(JSON.stringify(this.warnings))
    button.done('Copied')
  }

  copy_extra_info(button: DoButtonComponent) {
    navigator.clipboard.writeText(JSON.stringify(this.extra_info))
    button.done('Copied')
  }

  copy_mediainfo(button: DoButtonComponent) {
    navigator.clipboard.writeText(JSON.stringify(this.mediainfo))
    button.done('Copied')
  }

  copy_mediainfo_json(button: DoButtonComponent) {
    navigator.clipboard.writeText(JSON.stringify(this.mediainfo_json))
    button.done('Copied')
  }

}
