import {Component, Input, OnInit} from '@angular/core';
import {Airline, PoItem} from '../../../types-workflow';
import {Organization} from '../../../types';
import {RouterLink} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {ProcessLinkComponent} from '../../process-link/process-link.component';
import {DurationPipe} from '../../../pipes/duration.pipe';
import {CommonModule, DatePipe} from '@angular/common';
import {S3LinkComponent} from '../../s3-link/s3-link.component';
import {VodDemoPlayerPipe} from '../../../pipes/demo-player.pipe';
import {RemovePipe} from '../../../pipes/remove-pipe';

@Component({
  selector: '[app-po-item-details]',
  templateUrl: './po-item-details.component.html',
  styleUrls: ['./po-item-details.component.scss'],
  imports: [
    RouterLink,
    ProcessLinkComponent,
    DurationPipe,
    DatePipe,
    CommonModule,
    S3LinkComponent,
    VodDemoPlayerPipe,
    RemovePipe
  ]
})
export class PoItemDetailsComponent implements OnInit {
  public VTK_SERVER = environment.VTK
  envsuffix = environment.env_name === 'prod' ? '' : `-${environment.env_name}`;
  protected open = false

  @Input()
  poItem: PoItem;

  @Input()
  organization: Organization;

  @Input()
  airline: Airline;

  constructor() {
  }

  ngOnInit(): void {
  }


}
