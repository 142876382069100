import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {AsyncPipe, CommonModule} from '@angular/common';

@Component({
    selector: 'app-flytedge-metadata-preview',
    templateUrl: './flytedge-metadata-preview.component.html',
    styleUrl: './flytedge-metadata-preview.component.scss',
  imports: [CommonModule, AsyncPipe, ]
})
export class FlytedgeMetadataPreviewComponent implements OnChanges {
  @Input() url: string;
  // https://preview-thales.content-sannies.castlabs.com/thales/M01512/M01512-NEONDREAMS-2024.json
  // content-repo-apeterka-preview-thales/thales/delta-flytedge/delta-flytedge_flytedge-test-02-2024-06-17/M01512-TEARS-OF-STEEL-2024/09215893-8124-4c6f-96b1-30ab153929e2/feed/M01512-TEARS-OF-STEEL-2024.json
  // https://preview-thales.content-apeterka.castlabs.com/thales/delta-flytedge/delta-flytedge_flytedge-test-02-2024-06-17/M01512-TEARS-OF-STEEL-2024/09215893-8124-4c6f-96b1-30ab153929e2/feed/M01512-TEARS-OF-STEEL-2024.json

  metadata$ = new BehaviorSubject<any>(null);

  constructor(private httpClient: HttpClient) {
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes)
    if (changes.url && changes.url.currentValue !== null && changes.url.currentValue !== changes.url.previousValue && changes.url) {
      this.httpClient.get(changes.url.currentValue).subscribe({
        next: newMetadata => this.metadata$.next(newMetadata),
        error: error => {
          console.error('Error fetching metadata', error);
          this.metadata$.error(error)
        }
      });
    }
  }

  getImgUrl(value: string) {
    return this.url.replace(/(\/[^/]+){2}$/, '') + value.substring(value.indexOf('/assets/'));
  }
}
