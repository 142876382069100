import {Component, Inject, OnInit} from '@angular/core';
import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-player-select-video',
  templateUrl: './player-select-video.component.html',
  styleUrls: ['./player-select-video.component.scss'],
  imports: [
    FormsModule,
    CommonModule,
  ]
})
export class PlayerSelectVideoComponent implements OnInit {
  currentlySelectedRendition: Number;
  renditions: any;
  activeRenditionId: Number;
  streamTypeHLS: boolean;
  streamTypeDASH: boolean;

  constructor(protected dialogRef: DialogRef<Number>,
              @Inject(DIALOG_DATA) data: {
                renditions: any[],
                activeRenditionId: Number,
                streamTypeHLS: boolean,
                streamTypeDASH: boolean
              }) {
    this.renditions = data.renditions;
    this.activeRenditionId = data.activeRenditionId;
    this.currentlySelectedRendition = data.activeRenditionId
    this.streamTypeHLS = data.streamTypeHLS
    this.streamTypeDASH = data.streamTypeDASH
  }

  ngOnInit(): void {
  }

}
