import {Pipe, PipeTransform} from '@angular/core';
import {File, Folder} from '../types';
import {ContextService} from '../services/context.service';

@Pipe({
    name: 'link'
})
export class FileFolderLinkPipe implements PipeTransform {


  constructor(private contextService: ContextService) {
  }

  transform(value: File | Folder, ...args: unknown[]): string {
    if (value) {
      if (value.__typename === 'Folder') {
        return `/o/${this.contextService.organization().id}/files/browse/${value.id.replace('s3://', '')}`;
      } else {
        return `/o/${this.contextService.organization().id}/files/details/${value.id.replace('s3://', '')}`;
      }
    }
  }

}
