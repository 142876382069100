<div style="display: flex; flex-direction: column; justify-content: space-between; height: 100%;"
     class="cl-form">
  <select id="langselect" class="form-select" [(ngModel)]="lastSelected" (ngModelChange)="add($event)">
    <option value="">[Add language]</option>
    <option [value]="l.id" *ngFor="let l of allLanguages">{{ l.name }} ({{ l.id }})</option>
  </select>

  <div style="margin-top: 8px">
    <div class="animate drag-boundary" cdkDropList (cdkDropListDropped)="drop($event)">
      <div class="line " cdkDrag *ngFor="let l of  languages" cdkDragBoundary=".drag-boundary">
        <div class="placeholder" *cdkDragPlaceholder></div>
        <div style="display: flex; flex-direction: row">
          <div>
            <i style="padding-left: 8px; padding-right: 8px"
               class="fas fa-solid fa-grip-lines"></i>
          </div>
          <div
            style="flex-grow: 1; overflow: hidden;white-space: nowrap; text-overflow: ellipsis;">
            {{ l | langName }} ({{ l }})
          </div>
          <div><a role="button" [routerLink]="" class="cl-a-quickaction" (click)="remove(l)">Remove</a></div>
        </div>

      </div>
    </div>
  </div>
  <div style="flex-grow: 1">
  </div>
</div>
