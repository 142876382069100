import {Component, Input, OnDestroy, OnInit, signal, Signal} from '@angular/core';
import {File, Folder, Mutation, Organization, PreviewTokenPayload, ProcessStateEnum, Query, Tag, TagInput, TrackType} from '../../../types';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {BehaviorSubject, forkJoin, Subscription, combineLatest} from 'rxjs';
import {catchError, filter, map, mergeMap, skipWhile, startWith, switchMap, take, tap} from 'rxjs/operators';
import {GET_FOLDER_ALL_DETAILS, GET_UPFRONT_TOKEN, PREVIEW_GENERATE} from '../../../queries';
import {PreviewRegistrationService} from '../../../services/preview-registration.service';
import {Apollo} from 'apollo-angular';
import {BannerService} from '../../../banner/banner.service';
import gql from 'graphql-tag';
import {Constants} from '../../../globals';
import {Dialog} from '@angular/cdk/dialog';
import {GenerateUploadLinkComponent} from '../generate-upload-link-dialog/generate-upload-link-dialog.component';
import {PoMetaService} from '../../../services/po-meta.service';
import {ProcessService} from '../../../services/process.service';
import {DoButtonComponent} from '../../do-button/do-button.component';
import {S3LinkComponent} from '../../s3-link/s3-link.component';
import {IsAllowedPipe} from '../../../pipes/has-action-pipe';
import {MezzPreviewPlayerComponent} from './mezz-preview-player.component';
import {FileFolderLinkPipe} from '../../file-folder-link.pipe';
import {FormsModule} from '@angular/forms';
import {MessageFieldComponent} from '../../do-button/message-field.component';
import {ContextService} from '../../../services/context.service';
import {CommonModule} from '@angular/common';
import {RemovePipe} from '../../../pipes/remove-pipe';
import {toObservable} from '@angular/core/rxjs-interop';

export interface FileTrack {
  file: File;
  track: {
    index: number;
    enabled: boolean;
    language: string;
    track_type: TrackType;
  }
  channelLayout?: string;
  channelWarn?: boolean;
}

@Component({
  selector: 'app-folder-asset-view',
  templateUrl: './folder-asset-view.component.html',
  styleUrls: ['./folder-asset-view.component.scss'],
  imports: [
    S3LinkComponent,
    IsAllowedPipe,
    MezzPreviewPlayerComponent,
    FileFolderLinkPipe,
    RouterLink,
    FormsModule,
    DoButtonComponent,
    MessageFieldComponent,
    CommonModule,
    RemovePipe
  ]
})
export class FolderAssetViewComponent {

  folderSubject = new BehaviorSubject<Folder>(null);

  organization: Signal<Organization>;
  loading = signal(true);

  _folder: Folder;
  parent: Folder

  videoTracks: FileTrack[] = [];
  audioTracks: FileTrack[] = [];
  textTracks: FileTrack[] = [];
  imageTracks: FileTrack[] = [];

  languages = Constants.languages;

  selectedVideoFileTrack: FileTrack;
  selectedAudioFileTrack: FileTrack;
  selectedTextFileTrack: FileTrack;

  previewTokenPayload: PreviewTokenPayload;
  tags: Tag[];

  public readonly TrackType = TrackType;

  subscriptions: Subscription[] = [];

  previewGenerateActive = false;

  @Input()
  set folder(folder: Folder) {
    this.folderSubject.next(folder);
    this._folder = folder;
  }


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private previewRegistrationService: PreviewRegistrationService,
    private apollo: Apollo,
    private banner: BannerService,
    private contextService: ContextService,
    public poMetaService: PoMetaService,
    private dialog: Dialog,
    private processService: ProcessService
  ) {
    this.organization = this.contextService.organization

    this.folderSubject.pipe(
      filter(folder => !!folder),
      switchMap(folder => this.apollo.watchQuery<Query>({
          query: GET_FOLDER_ALL_DETAILS,
          variables: {
            id: folder.id
          },
          nextFetchPolicy: 'cache-only',
        }
      ).valueChanges.pipe(
        skipWhile(response => response.loading),
        map(response => response.data.folder)).pipe(
        tap(() => this.loading.set(false))
      ))).subscribe(_folder => {
      if (_folder.parents) {
        let ps = _folder.parents
        ps = [...ps].reverse()
        while (ps[0].id === this.folderSubject.value.id) {
          ps.shift()
        }
        this.parent = ps.shift()
      }

      this._folder = _folder;
      this.tags = this._folder.tags;
      if (this.tags == null || this.tags.length === 0) {
        this.tags = []
      }
      this.tags = this.tags.map(t => {
        return {'key': t.key, 'value': t.value}
      });
      this.audioTracks = [];
      this.videoTracks = [];
      this.textTracks = [];
      this.imageTracks = [];
      for (const file of _folder.files) {
        if (file.tracks && !file.deleted) {
          for (const track of file.tracks) {
            if (track.track_type === TrackType.Video) {
              this.videoTracks.push({file, track: {...track}})
            } else if (track.track_type === TrackType.Audio) {
              let channelLayout = '';
              let channelWarn = false;
              if (file.mediainfo_json) {
                const trackJson = file.mediainfo_json
                  .map(s => JSON.parse(s))
                  .find(s => (s.hasOwnProperty('StreamOrder') ? parseInt(s['StreamOrder'], 10) : 0) === track.index)
                if (trackJson) {
                  channelLayout = trackJson.hasOwnProperty('ChannelLayout') ? trackJson['ChannelLayout'] :
                    (trackJson.hasOwnProperty('Channels') ? trackJson['Channels'] + ' Ch' : 'unknown')
                  channelWarn = trackJson.hasOwnProperty('Channels') && trackJson['Channels'] !== '2'
                }
              }
              this.audioTracks.push({file, track: {...track}, channelLayout, channelWarn})
            } else if (track.track_type === TrackType.Subtitle || track.track_type === TrackType.Closedcaption) {
              this.textTracks.push({file, track: {...track}})
            } else if (track.track_type === TrackType.Image) {
              this.imageTracks.push({file, track: {...track}})
            }
          }
        }
      }
      this.selectedAudioFileTrack = this.audioTracks.find(t => t.track.enabled);
      this.selectedVideoFileTrack = this.videoTracks.find(t => t.track.enabled);
      this.selectedTextFileTrack = this.textTracks.find(t => t.track.enabled);
    })
    this.folderSubject.pipe(
      filter(folder => folder !== null),
      switchMap(folder => this.previewRegistrationService.getPreviews()),
      map(previews => previews.find(p => p.folder_id === this.folderSubject.value.id)),
      filter(p => p !== undefined),
      switchMap(preview_data => this.apollo.mutate<Mutation>({
        mutation: GET_UPFRONT_TOKEN,
        variables: {
          'asset_id': preview_data.asset_id
        }
      }).pipe(
        catchError((err, caught) => {
          this.previewRegistrationService.removePreview(preview_data)
          this.banner.open(err, ['Close']);
          throw err
        })
      )),
      map(upfrontTokenResponse => upfrontTokenResponse.data.previewGenerateToken),
    )
    combineLatest([this.previewRegistrationService.getPreviews(), this.folderSubject]).pipe(
      map(([ps, folder]) => ps.find(p => p.folder_id === folder?.id)),
      filter(p => p !== undefined),
      mergeMap(preview_data => {
        return this.apollo.mutate<Mutation>({
          mutation: GET_UPFRONT_TOKEN,
          variables: {
            'asset_id': preview_data.asset_id
          }
        }).pipe(
          catchError((err, caught) => {
            this.previewRegistrationService.removePreview(preview_data)
            this.banner.open(err, ['Close']);
            throw err
          })
        )
      }),
      map(upfrontTokenResponse => upfrontTokenResponse.data.previewGenerateToken),
      take(1),
    ).subscribe(upfrontTokenResponse => this.previewTokenPayload = upfrontTokenResponse)
  }

  viewAsListing() {
    this.router.navigate([], {relativeTo: this.route, queryParams: {view: 'listing'}, queryParamsHandling: 'merge', replaceUrl: true})
  }

  generateUploadLink() {
    this.dialog.open(GenerateUploadLinkComponent, {data: {folder: this.folderSubject.value}})
  }

  generatePreview() {
    this.previewGenerateActive = true
    this.apollo.mutate<Mutation>({
      mutation: PREVIEW_GENERATE,
      variables: {
        folder_id: this.folderSubject.value.id
      }
    }).pipe(
      switchMap(d => this.processService.observeProcess(d.data.previewGenerate, {
        successMessage: `${this.folderSubject.value.name} - preview generated.`,
        failureMessage: `${this.folderSubject.value.name} - preview failed.`,
        link: window.location.href
      })),
      skipWhile(process => process.state === ProcessStateEnum.InProgress)
    ).subscribe({
      next: process => {
        this.previewGenerateActive = false;
        if (process.state === ProcessStateEnum.Failed) {
          this.banner.open('Preview generation failed. ' + process.message, ['CLOSE'])
        } else if (process.state === ProcessStateEnum.Success) {
          this.previewRegistrationService.addPreview(JSON.parse(process.data));
        }
      },
      error: err => {
        this.previewGenerateActive = false;
        this.banner.open('Preview generation failed. ' + err, ['CLOSE'])
      }
    })

  }

  addToPo() {
    const preselectedAudio = new Set<string>();
    const preselectedSubtitles = new Set<string>();
    const preselectedCC = new Set<string>();
    const images = new Set<string>();
    for (const file of (this._folder || this.folderSubject.value).files) {
      if (!file.tracks) {
        continue;
      }

      for (const track of file.tracks) {
        if (!track.enabled) {
          continue;
        }

        switch (track.track_type) {
          case TrackType.Audio:
            preselectedAudio.add(track.language);
            break;
          case TrackType.Subtitle:
            preselectedSubtitles.add(track.language);
            break;
          case TrackType.Closedcaption:
            preselectedCC.add(track.language);
            break;
          case TrackType.Image:
            images.add(file.id.split('/').pop());
            continue;
        }
      }
    }

    this.poMetaService.poService.addToPO(
      this.folderSubject.value.id,
      [...preselectedAudio],
      [...preselectedSubtitles],
      [...preselectedCC],
      [...images]
    );
  }

  onAddTag(event: Event) {
    this.tags = [...this.tags, {key: '', value: ''}]
  }

  save_changes(button: DoButtonComponent) {
    const saveMutations = []
    for (const f of this._folder.files) {
      const tracks = [];
      [this.videoTracks, this.audioTracks, this.textTracks].forEach(t => {
        t.filter(v => v.file.id === f.id).forEach(v => {
          tracks.push(`{
            index: ${v.track.index},
            enabled: ${v.track.enabled},
            language: "${v.track.language}",
            track_type: ${v.track.track_type}
          }`)
        })
      })
      saveMutations.push(this.apollo.mutate<Mutation>({
        mutation: gql`mutation save_tracks($id: ID!) {
          updateFile(input: {
            file_id: $id
            tracks: [${tracks.join(',\n')}
            ]
          }) {
            file {
              id
              tracks {
                index
                enabled
                language
                track_type
              }
            }
          }
        }
        `,
        variables: {
          id: f.id,
        }
      }))
    }


    const tagInputArray: Array<TagInput> = this.tags.map(({key, value}) =>
      key && key !== '' ? {key, value} : null).filter(p => p !== null);


    saveMutations.push(this.apollo.mutate<Mutation>({
        mutation: gql`
          mutation foldertags($id: ID!, $tags: [TagInput!]) {
            tag_folder(input: { folder_id: $id, tags: $tags } ) {
              folder {
                id
                tags {
                  key
                  value
                }
              }
            }
          }`,
        variables: {
          'id': this.folderSubject.value.id,
          'tags': tagInputArray
        }
      }
    ))
    forkJoin([...saveMutations]).pipe(map(() => this.apollo.watchQuery<Query>({
        query: GET_FOLDER_ALL_DETAILS,
        variables: {
          id: this.folderSubject.value.id
        },
        nextFetchPolicy: 'network-only',
      }
    ))).subscribe({
      next: () => button.done('Changes saved successfully'),
      error: err => button.done('Failed to save changes: ' + err)
    })
  }


}
