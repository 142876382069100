import {Component, Inject, OnInit} from '@angular/core';

import {Apollo} from 'apollo-angular';
import {File, Folder, Mutation, Query, RepositoryProcessPayload} from '../../../types';
import gql from 'graphql-tag';
import {forkJoin, Observable, of, timer} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {BannerService} from '../../../banner/banner.service';
import {Router} from '@angular/router';
import {FILE_FOLDER_LIST} from '../../../queries';
import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {ProcessService} from '../../../services/process.service';
import {DoButtonComponent} from '../../do-button/do-button.component';
import {FormsModule} from '@angular/forms';
import {MessageFieldComponent} from '../../do-button/message-field.component';
import {AsyncPipe, DatePipe} from '@angular/common';

@Component({
  selector: 'app-move-file-dialog',
  templateUrl: './move-file-dialog.component.html',
  styleUrls: ['./move-file-dialog.component.scss'],
  imports: [FormsModule, DoButtonComponent, MessageFieldComponent, AsyncPipe, DatePipe]
})
export class MoveFileDialogComponent implements OnInit {
  src_basefolder: string;
  dst_folder: string;
  selection: [File | Folder]
  files$: Observable<(File | Folder)[]>;
  private responses: RepositoryProcessPayload[];
  private files: File[];

  constructor(public dialogRef: DialogRef<any>,
              @Inject(DIALOG_DATA) public data: { basefolder: string, selection: [File | Folder] },
              private apollo: Apollo,
              public router: Router,
              public banner: BannerService,
              public processService: ProcessService
  ) {
    this.src_basefolder = data.basefolder;
    this.selection = data.selection;
    this.dst_folder = this.src_basefolder
    const _f: (Observable<File[]> | Observable<Folder[]>) [] = []
    for (const item of this.selection) {
      if (item.__typename === 'Folder') {
        _f.push(this.apollo.query<Query>({
          query: gql`query list($id: ID!) {
            folder(id: $id) {
              id
              files {
                id
                name
                archived
              }
            }
          }`,
          variables: {
            id: item.id
          }
        }).pipe(
          map(d => {
              return d.data.folder.files.map(e => {
                const f = Object.assign({}, e)
                f.name = item.name + '/' + f.name
                return f
              })
            }
          )))
        console.log('push folder ' + item.id)
        _f.push(of([item as Folder]))
      } else {
        console.log('push file ' + item.id)
        _f.push(of([item as File]))
      }

    }

    this.files$ = forkJoin(..._f).pipe(map(f => {
      console.log('lemme concat')
      return [].concat(...f) as (File | Folder)[];
    }))
  }

  ngOnInit() {
  }

  public close() {
    console.log('closing ')
    if (this.responses) {
      this.responses.forEach((res, i) => {
        console.log(res)
        this.processService.observeProcess(res, {
          failureMessage: `Failed to move ${this.files[i].name}`,
          successMessage: `Successfully moved ${this.files[i].name}`,
          link: window.location.href
        })
      })
    }
    this.dialogRef.close()
  }


  public do_move(button: DoButtonComponent) {

    this.files$.pipe(
      map(files => files as File[]),
      mergeMap(files => {

        let mutation = 'mutation MyMutation {\n'
        files.forEach((value, i) => {
          mutation += `move${i}: move_file(input: {src: "${value.id}", dest: "${this.dst_folder}${value.id.substring(this.src_basefolder.length)}"}) {
                state
                id
              }
              `
        })
        mutation += '}'
        return this.apollo.mutate<Mutation>({
          mutation: gql(mutation)
        }).pipe(map(response => ({response, files})))
      })
    ).subscribe(({response, files}) => {
      this.responses = Object.values(response.data) as RepositoryProcessPayload[];
      this.files = files
      const processes$: Observable<RepositoryProcessPayload>[] = []
      const archived = files.filter(f => f.archived).length > 0
      files.forEach((value, i) => {
        processes$.push(this.processService.observeProcess(response.data[Object.keys(response.data)[i]], {
          failureMessage: `Failed to move ${value.name}`,
        }))
      })


      forkJoin(processes$).subscribe(processes => {
        [this.src_basefolder, this.dst_folder].forEach(folder => {
          this.apollo.query({
            query: FILE_FOLDER_LIST,
            fetchPolicy: 'network-only',
            variables: {
              id: folder,
              show_deleted: true
            }
          }).subscribe(() => {
            if (!archived) {
              button.done('Files moved successfully')
              timer(1000).subscribe(() => this.dialogRef.close())
            }
          })
        })
      })
      if (!archived) {
        button.info('Moving files ...', {duration: -1})
      } else {
        button.done('Files restoring. Move will begin after restore is complete...')
        timer(5000).subscribe(() => this.close())
      }
    })

  }

  public move(button: DoButtonComponent) {
    if (this.src_basefolder === this.dst_folder) {
      button.done('Source and destination are the same', {severity: 'cl-alert-warning'})
      return
    }


    this.do_move(button);
  }

}
