@use "@castlabs/ui/src/styles/global" as *;
@use "@castlabs/prestoplay/clpp.styles.css";

.player-container, .clpp-container {
  background-color: $color-ci-night;
  display: inline-block;
  width: 100%;
  aspect-ratio: 16/9;
}


.player-container video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.header-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: calc(100% - 24px);
  top: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.3);
  transition: opacity 150ms ease-in;
  opacity: 0;
}

.footer-container {
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 3;
  transition: opacity 150ms ease-in;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
}

.progress-bar-container {
  width: 100%;
  height: 6px;
  background: #888;
  border-radius: 2px;
  position: relative;
}

.progress-bar {
  height: 6px;
  background: #fff;
  border-radius: 2px;
}

.progress-bar-container:hover {
  cursor: pointer;
}

.progress-bar-container:hover .progress-bar-button {
  visibility: visible;
}

.progress-bar-button {
  visibility: hidden;
  height: 12px;
  width: 12px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: -3px;
}

