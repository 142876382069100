import {Component, computed, effect, Signal} from '@angular/core';
import {combineLatest} from 'rxjs';
import {Airline} from '../../types-workflow';
import {filter, map, tap} from 'rxjs/operators';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {FilterSectionComponent} from '../filter-section/filter-section.component';
import {OrgShortenerPipe} from '../../pipes/org-shortener-pipe';
import {ContextService} from '../../services/context.service';
import {toObservable, toSignal} from '@angular/core/rxjs-interop';
import {Apollo} from 'apollo-angular';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-airline-select-sections',
  templateUrl: './airline-select.component.html',
  styleUrls: ['./airline-select.component.scss'],
  imports: [FilterSectionComponent, ReactiveFormsModule, RouterModule, OrgShortenerPipe, CommonModule]
})
export class AirlineSelectComponent {
  loading = true;
  airlines: Signal<Airline[]>;
  filteredAirlines: Signal<Airline[]>;
  filters: FormGroup = new FormGroup({
    t: new FormControl<string>(''),
  });

  constructor(private router: Router, contextService: ContextService, activatedRoute: ActivatedRoute, apollo: Apollo) {
    /* effect(() => {
      if (this.airlines().length === 1) {
        console.log('Navigating to airline', this.airlines()[0].iata_code);
        this.router.navigate(['.', this.airlines()[0].iata_code], {
          replaceUrl: true,
          relativeTo: activatedRoute
        }).then(a => console.log('navidated to airline', a))
      }
    })*/
    this.airlines = computed(() => [...contextService.airlines()].sort((a, b) => a.airline_name.localeCompare(b.airline_name)));
    effect(() => {
      console.log('airlines effected in airline select',  contextService.airlines().length)
    });
    this.filteredAirlines = toSignal(combineLatest([this.filters.valueChanges, toObservable(this.airlines)]).pipe(
        filter(([f, airlines]) => !!airlines),
        map(([f, airlines]) => {
          return airlines.filter(airline => {
            return airline.airline_name.toLowerCase().includes((f['t'] || '').toLowerCase()) ||
              airline.iata_code.toLowerCase().includes((f['t'] || '').toLowerCase())
          })
        }),
        tap(() => this.loading = false),
      ),
    );
  }

}
