import {Component, computed, Input, OnInit, Signal} from '@angular/core';

import {BreadCrumb, LogoAreaComponent} from '../logo-area/logo-area.component';
import {Organization} from '../../types';
import {HeadlineSectionComponent} from '../headline-section/headline-section.component';
import {AirlineSelectComponent} from '../airline-select/airline-select.component';
import {ContextService} from '../../services/context.service';

@Component({
  selector: 'app-previews',
  templateUrl: './previews.component.html',
  styleUrls: ['./previews.component.scss'],
  imports: [
    LogoAreaComponent, HeadlineSectionComponent, AirlineSelectComponent
  ]
})
export class PreviewsComponent implements OnInit {
  organization: Signal<Organization>;

  breadCrumbs: Signal<BreadCrumb[]>;


  constructor(contextService: ContextService) {
    this.organization = contextService.organization;
  }

  ngOnInit(): void {
    this.breadCrumbs = computed(() => [
      {display: 'Content Portal', commands: ['/o', this.organization()?.id]},
      {display: this.organization()?.name, commands: ['/o', this.organization()?.id]},
      {display: 'Previews', commands: ['/o', this.organization()?.id, 'previews']},
    ]);
  }
}
