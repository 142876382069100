import {Inject, Injectable} from '@angular/core';
import {Po2024Service} from './po-2024.service';

@Injectable({
  providedIn: 'root'
})
export class PoMetaService {

  constructor(
    @Inject('above_default') private abovePoService: Po2024Service,
    @Inject('vod_batch') private vodWatermarkPoService: Po2024Service,
    @Inject('vod_default') private vodDefaultPoService: Po2024Service,
  ) {
  }

  get poService(): Po2024Service {
    return [this.abovePoService, this.vodWatermarkPoService, this.vodDefaultPoService].find(v => v.active)
  }

  setActive(serviceName: string) {
    this.abovePoService.active = serviceName === 'above_default'
    this.vodDefaultPoService.active = serviceName === 'vod_default'
    this.vodWatermarkPoService.active = serviceName === 'vod_batch'
  }



}
