import {AfterContentInit, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {debounceTime} from 'rxjs/operators';
import {CommonModule} from '@angular/common';

export const FILTER_DEBOUNCE_TIME = 350;

@Component({
    selector: 'app-filter-section',
    template: `
    <section class="cl-section-regular cl-section-tight" *ngIf="all || filtered">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <details class="cl-dropdown" style="width: 100%;"
                     [open]="searchOpen"
                     (toggle)="searchOpen = $event.target.open">
              <summary>
                <div *ngIf="all && filtered">Filter: {{ (filtered || []).length }}
                  of {{ (all || []).length }} shown
                </div>
                <div *ngIf="!all && filtered">Search: {{ (filtered || []).length }} results
                </div>
              </summary>
              <ng-content></ng-content>
            </details>

          </div>
        </div>
      </div>
    </section>
  `,
    styleUrls: ['./filter-section.component.scss'],
  imports: [CommonModule]
})
export class FilterSectionComponent implements AfterContentInit, OnChanges {
  @Input() openByDefault = false;
  @Input() all: any[] | null;
  @Input() filtered: any[] | null;
  @Input() formGroup: FormGroup;

  searchOpen = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {

  }

  filterEmptyParams(params: { [key: string]: any }): { [key: string]: any } {
    const filteredParams: { [key: string]: any } = {};
    Object.keys(params).forEach(key => {
      if (params[key] !== null && params[key] !== '' && params[key]) {
        filteredParams[key] = params[key];
      }
    });
    return filteredParams;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.all && this.filtered && this.all.length !== this.filtered.length) {
      this.searchOpen = true;
    }
  }

  ngAfterContentInit(): void {
    this.searchOpen = this.openByDefault;
    this.formGroup.valueChanges.pipe(debounceTime(FILTER_DEBOUNCE_TIME)).subscribe(val => {
      const mergedParams = Object.assign([], this.activatedRoute.snapshot.queryParams, val)
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: this.filterEmptyParams(mergedParams)
      })
    });
    this.activatedRoute.queryParams.subscribe(params => {
      const values = this.formGroup.value;
      Object.keys(values).forEach(key => values[key] = params[key] ?? '');
      this.formGroup.setValue(values);
    })

  }
}
