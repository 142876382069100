import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-file-icon',
  templateUrl: './file-icon.component.html',
  styleUrls: ['./file-icon.component.scss'],
  imports: [CommonModule]
})
export class FileIconComponent implements OnInit {

  @Input()
  filename: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
