<app-logo-area
  [breadcrumbs]="breadcrumbs"></app-logo-area>
<main>
  <app-headline-section [title]="'My organizations'"></app-headline-section>
  <section class="cl-section-regular">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="cl-p-large">
            Please choose the organization you want to manage.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="cl-section-regular">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <table class="cl-table-data">
            <colgroup>
              <col class="cl-col-id" style="width: 50px">
              <col class="cl-col-legacy-name" style="width: 50%">
              <col class="cl-col-name" style="width: 50%">
              <col class="cl-col-actions" style="width: 50px">
            </colgroup>
            <thead>
            <tr>
              <th class="cl-p-tiny">ID</th>
              <th class="cl-p-tiny">Organization</th>
              <th class="cl-p-tiny">API Name</th>
              <th class="cl-p-tiny"></th>
            </tr>
            </thead>
            <tbody>
            <tr class="cl-tr-no-data" *ngIf="loading; else bodyWithData">
              <td colspan="100">
                <div class="cl-spinner">Loading ...<i class="fas fa-spinner fa-spin"></i>
                </div>
              </td>
            </tr>
            <ng-template #bodyWithData>
              <tr *ngFor="let organization of organizations ">
                <td class="cl-td-id vtk-col-id">{{ organization.id | orgShort }}</td>
                <td>
                <span class="cl-org">{{ organization.name }}
                  <a [routerLink]="['/o',  organization.id]" role="button" class="cl-a-transparent"></a>
                </span>
                </td>
                <td>
                <span class="cl-org">{{ organization.legacy_name }}
                  <a [routerLink]="['/o',  organization.id]" role="button" class="cl-a-transparent"></a>
                </span>
                </td>
                <td class="cl-td-links">
                  <a [routerLink]="['/o',  organization.id]" role="button" class="cl-a-btn">Switch</a>
                </td>
              </tr>
            </ng-template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</main>
