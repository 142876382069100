import {Pipe, PipeTransform , Signal} from '@angular/core';
import {ContextService} from '../services/context.service';
import {Organization} from '../types';

@Pipe({
    name: 'isAllowed'
})
export class IsAllowedPipe implements PipeTransform {


  constructor(private organizationService: ContextService) {
  }

  transform(action: string, resource: string, organization: Organization): boolean {
    return this.organizationService.isAllowed(action, resource)(); // Call the Signal function directly
  }
}

@Pipe({
    name: 'isAllowedAnywhere'
})
export class IsAllowedAnywherePipe implements PipeTransform {


  constructor(private organizationService: ContextService) {
  }

  transform(action: string, organization: Organization): boolean {
    return this.organizationService.isAllowedAnywhere(action)();
  }
}

