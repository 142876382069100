import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {ca_payload_sizes, densities, sd_payload_sizes, strengths} from '../constants';
import {Subscription} from 'rxjs';
import {CommonModule} from '@angular/common';
import {CommaSeparatedInputDirective} from '../comma-separated-input.directive';

@Component({
    selector: 'app-vod-watermark-format-specific',
    templateUrl: './vod-watermark-format-specific.component.html',
    styleUrls: ['./vod-watermark-format-specific.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => VodWatermarkFormatSpecificComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: VodWatermarkFormatSpecificComponent,
            multi: true
        }
    ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CommaSeparatedInputDirective
  ]
})
export class VodWatermarkFormatSpecificComponent implements OnInit, ControlValueAccessor {
  static DEFAULTS = {
    watermarks: ''
  };

  @Input()
  parentForm: FormGroup<any>;

  form: FormGroup;
  subscriptions: Subscription[] = [];

  protected readonly environment = environment;


  protected readonly ca_payload_sizes = ca_payload_sizes;
  protected readonly sd_payload_sizes = sd_payload_sizes;
  protected readonly densities = densities;
  protected readonly strengths = strengths;

  changed: any = () => {
  };
  touched: any = () => {
  };

  constructor(private formBuilder: FormBuilder) {
    console.log('constructing VodWatermarkFormatSpecificComponent')
    this.form = this.formBuilder.group({
      watermark: ['contentarmor/embed'],
      encrypt: true,
      thumbnail: false,
      sd_watermarks: [[], Validators.nullValidator],
      ca_watermarks: [[], Validators.nullValidator],
      sd_strength: strengths[3].id,
      sd_superpixel_density: 50,
      sd_pixel_density: 50,
      sd_payload_size: sd_payload_sizes[1].id,
      ca_payload_size: ca_payload_sizes[2].id,
    })
    this.subscriptions.push(this.form.valueChanges.subscribe((val) => {
      this.touched();
      this.changed(val)
      console.log(val)
      function allInRange(max) {
        return (control) => {
          for (const v of control.value) {
            if (v < 1 || v > max) {
              return { 'range': { 'max': max, 'actual': v } }
            }
          }
          return null
        };
      }
      this.form.controls['sd_watermarks'].clearValidators()
      this.form.controls['sd_watermarks'].setErrors(null);
      this.form.controls['ca_watermarks'].clearValidators()
      this.form.controls['ca_watermarks'].setErrors(null);
      if (this.form.get('watermark').value === 'stardust/sf') {
        const nuMax = sd_payload_sizes.find(pls => pls.id === this.form.controls['sd_payload_size'].value).max
        this.form.controls['sd_watermarks'].setValidators([
          Validators.required,
          Validators.pattern(/^([0-9]+ *, *)* *[0-9]+ *$/),
          allInRange(nuMax)])
      }
      if (this.form.get('watermark').value === 'contentarmor/embed') {
        const nuMax = ca_payload_sizes.find(pls => pls.id === this.form.controls['ca_payload_size'].value).max
        this.form.controls['ca_watermarks'].setValidators([
          Validators.required,
          Validators.pattern(/^([0-9]+ *, *)* *[0-9]+ *$/),
          allInRange(nuMax)])
      }
      this.form.controls['sd_watermarks'].updateValueAndValidity({emitEvent: false})
      this.form.controls['ca_watermarks'].updateValueAndValidity({emitEvent: false})




    }));
  }

  ngOnInit(): void {
  }

  writeValue(value: any): void {
    if (value) {
      this.form.patchValue(value, {emitEvent: false});
    }
  }

  registerOnChange(fn: any): void {
    this.changed = fn;
  }

  registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  setDisabledState ?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  validate({value}: FormControl) {
    const isNotValid = !this.form.valid
    return isNotValid && {
      invalid: true
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
