import {Component, Input, OnInit, Signal} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {combineLatest, Observable, of} from 'rxjs';
import {Apollo} from 'apollo-angular';
import {catchError, map, mergeMap, shareReplay} from 'rxjs/operators';
import {Airline, PoItemPreview, Query} from '../../../types-workflow';
import gql from 'graphql-tag';
import {BreadCrumb, LogoAreaComponent} from '../../logo-area/logo-area.component';
import {HttpClient} from '@angular/common/http';
import {Organization} from '../../../types';
import {HeadlineSectionComponent} from '../../headline-section/headline-section.component';
import {ClTabsModule} from '../../cl-tabs/cl-tabs.module';
import {AsyncPipe, CommonModule} from '@angular/common';
import {PlayerComponent} from '../player/player.component';
import {FlytedgeMetadataPreviewComponent} from '../../ifes/flytedge-metadata-preview/flytedge-metadata-preview.component';
import {FlytedgeMetadataRawComponent} from '../../ifes/flytedge-metadata-raw/flytedge-metadata-raw.component';
import {ContextService} from '../../../services/context.service';


const PREVIEW_QUERY = gql`query PreviewQuery($po_name: String!, $po_item_id: String!) {
  list_POs(input: { filter: { po_name: { eq: $po_name}}}) {
    pos {
      id
      poitems(po_item_id: $po_item_id) {
        po_item_id
        filename
        preview {
          dash_manifest_last_modified
          dash_manifest_url
          hls_manifest_url
          hls_manifest_last_modified
          drmtoday_token
          access_token
          ab_token
        }
      }
    }
  }
}
`

@Component({
  selector: 'app-preview',
  templateUrl: './preview-poitem.component.html',
  styleUrls: ['./preview-poitem.component.css'],
  imports: [
    HeadlineSectionComponent,
    LogoAreaComponent,
    ClTabsModule,
    AsyncPipe,
    PlayerComponent,
    FlytedgeMetadataPreviewComponent,
    FlytedgeMetadataRawComponent,
    CommonModule,
  ]
})
export class PreviewPoitemComponent implements OnInit {

  preview$: Observable<PoItemPreview>;
  po_item_id$: Observable<string>;

  filename$: Observable<string>;

  flytedgeMetadataUrl$: Observable<string>;

  breadcrumps$: Observable<BreadCrumb[]>;
  airline: Signal<Airline>;
  organization: Signal<Organization>;

  ngOnInit() {
    this.breadcrumps$ = this.route.params.pipe(
      map((params) => {

        return [
          {
            commands: ['/previews'],
            display: 'Previews'
          },
          {
            commands: ['/previews', params['airline']],
            display: this.airline().airline_name
          },
          {
            commands: ['/previews', params['airline'], params['po']],
            display: params['po']
          },
          {
            commands: ['/previews', params['airline'], params['po'], params['poitem'], 'preview'],
            display: params['poitem']
          }]
      }));

  };

  constructor(
    private route: ActivatedRoute,
    private apollo: Apollo,
    contextService: ContextService,
    http: HttpClient,
  ) {

    this.airline = contextService.airline;
    this.organization = contextService.organization;
    const sub = this.route.params.pipe(
      mergeMap(p => {
        return this.apollo.use('workflow').query<Query>({
          query: PREVIEW_QUERY,
          variables: {
            po_name: p['po'],
            po_item_id: p['poitem']
          }
        })
      }),
      shareReplay(1),
    )

    this.preview$ = sub.pipe(
      map(d => d.data.list_POs.pos[0].poitems[0].preview)
    )
    this.po_item_id$ = sub.pipe(
      map(d => d.data.list_POs.pos[0].poitems[0].po_item_id)
    )

    this.filename$ = sub.pipe(
      map(d => d.data.list_POs.pos[0].poitems[0].filename)
    )
    this.flytedgeMetadataUrl$ = combineLatest([this.filename$, this.preview$]).pipe(
      map(([filename, preview]) => {
        const url = preview.dash_manifest_url != null ? preview.dash_manifest_url : preview.hls_manifest_url;
        return url.split('/').slice(0, -1).join('/') + '/feed/' + filename + '.json'
      }),
      mergeMap(url => http.get<any>(url).pipe(catchError(() => of(null)), map((res: any) => res ? url : null))),
      shareReplay(1)
    )
  }
}
