import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ClTabsComponent} from './cl-tabs.component';
import {ClTabComponent} from './cl-tab/cl-tab.component';

@NgModule({
  declarations: [
    ClTabsComponent,
    ClTabComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ClTabsComponent,
    ClTabComponent
  ]
})
export class ClTabsModule { }
