import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MessageFieldComponent} from './message-field.component';

@Component({
  selector: 'app-do-button',
  imports: [
  ],
  templateUrl: './do-button.component.html',
  styleUrl: './do-button.component.scss',
  standalone: true
})
export class DoButtonComponent {
  @Input() messageField: MessageFieldComponent;
  @Input() buttonClass = 'btn btn-sm cl-btn-outline-primary';
  @Output() actionTriggered = new EventEmitter<DoButtonComponent>();

  _processing = false;

  performAction(): void {
    this._processing = true;
    this.actionTriggered.emit(this);
  }

  public info(message?: string,
              options?: {
                severity?: 'cl-alert-danger' | 'cl-alert-warning' | 'cl-alert-info',
                duration?: number;
              }): void {
    if (message) {
      if (this.messageField) {
        this.messageField.setMessage(message, options?.severity || 'cl-alert-info', options?.duration || 5000);
      } else {
        console.log('Message field not set', message);
      }
    }
  }

  public done(message?: string,
              options?: {
                severity?: 'cl-alert-danger' | 'cl-alert-warning' | 'cl-alert-info',
                duration?: number;
              }): void {
    if (message) {
      this.info(message, options)
    }
    this._processing = false;
    console.log('done: ', message)

  }
}
