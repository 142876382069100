import {Component, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {UserProfileService} from '../../services/user-profile.service';
import {AsyncPipe, CommonModule} from '@angular/common';

@Component({
  selector: 'app-process-link',
  templateUrl: './process-link.component.html',
  styleUrls: ['./process-link.component.scss'],
  imports: [
    AsyncPipe,
    CommonModule,
  ]
})
export class ProcessLinkComponent {

  @Input()
  processId: string;

  environment = environment;

  @Input()
  type: 'wf' | 'cr' // workflow or content repo

  deepLink$: Observable<boolean>;

  constructor(staffService: UserProfileService) {
    this.deepLink$ = staffService.staff
  }
}
