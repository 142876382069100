import {Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {BreadCrumbs, LogoAreaComponent} from '../logo-area/logo-area.component';
import {Organization, Query} from '../../types';
import {HeadlineSectionComponent} from '../headline-section/headline-section.component';
import {RouterModule} from '@angular/router';
import {OrgShortenerPipe} from '../../pipes/org-shortener-pipe';
import {CommonModule} from '@angular/common';
import {Apollo} from 'apollo-angular';
import {OAuthEvent, OAuthService} from 'angular-oauth2-oidc';
import {filter, map, startWith, take, tap} from 'rxjs/operators';
import {Subscription, switchMap} from 'rxjs';
import {GET_ORGANIZATIONS} from '../../queries';

class DummyOAuthEvent extends OAuthEvent {
  constructor() {
    super('silently_refreshed');
  }
}

@Component({
  selector: 'app-organization-select',
  templateUrl: './organization-switcher.component.html',
  styleUrls: ['./organization-switcher.component.scss'],
  imports: [
    LogoAreaComponent, HeadlineSectionComponent, RouterModule, OrgShortenerPipe, CommonModule
  ]
})
export class OrganizationSwitcherComponent implements OnDestroy {

  breadcrumbs: BreadCrumbs = [
    {
      commands: null,
      display: 'Content Portal'
    },
    {
      commands: null,
      display: 'My Organizations'
    }
  ]

  organizations: Organization[];
  loading = true;
  subscription: Subscription;

  constructor(apollo: Apollo, authService: OAuthService) {

    this.subscription = authService.events.pipe(
      startWith(new DummyOAuthEvent()),
      filter(() => authService.hasValidAccessToken()),
      take(1),
      tap(() => this.loading = true),
      switchMap(() => apollo.query<Query>({
        query: GET_ORGANIZATIONS
      }).pipe(
        map(res => res.data.organizations.organizations),
        map(orgs => [...orgs].sort((a, b) => a.name.localeCompare(b.name)))
      ))
    ).subscribe(orgs => {
        this.organizations = orgs
        this.loading = false;
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


}
