import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';

import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import {WarnLogoutComponent} from '../warn-logout/warn-logout.component';
import {Dialog} from '@angular/cdk/dialog';
import {environment} from '../../../../e2e/environment';
import {Router} from '@angular/router';

@Component({
  selector: 'app-auto-logout',
  template: '',
  styleUrls: []
})
export class AutoLogoutComponent implements OnInit, OnDestroy {

  constructor(private oauthService: OAuthService,
              private dialog: Dialog,
              router: Router,
              private idle: Idle) {
    this.idle.setIdle(14 * 60 + 30); // 14m30s
    this.idle.setTimeout(10); // 30s
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);


    this.idle.onIdleStart.subscribe(() => {
      this.dialog.open(WarnLogoutComponent)
    });
    this.idle.onTimeout.subscribe(() => {
      localStorage.setItem('post-logout-path', router.url);
      this.oauthService.logOut({
        client_id: this.oauthService.clientId,
        logout_uri: this.oauthService.redirectUri,
      });
    });
  }

  ngOnInit(): void {
    this.idle.watch();
  }

  ngOnDestroy(): void {
    this.idle.stop();
  }

}
