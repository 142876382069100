import { clpp } from "./cl.core.js";
import './cl.mse.js';
let g = {};
const _ = clpp._;
var f = function (window) {
  'use strict';

  var bL = "Channel parsing failure! Ignoring scheme and value",
    cL = "ContentProtection",
    dL = "EssentialProperty",
    eL = "Failed to parse UTCTiming tags.",
    fL = "Missing \x3cms:pro/\x3e node in ContentProtection element",
    gL = "No UTCTiming elements found.",
    hL = "SegmentBase does not contain sufficient segment information:",
    iL = "SegmentList does not contain sufficient segment information:",
    jL = "SegmentTemplate does not contain sufficient segment information:",
    kL = "SegmentTimeline",
    lL = 'The last "S" element cannot have a negative repeat',
    mL = "application",
    nL = "duration",
    oL = "http://dashif.org/guidelines/trickmode",
    pL = "http://www.w3.org/1999/xlink",
    qL = 'ignoring the last "S" element.',
    rL = 'ignoring the remaining "S" elements.',
    sL = "presentationTimeOffset",
    tL = "schemeIdUri",
    uL = "static",
    vL = "timescale",
    wL = "urn:microsoft:playready",
    xL = "urn:mpeg:cenc:2013",
    yL = "urn:mpeg:dash:utc:http-head:2014",
    zL = "urn:uuid:9a04f079-9840-4286-ab92-e65be0885f95",
    AL = "urn:uuid:edef8ba9-79d6-4ace-a3c8-27dcd51d21ed",
    BL = function (a, b, c) {
      try {
        const d = a(b);
        if (d && d.W && d.abort) return c.resolve(d.W), () => d.abort();
        c.resolve(d);
        return () => Promise.resolve(d).then(() => {}).catch(() => {});
      } catch (d) {
        return c.reject(d), () => Promise.resolve();
      }
    },
    CL = function (a, b, c) {
      const d = new _.xn();
      let e = () => {
        d.reject(new _.G(_.E, 7, 7001));
        return a.abort();
      };
      a.W.then(f => {
        a.a ? d.reject(new _.G(_.E, 7, 7001)) : b ? e = BL(b, f, d) : d.resolve(f);
      }, f => {
        c ? e = BL(c, f, d) : d.reject(f);
      });
      return new _.Eo(d, () => e());
    },
    DL = function (a, b) {
      a.G = Math.max(a.G, b);
      a.g.debug("notifyAvailabilityTimeOffset: maxAvailabilityTimeOffset\x3d" + a.G);
    },
    EL = function (a, b) {
      return a.reduce(((c, d, e) => d.catch(c.bind(null, e))).bind(null, b), Promise.reject());
    },
    FL = function (a) {
      return new _.Eo(Promise.reject(a), () => Promise.resolve());
    },
    GL = function (a) {
      return new _.Eo(Promise.all(a.map(b => b.W)), () => Promise.all(a.map(b => b.abort())));
    },
    HL = function (a, b, c) {
      return Array.prototype.filter.call(a.childNodes, d => d instanceof Element && d.localName === c && d.namespaceURI === b);
    },
    IL = function (a, b, c) {
      a = HL(a, b, c);
      return 1 !== a.length ? null : a[0];
    },
    JL = function (a, b, c) {
      return a.hasAttributeNS(b, c) ? a.getAttributeNS(b, c) : null;
    },
    KL = function (a, b, c, d = null) {
      let e = null;
      a = a.getAttribute(b);
      null != a && (e = c(a));
      return null == e ? d : e;
    },
    LL = function (a) {
      if (!a) return null;
      /^\d+-\d+-\d+T\d+:\d+:\d+(\.\d+)?$/.test(a) && (a += "Z");
      a = Date.parse(a);
      return isNaN(a) ? null : Math.floor(a / 1E3);
    },
    ML = function (a) {
      if (!a) return null;
      const b = /^P(?:([0-9]*)Y)?(?:([0-9]*)M)?(?:([0-9]*)D)?(?:T(?:([0-9]*)H)?(?:([0-9]*)M)?(?:([0-9.]*)S)?)?$/.exec(a);
      if (!b) return _.B("Invalid duration string:", a), null;
      a = 31536E3 * Number(b[1] || null) + 2592E3 * Number(b[2] || null) + 86400 * Number(b[3] || null) + 3600 * Number(b[4] || null) + 60 * Number(b[5] || null) + Number(b[6] || null);
      return isFinite(a) ? a : null;
    },
    NL = function (a) {
      var b = /([0-9]+)-([0-9]+)/.exec(a);
      if (!b) return null;
      a = Number(b[1]);
      if (!isFinite(a)) return null;
      b = Number(b[2]);
      return isFinite(b) ? {
        start: a,
        end: b
      } : null;
    },
    OL = function (a) {
      a = Number(a);
      return 0 === a % 1 ? a : null;
    },
    PL = function (a) {
      a = Number(a);
      return 0 === a % 1 && 0 < a ? a : null;
    },
    QL = function (a) {
      a = Number(a);
      return 0 === a % 1 && 0 <= a ? a : null;
    },
    RL = function (a) {
      let b;
      a = (b = a.match(/^(\d+)\/(\d+)$/)) ? Number(b[1]) / Number(b[2]) : Number(a);
      return isNaN(a) ? null : a;
    },
    SL = function (a, b) {
      try {
        const c = _.Ei(a);
        return _.Nm(c, b);
      } catch (c) {
        return null;
      }
    },
    TL = function (a) {
      var b = void 0 === b ? 1024 : b;
      if (!(a instanceof Uint8Array)) return "";
      if (typeof b !== _.r || 0 >= b) b = 1024;
      return (a.byteLength > b ? a.subarray(0, b) : a).toString();
    },
    UL = function (a, b, c, d, e) {
      const f = {
        RepresentationID: b,
        Number: c,
        Bandwidth: d,
        Time: e
      };
      return a.replace(/\$(RepresentationID|Number|Bandwidth|Time)?(?:%0([0-9]+)([diouxX]))?\$/g, (g, h, k, l) => {
        if ("$$" === g) return "$";
        let m = f[h];
        if (null == m) return _.B("URL template does not have an available substitution ", "for identifier", '"' + h + '":', a), g;
        "RepresentationID" === h && k && (_.B("URL template should not contain a width specifier for identifier", '"RepresentationID":', a), k = void 0);
        "Time" === h && (m = Math.round(m));
        switch (l) {
          case void 0:
          case "d":
          case "i":
          case "u":
            g = m.toString();
            break;
          case "o":
            g = m.toString(8);
            break;
          case "x":
            g = m.toString(16);
            break;
          case "X":
            g = m.toString(16).toUpperCase();
            break;
          default:
            g = m.toString();
        }
        k = window.parseInt(k, 10) || 1;
        return Array(Math.max(0, k - g.length) + 1).join("0") + g;
      });
    },
    XL = function (a, b) {
      var c = VL(a, b, vL);
      let d = 1;
      c && (d = PL(c) || 1);
      c = VL(a, b, nL);
      (c = PL(c || "")) && (c /= d);
      let e = Number(VL(a, b, sL)) || 0,
        f = e / d || 0;
      a.Wg && null != a.bd && a.bd !== f && (_.B("The stream is non-compliant with the DASH spec. The value of `presentationTimeOffset` has changed during the transition from dynamic to static manifest, which is not allowed. The last " + `value will be ignored. (Period ID: ${a.Ea.id}, ` + `Representation ID: ${a.P.id})`), e = a.bd * d, f = a.bd);
      var g = VL(a, b, "startNumber");
      let h = QL(g || "");
      if (null == g || null == h) h = 1;
      g = VL(a, b, "endNumber");
      g = null !== g ? QL(g || "") : null;
      var k = WL(a, b, kL),
        l = null;
      if (k) {
        {
          l = d;
          var m = e,
            n = a.Aa.duration || Infinity;
          k = _.Km(k, "S");
          const A = [];
          var q = 0;
          for (let D = 0; D < k.length; ++D) {
            var u = k[D],
              v = KL(u, "t", QL);
            const H = KL(u, "d", QL);
            var y = KL(u, "r", OL);
            null != v && (v -= m);
            if (!H) {
              _.B('"S" element must have a duration:', rL, u);
              break;
            }
            v = null != v ? v : q;
            y = y || 0;
            if (0 > y) if (D + 1 < k.length) {
              y = KL(k[D + 1], "t", QL);
              if (null == y) {
                _.B('An "S" element cannot have a negative repeat', 'if the next "S" element does not have a valid start time:', rL, u);
                break;
              } else if (v >= y) {
                _.B('An "S" element cannot have a negative repeat if its start ', 'time exceeds the next "S" element\'s start time:', rL, u);
                break;
              }
              y = Math.ceil((y - v) / H) - 1;
            } else {
              if (Infinity === n) {
                _.B(lL, "if the Period has an infinite duration:", qL, u);
                break;
              } else if (v / l >= n) {
                _.B(lL, "if its start time exceeds the Period's duration:", qL, u);
                break;
              }
              y = Math.ceil((n * l - v) / H) - 1;
            }
            0 < A.length && v !== q && (Math.abs((v - q) / l) >= _.jt && _.B("SegmentTimeline contains a large gap/overlap:", "the content may have errors in it.", u), A[A.length - 1].end = v / l);
            for (u = 0; u <= y; ++u) q = v + H, A.push({
              start: v / l,
              end: q / l,
              Jh: v
            }), v = q;
          }
          l = A;
        }
      }
      a = VL(a, b, "availabilityTimeOffset");
      b = Number(a || "");
      b = isNaN(b) ? null : b;
      if (null == a || null == b) b = 0;
      return {
        timescale: d,
        Ga: c,
        oc: h,
        Yd: g,
        Ib: f,
        Me: e,
        timeline: l,
        Pd: b
      };
    },
    VL = function (a, b, c) {
      return [b(a.P), b(a.ra), b(a.Ea)].filter(_.Zh).map(d => d.getAttribute(c)).reduce((d, e) => d || e);
    },
    WL = function (a, b, c) {
      return [b(a.P), b(a.ra), b(a.Ea)].filter(_.Zh).map(d => _.Lm(d, c)).reduce((d, e) => d || e);
    },
    ZL = function (a, b, c, d, e, f) {
      const g = JL(a, pL, "href"),
        h = JL(a, pL, "actuate") || "onRequest";
      for (let l = 0; l < a.attributes.length; l++) {
        const m = a.attributes[l];
        m.namespaceURI === pL && (a.removeAttributeNS(m.namespaceURI, m.localName), --l);
      }
      if (5 <= f) return FL(new _.G(_.E, 4, 4028));
      if ("onLoad" !== h) return FL(new _.G(_.E, 4, 4027));
      const k = _.wB([d], [g]);
      d = _.ij(k, b);
      d.type = 1;
      return CL(e.fetch(d), l => {
        l = SL(l.data, a.tagName);
        if (!l) return FL(new _.G(_.E, 4, 4001, {
          uri: g
        }));
        for (; a.childNodes.length;) a.removeChild(a.childNodes[0]);
        for (; l.childNodes.length;) {
          var m = l.childNodes[0];
          l.removeChild(m);
          a.appendChild(m);
        }
        for (m = 0; m < l.attributes.length; m++) {
          const n = l.attributes[m].nodeName,
            q = l.getAttribute(n);
          a.setAttribute(n, q);
        }
        return YL(a, b, c, k[0], e, f + 1);
      });
    },
    YL = function (a, b, c, d, e, f = 0) {
      if (JL(a, pL, "href")) {
        var g = ZL(a, b, c, d, e, f);
        c && (g = CL(g, void 0, () => YL(a, b, c, d, e, f)));
        return g;
      }
      g = [];
      for (let h = 0; h < a.childNodes.length; h++) {
        const k = a.childNodes[h];
        k instanceof Element && ("urn:mpeg:dash:resolve-to-zero:2013" === JL(k, pL, "href") ? (a.removeChild(k), --h) : k.tagName !== kL && g.push(YL(k, b, c, d, e, f)));
      }
      return CL(GL(g), () => a);
    },
    $L = function (a, b, c) {
      const d = a.Aa.duration;
      var e = a.Aa.xf;
      if (!a.Xd) return e = new _.MH(b), _.WB(e, d), e;
      {
        var f = a.Ea;
        const g = a.P;
        f = f && f.id && g && g.id ? `${f.id},${g.id}` : null;
      }
      if (!a.yf || null == c[f]) return a = new _.MH(b), e || _.WB(a, d), c[f] = a;
      c = c[f];
      e || _.WB(new _.MH(b), d);
      _.vC(c, b);
      _.wC(c, _.lC(a.presentationTimeline) - a.Aa.start);
      return c;
    },
    aM = function (a, b) {
      var c = a.presentationTimeline,
        d = [...b.a];
      c.l || 0 === d.length || (c.I = Math.floor(Math.max(c.I || 0, d[d.length - 1].endTime + a.Aa.start)));
      a.yf || _.iC(a.presentationTimeline, [...b.a], a.Aa.start);
    },
    bM = function (a) {
      return "" === a.id ? `${a.schemeIdUri}:${a.startTime}:${a.endTime}` : a.id;
    },
    dM = function (a, b, c, d) {
      let e;
      var f = new _.em().ca("sidx", g => {
        e = cM(b, d, c, g);
      });
      a && f.parse(a);
      if (e) return e;
      {
        f = `${b}-${b + a.byteLength - 1}`;
        const g = c[0];
        a = _.J(a);
        _.jh('Invalid box type. Expected "sidx", but received:', a, "from:", g, "byte range:", f);
        throw new _.G(_.E, 3, 3004, {
          url: g,
          Uh: f,
          size: a.byteLength,
          ii: TL(a)
        });
      }
    },
    cM = function (a, b, c, d) {
      const e = [];
      d.reader.skip(4);
      const f = _.O(d.reader);
      if (0 === f) throw _.jh("Invalid timescale."), new _.G(_.E, 3, 3005);
      if (0 === d.version) {
        var g = _.O(d.reader);
        var h = _.O(d.reader);
      } else g = _.Tl(d.reader), h = _.Tl(d.reader);
      d.reader.skip(2);
      const k = _.Rl(d.reader);
      a = a + d.size + h;
      for (h = 0; h < k; h++) {
        var l = _.O(d.reader);
        const m = (l & 2147483648) >>> 31;
        l &= 2147483647;
        const n = _.O(d.reader);
        d.reader.skip(4);
        if (1 === m) throw _.jh("Hierarchical SIDXs are not supported."), new _.G(_.E, 3, 3006);
        e.push(new _.uC(e.length, g / f - b, (g + n) / f - b, () => c, a, a + l - 1, b));
        g += n;
        a += l;
      }
      d.parser.stop();
      return e;
    },
    eM = function (a) {
      var b = _.Ql(a.a);
      let c;
      for (c = 1; 8 >= c && !(b & 1 << 8 - c); c++);
      if (8 < c) throw new _.G(_.E, 3, 3002);
      const d = new Uint8Array(c);
      d[0] = b;
      for (b = 1; b < c; b++) d[b] = _.Ql(a.a);
      return d;
    },
    hM = function (a) {
      var b = eM(a);
      if (7 < b.length) throw new _.G(_.E, 3, 3002);
      var c = 0;
      for (var d = 0; d < b.length; d++) c = 256 * c + b[d];
      b = c;
      c = eM(a);
      a: {
        for (e of fM) if (_.Xi(c, new Uint8Array(e))) {
          var e = !0;
          break a;
        }
        e = !1;
      }
      if (e) c = a.f.byteLength - a.a.getPosition();else {
        if (8 === c.length && c[1] & 224) throw new _.G(_.E, 3, 3001);
        e = c[0] & (1 << 8 - c.length) - 1;
        for (d = 1; d < c.length; d++) e = 256 * e + c[d];
        c = e;
      }
      c = a.a.getPosition() + c <= a.f.byteLength ? c : a.f.byteLength - a.a.getPosition();
      e = new DataView(a.f.buffer, a.f.byteOffset + a.a.getPosition(), c);
      a.a.skip(c);
      return new gM(b, e);
    },
    iM = function (a) {
      if (8 < a.a.byteLength) throw new _.G(_.E, 3, 3002);
      if (8 === a.a.byteLength && a.a.getUint8(0) & 224) throw new _.G(_.E, 3, 3001);
      let b = 0;
      for (let c = 0; c < a.a.byteLength; c++) {
        const d = a.a.getUint8(c);
        b = 256 * b + d;
      }
      return b;
    },
    lM = function (a, b, c, d, e, f) {
      const g = [],
        h = () => e;
      a = new jM(a.a);
      let k = null,
        l = null;
      for (; _.Ol(a.a);) {
        var m = hM(a);
        if (187 === m.id) {
          var n = kM(m);
          n && (m = c * n.Kh, n = b + n.oh, null != k && g.push(new _.uC(g.length, k - f, m - f, h, l, n - 1, f)), k = m, l = n);
        }
      }
      null != k && g.push(new _.uC(g.length, k - f, d - f, h, l, null, f));
      return g;
    },
    kM = function (a) {
      var b = new jM(a.a);
      a = hM(b);
      if (179 !== a.id) throw _.B("Not a CueTime element."), new _.G(_.E, 3, 3013);
      a = iM(a);
      b = hM(b);
      if (183 !== b.id) throw _.B("Not a CueTrackPositions element."), new _.G(_.E, 3, 3012);
      b = new jM(b.a);
      let c = 0;
      for (; _.Ol(b.a);) {
        const d = hM(b);
        if (241 === d.id) {
          c = iM(d);
          break;
        }
      }
      return {
        Kh: a,
        oh: c
      };
    },
    mM = function (a, b) {
      b = WL(a, b, "Initialization");
      if (!b) return null;
      let c = a.P.gb;
      var d = b.getAttribute("sourceURL");
      d && (c = _.wB(a.P.gb, [d]));
      a = 0;
      d = null;
      if (b = KL(b, "range", NL)) a = b.start, d = b.end;
      return new _.IH(() => c, a, d);
    },
    pM = function (a, b) {
      var c = Number(VL(a, nM, sL)) || 0,
        d = VL(a, nM, vL),
        e = 1;
      d && (e = PL(d) || 1);
      c = c / e || 0;
      d = mM(a, nM);
      {
        e = a.P.mimeType.split("/")[1];
        if (a.P.contentType !== _.F && "mp4" !== e && "webm" !== e) throw _.jh("SegmentBase specifies an unsupported container type.", a.P), new _.G(_.E, 4, 4006);
        if ("webm" === e && !d) throw _.jh(hL, "the SegmentBase uses a WebM container,", "but does not contain an Initialization element.", a.P), new _.G(_.E, 4, 4005);
        const g = WL(a, nM, "RepresentationIndex");
        var f = VL(a, nM, "indexRange");
        let h = a.P.gb;
        f = NL(f || "");
        if (g) {
          const k = g.getAttribute("sourceURL");
          k && (h = _.wB(a.P.gb, [k]));
          f = KL(g, "range", NL, f);
        }
        if (!f) throw _.jh(hL, "the SegmentBase does not contain @indexRange", "or a RepresentationIndex element.", a.P), new _.G(_.E, 4, 4002);
        a.presentationTimeline.B = null;
        a = oM(a, b, d, h, f.start, f.end, e, c);
      }
      return {
        createSegmentIndex: a.createSegmentIndex,
        findSegmentPosition: a.findSegmentPosition,
        getSegmentReference: a.getSegmentReference,
        adjustSegmentDurations: a.adjustSegmentDurations,
        getDuration: a.getDuration,
        initSegmentReference: d,
        getLastSegmentEndTime: a.getLastSegmentEndTime,
        Ba: c
      };
    },
    nM = function (a) {
      return a.gd;
    },
    oM = function (a, b, c, d, e, f, g, h) {
      const k = a.presentationTimeline,
        l = !a.Xd || !a.Aa.xf,
        m = a.Aa.start,
        n = a.Aa.duration;
      let q = b,
        u = null;
      return {
        createSegmentIndex: function (v) {
          return _.w(function* () {
            var y = [q(d, e, f, v), "webm" === g ? q(c.Wa(), c.wa, c.ka, v) : null];
            q = null;
            y = yield Promise.all(y);
            var A = y[0];
            const D = y[1] || null;
            A = "mp4" === g ? dM(A, e, d, h) : new qM().parse(A, D, d, h);
            _.iC(k, A, m);
            u = new _.MH(A);
            l && _.WB(u, n);
            return y;
          });
        },
        findSegmentPosition: function (v) {
          return u.f(v);
        },
        getSegmentReference: function (v) {
          return u.get(v);
        },
        adjustSegmentDurations: function (v, y) {
          return u.h(v, y);
        },
        getDuration: function () {
          return u.getDuration();
        },
        getLastSegmentEndTime: () => n
      };
    },
    xM = function (a, b, c, d) {
      const e = rM(a),
        f = sM(a);
      tM(a, f);
      let g = null;
      if (f.Xc) a.presentationTimeline.B = null, g = uM(a, b, e, f);else if (f.Ga) d || (a.presentationTimeline.B = null, DL(a.presentationTimeline, f.Pd), _.jC(a.presentationTimeline, f.Ga), _.gC(a.presentationTimeline, a.Aa.start)), g = vM(a, f);else {
        b = wM(a, f);
        const h = $L(a, b, c);
        aM(a, h);
        g = {
          createSegmentIndex: Promise.resolve.bind(Promise),
          findSegmentPosition: h.f.bind(h),
          getSegmentReference: h.get.bind(h),
          adjustSegmentDurations: h.h.bind(h),
          getDuration: h.getDuration.bind(h),
          getLastSegmentEndTime: () => _.tC(h)
        };
      }
      return {
        createSegmentIndex: g.createSegmentIndex,
        findSegmentPosition: g.findSegmentPosition,
        getSegmentReference: g.getSegmentReference,
        adjustSegmentDurations: g.adjustSegmentDurations,
        getDuration: g.getDuration,
        initSegmentReference: e,
        getLastSegmentEndTime: g.getLastSegmentEndTime,
        Ba: f.Ib
      };
    },
    rM = function (a) {
      const b = VL(a, yM, "initialization");
      if (!b) return null;
      const c = a.P.id,
        d = a.bandwidth || null,
        e = a.P.gb;
      return new _.IH(function () {
        const f = UL(b, c, null, d, null);
        return _.wB(e, [f]);
      }, 0, null);
    },
    sM = function (a) {
      const b = XL(a, yM),
        c = VL(a, yM, "media");
      a = VL(a, yM, "index");
      return {
        Ga: b.Ga,
        timescale: b.timescale,
        oc: b.oc,
        Yd: b.Yd,
        Ib: b.Ib,
        Me: b.Me,
        timeline: b.timeline,
        fe: c,
        Xc: a,
        Pd: b.Pd
      };
    },
    tM = function (a, b) {
      let c;
      c = b.Xc ? 1 : 0;
      c += b.timeline ? 1 : 0;
      c += b.Ga ? 1 : 0;
      if (0 === c) throw _.jh("SegmentTemplate does not contain any segment information:", "the SegmentTemplate must contain either an index URL template", "a SegmentTimeline, or a segment duration.", a.P), new _.G(_.E, 4, 4002);
      1 !== c && (_.B("SegmentTemplate contains multiple segment information sources:", "the SegmentTemplate should only contain an index URL template,", "a SegmentTimeline or a segment duration.", a.P), b.Xc ? (_.hh("Using the index URL template by default."), b.timeline = null) : _.hh("Using the SegmentTimeline by default."), b.Ga = null);
      if (!b.Xc && !b.fe) throw _.jh(jL, "the SegmentTemplate's media URL template is missing.", a.P), new _.G(_.E, 4, 4002);
    },
    uM = function (a, b, c, d) {
      const e = a.P.mimeType.split("/")[1];
      if ("mp4" !== e && "webm" !== e) throw _.jh("SegmentTemplate specifies an unsupported container type.", a.P), new _.G(_.E, 4, 4006);
      if ("webm" === e && !c) throw _.jh(jL, "the SegmentTemplate uses a WebM container,", "but does not contain an initialization URL template.", a.P), new _.G(_.E, 4, 4005);
      var f = UL(d.Xc, a.P.id, null, a.bandwidth || null, null);
      f = _.wB(a.P.gb, [f]);
      return oM(a, b, c, f, 0, null, e, d.Ib);
    },
    vM = function (a, b) {
      const c = b.fe,
        d = a.Aa.duration,
        e = b.Ga,
        f = b.oc,
        g = b.Yd,
        h = b.timescale,
        k = a.bandwidth || null,
        l = a.P.id,
        m = a.P.gb;
      return {
        createSegmentIndex: Promise.resolve.bind(Promise),
        findSegmentPosition: function (n) {
          return 0 > n || d && n >= d ? null : Math.floor(n / e);
        },
        getSegmentReference: function (n) {
          const q = n + f;
          if (null !== g && q > g) return null;
          const u = n * e;
          let v = u + e;
          d && (v = Math.min(v, d));
          return 0 > v || d && u >= d ? null : new _.uC(n, u, v, function () {
            const y = UL(c, l, q, k, u * h);
            return _.wB(m, [y]);
          }, 0, null, b.Ib);
        },
        adjustSegmentDurations: () => {},
        getDuration: () => d,
        getLastSegmentEndTime: () => d
      };
    },
    wM = function (a, b) {
      const c = b.fe,
        d = b.timeline;
      if (0 === d.length) return [];
      const e = [];
      for (let f = 0; f < d.length; f++) {
        const g = f + b.oc,
          h = d[f].Jh + b.Me,
          k = a.P.id,
          l = a.bandwidth || null,
          m = a.P.gb;
        e.push(new _.uC(g, d[f].start, d[f].end, () => {
          const n = UL(c, k, g, l, h);
          return _.wB(m, [n]);
        }, 0, null, b.Ib));
      }
      return e;
    },
    yM = function (a) {
      return a.ac;
    },
    DM = function (a, b) {
      const c = mM(a, zM),
        d = AM(a);
      BM(a, d);
      const e = CM(a.Aa.duration, d.oc, a.P.gb, d),
        f = $L(a, e, b);
      aM(a, f);
      return {
        createSegmentIndex: Promise.resolve.bind(Promise),
        findSegmentPosition: f.f.bind(f),
        getSegmentReference: f.get.bind(f),
        adjustSegmentDurations: f.h.bind(f),
        getDuration: f.getDuration.bind(f),
        initSegmentReference: c,
        getLastSegmentEndTime: () => _.tC(f),
        Ba: d.Ib
      };
    },
    zM = function (a) {
      return a.$b;
    },
    AM = function (a) {
      const b = EM(a);
      a = XL(a, zM);
      let c = a.oc;
      0 === c && (_.B("SegmentList@startNumber must be \x3e 0"), c = 1);
      let d = 0;
      a.Ga ? d = a.Ga * (c - 1) : a.timeline && 0 < a.timeline.length && (d = a.timeline[0].start);
      return {
        Ga: a.Ga,
        startTime: d,
        oc: c,
        Ib: a.Ib,
        timeline: a.timeline,
        nc: b
      };
    },
    BM = function (a, b) {
      if (!b.Ga && !b.timeline && 1 < b.nc.length) throw _.B(iL, "the SegmentList specifies multiple segments,", "but does not specify a segment duration or timeline.", a.P), new _.G(_.E, 4, 4002);
      if (!b.Ga && !a.Aa.duration && !b.timeline && 1 === b.nc.length) throw _.B(iL, "the SegmentList specifies one segment,", "but does not specify a segment duration, period duration,", "or timeline.", a.P), new _.G(_.E, 4, 4002);
      if (b.timeline && 0 === b.timeline.length) throw _.B(iL, "the SegmentList has an empty timeline.", a.P), new _.G(_.E, 4, 4002);
    },
    CM = function (a, b, c, d) {
      let e = d.nc.length;
      d.timeline && d.timeline.length !== d.nc.length && (e = Math.min(d.timeline.length, d.nc.length), _.B("The number of items in the segment timeline and the number of", "segment URLs do not match, truncating", d.nc.length, "to", e));
      const f = [];
      var g = d.startTime;
      for (let h = 0; h < e; h++) {
        const k = d.nc[h],
          l = _.wB(c, [k.ah]);
        let m;
        m = null != d.Ga ? g + d.Ga : d.timeline ? d.timeline[h].end : g + a;
        f.push(new _.uC(h + b, g, m, () => l, k.start, k.end, d.Ib));
        g = m;
      }
      return f;
    },
    EM = function (a) {
      return [a.P.$b, a.ra.$b, a.Ea.$b].filter(_.Zh).map(b => _.Km(b, "SegmentURL")).reduce((b, c) => 0 < b.length ? b : c).map(b => {
        b.getAttribute("indexRange") && !a.be && (a.be = !0, _.B("We do not support the SegmentURL@indexRange attribute on SegmentList.  We only use the SegmentList@duration attribute or SegmentTimeline, which must be accurate."));
        const c = b.getAttribute("media");
        b = KL(b, "mediaRange", NL, {
          start: 0,
          end: null
        });
        return {
          ah: c,
          start: b.start,
          end: b.end
        };
      });
    },
    GM = function (a, b, c) {
      c = c.map(e => ({
        scheme: e.getAttribute(tL),
        value: e.getAttribute(_.gg)
      }));
      const d = a.a.clockSyncUri;
      !c.length && d && (a.g.info("No UTCTiming elements found, falling back on clockSyncUri from config to synchronize clocks."), c.push({
        scheme: yL,
        value: d
      }));
      return 0 === c.length ? (a.g.warn(gL), Promise.reject(Error(gL))) : EL(c, e => {
        const f = e.scheme;
        e = e.value;
        switch (f) {
          case yL:
          case "urn:mpeg:dash:utc:http-head:2012":
            return FM(a, b, e, "HEAD");
          case "urn:mpeg:dash:utc:http-xsdate:2014":
          case "urn:mpeg:dash:utc:http-iso:2014":
          case "urn:mpeg:dash:utc:http-xsdate:2012":
          case "urn:mpeg:dash:utc:http-iso:2012":
            return FM(a, b, e, "GET");
          case "urn:mpeg:dash:utc:direct:2014":
          case "urn:mpeg:dash:utc:direct:2012":
            {
              const g = Date.parse(e);
              return isNaN(g) ? (a.g.warn(`Invalid date for scheme ${f}, ` + `value: ${e}`), Promise.reject()) : Promise.resolve(g - Date.now());
            }
          case "urn:mpeg:dash:utc:http-ntp:2014":
          case "urn:mpeg:dash:utc:ntp:2014":
          case "urn:mpeg:dash:utc:sntp:2014":
            return a.g.warn("NTP UTCTiming scheme is not supported"), Promise.reject();
          default:
            return a.g.warn("Unrecognized scheme in UTCTiming element", f), Promise.reject();
        }
      }).catch(() => {
        a.g.warn(eL);
        return Promise.reject(Error(eL));
      });
    },
    HM = function (a, b, c, d) {
      return _.w(function* () {
        const e = _.Km(b, "UTCTiming");
        try {
          const f = yield GM(a, c, e);
          d.H = f;
          a.g.info("Clock synchronized. The difference between server and " + `client clock was ${f} ms.`);
        } catch (f) {
          a.g.warn("Failed to synchronize clocks. This content may not play on clients with bad clocks!");
        }
        return d;
      });
    },
    FM = function (a, b, c, d) {
      return _.w(function* () {
        var e = _.wB(b, [c]);
        e = yield a.f(e, d);
        if ("HEAD" === d) {
          if (!e.headers || !e.headers.date) throw a.g.warn("UTC timing response is missing", "expected date header"), Error("Date HTTP header missing.");
          e = e.headers.date;
        } else e = _.Ei(e.data);
        e = Date.parse(e);
        if (isNaN(e)) throw a.g.warn("Unable to parse date from UTC timing response"), Error("Date value invalid.");
        return e - Date.now();
      });
    },
    JM = function (a, b) {
      return a.find(c => IM.get(c.Rb) === b);
    },
    LM = function (a) {
      const b = IL(a.node, wL, "pro");
      if (!b) return KM.warn(fL, a), null;
      a = _.Ui(b.textContent);
      a = _.Ym(a);
      a = _.Qm(a.a);
      return {
        keyId: null,
        Rb: AL,
        node: document.createElement("null"),
        init: [{
          initData: _.kB(a),
          initDataType: _.ad,
          keyId: null
        }]
      };
    },
    MM = function (a, b) {
      b = void 0 === b ? "4.0.0.0" : b;
      const c = IL(a.node, xL, "pssh");
      if (!c) return _.B("Missing \x3ccenc:pssh/\x3e node in ContentProtection element", a), null;
      a = _.Ui(c.textContent);
      a = _.Sm(a);
      return a ? (b = _.lB(b, a.a)) ? {
        keyId: null,
        Rb: zL,
        node: document.createElement("null"),
        init: [{
          initData: _.kB(b),
          initDataType: _.ad,
          keyId: null
        }]
      } : null : (_.B("Unable to parse WV PSSH data. Can not create PR data!"), null);
    },
    NM = function (a, b) {
      const c = IL(a.node, wL, "pro");
      if (!c) return _.B(fL, a), null;
      a = _.Ui(c.textContent);
      a = _.Ym(a);
      return a.version !== b && (_.ih(`Parsing from ${a.version} to ${b}.`), a = _.mB(a, b)) ? {
        keyId: null,
        Rb: zL,
        node: document.createElement("null"),
        init: [{
          initData: _.kB(a),
          initDataType: _.ad,
          keyId: null
        }]
      } : null;
    },
    QM = function (a, b, c, d) {
      const e = OM(a);
      let f = null;
      a = [];
      let g = [];
      const h = JM(e, _.xd),
        k = JM(e, _.vd);
      if (k && d) {
        var l = NM(k, d);
        l && (e[e.indexOf(k)] = l);
      }
      k && !h && (l = LM(k)) && e.push(l);
      h && !k && (d = MM(h, d || void 0)) && e.push(d);
      d = new Set(e.map(m => m.keyId));
      d.delete(null);
      if (1 < d.size) throw new _.G(_.E, 4, 4010);
      b || (g = e.filter(m => "urn:mpeg:dash:mp4protection:2011" === m.Rb ? (f = m.init || f, !1) : !0), g.length && (a = PM(f, g, c), 0 === a.length && (a = [_.xB(_.Ce, f, c)])));
      if (e.length && (b || !g.length)) {
        a = [];
        for (const m of IM.values()) m !== _.Le && a.push(_.xB(m, f, c));
      }
      if (b = Array.from(d)[0] || null) for (const m of a) for (const n of m.initData) n.keyId = b;
      return {
        Nc: b,
        Xh: f,
        drmInfos: a,
        gf: !0
      };
    },
    OM = function (a) {
      const b = [];
      for (const c of a) (a = RM(c)) && b.push(a);
      return b;
    },
    PM = function (a, b, c) {
      const d = [];
      for (const f of b) {
        var e = IM.get(f.Rb);
        if (e) {
          b = _.xB(e, f.init || a, c);
          if (e = SM.get(e)) b.licenseServerUri = e(f);
          d.push(b);
        }
      }
      return d;
    },
    TM = function (a, b, c, d, e) {
      const f = QM(a, c, d, e);
      if (b.gf) {
        a = 1 === b.drmInfos.length && !b.drmInfos[0].keySystem;
        c = 0 === f.drmInfos.length;
        if (0 === b.drmInfos.length || a && !c) b.drmInfos = f.drmInfos;
        b.Nc = f.Nc || b.Nc;
        b.gf = !1;
      } else if (0 < f.drmInfos.length && (b.drmInfos = b.drmInfos.filter(g => f.drmInfos.some(h => h.keySystem === g.keySystem)), 0 === b.drmInfos.length)) throw new _.G(_.E, 4, 4008);
      return f.Nc || b.Nc;
    },
    RM = function (a) {
      let b = a.getAttribute(tL),
        c = JL(a, xL, "default_KID");
      const d = HL(a, xL, "pssh").map(_.Mm),
        e = IL(a, wL, "pro");
      if (!b) return _.jh("Missing required schemeIdUri attribute on", "ContentProtection element", a), null;
      b = b.toLowerCase();
      if (c && (c = c.replace(/-/g, "").toLowerCase(), c.includes(" "))) throw new _.G(_.E, 4, 4009);
      let f = [];
      try {
        if (f = d.map(g => ({
          initDataType: _.ad,
          initData: _.Ui(g),
          keyId: null
        })), 0 === f.length && e) try {
          const g = _.Ui(e.textContent),
            h = _.Ym(g);
          f.push({
            initDataType: _.ad,
            initData: _.kB(h),
            keyId: null
          });
        } catch (g) {
          _.B("Unable to parse ms:pro payload", g), f = [];
        }
      } catch (g) {
        throw new _.G(_.E, 4, 4007);
      }
      return {
        node: a,
        Rb: b,
        keyId: c,
        init: 0 < f.length ? f : null
      };
    },
    VM = function (a, b) {
      a = UM(a).filter(c => c.getLastSegmentEndTime).map(c => c.getLastSegmentEndTime());
      0 < a.length && (a = Math.min(...a), _.hC(b.presentationTimeline, a + b.Aa.start));
    },
    WM = function (a, b) {
      return _.VC(_.gk(a, b)) ? _.F : a.split("/")[0];
    },
    UM = function (a) {
      const b = new Set();
      a.forEach(c => {
        c.variants.forEach(d => {
          d.audio && b.add(d.audio);
          d.video && b.add(d.video);
        });
      });
      return Array.from(b);
    },
    YM = function (a) {
      return _.w(function* () {
        var b = _.ij(a.l, a.a.attemptParameters);
        b.type = 1;
        var c = Date.now();
        b = a.D.networkingEngine.fetch(b);
        _.tj(a.o, b);
        var d = yield b.W;
        if (!a.D) return 0;
        d.uri && !a.l.includes(d.uri) && a.l.unshift(d.uri);
        b = null != a.f;
        d = yield XM(a, d.data, d.uri);
        c = (Date.now() - c) / 1E3;
        _.YB(a.H, 1, c);
        if (b && a.D.onUpdate) a.D.onUpdate(d.manifest);
        if (d.Ih) {
          if (a.D.onTurnedStatic) a.D.onTurnedStatic();
          a.D.onEvent(new _.C(_.Ae));
        }
        return c;
      });
    },
    ZM = function (a, b) {
      0 > a.j || a.A.fa(Math.max(3, a.j - b, _.ZB(a.H)));
    },
    XM = function (a, b, c) {
      return _.w(function* () {
        var d = SL(b, "MPD");
        if (!d) throw new _.G(_.E, 4, 4001, c);
        d = YL(d, a.a.attemptParameters, a.a.xlinkFailGracefully, c, a.D.networkingEngine);
        _.tj(a.o, d);
        d = yield d.W;
        return $M(a, d, c);
      });
    },
    $M = function (a, b, c) {
      return _.w(function* () {
        var d = [c],
          e = _.Km(b, "Location").map(_.Mm).filter(_.Zh);
        0 < e.length && (d = _.wB(d, e), a.l = d);
        e = _.Km(b, "BaseURL").map(_.Mm);
        var f = _.wB(d, e);
        d = b.getAttribute("type") || uL;
        a.j = KL(b, "minimumUpdatePeriod", ML, -1);
        d === uL && 0 <= a.j && (a.g.warn("Invalid manifest - a static manifest should not contain the `minimumUpdatePeriod` attribute. The attribute will be ignored."), a.j = -1, a.A.stop());
        e = KL(b, "maxSegmentDuration", ML);
        const g = d === uL && "dynamic" === a.B;
        g && (a.g.info("MPD dynamic to static transition."), d = "dynamic");
        const h = d === uL;
        var k = "dynamic" === d;
        if (a.f) var l = a.f.presentationTimeline;else {
          l = a.a.timeline || {};
          var m = KL(b, "suggestedPresentationDelay", ML),
            n = KL(b, "minBufferTime", ML, 0);
          m = null != a.a.suggestedPresentationDelay ? a.a.suggestedPresentationDelay : a.a.lowLatencyMode ? 0 : null != m ? m : 3 * n;
          n = KL(b, "availabilityStartTime", LL);
          l = new _.LH(l, n, m, h, !!a.a.autoCorrectDrift, !!a.a.lowLatencyMode);
          l = h ? l : HM(a.I, b, f, l);
        }
        l = yield l;
        m = KL(b, "timeShiftBufferDepth", ML);
        m = k && a.a.availabilityWindowOverride && !isNaN(a.a.availabilityWindowOverride) ? a.a.availabilityWindowOverride : null === m ? Infinity : m;
        l.A = m;
        if (!a.D) throw new _.G(_.E, 7, 7001);
        k = {
          Xd: k,
          yf: null != a.f,
          presentationTimeline: l,
          Ea: null,
          Aa: null,
          ra: null,
          P: null,
          bandwidth: 0,
          be: !1,
          Wg: g,
          Ge: null,
          bd: null
        };
        f = aN(a, k, f, b);
        m = f.duration;
        n = f.periods;
        g && (VM(n, k), l.C = !0);
        if (h || !g && !f.df) l.f = m || Infinity, m && _.aC(l.a, 0, m);
        _.jC(l, e || 1);
        l.isLive();
        a.f || (a.f = {
          presentationTimeline: l,
          periods: n,
          minBufferTime: 0,
          cues: []
        });
        a.f.cues = f.cues;
        a.B = d;
        return {
          manifest: a.f,
          Ih: g
        };
      });
    },
    aN = function (a, b, c, d) {
      const e = KL(d, "mediaPresentationDuration", ML),
        f = a.f ? a.f.periods[a.f.periods.length - 1] || null : null;
      var g = [];
      const h = [];
      var k = 0;
      d = _.Km(d, "Period");
      for (let q = 0; q < d.length; q++) {
        var l = d[q];
        k = KL(l, _.Lf, ML, k);
        var m = KL(l, nL, ML);
        let u = null;
        if (q !== d.length - 1) {
          var n = KL(d[q + 1], _.Lf, ML);
          null != n && (u = n - k);
        } else null != e && (u = e - k);
        n = _.jt;
        u && m && Math.abs(u - m) > n && a.g.warn("There is a gap/overlap between Periods", l);
        null == u && (u = m);
        l = bN(a, b, c, {
          start: k,
          duration: u,
          node: l,
          xf: null == u || q === d.length - 1
        });
        m = l.Ea;
        h.push(m);
        g = g.concat(l.cues);
        a.F.includes(m.id) || (a.F.push(m.id), a.f && (a.D.filterNewPeriod(m), a.f.periods.push(m)));
        if (null == u) {
          q !== d.length - 1 && a.g.warn("Skipping Period", q + 1, "and any subsequent Periods:", "Period", q + 1, "does not have a valid start time.", h[q + 1]);
          k = null;
          break;
        }
        k += u;
      }
      null == a.f && a.D.filterAllPeriods(h);
      f && (b = h.find(q => q.id === f.id)) && (f.endTime = b.endTime);
      g = cN(a, g);
      return null != e ? (k !== e && a.g.warn("@mediaPresentationDuration does not match the total duration of ", "all Periods."), {
        periods: h,
        duration: e,
        df: !1,
        cues: g
      }) : {
        periods: h,
        duration: k,
        df: !0,
        cues: g
      };
    },
    bN = function (a, b, c, d) {
      b.Ea = dN(a, d.node, null, c);
      b.Aa = d;
      c = b.Ea.id || `__clpp_period_${d.start}`;
      b.Ea.id || (a.g.info("No Period ID given for Period with start time " + d.start + `,  Assigning a default: ${c}.`), b.Ea.id = c);
      const e = _.Km(d.node, "EventStream").flatMap(A => eN(a, d.start, d.duration, A)),
        f = _.Km(d.node, _.ea).map(A => fN(a, b, A)).filter(_.Zh);
      if (b.Xd) {
        var g = [];
        for (var h of f) for (var k of h.rh) g.push(k);
        if (g.length !== new Set(g).size) throw new _.G(_.E, 4, 4018);
      }
      h = f.filter(A => !A.Le);
      k = f.filter(A => A.Le);
      for (var l of k) {
        l.streams.forEach(A => {
          A.trickPlay = !0;
        });
        k = l.streams[0];
        g = l.Le;
        for (var m of h) if (g.includes(m.id)) for (var n of m.streams) n.trickModeVideo = k;
      }
      m = gN(f, _.t);
      n = gN(h, _.p);
      if (!m.length && !n.length) throw new _.G(_.E, 4, 4004);
      n.length || (n = [null]);
      m.length || (m = [null]);
      l = [];
      for (var q of n) for (var u of m) hN(a, q, u, l);
      u = [...gN(h, _.F), ...gN(h, _.eB)];
      q = [];
      for (var v of u) q.push.apply(q, v.streams);
      u = gN(h, _.be);
      v = [];
      for (var y of u) v.push.apply(v, y.streams);
      y = iN(d, h);
      return {
        Ea: {
          id: c,
          configurationId: a.D.configurationId,
          startTime: d.start,
          endTime: y,
          textStreams: q,
          thumbStreams: v,
          variants: l
        },
        cues: e
      };
    },
    cN = function (a, b) {
      const c = new Set(b.map(bM));
      a.h.forEach((d, e) => {
        c.has(e) || a.h.delete(e);
      });
      b.forEach(d => {
        const e = bM(d);
        a.h.has(e) || a.h.set(e, d);
      });
      return Array.from(a.h.values());
    },
    dN = function (a, b, c, d) {
      c = c || {
        contentType: "",
        mimeType: "",
        codecs: "",
        emsgSchemeIdUris: [],
        frameRate: void 0,
        he: null
      };
      d = d || c.gb;
      const e = _.Km(b, "BaseURL").map(_.Mm);
      let f = b.getAttribute("contentType") || c.contentType;
      const g = b.getAttribute(_.xe) || c.mimeType,
        h = b.getAttribute(_.pd) || c.codecs,
        k = KL(b, "frameRate", RL) || c.frameRate;
      var l = _.Km(b, "InbandEventStream");
      var m = c.emsgSchemeIdUris.slice();
      for (var n of l) l = n.getAttribute(tL), m.includes(l) || m.push(l);
      n = _.Km(b, "AudioChannelConfiguration");
      a = jN(a, n) || c.he;
      f || (f = WM(g, h));
      return {
        gb: _.wB(d, e),
        gd: _.Lm(b, "SegmentBase") || c.gd,
        $b: _.Lm(b, "SegmentList") || c.$b,
        ac: _.Lm(b, "SegmentTemplate") || c.ac,
        width: KL(b, _.Bg, QL) || c.width,
        height: KL(b, _.Zd, QL) || c.height,
        contentType: f,
        mimeType: g,
        codecs: h,
        frameRate: k,
        emsgSchemeIdUris: m,
        id: b.getAttribute("id"),
        he: a
      };
    },
    eN = function (a, b, c, d) {
      const e = d.getAttribute(tL) || "",
        f = d.getAttribute(_.gg) || "",
        g = KL(d, vL, QL) || 1,
        h = KL(d, sL, QL) || 0,
        k = [];
      for (const m of _.Km(d, "Event")) {
        d = KL(m, "presentationTime", QL) || 0;
        var l = KL(m, nL, QL) || 0;
        d = b + d / g - h / g;
        l = d + l / g;
        null != c && (d = Math.min(d, b + c), l = Math.min(l, b + c));
        let n = _.ag;
        e.startsWith(_.cg) ? n = _.Bh : "urn:mpeg:dash:event:callback:2015" === e && (n = _.Be);
        const q = {
          configurationId: a.D.configurationId,
          schemeIdUri: e,
          value: f,
          startTime: d,
          endTime: l,
          id: m.getAttribute("id") || "",
          type: n,
          eventElement: m
        };
        q.type === _.Be && (q.Ed = () => {
          if (q.eventElement) {
            var u = q.eventElement.getAttribute("messageData") || "";
            u && u.startsWith("http") && (u = _.ij(u), u.type = _.hj, a.D.networkingEngine.fetch(u));
          }
        });
        k.push(q);
      }
      return k;
    },
    fN = function (a, b, c) {
      b.ra = dN(a, c, b.Ea, null);
      let d = !1;
      var e = _.Km(c, "Role");
      const f = e.map(X => X.getAttribute(_.gg)).filter(_.Zh);
      let g = null;
      var h = _.Km(c, "Label").map(X => X.textContent).filter(_.Zh);
      h && 0 < h.length && (g = h[h.length - 1]);
      let k = void 0;
      (h = b.ra.contentType === _.F) && (k = _.eB);
      for (var l of e) if (e = l.getAttribute(tL), null == e || "urn:mpeg:dash:role:2011" === e || "urn:mpeg:dash:role" === e) switch (e = l.getAttribute(_.gg), e) {
        case _.se:
          d = !0;
          break;
        case _.PA:
        case _.eB:
          k = e;
          break;
        case "forced-subtitle":
        case "forced_subtitle":
        case _.Wd:
          k = _.Wd;
      }
      e = _.Km(c, dL);
      l = null;
      for (var m of e) {
        e = m.getAttribute(tL);
        var n = m.getAttribute(_.gg);
        if (e !== oL && !kN.includes(e) || void 0 === n) return null;
        e === oL && (0 === n.length && (l = null), l = n.split(" "));
      }
      m = _.Km(c, "Accessibility");
      const q = new Map();
      for (var u of m) if (e = u.getAttribute(tL), "urn:scte:dash:cc:cea-608:2015" === e || "urn:scte:dash:cc:cea-708:2015" === e) if (e = 1, n = u.getAttribute(_.gg), null != n) for (var v of n.split(";")) {
        if (v.includes("\x3d")) {
          var y = v.split("\x3d");
          n = y[0].startsWith("CC") ? y[0] : "CC" + y[0];
          y = y[1].split(",")[0].split(":").pop();
        } else n = "CC" + e, e += 2, y = v;
        q.set(n, _.qi(y));
      } else q.set("CC1", _.$f);
      u = _.Km(c, cL);
      const A = QM(u, a.a.ignoreDrmInfo, b.Ea.id, a.a.playreadyVersion || null),
        D = c.getAttribute("lang") || _.$f;
      u = _.qi(D);
      null == g && (g = c.getAttribute("label"));
      v = _.Km(c, _.Db);
      c = v.map(X => lN(a, b, A, k, D, g, d, f, q, X)).filter(_.Zh);
      if (0 === c.length) {
        if (h) return null;
        throw new _.G(_.E, 4, 4003);
      }
      if (!b.ra.contentType || b.ra.contentType === mL) {
        b.ra.contentType = WM(c[0].mimeType, c[0].codecs);
        for (var H of c) H.type = b.ra.contentType;
      }
      for (const X of c) for (const vb of A.drmInfos) X.keyId && !vb.keyIds.includes(X.keyId) && vb.keyIds.push(X.keyId);
      c = mN(c, b.ra.contentType, m);
      H = v.map(X => X.getAttribute("id")).filter(_.Zh);
      return {
        id: b.ra.id || "__fake__" + a.w++,
        contentType: b.ra.contentType,
        language: u,
        originalLanguage: D,
        de: d,
        streams: c,
        drmInfos: A.drmInfos,
        Le: l,
        rh: H
      };
    },
    gN = function (a, b) {
      return a.filter(c => c.contentType === b);
    },
    hN = function (a, b, c, d) {
      if (b || c) if (b && c) {
        if (_.EB(b.drmInfos, c.drmInfos)) {
          var e = _.DB(b.drmInfos, c.drmInfos);
          for (var f of b.streams) for (const h of c.streams) {
            var g = (h.bandwidth || 0) + (f.bandwidth || 0);
            g = {
              id: a.w++,
              language: b.language,
              primary: b.de || c.de,
              audio: f,
              video: h,
              bandwidth: g,
              drmInfos: e,
              allowedByApplication: !0,
              allowedByKeySystem: !0
            };
            d.push(g);
          }
        }
      } else {
        f = b || c;
        for (e of f.streams) g = e.bandwidth || 0, g = {
          id: a.w++,
          language: f.language || _.$f,
          primary: f.de,
          audio: b ? e : null,
          video: c ? e : null,
          bandwidth: g,
          drmInfos: f.drmInfos,
          allowedByApplication: !0,
          allowedByKeySystem: !0
        }, d.push(g);
      }
    },
    iN = function (a, b) {
      return null !== a.duration ? a.start + a.duration : 0 < b.length ? (b = b.filter(c => 0 < c.streams.length).map(c => c.streams).flat().filter(c => c.getLastSegmentEndTime).map(c => c.getLastSegmentEndTime()), 0 < b.length ? a.start + Math.min(...b) : null) : null;
    },
    lN = function (a, b, c, d, e, f, g, h, k, l) {
      const m = _.qi(e);
      b.P = dN(a, l, b.ra, null);
      if (!nN(a, b.P)) return a.g.warn("Skipping Representation", b.P), null;
      b.Ge = `${b.Ea.id} ${b.P.id}`;
      b.bd = a.C.get(b.Ge);
      b.bandwidth = KL(l, "bandwidth", PL) || 0;
      const n = oN(a, b);
      if (!n) return null;
      a.C.set(b.Ge, n.Ba);
      var q = _.Km(l, cL);
      a = TM(q, c, a.a.ignoreDrmInfo, b.Ea.id, a.a.playreadyVersion || null);
      q = !1;
      _.Km(l, "SupplementalProperty").some(y => "tag:dolby.com,2018:dash:EC3_ExtensionType:2018" === y.getAttribute(tL) && "JOC" === y.getAttribute(_.gg)) && (b.P.mimeType = "audio/eac3-joc", q = !0);
      let u = void 0;
      if (b.P.contentType === _.be) {
        var v = (l = _.Lm(l, dL)) && l.getAttribute(tL);
        v = v || "";
        if (pN.includes(v) && (l = _.BB(l.getAttribute(_.gg) || "")) && (n.horizontalTiles = l.Uc, n.verticalTiles = l.kd, b.ra.ac)) {
          v = KL(b.ra.ac, nL, QL) || 0;
          const y = KL(b.ra.ac, vL, QL) || 1;
          (v /= y) && (u = v / l.Fh);
        }
      }
      return {
        id: _.QH++,
        originalId: b.P.id,
        createSegmentIndex: n.createSegmentIndex,
        findSegmentPosition: n.findSegmentPosition,
        getSegmentReference: n.getSegmentReference,
        initSegmentReference: n.initSegmentReference,
        adjustSegmentDurations: n.adjustSegmentDurations,
        getLastSegmentEndTime: n.getLastSegmentEndTime,
        getDuration: n.getDuration,
        segmentLookupStrategy: _.TA,
        mimeType: b.P.mimeType,
        codecs: b.P.codecs,
        frameRate: b.P.frameRate,
        bandwidth: b.bandwidth,
        width: b.P.width,
        height: b.P.height,
        kind: d,
        encrypted: 0 < c.drmInfos.length,
        keyId: a,
        language: m,
        originalLanguage: e,
        label: f,
        type: b.ra.contentType,
        primary: g,
        trickModeVideo: null,
        emsgSchemeIdUris: b.P.emsgSchemeIdUris,
        roles: h,
        channelsCount: b.P.he,
        spatialAudio: q,
        closedCaptions: k,
        horizontalTiles: n.horizontalTiles,
        verticalTiles: n.verticalTiles,
        durationPerTile: u
      };
    },
    mN = function (a, b, c) {
      const d = b === _.eB ? _.F : b;
      b = c.map(e => ({
        scheme: e.getAttribute(tL) || "",
        id: e.getAttribute("id") || void 0,
        value: e.getAttribute(_.gg) || "",
        protocol: "DASH",
        type: d
      }));
      for (const e of a) e.accessibility = b;
      return a;
    },
    nN = function (a, b) {
      let c;
      c = b.gd ? 1 : 0;
      c += b.$b ? 1 : 0;
      c += b.ac ? 1 : 0;
      if (0 === c) {
        if (b.contentType === _.F || b.contentType === mL) return !0;
        a.g.warn("Representation does not contain a segment information source:", "the Representation must contain one of SegmentBase, SegmentList,", 'SegmentTemplate, or explicitly indicate that it is "text".', b);
        return !1;
      }
      1 !== c && (a.g.warn("Representation contains multiple segment information sources:", "the Representation should only contain one of SegmentBase,", "SegmentList, or SegmentTemplate.", b), b.gd ? (a.g.info("Using SegmentBase by default."), b.$b = null) : a.g.info("Using SegmentList by default."), b.ac = null);
      return !0;
    },
    oN = function (a, b) {
      const c = a.a.attemptParameters;
      var d = b.P.contentType;
      d = d === _.F || d === mL;
      try {
        const e = (f, g, h, k) => _.w(function* () {
          const l = _.$B(f, g, h, c);
          l.type = 2;
          return (yield k.fetch(l)).data;
        });
        if (b.P.gd) return pM(b, e);
        if (b.P.$b) return DM(b, a.G);
        if (b.P.ac) return xM(b, e, a.G, !!a.f);
        {
          const f = b.P.gb,
            g = b.Aa.duration || 0;
          a = () => g;
          return {
            createSegmentIndex: () => Promise.resolve(),
            findSegmentPosition: h => 0 <= h && h < g ? 1 : null,
            getSegmentReference: h => 1 !== h ? null : new _.uC(1, 0, g, () => f, 0, null, 0),
            adjustSegmentDurations: () => {},
            getDuration: a,
            getLastSegmentEndTime: a,
            verticalTiles: void 0,
            horizontalTiles: void 0,
            initSegmentReference: null,
            Ba: 0
          };
        }
      } catch (e) {
        if (d && 4002 === e.code) return a.g.debug("Ignoring DASH_NO_SEGMENT_INFO error in a text stream", e), null;
        throw e;
      }
    },
    jN = function (a, b) {
      for (const d of b) {
        b = d.getAttribute(tL);
        if (!b) continue;
        const e = d.getAttribute(_.gg);
        if (e) switch (b) {
          case "urn:mpeg:dash:outputChannelPositionList:2012":
            return e.trim().split(/ +/).length;
          case "urn:mpeg:dash:23003:3:audio_channel_configuration:2011":
          case "urn:dts:dash:audio_channel_configuration:2012":
            var c = parseInt(e, 10);
            if (!c) {
              a.g.warn(bL, b, e);
              continue;
            }
            return c;
          case "tag:dolby.com,2014:dash:audio_channel_configuration:2011":
          case "urn:dolby:dash:audio_channel_configuration:2011":
            c = parseInt(e, 16);
            if (!c) {
              a.g.warn(bL, b, e);
              continue;
            }
            for (a = 0; c;) c & 1 && ++a, c >>= 1;
            return a;
          default:
            a.g.warn("Unrecognized audio channel scheme:", b, e);
        }
      }
      return null;
    },
    pN = ["http://dashif.org/thumbnail_tile", "http://dashif.org/guidelines/thumbnail_tile"],
    kN = ["urn:mpeg:mpegB:cicp:ColourPrimaries", "urn:mpeg:mpegB:cicp:MatrixCoefficients", "urn:mpeg:mpegB:cicp:TransferCharacteristics"];
  var jM = class {
      constructor(a) {
        this.f = a;
        this.a = new _.Rm(a, !1);
      }
    },
    fM = [[255], [127, 255], [63, 255, 255], [31, 255, 255, 255], [15, 255, 255, 255, 255], [7, 255, 255, 255, 255, 255], [3, 255, 255, 255, 255, 255, 255], [1, 255, 255, 255, 255, 255, 255, 255]],
    gM = class {
      constructor(a, b) {
        this.id = a;
        this.a = b;
      }
    };
  var qM = class {
    parse(a, b, c, d) {
      var e;
      b = new jM(new DataView(b));
      if (440786851 !== hM(b).id) throw _.jh("Not an EBML element."), new _.G(_.E, 3, 3008);
      var f = hM(b);
      if (408125543 !== f.id) throw _.jh("Not a Segment element."), new _.G(_.E, 3, 3009);
      b = f.a.byteOffset;
      f = new jM(f.a);
      for (e = null; _.Ol(f.a);) {
        var g = hM(f);
        if (357149030 === g.id) {
          e = g;
          break;
        }
      }
      if (!e) throw _.jh("Not an Info element."), new _.G(_.E, 3, 3010);
      f = new jM(e.a);
      g = 1E6;
      for (e = null; _.Ol(f.a);) {
        const h = hM(f);
        if (2807729 === h.id) g = iM(h);else if (17545 === h.id) if (e = h, 4 === e.a.byteLength) e = e.a.getFloat32(0);else if (8 === e.a.byteLength) e = e.a.getFloat64(0);else throw new _.G(_.E, 3, 3003);
      }
      if (null == e) throw new _.G(_.E, 3, 3011);
      f = g /= 1E9;
      e *= g;
      a = hM(new jM(new DataView(a)));
      if (475249515 !== a.id) throw _.jh("Not a Cues element."), new _.G(_.E, 3, 3007);
      return lM(a, b, f, e, c, d);
    }
  };
  var qN = class {
    constructor(a, b) {
      this.g = a;
      this.f = b;
      this.a = {
        clockSyncUri: void 0
      };
    }
  };
  var IM = new Map().set("urn:uuid:1077efec-c0b2-4d02-ace3-3c1e52e2fb4b", _.Le).set(AL, _.xd).set(zL, _.vd).set("urn:uuid:79f0049a-4098-8642-ab92-e65be0885f95", _.vd).set("urn:uuid:94ce86fb-07ff-4f43-adb8-93d2fa968ca2", _.sd).set("urn:uuid:f239e769-efa3-4850-9c16-a903c6932efb", _.rd),
    SM = new Map().set(_.xd, function (a) {
      return (a = IL(a.node, "urn:microsoft", "laurl")) ? a.getAttribute("licenseUrl") || "" : "";
    }).set(_.vd, function (a) {
      a = IL(a.node, wL, "pro");
      if (!a) return "";
      a = _.Ui(a.textContent);
      return _.Ym(a).h || "";
    }),
    KM = new _.R("clpp.dash.ContentProtection");
  var rN = class {
    constructor() {
      this.D = this.a = null;
      this.l = [];
      this.f = null;
      this.F = [];
      this.w = 1;
      this.G = {};
      this.j = 0;
      this.H = new _.JH(5);
      this.A = new _.mk(() => {
        this.Pb();
      });
      this.o = new _.ut();
      this.h = new Map();
      this.g = new _.R("clpp.dash.DashParser");
      this.B = null;
      this.I = new qN(this.g, (a, b) => {
        a = _.ij(a, this.a.attemptParameters);
        a.type = 5;
        a.method = b;
        b = this.D.networkingEngine.fetch(a);
        _.tj(this.o, b);
        return b.W;
      });
      this.C = new Map();
      this.J = !1;
    }
    configure(a) {
      this.a = a;
      this.I.a = {
        clockSyncUri: a.clockSyncUri
      };
    }
    start(a, b) {
      const c = this;
      return _.w(function* () {
        c.l = [a];
        c.D = b;
        const d = yield YM(c);
        c.D && ZM(c, d);
        if (!c.D) throw new _.G(_.E, 7, 7001);
        return c.f;
      });
    }
    requestStop() {
      this.J = !0;
    }
    stop() {
      this.a = this.D = null;
      this.l = [];
      this.f = null;
      this.F = [];
      this.G = {};
      this.B = null;
      this.j = -1;
      this.A.stop();
      this.C.clear();
      this.h.clear();
      return this.o.destroy();
    }
    update() {
      YM(this).catch(a => {
        if (this.D && a) this.D.onError(a);
      });
    }
    setCurrentVariant() {}
    Pb() {
      const a = this;
      return _.w(function* () {
        a.g.debug(_.KA);
        let b = 0;
        try {
          b = yield YM(a);
        } catch (c) {
          if (a.D && c.a && (a.D.onError(c), c.severity === _.E)) return;
        }
        a.D && (a.J ? yield a.stop() : ZM(a, b));
      });
    }
  };
  _.x("clpp.dash.DashComponent", class extends _.qu {
    f() {
      _.fs.mpd = rN;
      _.ds[_.Ac] = rN;
    }
    a() {
      _.TC("mpd");
      _.UC(_.Ac);
    }
    id() {
      return "dash";
    }
  });
};
f.call(g, window);