import {Component, Input, OnDestroy, OnInit, Signal} from '@angular/core';
import {ActivatedRoute, RouterOutlet} from '@angular/router';
import {Apollo} from 'apollo-angular';
import {Organization, Policy, Query} from '../../types';
import {ContextService} from '../../services/context.service';
import {map, switchMap, tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {GET_POLICIES} from '../../queries';
import {HttpHeaders} from '@angular/common/http';
import {NgIf} from '@angular/common';
import {HeadlineSectionComponent} from '../headline-section/headline-section.component';
import {LogoAreaComponent} from '../logo-area/logo-area.component';

@Component({
  selector: 'app-organization-selected',
  imports: [
    RouterOutlet,
    NgIf,
    HeadlineSectionComponent,
    LogoAreaComponent
  ],
  templateUrl: './organization-selected.component.html',
  styleUrl: './organization-selected.component.scss',
  standalone: true
})
export class OrganizationSelectedComponent implements OnDestroy, OnInit {
  policies: Signal<Policy[]>;

  constructor(contextService: ContextService) {
     this.policies = contextService.policies
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }
}
