import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  HostListener,
  Input,
  signal,
  Signal,
  WritableSignal
} from '@angular/core';
import {forkJoin, timer} from 'rxjs';
import {File, Folder, Organization, Query, RestoreState, TrackType} from '../../../types';
import {debounceTime, map} from 'rxjs/operators';
import {MkdirDialogComponent} from '../mkdir-dialog/mkdir-dialog.component';
import {Dialog} from '@angular/cdk/dialog';
import {GenerateUploadLinkComponent} from '../generate-upload-link-dialog/generate-upload-link-dialog.component';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {FILTER_DEBOUNCE_TIME, FilterSectionComponent} from '../../filter-section/filter-section.component';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {SelectionModel} from '@angular/cdk/collections';
import {Apollo} from 'apollo-angular';
import {FILE_FOLDER_LIST_WITH_ARCHIVE, GET_FOLDER2} from '../../../queries';
import {MoveFileDialogComponent} from '../move-file-dialog/move-file-dialog.component';
import {RenameFileDialogComponent} from '../rename-file-dialog/rename-file-dialog.component';
import {ArchiveRestoreDialogComponent} from '../../archive-restore-dialog/archive-restore-dialog.component';
import {SideNavService} from '../../../services/side-nav.service';
import {DeleteDialogComponent} from '../../../delete-dialog/delete-dialog.component';
import {UndeleteDialogComponent} from '../../../undelete-dialog/undelete-dialog.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {ContextService} from '../../../services/context.service';
import {PoMetaService} from '../../../services/po-meta.service';
import {FolderInfoComponent} from '../folder-info/folder-info.component';
import {AsyncPipe, CommonModule, DatePipe} from '@angular/common';
import {S3LinkComponent} from '../../s3-link/s3-link.component';
import {AssetIconComponent} from '../file-icon/asset-icon.component';
import {TooltipDirective} from '../../../tooltip.directive';
import {EstimatedRestorationTimePipe} from '../../../pipes/estimated-restoration-time-pipe';
import {IsAllowedPipe} from '../../../pipes/has-action-pipe';
import {FileFolderLinkPipe} from '../../file-folder-link.pipe';
import {FileSizePipe} from '../../../pipes/file-size-pipe';
import {toSignal} from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-folder-listing',
  templateUrl: './folder-listing.component.html',
  styleUrls: ['./folder-listing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('flyInAnimation', [
      transition(':leave', [
        animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)'),
        style({transform: 'translateX(100%)'})
      ]),
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')
      ])
    ])
  ],
  imports: [
    FolderInfoComponent,
    FilterSectionComponent,
    AsyncPipe,
    S3LinkComponent,
    ReactiveFormsModule,
    AssetIconComponent,
    RouterModule,
    TooltipDirective,
    EstimatedRestorationTimePipe,
    DatePipe,
    IsAllowedPipe,
    CommonModule,
    FileFolderLinkPipe,
    FileSizePipe
  ]
})
export class FolderListingComponent  {
  protected readonly RestoreState = RestoreState;
  selection = new SelectionModel<Folder | File>(true, [], true, (f1, f2) => f1.id === f2.id);
  @Input() folder: Signal<Folder>;


  protected noDeletedFilesSelected: Signal<boolean>;
  protected only_deleted_files_selected: Signal<boolean>;
  protected only_archived_files_selected: Signal<boolean>;
  protected only_one_folder_selected: Signal<boolean>;
  protected allowSubmitOnAllFolder: Signal<boolean>;
  protected indeterminate: Signal<boolean>;
  protected checked: Signal<boolean>;
  copyid_feedback = signal(false);

  isSmall: Signal<boolean>;

  sorters: WritableSignal<Array<{
    dir: 'up' | 'down' | null,
    order: Date | null,
    property: string
  }>> = signal([
    {dir: null, order: null, property: 'selected'},
    {dir: null, order: null, property: '__typename'},
    {dir: 'down', order: new Date(), property: 'name'},
    {dir: null, order: null, property: 'last_modified'},
    {dir: null, order: null, property: 'size'}
  ]);

  parent: Signal<Folder>;
  items: Signal<(File | Folder)[]>;
  items_sorted_and_filtered: Signal<(File | Folder)[]>;

  filters: FormGroup = new FormGroup({
    t: new FormControl<string>(''),
    d: new FormControl<boolean>(false),
  });
  refreshing = signal(false);
  addingFolder = signal(false);
  organization: Signal<Organization>;

  @HostListener('window:keydown.escape', ['$event']) onKeydownHandler() {
    console.log(this.dialog.openDialogs.length)
    if (this.dialog.openDialogs.length === 0) {
      this.selection.clear()
    }
  }

  constructor(
    private dialog: Dialog,
    private breakPointObserver: BreakpointObserver,
    private apollo: Apollo,
    public sideNavService: SideNavService,
    private router: Router,
    private route: ActivatedRoute,
    private contextService: ContextService,
    private cdRef: ChangeDetectorRef,
    private poMetaService: PoMetaService) {
    this.isSmall = toSignal(this.breakPointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
      .pipe(
        map(result => result.matches),
      ));
    this.organization = this.contextService.organization;

    this.items = computed(() => {
      const folder = this.folder();
      return [...folder.folders, ...folder.files];
    })
    this.parent = computed(() => {
      const folder = this.folder();
      return folder.parents.length > 0 ? folder.parents.slice(-1)[0] : null
    })

    const filtersSignal = toSignal(this.filters.valueChanges.pipe(debounceTime(FILTER_DEBOUNCE_TIME)));

    this.items_sorted_and_filtered = computed(() => {
      const filters = filtersSignal();
      const items = this.items();
      const sorters = this.sorters();
      console.log('items_sorted_and_filtered')

      let items_filtered_sorted = filters ? items.filter(
        f => f.name.toLowerCase().includes((filters['t'] || '').toLowerCase())).filter(
        f => f.__typename === 'Folder' || f.__typename === 'File' && (f.deleted && !(filters['d'] || false) || !f.deleted)) : items;

      const applicableSorters = sorters.filter(v => v.dir !== null).sort((a, b) => a.order.getTime() - b.order.getTime())
      for (const applicableSorter of applicableSorters) {
        items_filtered_sorted = items_filtered_sorted.sort((a, b) => {
          if (applicableSorter.property === '__typename' && a.__typename === 'File' && a.deleted) {
            if (applicableSorter.dir === 'up') {
              return -1
            } else {
              return +1
            }
          }
          if (applicableSorter.property === '__typename' && b.__typename === 'File' && b.deleted) {
            if (applicableSorter.dir === 'up') {
              return +1
            } else {
              return -1
            }
          }
          if (a[applicableSorter.property] === b[applicableSorter.property]) {
            return 0
          }
          if (a[applicableSorter.property] === undefined) {
            return +1
          }
          if (b[applicableSorter.property] === undefined) {
            return -1
          }

          function addLeadingZero(str) {
            const pattern = /S(\d+)E(\d+)/g;
            return str.replace(pattern, (match, p1, p2) => {
              return `S${p1.padStart(2, '0')}E${p2.padStart(2, '0')}`;
            });
          }

          if (typeof a[applicableSorter.property] === 'string') {
            if (applicableSorter.dir === 'down') {
              return addLeadingZero(a[applicableSorter.property]).localeCompare(addLeadingZero(b[applicableSorter.property]))
            } else {
              return addLeadingZero(b[applicableSorter.property]).localeCompare(addLeadingZero(a[applicableSorter.property]))
            }
          } else if (typeof a[applicableSorter.property] === 'number') {
            if (applicableSorter.dir === 'up') {
              return a[applicableSorter.property] - b[applicableSorter.property]
            } else {
              return b[applicableSorter.property] - a[applicableSorter.property]
            }
          }
        })
      }
      return items_filtered_sorted;
    });

    const selectedItems = toSignal(this.selection.changed.pipe(map(() => this.selection.selected)), {initialValue: []})


    this.noDeletedFilesSelected = computed(() =>
      selectedItems().filter(f => f.__typename === 'File' && !f.deleted).length === selectedItems().length);

    this.only_deleted_files_selected = computed(() =>
      this.selection.selected.filter(f => f.__typename === 'File' && f.deleted).length === this.selection.selected.length);


    this.only_archived_files_selected = computed(() => {
      const s = this.selection
      return s.selected.filter(f => f.__typename === 'File' && f.archive).length === s.selected.length
    });


    this.only_one_folder_selected = computed(() => {
      return this.selection.selected.length === 1 && this.selection.selected[0].__typename === 'Folder';
    })


    this.allowSubmitOnAllFolder = computed(() => {
      const folders = selectedItems().filter(f => f.__typename === 'Folder');

      return folders.length > 0 && folders.every(folder =>
        this.contextService.isAllowed(
          this.poMetaService.poService.action,
          `contentplatform:asset:input.input_brefix/${folder.id.replace('s3://', '')}`
        )() ||
        this.contextService.isAllowed(
          this.poMetaService.poService.action,
          `contentplatform:asset:input.content_identifier/input_brefix://${folder.id.replace('s3://', '')}`
        )()
      );
    });

    this.indeterminate = computed(() => selectedItems().length > 0 && selectedItems().length < this.items().length)
    this.checked = computed(() => selectedItems().length === this.items().length)


  }


  mkdir() {
    this.dialog.open(MkdirDialogComponent, {
      data: {
        folder: this.folder()
      }
    });
  }

  openGenUploadLink(): void {
    this.dialog.open(GenerateUploadLinkComponent, {
      data: {
        folder: this.folder()
      }
    }).closed.subscribe(() => {
      this.refreshWithFeedback()
    });
  }

  openGenUploadLinkFromSelection(): void {
    this.dialog.open(GenerateUploadLinkComponent, {
      data: {
        folder: this.selection.selected[0]
      }
    });
  }


  clicked(col: number) {
    this.sorters.update(sorters => {
      const updatedSorters = [...sorters];

      if (updatedSorters[col].dir === null) {
        updatedSorters[col].dir = 'up';
      } else if (updatedSorters[col].dir === 'up') {
        updatedSorters[col].dir = 'down';
      } else if (updatedSorters[col].dir === 'down') {
        updatedSorters[col].dir = 'up';
      }

      updatedSorters[col].order = new Date();

      return updatedSorters;
    });
  }

  toggleAll() {
    if (this.selection.isEmpty()) {
      this.selection.select(...this.items_sorted_and_filtered());
    } else {
      this.selection.clear();
    }
  }

  onClickUndelete() {
    this.dialog.open(UndeleteDialogComponent, {
      data: {
        basedir: this.folder(),
        files: this.selection.selected
      }
    })
  }

  onClickDelete() {
    this.dialog.open(DeleteDialogComponent, {
      data: {
        basedir: this.folder(),
        files: this.selection.selected
      }
    })
  }

  refreshWithFeedback() {
    this.refreshing.set(true);
    this.cdRef.markForCheck()
    this.apollo.query<Query>({
      query: FILE_FOLDER_LIST_WITH_ARCHIVE,
      variables: {
        id: this.folder().id,
        show_deleted: true
      },
      fetchPolicy: 'network-only'
    }).subscribe({
      complete: () => {
        this.refreshing.set(false);
      }
    })

  }

  copyid() {
    const clipb = []
    this.copyid_feedback.set(true)
    for (const forf of this.selection.selected) {
      clipb.push(forf.id)
    }

    navigator.clipboard.writeText(clipb.join('\n'));
    timer(500).subscribe(() => {
      this.copyid_feedback.set(false);
    })
  }

  public move() {
    this.dialog.open(MoveFileDialogComponent, {
      data: {basefolder: this.folder().id, selection: this.selection.selected}
    });
  }

  rename() {
    this.dialog.open(RenameFileDialogComponent, {
      data: {file: this.selection.selected[0], folder: this.folder()}
    });
  }

  restore() {
    this.dialog.open(ArchiveRestoreDialogComponent, {
      data: {
        files: <File[]>this.selection.selected.map((f) => f).filter(f => f.__typename === 'File' && f.archive),
        folder: this.folder()
      }
    });
  }


  get width() {
    return this.sideNavService.isOpen.pipe(
      map(open => open ? 'calc(100% - 288px)' : '100%')
    )
  }


  submitSelected() {
    this.addingFolder.set(true)
    const f$ = this.selection.selected.filter(f => f.__typename === 'Folder').map(folder => {
      console.log(folder)
      return this.apollo.query<Query>({
        query: GET_FOLDER2,
        variables: {
          id: folder.id
        }
      }).pipe(map(res => res.data.folder))
    })

    forkJoin(...f$).subscribe((folders: Folder[]) => {
      for (const folder of folders) {
        console.log('folder')
        console.log(folder)
        const preselectedAudio = new Set<string>();
        const preselectedSubtitles = new Set<string>();
        const preselectedCC = new Set<string>();
        const images = new Set<string>();
        for (const file of folder.files) {
          if (!file.tracks) {
            continue;
          }

          for (const track of file.tracks) {
            if (!track.enabled) {
              continue;
            }

            if (track.track_type === TrackType.Audio) {
              preselectedAudio.add(track.language);
            } else if (track.track_type === TrackType.Subtitle) {
              preselectedSubtitles.add(track.language);
            } else if (track.track_type === TrackType.Closedcaption) {
              preselectedCC.add(track.language);
            } else if (track.track_type === TrackType.Image) {
              images.add(file.id.split('/').pop());
            }
          }
        }

        console.log('About to add to PO');
        this.poMetaService.poService.addToPO(
          folder.id,
          [...preselectedAudio],
          [...preselectedSubtitles],
          [...preselectedCC],
          [...images]
        );

        this.addingFolder.set(false)
      }
    })
  }

  toggle(it: Folder | File) {
    this.selection.toggle(it);
  }

  viewAsAsset() {
    this.router.navigate([], {relativeTo: this.route, queryParams: {view: 'asset'}, queryParamsHandling: 'merge', replaceUrl: true})
  }
}
