import {Component, EventEmitter, Input, OnChanges, OnInit, Output, Signal, SimpleChanges} from '@angular/core';
import {Mutation as WorkflowMutation, PoItem, ProcessStateEnum} from '../../../types-workflow';
import {PO_DESTINATIONS} from '../../../target_systems';
import {DownloadEncodeDialogComponent} from '../download-encode-dialog/download-encode-dialog.component';
import {Dialog} from '@angular/cdk/dialog';
import {Apollo} from 'apollo-angular';
import {BannerService} from '../../../banner/banner.service';
import {RESTART_WORKFLOW} from '../../../queries';
import {ConfirmDialogComponent} from '../../../confirm-dialog/confirm-dialog.component';
import gql from 'graphql-tag';
import {skipWhile, switchMap, tap} from 'rxjs/operators';
import {ProcessService} from '../../../services/process.service';
import {IsAllowedPipe} from '../../../pipes/has-action-pipe';
import {TooltipDirective} from '../../../tooltip.directive';
import {CommonModule} from '@angular/common';
import {ContextService} from '../../../services/context.service';
import {Organization} from '../../../types';

@Component({
  selector: '[app-po-item-line]',
  templateUrl: './po-item-line.component.html',
  styleUrls: ['./po-item-line.component.scss'],
  imports: [IsAllowedPipe, CommonModule]
})
export class PoItemLineComponent {

  protected open = false

  @Input()
  poItem: PoItem;

  @Output()
  toggleDetails = new EventEmitter<boolean>();

  organization: Signal<Organization>;

  protected readonly PO_DESTINATIONS = PO_DESTINATIONS;
  publish_single_po_inflight = false;
  restart_single_po_inflight = false;

  @Input() displayMetadata!: boolean;

  constructor(
    private dialog: Dialog,
    private apollo: Apollo,
    private processService: ProcessService,
    private contextService: ContextService,
    private banner: BannerService) {

    this.organization = this.contextService.organization;
  }


  toggle() {
    console.log('toggle', this.open)
    this.open = !this.open
    this.toggleDetails.emit(this.open)
  }

  openDlDialog() {
    this.dialog.open(DownloadEncodeDialogComponent, {
      data: {po_name: this.poItem.po.po_name, po_item_id: this.poItem.po_item_id},
    })
  }


  publish_single_po() {
    const action = () => {
      this.publish_single_po_inflight = true;
      this.apollo.use('workflow').mutate<WorkflowMutation>({
        mutation: gql`mutation publish_single_po {
          publishPoItem(input: { po_name: "${this.poItem.po.po_name}", po_item_id: "${this.poItem.po_item_id}" }) {
            id
            state
            message
            start_date
            end_date
          }
        }`
      }).pipe(
        tap((res) => {
          this.publish_single_po_inflight = false;
          const client = this.apollo.use('workflow').client;
          const cache = client.cache;
          cache.modify({
            id: cache.identify({__typename: 'POItem', id: this.poItem.id}),
            fields: {
              publish_process(existingPublishProcess, {toReference}) {
                return toReference(res.data.publishPoItem);
              },
            },
          });
        }),
        switchMap(d => this.processService.observeProcess(d.data.publishPoItem, {
          successMessage: `${this.poItem.po_item_id} published successfully.`,
          failureMessage: `${this.poItem.po_item_id} failed to publish.`,
          link: window.location.href
        })),
        skipWhile(process => process.state === ProcessStateEnum.InProgress)
      ).subscribe({
        error: (error) => {
          this.banner.open(error, ['Close']);
        }
      })
    }
    if (this.poItem.publish_process &&
      (this.poItem.publish_process.state === ProcessStateEnum.InProgress ||
        this.poItem.publish_process.state === ProcessStateEnum.Success)) {
      let secondLine = ''
      if (this.poItem.publish_process.state === ProcessStateEnum.InProgress) {
        secondLine = 'This will restart an already running publish process.'
      }
      if (this.poItem.publish_process.state === ProcessStateEnum.Success) {
        secondLine = 'This will restart a completed publish process.'
      }
      this.dialog.open(ConfirmDialogComponent, {
        data: {
          headline: 'Publish',
          message: `Do you really want to publish ${this.poItem.po_item_id}?<br/>${secondLine}`,
          actionName: 'Publish',
          action: action
        }
      });
    } else {
      action();
    }

  }

  restartWorkflow() {
    const action = () => {
      this.restart_single_po_inflight = true;
      this.apollo.use('workflow').mutate<WorkflowMutation>({
        mutation: RESTART_WORKFLOW,
        variables: {
          po_name: this.poItem.po.po_name,
          po_item_id: this.poItem.po_item_id
        }
      }).pipe(
        tap(res => {
          const client = this.apollo.use('workflow').client;
          const cache = client.cache;
          console.log('update', res.data.restartPoItem)
          cache.modify({
            id: cache.identify({__typename: 'POItem', id: this.poItem.id}),
            fields: {
              tracks() {
                return []
              },
              checkpoint_content_uploaded() {
                return false
              },
              checkpoint_content_complete() {
                return false
              },
              checkpoint_encodes_done() {
                return false
              },
              vtk_jobs() {
                return []
              },
              preview() {
                return null
              },
              workflow_process(existingWorkflowProcess, {toReference}) {
                console.log('update workflow_process', res.data.restartPoItem.id)
                return toReference(res.data.restartPoItem);
              },
            },
          });


          this.restart_single_po_inflight = false;
        }),
        switchMap(res => this.processService.observeProcess(res.data.restartPoItem, {
          successMessage: `${this.poItem.po_item_id} encoded successfully.`,
          failureMessage: `${this.poItem.po_item_id} failed to encode.`,
          link: window.location.href
        }))
      ).subscribe({
          error:
            error => {
              this.restart_single_po_inflight = false;
              this.banner.open('Error restarting ' + this.poItem.po_item_id + ': ' + error.message, ['Close']);
            }
        }
      )
    }

    if (this.poItem.workflow_process &&
      (this.poItem.workflow_process.state === ProcessStateEnum.InProgress ||
        this.poItem.workflow_process.state === ProcessStateEnum.Success)) {
      let secondLine = ''
      if (this.poItem.workflow_process.state === ProcessStateEnum.InProgress) {
        secondLine = 'This will restart an already running encode process.'
      }
      if (this.poItem.workflow_process.state === ProcessStateEnum.Success) {
        secondLine = 'This will restart a completed encode process.'
      }
      this.dialog.open(ConfirmDialogComponent, {
        data: {
          headline: 'Restart',
          message: `Do you really want to restart ${this.poItem.po_item_id}?<br/>${secondLine}`,
          actionName: 'Restart',
          action: action
        }
      });
    } else {
      action();
    }

  }
}
