import {ApplicationConfig, inject, provideZoneChangeDetection} from '@angular/core';
import {provideRouter, withDebugTracing, withHashLocation} from '@angular/router';
import {provideNamedApollo} from 'apollo-angular';
import {routes} from './app.routes';
import {OAuthService, OAuthStorage, provideOAuthClient} from 'angular-oauth2-oidc';
import {environment} from '../environments/environment';
import {HttpLink} from 'apollo-angular/http';
import {ContextService} from './services/context.service';
import {ApolloLink, InMemoryCache, InMemoryCacheConfig} from '@apollo/client/core';
import {HttpHeaders, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {TypePolicy} from '@apollo/client/cache/inmemory/policies';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {DEFAULT_DIALOG_CONFIG} from '@angular/cdk/dialog';
import {Po2024Service} from './services/po-2024.service';
import {provideServiceWorker} from '@angular/service-worker';
import {ngsw} from '../environments/ngsw-state';
import {provideAnimations} from '@angular/platform-browser/animations';
import {provideNgIdle} from '@ng-idle/core';
import {FullscreenOverlayContainer, OverlayContainer} from '@angular/cdk/overlay';


export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({eventCoalescing: true}),
    provideRouter(routes, withHashLocation()),
    provideAnimations(),
    provideNgIdle(),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: LocationStrategy, useClass: HashLocationStrategy
    },
    {
      provide: OAuthStorage, useValue: localStorage
    },
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    {
      provide: DEFAULT_DIALOG_CONFIG, useValue: {
        hasBackdrop: true,
        disableClose: true,
        width: '720px',
        'max-width': '100%',
      }
    },
    provideNamedApollo(() => {
        const httpLink = inject(HttpLink);
        const oAuthService = inject(OAuthService);
        const contextService = inject(ContextService);
        const authMiddleware = new ApolloLink((operation, forward) => {
          const accessToken = oAuthService.getAccessToken();
          let headers = operation.getContext().headers || new HttpHeaders();
          headers = headers.set('Authorization', accessToken);
//          console.log('Appsync request',  contextService.organization())
//          console.log('operation', operation.operationName)
          if (contextService.organization() && !headers.get('X-Castlabs-Organization')) {

            headers = headers.set('X-Castlabs-Organization', contextService.organization().id);
          }

          operation.setContext({
            headers: headers
          });

          return forward(operation);
        });

        function createCache(organizationService: ContextService) {
          const generateCacheKey = (args) => {
            if (organizationService.organizationId()) {
              if (!args) {
                return organizationService.organizationId();
              } else {
                return `${organizationService.organizationId()}:${JSON.stringify(args)}`;
              }
            }
            if (!args) {
              return null;
            } else {
              return JSON.stringify(args);
            }
          };
          const headerAware = {
            keyArgs: false,
            read(existing, {args}) {
              const key = generateCacheKey(args)
              return existing ? existing[key] : undefined;
            },
            merge(existing = {}, incoming, {args}) {
              const key = generateCacheKey(args);
              return {
                ...existing,
                [key]: incoming,
              };
            },
          } as TypePolicy;

          const cacheParams = {

            typePolicies: {
              Query: {
                fields: {
                  roots: headerAware,
                  airlines: headerAware,
                  policies: headerAware,
                  list_POs: headerAware,
                }
              },
              File: {
                fields: {
                  archive: {
                    merge: true,
                  },
                },
              },
              POItem: {
                fields: {
                  // after a restart it takes a while until the process is updated on the poitem
                  // and this makes sure that local cache updates are not overwritten by stale data from the server
                  workflow_process: {
                    merge(existingRef, incomingRef, {readField, toReference}) {
                      // Resolve the references to get the actual objects
                      const existing = existingRef ? readField('start_date', existingRef) : undefined;
                      const incoming = incomingRef ? readField('start_date', incomingRef) : undefined;

                      if (existing === undefined) {
                        return incomingRef;
                      }
                      if (incoming === null && existing !== null) {
                        return existingRef;
                      }
                      if (existing > incoming) {
                        return existingRef;
                      }
                      return incomingRef;
                    }
                  },
                  publish_process: {
                    merge(existingRef, incomingRef, {readField, toReference}) {
                      // Resolve the references to get the actual objects
                      const existing = existingRef ? readField('start_date', existingRef) : undefined;
                      const incoming = incomingRef ? readField('start_date', incomingRef) : undefined;

                      if (existing === undefined) {
                        return incomingRef;
                      }
                      if (incoming === null && existing !== null) {
                        return existingRef;
                      }
                      if (existing > incoming) {
                        return existingRef;
                      }
                      return incomingRef;
                    }
                  },
                },
              },
            },
          } as InMemoryCacheConfig;
          return new InMemoryCache(cacheParams);
        }

        return {
          'default': {
            cache: createCache(contextService),
            connectToDevTools: true,
            link: ApolloLink.from([
              authMiddleware,
              httpLink.create({
                uri: environment.graphql
              })])
          },
          'workflow': {
            cache: createCache(contextService),
            connectToDevTools: true,
            resolvers: {
              POItem: {
                format_specific_data_json(po_item, _args, {cache}) {
                  return JSON.parse(po_item.format_specific_data);
                }
              }
            },
            link: ApolloLink.from([
              authMiddleware,
              httpLink.create({
              uri: environment.workflow_graphql
            })])
          }
        }
      }
    ),
    provideOAuthClient({
      resourceServer: {
        allowedUrls: [environment.apiurl],
        sendAccessToken: false,
      }
    }),
    {
      provide: 'above_default',
      useFactory: (oAuthService: OAuthService) =>
        new Po2024Service(oAuthService, 'contentplatform:workflow:Mutation/StartWorkflowDefault'),
      deps: [OAuthService]
    },
    {
      provide: 'vod_batch',
      useFactory: (oAuthService: OAuthService) =>
        new Po2024Service(oAuthService, 'contentplatform:workflow:Mutation/StartWorkflowVodBatch'),
      deps: [OAuthService]
    },
    {
      provide: 'vod_default',
      useFactory: (oAuthService: OAuthService) =>
        new Po2024Service(oAuthService, 'contentplatform:workflow:Mutation/StartWorkflowVodDefault'),
      deps: [OAuthService]
    },
    provideServiceWorker('ngsw-worker.js', {
      enabled: ngsw.enabled, // Enable based on your environment
      registrationStrategy: 'registerWhenStable:30000' // Wait for app stability
    }),
  ]
};
