import {ChangeDetectionStrategy, Component, ElementRef, Input, OnInit} from '@angular/core';
import {delayWhen, filter, map, shareReplay, switchMap, take} from 'rxjs/operators';
import {animate, style, transition, trigger} from '@angular/animations';
import {interval, Observable, of} from 'rxjs';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';
import {Folder, Query} from '../../../types';
import {VisibilityService} from '../../../services/visibility.service';
import {FetchPolicy} from '@apollo/client';
import {AsyncPipe, CommonModule} from '@angular/common';

@Component({
    selector: 'app-folder-info',
    templateUrl: './folder-info.component.html',
    styleUrls: ['./folder-info.component.scss'],
    animations: [
        trigger('appear', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('300ms', style({ opacity: 1 })),
            ]),
            transition(':leave', [
                animate('300ms', style({ opacity: 0 }))
            ])
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
      CommonModule,
      AsyncPipe
    ]
})
export class FolderInfoComponent implements OnInit {
  @Input()
  folder: Folder;
  @Input()
  fetchPolicy: FetchPolicy = 'cache-first'
  @Input()
  display: 'date' | 'numberOfFiles' = 'date'

  fileNo: Observable<string>;
  lastMod: Observable<string>;


  requestInflight = false;
  cancelRequest = false;


  constructor(
    private visibilityService: VisibilityService,
    private host: ElementRef,
    private apollo: Apollo
  ) {
  }

  queryFolderData = () => this.apollo.query<Query>({
    query: gql`
    {
      folder(id: "${this.folder.id}") {
        id
        name
        files {
          id
          name
          size
          last_modified
          archived
        }
        folders {
          id
          name
        }
      }
    }
    `
  });

  ngOnInit(): void {
    const t = this.visibilityService.elementInSight(this.host).pipe(
      filter(v => (!v && this.requestInflight) || (v && !this.requestInflight)),
      delayWhen((visible) => {
        if (visible) {
          this.cancelRequest = false;
          this.requestInflight = true;
          return interval(500)
        } else {
          this.cancelRequest = true;
          return interval(0)
        }
      }),
      filter(visible => visible),
      switchMap(() => {
        if (!this.cancelRequest) {
          return this.queryFolderData().pipe(
            map(response => {
              const folder = response.data.folder;
              const lastModified = folder.files.map(
                file => file.last_modified
              ).reduce(
                (folder_lm, lm) => lm > folder_lm ? lm : folder_lm,
                '',
              )
              this.requestInflight = false;
              return [folder.files.length === 0 ? 0 : folder.files.length, lastModified]
            })
          )
        }
        this.requestInflight = false;
        return of([null, null]);
      }),
      filter(([v, w]) => v != null),
      take(1),
      shareReplay(1),
    );
    this.fileNo = t.pipe(map(([v, w]) => v));
    this.lastMod = t.pipe(map(([v, w]) => w));
  }

}
