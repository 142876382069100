import { clpp } from "./cl.core.js";
let g = {};
const _ = clpp._;
var f = function (window) {
  'use strict';

  var $u = " The setting will be ignored.",
    av = "VTT parser encountered an invalid VTT setting: ",
    bv = "application/x-subrip",
    cv = "text/srt",
    dv = 'text/vtt; codecs\x3d"vtt"',
    ev = 'text/vtt; codecs\x3d"wvtt"',
    fv = function (a) {
      return a.a === a.f.length ? null : (a = _.Zi(a, /[^ \t\n]*/gm)) ? a[0] : null;
    },
    gv = function (a) {
      _.Zi(a, /[ \t]+/gm);
    },
    hv = function (a) {
      let b = "";
      for (a = a.split(/\n/); 3 < a.length;) {
        for (var c = 3; c < a.length; ++c) a[2] += `\n${a[c]}`;
        a.splice(3, a.length - 3);
      }
      if (3 !== a.length) return "";
      c = 0;
      !a[0].match(/\d+:\d+:\d+/) && a[1].match(/\d+:\d+:\d+/) && (b += `${a[0].match(/\w+/)}\n`, ++c);
      if (a[c].match(/\d+:\d+:\d+/)) {
        const d = a[1].match(/(\d+):(\d+):(\d+)(?:,(\d+))?\s*--?>\s*(\d+):(\d+):(\d+)(?:,(\d+))?/);
        if (d) b += `${d[1]}:${d[2]}:${d[3]}.${d[4]} --> `, b += `${d[5]}:${d[6]}:${d[7]}.${d[8]}\n`, ++c;else return "";
      } else return "";
      a[c] && (b += `${a[c]}\n\n`);
      return b;
    },
    iv = function (a) {
      a = _.Zi(a, /(?:(\d{1,}):)?(\d{2}):(\d{2})\.(\d{2,3})/g);
      if (null == a) return null;
      const b = Number(a[2]),
        c = Number(a[3]);
      return 59 < b || 59 < c ? null : Number(a[4]) / 1E3 + c + 60 * b + 3600 * (Number(a[1]) || 0);
    },
    kv = function (a, b, c) {
      let d;
      if (d = /^align:(start|middle|center|end|left|right)$/.exec(b)) b = d[1], "middle" === b ? a.textAlign = _.Us : a.textAlign = _.$s[b.toUpperCase()];else if (d = /^vertical:(lr|rl)$/.exec(b)) a.writingMode = "lr" === d[1] ? _.ig : _.jg;else if (d = /^size:([\d.]+)%$/.exec(b)) a.size = Number(d[1]);else if (d = /^position:([\d.]+)%(?:,(line-left|line-right|center|start|end))?$/.exec(b)) a.position = Number(d[1]), d[2] && (b = d[2], a.positionAlign = b === _.he || b === _.Lf ? _.he : b === _.ie || b === _.Sd ? _.ie : _.bd);else if (d = /^region:(.*)$/.exec(b)) {
        if (b = jv(c, d[1])) a.region = b;
      } else {
        a: {
          if (c = /^line:([\d.]+)%(?:,(start|end|center))?$/.exec(b)) a.lineInterpretation = 1, a.line = Number(c[1]), c[2] && (a.lineAlign = _.at[c[2].toUpperCase()]);else if (c = /^line:(-?\d+)(?:,(start|end|center))?$/.exec(b)) a.lineInterpretation = _.Ws, a.line = Number(c[1]), c[2] && (a.lineAlign = _.at[c[2].toUpperCase()]);else {
            a = !1;
            break a;
          }
          a = !0;
        }
        return a;
      }
      return !0;
    },
    jv = function (a, b) {
      a = a.filter(c => c.id === b);
      return a.length ? a[0] : (_.B("VTT parser could not find a region with id: ", b, " The region will be ignored."), null);
    },
    mv = function (a, b, c) {
      let d, e, f;
      new _.em().N("payl", _.$l(g => {
        d = _.Ei(g);
      })).N("iden", _.$l(g => {
        e = _.Ei(g);
      })).N("sttg", _.$l(g => {
        f = _.Ei(g);
      })).parse(a);
      return d ? lv(d, e, f, b, c) : null;
    },
    lv = function (a, b, c, d, e) {
      a = new _.Zs(d, e, a, _.tg);
      b && (a.id = b);
      if (c) for (b = new _.$i(c), c = fv(b); c;) kv(a, c, []) || _.B(av, c, $u), gv(b), c = fv(b);
      return a;
    },
    nv = class {
      a(a) {
        a = _.Ei(a);
        a = a.replace(/\r+/g, "");
        a = a.replace(/^\s+|\s+$/g, "");
        a = a.replace(/<[a-zA-Z\\/][^>]*>/g, "");
        a = a.split("\n\n");
        let b = "";
        if (0 < a.length) {
          b += "WEBVTT\n\n";
          for (const c of a) b += hv(c);
        }
        return _.Ii(b);
      }
    },
    ov = class {
      create() {
        return new nv();
      }
      probe(a, b) {
        return (a === cv || a === bv) && b === _.Uf;
      }
    };
  var pv = class {
    constructor(a) {
      this.a = a;
    }
    parseInit() {}
    parseMedia(a, b) {
      a = _.Ei(a);
      a = a.replace(/\r\n|\r(?=[^\n]|$)/gm, "\n");
      var c = a.split(/\n{2,}/m);
      if (!/^WEBVTT($|[ \t\n])/m.test(c[0])) throw new _.G(_.E, 2, 2E3);
      a = b.periodStart;
      if (c[0].includes("X-TIMESTAMP-MAP")) {
        var d = c[0].match(/LOCAL:((?:(\d{1,}):)?(\d{2}):(\d{2})\.(\d{3}))/m),
          e = c[0].match(/MPEGTS:(\d+)/m);
        if (d && e) {
          a = iv(new _.$i(d[1]));
          if (null == a) throw new _.G(_.E, 2, 2E3);
          e = Number(e[1]);
          for (d = b.segmentStart; 95443.7176888889 <= d;) d -= 95443.7176888889, e += 8589934592;
          a = b.periodStart + e / 9E4 - a;
        }
      } else this.a.streaming.relativeTextTimestamp && null !== b.segmentStart && (a = b.segmentStart);
      b = [];
      for (var f of c) if (/^region/.test(f.toLowerCase())) {
        e = new _.$i(f.replace(/\n/g, " "));
        d = new _.dt();
        fv(e);
        gv(e);
        for (var g = fv(e); g;) {
          a: {
            var h;
            var k = d;
            var l = g;
            if (h = /^id:(.*)$/.exec(l)) k.id = h[1];else if (h = /^width:(\d{1,2}|100)%$/.exec(l)) k.width = Number(h[1]);else if (h = /^lines:(\d+)$/.exec(l)) k.height = Number(h[1]), k.heightUnits = 2;else if (h = /^regionanchor:(\d{1,2}|100)%,(\d{1,2}|100)%$/.exec(l)) k.regionAnchorX = Number(h[1]), k.regionAnchorY = Number(h[2]);else if (h = /^viewportanchor:(\d{1,2}|100)%,(\d{1,2}|100)%$/.exec(l)) k.viewportAnchorX = Number(h[1]), k.viewportAnchorY = Number(h[2]);else if (/^scroll:up$/.exec(l)) k.scroll = "up";else {
              k = !1;
              break a;
            }
            k = !0;
          }
          k || _.B("VTT parser encountered an invalid VTTRegion setting: ", g, $u);
          gv(e);
          g = fv(e);
        }
        e = d;
        b.push(e);
      }
      f = [];
      for (var m of c) if (/^style/.test(m.toLowerCase())) {
        e = m.replace(/^style/i, "").replace(/\n/g, " ");
        d = /[^{}]+{[^{}]*}/g;
        do (g = d.exec(e)) && f.push(g[0]); while (g);
      }
      m = [];
      for (const n of c) if (n.includes("--\x3e")) {
        a: {
          l = n.split("\n");
          h = a;
          c = b;
          if (1 === l.length && !l[0] || /^NOTE($|[ \t])/.test(l[0]) || "STYLE" === l[0]) {
            c = null;
            break a;
          }
          e = null;
          l[0].includes("--\x3e") || (e = l[0], l.splice(0, 1));
          d = new _.$i(l[0]);
          g = iv(d);
          const q = _.Zi(d, /[ \t]+--\x3e[ \t]+/g);
          k = iv(d);
          if (null == g || null == q || null == k) throw new _.G(_.E, 2, 2001);
          g += h;
          k += h;
          l = l.slice(1).join("\n").trim();
          g = new _.Zs(g, k, l, _.tg);
          e && (g.id = e);
          gv(d);
          for (k = fv(d); k;) kv(g, k, c) || _.B(av, k, $u), gv(d), k = fv(d);
          null != e && (g.id = e);
          c = g;
        }
        c && m.push(c);
      }
      return {
        cues: m,
        styles: f
      };
    }
  };
  var qv = class extends pv {
    parseMedia(a, b) {
      return super.parseMedia(new nv().a(a), b);
    }
  };
  var rv = class {
    constructor() {
      this.a = null;
      this.g = new _.R("clpp.vtt.mp4");
    }
    parseInit(a) {
      let b = !1;
      new _.em().N(_.ze, _.Yl).N(_.Xf, _.Yl).N(_.ue, _.Yl).ca("mdhd", c => {
        0 === c.version ? (c.reader.skip(4), c.reader.skip(4), this.a = _.O(c.reader), c.reader.skip(4)) : (c.reader.skip(8), c.reader.skip(8), this.a = _.O(c.reader), c.reader.skip(8));
        c.reader.skip(4);
      }).N(_.ye, _.Yl).N(_.Of, _.Yl).ca(_.Qf, _.Zl).N("wvtt", () => {
        b = !0;
      }).parse(a);
      if (!this.a) throw new _.G(_.E, 2, 2008);
      if (!b) throw new _.G(_.E, 2, 2008);
    }
    parseMedia(a, b) {
      if (!this.a) throw this.g.error("No init segment for MP4+VTT!"), new _.G(_.E, 2, 2008);
      let c = 0,
        d = [],
        e;
      const f = [];
      let g = !1,
        h = !1,
        k = !1,
        l = null;
      new _.em().N("moof", _.Yl).N("traf", _.Yl).ca("tfdt", q => {
        g = !0;
        c = 0 === q.version ? _.O(q.reader) : _.Tl(q.reader);
      }).ca("tfhd", q => {
        var u = q.flags;
        q = q.reader;
        q.skip(4);
        u & 1 && q.skip(8);
        u & 2 && q.skip(4);
        l = u & 8 ? _.O(q) : null;
      }).ca("trun", q => {
        h = !0;
        {
          var u = q.version,
            v = q.flags;
          q = q.reader;
          const y = _.O(q);
          v & 1 && q.skip(4);
          v & 4 && q.skip(4);
          const A = [];
          for (let D = 0; D < y; D++) {
            const H = {
              duration: null,
              sampleSize: null,
              Ke: null
            };
            v & 256 && (H.duration = _.O(q));
            v & 512 && (H.sampleSize = _.O(q));
            v & 1024 && q.skip(4);
            v & 2048 && (H.Ke = 0 === u ? _.O(q) : _.Sl(q));
            A.push(H);
          }
          d = A;
        }
      }).N(_.te, _.$l(q => {
        k = !0;
        e = q;
      })).parse(a);
      if (!k && !g && !h) throw new _.G(_.E, 2, 2008);
      a = c;
      var m = new DataView(e.buffer, e.byteOffset, e.byteLength);
      m = new _.Rm(m, !1);
      for (const q of d) {
        const u = q.duration || l,
          v = q.Ke ? c + q.Ke : a;
        a = v + (u || 0);
        let y = 0;
        do {
          const A = _.O(m);
          y += A;
          var n = _.O(m);
          n = _.Xl(n);
          let D = null;
          "vttc" === n ? 8 < A && (D = _.Vl(m, A - 8)) : ("vtte" !== n && this.g.error("Unknown box " + n + "! Skipping!"), m.skip(A - 8));
          u ? D && f.push(mv(D, b.periodStart + v / this.a, b.periodStart + a / this.a)) : this.g.error("WVTT sample duration unknown, and no default found!");
        } while (q.sampleSize && y < q.sampleSize);
      }
      return {
        cues: f.filter(_.Zh),
        styles: []
      };
    }
  };
  _.x("clpp.vtt.VttComponent", class extends _.qu {
    constructor() {
      super();
      this.j = new ov();
    }
    f() {
      _.Es(_.Uf, pv);
      _.Es(dv, pv);
      _.Es(ev, pv);
      _.Es(_.Dc, rv);
      _.Es(cv, qv);
      _.Es(bv, qv);
      _.Wj.push(this.j);
    }
    a() {
      _.Gs(_.Uf);
      _.Gs(dv);
      _.Gs(ev);
      _.Gs(_.Dc);
      _.Gs(cv);
      _.Gs(bv);
      _.Yj(this.j);
    }
    id() {
      return "subtitles-vtt";
    }
  });
};
f.call(g, window);