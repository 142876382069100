import {Component, effect, Inject, OnInit, Signal, TrackByFunction} from '@angular/core';
import {map} from 'rxjs/operators';
import {FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {Apollo} from 'apollo-angular';
import {Airline, Mutation} from '../../../types-workflow';

import {START_WORKFLOW_VOD_BATCH} from '../../../queries';
import {forkJoin} from 'rxjs';
import {BasicPoitemVodInfoComponent} from '../basics-poitem-vod-info/basic-poitem-vod-info.component';
import {VodWatermarkFormatSpecificComponent} from '../vod-watermark-format-specific/vod-watermark-format-specific.component';
import {clean_datetime} from '../../utils';
import {Organization} from '../../../types';
import {Po2024Service} from '../../../services/po-2024.service';
import {CommonModule} from '@angular/common';
import {ContextService} from '../../../services/context.service';
import {DoButtonComponent} from '../../do-button/do-button.component';
import {MessageFieldComponent} from '../../do-button/message-field.component';
import {ProcessService} from '../../../services/process.service';


@Component({
  selector: 'app-vod-watermark-po',
  templateUrl: './vod-watermark-po.component.html',
  styleUrls: ['./vod-watermark-po.component.css'],
  imports: [
    ReactiveFormsModule,
    BasicPoitemVodInfoComponent,
    VodWatermarkFormatSpecificComponent,
    CommonModule,
    DoButtonComponent,
    MessageFieldComponent,

  ]
})
export class VodWatermarkPoComponent implements OnInit {
  organization: Signal<Organization>;
  airlines: Signal<Airline[]>;

  poFormGroup: FormGroup;

  poItemTrackBy: TrackByFunction<FormGroup> = (index: number, poitem: FormGroup) => {
    return poitem.get('id').value;
  }

  constructor(
    private apollo: Apollo,
    private processService: ProcessService,
    @Inject('vod_batch') protected poService: Po2024Service,
    formBuilder: FormBuilder,
    contextService: ContextService
  ) {
    this.organization = contextService.organization;
    this.airlines = contextService.airlines;

    this.poFormGroup = formBuilder.group({
      po_number: [clean_datetime(new Date()), Validators.required],
      airline: [null, Validators.required],
      auto_publish: [true, Validators.required],
      poItems: formBuilder.array([], Validators.required),
      date_created: [null],
    });

    effect(() => {
      if (this.airlines().length === 1) {
        this.poFormGroup.controls['airline'].setValue(this.airlines()[0].iata_code);
      }
    })

    poService.poItemToAdd$.subscribe(data => {
      console.log('poItemToAdd$')
      if (data) {
        (this.poFormGroup.controls.poItems as FormArray).push(formBuilder.group(
          Object.assign(
            {},
            {
              basics: BasicPoitemVodInfoComponent.DEFAULTS,
              format_specific_data: VodWatermarkFormatSpecificComponent.DEFAULTS
            },
            data)))
      }
    });
    poService.poItemToRemove$.subscribe(id => {
      console.log('poItemToRemove$')
      if (id) {
        const index = (this.poFormGroup.controls.poItems as FormArray).controls.findIndex(poItem => poItem.get('id').value === id);
        (this.poFormGroup.controls.poItems as FormArray).removeAt(index);
      }
    });
  }

  ngOnInit() {
  }


  submit_po(button: DoButtonComponent) {

    if (!this.poFormGroup.valid) {
      console.log(this.poFormGroup)
      this.poFormGroup.markAllAsTouched();
      button.done('Form Invalid. Check for errors.', {
        severity: 'cl-alert-danger'
      })
      return
    }
    const workflows = []
    for (const poItem of this.poFormGroup.value.poItems) {
      workflows.push(this.apollo.use('workflow').mutate<Mutation>({
        mutation: START_WORKFLOW_VOD_BATCH,
        variables: {
          input_brefix: poItem.basics.input_brefix,
          po_item_id: poItem.basics.po_item_id.trim(),
          po_name: `${this.poFormGroup.value.airline}_${this.poFormGroup.value.po_number.trim()}`,
          po_destination: 'vod',
          format_specific_data: JSON.stringify(poItem.format_specific_data),
          vtk_template: 'cmaf-abr',
          auto_publish: this.poFormGroup.value.auto_publish,
          email_notification: this.poFormGroup.value.email_notification,
        }
      }).pipe(
        map(d => ({
          process: d.data.start_workflow_vod_batch,
          po_item_id: poItem.basics.po_item_id.trim()
        }))))
    }
    const organization = this.organization();
    forkJoin(workflows).subscribe(
      {
        next: wfs => {
          button.done('All encodes started successfully.');
          wfs.forEach(workflow => {
            this.processService.observeProcess(workflow.process, {
              successMessage: `${workflow.po_item_id} encoded.`,
              failureMessage: `Failed to encode ${workflow.po_item_id}.`,
              link: ['/#/o', organization, 'workflows',
                this.poFormGroup.value.airline,
                `${this.poFormGroup.value.airline}_${this.poFormGroup.value.po_number}`].join('/')
            })
          })
        },
        error: err => {
          button.done('Workflow submission failed: ' + err, {severity: 'cl-alert-danger'});
        }
      }
    )

  }


  get poItems() {
    return this.poFormGroup.get('poItems') as FormArray<FormGroup>;
  }

  get fields() {
    return this.poFormGroup.controls;
  }

}
