import {Component, Inject, OnInit} from '@angular/core';
import {Apollo, QueryRef} from 'apollo-angular';
import gql from 'graphql-tag';
import {Encode, Query as WorkflowQuery} from '../../../types-workflow';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Folder, Mutation} from '../../../types';
import {BannerService} from '../../../banner/banner.service';
import {EmptyObject} from 'apollo-angular/types';
import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {AsyncPipe, CommonModule, DatePipe} from '@angular/common';
import {FileSizePipe} from '../../../pipes/file-size-pipe';
import {IsAllowedPipe} from '../../../pipes/has-action-pipe';

@Component({
  selector: 'app-download-encode-dialog',
  templateUrl: './download-encode-dialog.component.html',
  styleUrls: ['./download-encode-dialog.component.scss'],
  imports: [
    AsyncPipe,
    FileSizePipe,
    DatePipe,
    IsAllowedPipe,
    CommonModule,
  ]
})
export class DownloadEncodeDialogComponent implements OnInit {

  encodes$: Observable<Encode[]>
  outputFolder$: Observable<Folder>
  queryRef: QueryRef<WorkflowQuery, EmptyObject>;

  constructor(public dialogRef: DialogRef<DownloadEncodeDialogComponent>,
              private apollo: Apollo,
              private banner: BannerService,
              @Inject(DIALOG_DATA) public data: { po_name: string, po_item_id: string }) {
  }

  ngOnInit(): void {
    const query = gql`
      query RetrievePoEncodes($po_name: String!, $po_item_id: String!) {
        list_POs(input: { filter: { po_name: { eq: $po_name}}} ){
          pos {
            poitems(po_item_id: $po_item_id) {
              id
              po_item_id
              output_brefix
              vtk_template
              encodes {
                id
                name
                size
                last_modified
              }
            }
          }
        }
      }
    `
    this.queryRef = this.apollo.use('workflow').watchQuery<WorkflowQuery>({
      query: query,
      pollInterval: 1000,
      variables: {
        'po_name': this.data.po_name,
        'po_item_id': this.data.po_item_id
      }
    })
    this.outputFolder$ = this.queryRef.valueChanges.pipe(
      map(d => d.data.list_POs.pos[0].poitems[0].output_brefix),
      map(outputBrefix => {
        return {'id': `s3://${outputBrefix}`} as Folder
      }))

    this.encodes$ = this.queryRef.valueChanges.pipe(
      map(d => d.data.list_POs.pos[0].poitems[0].encodes),
      map(d => d.slice()),
      map(d => d.sort((a, b) => a.name.localeCompare(b.name))),
    )
  }

  download(encode: Encode) {
    const query = gql`mutation GenerateDownloadUrl($encode_id: ID!) {
      generateDownloadUrl(input: {file_id: $encode_id}) {
        signed_url
      }
    }
    `
    this.apollo.mutate<Mutation>({
      mutation: query,
      variables: {
        encode_id: encode.id
      }
    }).subscribe(d => {
        const aElement = document.createElement('a')
        aElement.href = d.data.generateDownloadUrl.signed_url;
        aElement.click()
      },
      err => {
        this.dialogRef.close()
        this.banner.open('Download failed: ' + err.message, ['dismiss'])
      })
  }

  deleteEncode(encode: Encode) {
    const query = gql`mutation DeleteEncode($encode_id: ID!) {
      delete(input: {id: $encode_id}) {
        file {
          id
        }
      }
    }
    `
    this.apollo.mutate<Mutation>({
      mutation: query,
      variables: {
        encode_id: encode.id
      }
    }).subscribe(() => {
      this.queryRef.refetch().then()
    })
  }
}
