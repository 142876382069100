import {Component} from '@angular/core';
import {Observable, Subject} from 'rxjs';

import {GROW_DOWN_ANIMATION} from './animations';
import {BannerService} from './banner.service';
import {SideNavService} from '../services/side-nav.service';
import {AsyncPipe, CommonModule} from '@angular/common';

@Component({
  selector: 'banner-outlet',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  animations: [GROW_DOWN_ANIMATION],
  imports: [
    AsyncPipe,
    CommonModule
  ]
})
export class BannerOutlet {
  // Emits one value when the user picks an action
  private _clicks?: Subject<number>;
// Text message to display
  private _message?: string;

  constructor(bannerService: BannerService, protected sideNavService: SideNavService) {
    bannerService.init(this);
  }

  public get message(): string | undefined {
    return this._message;
  }

  // List of button labels to show
  private _actions?: string[];

  public get actions(): string[] | undefined {
    return this._actions;
  }

  // True if the panel is opened
  public get opened(): boolean {
    return !!this._clicks;
  }

  // Open this banner with a message and at least one action
  open(message: string, actions: string[]): Observable<number> {
    if (this._clicks) {
      throw new Error('Tried to open banner when outlet was already opened.');
    }

    if (actions.length === 0) {
      throw new Error('Tried to open banner without any action buttons.');
    }

    this._message = message;
    this._actions = actions;
    this._clicks = new Subject();
    return this._clicks.asObservable();
  }

  actionClicked(idx: number): void {
    if (!this._clicks) {
      console.log('Developer Error: banner action clicked but observable available!');
      return;
    }

    // Click subject can only ever emit one value
    this._clicks.next(idx);
    this._clicks.complete();
    this._clicks.unsubscribe();
    this._clicks = undefined;
  }

}
