import {Pipe, PipeTransform} from '@angular/core';

/*
 *
 */
@Pipe({
    name: 'orgShort'
})
export class OrgShortenerPipe implements PipeTransform {

    transform(message: string): string {
      if (message) {
        const regex = /urn:janus:organization:([a-f0-9]{32})/g;
        return message.replace(regex, (match, p1) => `${p1.substring(0, 8)}`);
      } else {
        return ''
      }
    }
}
