import { clpp } from "./cl.core.js";
let g = {};
const _ = clpp._;
var f = function (window) {
  'use strict';

  var gw = 'application/mp4; codecs\x3d"stpp.ttml"',
    hw = 'application/mp4; codecs\x3d"stpp.ttml.im1i"',
    iw = 'application/mp4; codecs\x3d"stpp.ttml.im1t"',
    jw = "backgroundColor",
    kw = "direction",
    lw = "displayAlign",
    mw = "region",
    nw = "textAlign",
    ow = "textDecoration",
    pw = "writingMode",
    qw = function (a, b, c) {
      a = a.f;
      for (let d = a.length - 1; 0 <= d; d--) {
        const e = b.get(a[d]);
        if (c in e.a) return e.getAttribute(c);
      }
      return null;
    },
    yw = function (a, b) {
      var c = null;
      if (rw.test(a)) {
        {
          a = rw.exec(a);
          c = Number(a[1]);
          const d = Number(a[2]);
          let e = Number(a[3]),
            f = Number(a[4]);
          f += (Number(a[5]) || 0) / b.f;
          e += f / b.frameRate;
          c = e + 60 * d + 3600 * c;
        }
      } else sw.test(a) ? c = tw(sw, a) : uw.test(a) ? c = tw(uw, a) : vw.test(a) ? (a = vw.exec(a), c = Number(a[1]) / b.frameRate) : ww.test(a) ? (a = ww.exec(a), c = Number(a[1]) / b.a) : xw.test(a) && (c = tw(xw, a));
      return c;
    },
    tw = function (a, b) {
      a = a.exec(b);
      return null == a || "" === a[0] ? null : (Number(a[4]) || 0) / 1E3 + (Number(a[3]) || 0) + 60 * (Number(a[2]) || 0) + 3600 * (Number(a[1]) || 0);
    },
    zw = function (a) {
      return a.split(":").pop();
    },
    Aw = function (a) {
      return a.replace(/(\d*\.?\d+)c/g, "$1em");
    },
    Cw = function (a, b) {
      for (; Bw.test(a);) {
        const c = Bw.exec(a)[0];
        a = a.replace(c, `${parseInt(c, 10) / b}em`);
      }
      return a;
    },
    Dw = function (a) {
      return a ? a.replace(/(\d*\.?\d+)%/g, (b, c) => `${c / 100}em`) : "";
    },
    Ew = function (a, b, c) {
      a = qw(a, b, nw);
      if (!a && c && (a = qw(c, b, nw), !a && "rltb" === qw(c, b, pw))) return _.yf;
      switch (a) {
        case _.bd:
          return _.Us;
        case _.yf:
        case _.Sd:
          return _.yf;
        default:
          return _.ee;
      }
    },
    Fw = function (a, b, c) {
      if (_.Ni(a.w)) return document.createTextNode(_.Ri(a.w || ""));
      if (a.A) return _.Mo("br");
      const d = _.Mo("span");
      for (const e of a.f) _.No(d, e);
      for (const e of a.j) d.appendChild(Fw(c.get(e), b, c));
      return d;
    },
    Iw = function (a, b, c, d) {
      const e = Gw.exec(a);
      a = Hw.exec(a);
      return typeof b === _.r && 0 < b && a ? 100 * Number(a[c]) / b : e ? Number(e[c]) : a ? Number(a[c]) : d;
    },
    Jw = function (a, b) {
      return typeof b === _.r && 0 < b ? _.bt : Hw.test(a) ? 0 : _.bt;
    },
    Kw = function (a, b, c) {
      return typeof a === _.r ? a : typeof b === _.r ? b : c;
    },
    Lw = function (a, b) {
      a = a.getAttribute("extent");
      return (a = Hw.exec(a)) ? Number(a[b]) : null;
    },
    Nw = function (a) {
      a = a.getAttribute("cellResolution");
      return (a = Mw.exec(a)) ? Number(a[2]) : null;
    },
    Pw = function () {
      return `${"inline-"}${Ow++}`;
    },
    Rw = function (a) {
      return Qw.some(b => b in a.attributes);
    },
    Sw = function (a, b) {
      if (0 === b.length) return [];
      const c = [];
      for (const d of b) a.a.has(d) && c.push(...Sw(a, a.a.get(d).f), d);
      return c;
    },
    Tw = function (a) {
      return a.o.map(b => b.build(a.l, a.w, a.A, a.h, a.a, a.j, a.f)).filter(b => null != b);
    },
    Uw = function (a) {
      return zw(a.h.nodeName);
    },
    Xw = function (a, b) {
      if (a.a.has(b)) return a.a.get(b);
      var c = null,
        d = null;
      let e = [],
        f = [],
        g = {};
      const h = b.parentNode;
      if (h && a.a.has(h)) {
        const k = a.a.get(h);
        e = [...k.l];
        -1 === ["#text", "span", "br"].indexOf(b.nodeName) && (c = k.a, d = k.j, f = [...k.f], g = {}, Vw.forEach(l => {
          l in k.attributes && (g[l] = k.attributes[l]);
        }));
      }
      if (b.nodeType === b.ELEMENT_NODE) {
        b.hasAttribute(mw) && (c = b.getAttribute(mw));
        b.hasAttribute(_.Rf) && f.push(...b.getAttribute(_.Rf).split(" "));
        d = Array.from(b.attributes).map(k => k.name);
        for (const k of d) d = zw(k), g[d] = b.getAttribute(k);
        d = g.backgroundImage;
      }
      c = new Ww(b, c, d, e, f, g);
      a.a.set(b, c);
      return c;
    },
    Yw = function (a) {
      return `${String(Math.floor(a / 3600)).padStart(2, "0")}:${String(Math.floor(a / 60 % 60)).padStart(2, "0")}:${String(Math.floor(a % 60)).padStart(2, "0")}.${String(Math.floor(1E3 * a % 1E3)).padStart(3, "0")}`;
    },
    Zw = class {
      constructor(a, b, c) {
        this.h = a;
        this.f = b;
        this.a = c;
      }
      getId() {
        return this.h;
      }
      getAttribute(a) {
        return this.a[a];
      }
    };
  var $w = class extends Zw {
    constructor(a, b, c) {
      super(a, [], c);
      this.j = b || "";
    }
  };
  var ax = class extends Zw {
    constructor(a, b, c) {
      super(a, b, c);
    }
  };
  var rw = /^(\d{2,}):(\d{2}):(\d{2}):(\d{2})\.?(\d+)?$/,
    sw = /^(?:(\d{2,}):)?(\d{2}):(\d{2})$/,
    uw = /^(?:(\d{2,}):)?(\d{2}):(\d{2}\.\d+)$/,
    vw = /^(\d*(?:\.\d*)?)f$/,
    ww = /^(\d*(?:\.\d*)?)t$/,
    xw = /^(?:(\d*(?:\.\d*)?)h)?(?:(\d*(?:\.\d*)?)m)?(?:(\d*(?:\.\d*)?)s)?(?:(\d*(?:\.\d*)?)ms)?$/,
    Gw = /^(\d{1,2}(?:\.\d+)?|100(?:\.0+)?)% (\d{1,2}(?:\.\d+)?|100(?:\.0+)?)%$/,
    Hw = /^(\d+)px (\d+)px$/,
    Mw = /^(\d+) (\d+)$/,
    Bw = /(\d+)px/,
    bx = /^(\d+px|\d+em|\d*\.?\d+c)$/,
    Qw = [jw, "color", kw, lw, "fontFamily", "fontSize", "fontStyle", "fontWeight", "lineHeight", "opacity", "padding", nw, ow, "textOutline", pw, "zIndex"],
    Vw = ["begin", _.Sd];
  var cx = class extends ax {
    constructor(a, b, c, d, e, f, g) {
      super(a, f, g);
      this.w = b;
      this.A = c;
      this.o = d;
      this.l = e;
      this.j = [];
    }
    build(a, b, c, d, e, f, g) {
      let h = yw(this.a.begin, b);
      b = yw(this.a.end, b);
      if (null === h || null === b) return null;
      h += c;
      b += c;
      c = _.Mo(_.Ed);
      for (const k of this.j) c.appendChild(Fw(g.get(k), e, g));
      g = new _.Zs(h, b, c.innerHTML.trim(), _.Yf);
      g.cssClassList = [...this.f];
      g.textAlign = Ew(this, e, null);
      g.writingMode = _.Vs;
      g.line = -1;
      g.lineInterpretation = _.Ws;
      g.lineAlign = _.Xs;
      g.containerRows = Kw(null, Nw(a), 15);
      if (this.o && (f = f.get(this.o))) {
        g.region = f.build(a, e);
        g.textAlign = Ew(this, e, f);
        a: switch (qw(f, e, pw)) {
          case "tb":
          case "tblr":
            a = _.ig;
            break a;
          case "tbrl":
            a = _.jg;
            break a;
          default:
            a = _.Vs;
        }
        g.writingMode = a;
        a: switch (qw(f, e, lw)) {
          case _.bd:
            a = 50;
            break a;
          case "after":
            a = -1;
            break a;
          default:
            a = 0;
        }
        g.line = a;
        a: switch (qw(f, e, lw)) {
          case _.bd:
            a = 1;
            break a;
          default:
            a = _.Ws;
        }
        g.lineInterpretation = a;
        a: switch (qw(f, e, lw)) {
          case _.bd:
            e = _.bd;
            break a;
          default:
            e = _.Xs;
        }
        g.lineAlign = e;
      }
      this.l && (d = d.get(this.l)) && (a = d, d = a.getAttribute("encoding"), e = a.getAttribute("imageType") || a.getAttribute("imagetype"), a = a.j.trim(), d = "PNG" === e && "Base64" === d && 0 < a.length ? `data:image/png;base64,${a}` : null, g.backgroundImage = d || "");
      return g;
    }
  };
  var dx = class {
    constructor(a, b, c, d) {
      this.frameRate = Number(a) || 30;
      this.f = Number(b) || 1;
      this.a = Number(d);
      0 === this.a && (this.a = a ? this.frameRate * this.f : 1);
      c && (a = /^(\d+) (\d+)$/g.exec(c)) && (this.frameRate *= Number(a[1]) / Number(a[2]));
    }
  };
  var ex = class extends ax {
    constructor(a, b, c) {
      super(a, b, c);
    }
    build(a, b) {
      const c = new _.dt();
      c.id = this.getId();
      const d = Kw(a.j.containerWidth, Lw(a, 1), 720);
      a = Kw(a.j.containerHeight, Lw(a, 2), 480);
      a: switch (qw(this, b, lw)) {
        case "after":
          var e = "flex-start";
          break a;
        case _.bd:
          e = _.bd;
          break a;
        default:
          e = "flex-end";
      }
      c.displayAlign = e;
      a: switch (qw(this, b, pw)) {
        case "tb":
        case "tblr":
          e = "row-reverse";
          break a;
        case "tbrl":
          e = "row";
          break a;
        default:
          e = _.qd;
      }
      c.writingMode = e;
      e = this.getAttribute("extent") || qw(this, b, "extent");
      c.width = Iw(e, d, 1, 100);
      c.widthUnits = Jw(e, d);
      c.height = Iw(e, a, 2, 100);
      c.heightUnits = Jw(e, a);
      b = this.getAttribute("origin") || qw(this, b, "origin");
      c.viewportAnchorX = Iw(b, d, 1, 0);
      c.viewportAnchorY = Iw(b, a, 2, 0);
      c.viewportAnchorUnits = Jw(b, d);
      c.cssClassList = [...this.f];
      return c;
    }
  };
  var fx = class extends Zw {
    constructor(a, b) {
      super("root-container", [], b);
      this.j = a;
    }
  };
  var hx = class extends Zw {
      constructor(a, b, c) {
        super(a, b, c);
      }
      build(a) {
        var b = null;
        var c = Nw(a);
        c && (b = Kw(a.j.containerHeight, Lw(a, 2), 480) / c);
        b = Kw(a.j.fontSizeRadix, b, 16);
        a = [];
        c = `::ttml .${this.h}, ::ttml .${this.h} * {`;
        for (const [g, h] of gx.entries()) g in this.a && (c += `${h}:${Aw(this.a[g])};`);
        a: {
          if ("unicodeBidi" in this.a) {
            var d = this.a.unicodeBidi;
            var e = "";
            switch (d) {
              case "normal":
              case "embed":
                e = d;
                break;
              case "bidiOverride":
                e = "bidi-override";
            }
            if (0 < e.length) {
              d = `unicode-bidi:${e};`;
              break a;
            }
          }
          d = "";
        }
        c += d;
        a: {
          if ("textOutline" in this.a) {
            d = this.a.textOutline.split(" ");
            e = "black";
            let g = ".4em";
            const h = d[0] && d[0].trim(),
              k = d[1] && d[1].trim(),
              l = d[2] && d[2].trim();
            3 === d.length && !bx.test(h) && bx.test(k) && bx.test(l) ? (e = h, g = k) : 2 === d.length && !bx.test(h) && bx.test(k) ? (e = h, g = k) : 2 === d.length && bx.test(h) && bx.test(k) && (g = h);
            if ("" !== g) {
              g = Aw(g);
              d = "text-shadow:" + `0 0 ${g} ${e},` + `0 0 ${g} ${e},` + `0 0 ${g} ${e};`;
              break a;
            }
          }
          d = "";
        }
        c += d;
        "textShadow" in this.a ? (d = Dw(this.a.textShadow), d = Aw(d), d = `text-shadow:${d};`) : d = "";
        c = c + d + ("fontFamily" in this.a ? `font-family:${this.a.fontFamily.replace("monospaceSansSerif", "monospace").replace("monospaceSerif", "monospace").replace("proportionalSansSerif", "sans-serif").replace("proportionalSerif", "sans-serif").replace("sansSerif", "sans-serif").replace("default", "sans-serif")};` : "");
        a: {
          if (ow in this.a) {
            d = this.a.textDecoration.split(" ");
            e = [];
            for (f of d) switch (f) {
              case "underline":
              case "overline":
                e.push(f);
                break;
              case "lineThrough":
                e.push("line-through");
            }
            if (0 < e.length) {
              var f = `text-decoration:${e.join(" ")};`;
              break a;
            }
          }
          f = "";
        }
        "padding" in this.a ? (d = "border: solid transparent;border-width:" + Aw(this.a.padding), d += ";") : d = "";
        f = c = c + f + d;
        "lineHeight" in this.a ? (c = "", d = this.a.lineHeight, d = Aw(d), d = Cw(d, b), c += "line-height:", c += d, c += ";") : c = "";
        a.push(f + c + "}");
        a: {
          if ("fontSize" in this.a && (f = this.a.fontSize.split(" "), c = null, 1 === f.length && (c = f[0]), 2 === f.length && (c = f[1]), c)) {
            c = c.trim();
            c = Aw(c);
            c = Cw(c, b);
            b = `font-size:${c};`;
            break a;
          }
          b = null;
        }
        b && (f = `::ttml .${this.h} .clpp-text-wrapper {`, f += b, f += "}", a.push(f), f = `::ttml .clpp-text-wrapper__${this.h} {`, f += b, f += "}", a.push(f));
        return a;
      }
    },
    gx = new Map([[jw, "background-color"], ["color", "color"], [kw, kw], ["fontStyle", "font-style"], ["fontWeight", "font-weight"], ["opacity", "opacity"], ["zIndex", "z-index"]]);
  var ix = class {
      constructor(a) {
        this.B = a;
        this.w = this.l = null;
        this.A = 0;
        this.h = new Map();
        this.a = new Map();
        this.j = new Map();
        this.f = new Map();
        this.o = [];
      }
    },
    Ow = 0;
  var lx = class {
      constructor(a) {
        this.a = a.firstChild;
        this.f = new Set();
      }
      Vc() {
        return !!this.a;
      }
      next() {
        if (!this.a) return null;
        let a = this.a;
        do this.f.add(a), a.nodeType !== Node.TEXT_NODE && !jx.has(zw(a.nodeName)) || kx.has(zw(a.nodeName)) || !a.firstChild || this.f.has(a.firstChild) ? a.nextSibling ? a = a.nextSibling : a = a.parentNode : a = a.firstChild; while (a && (this.f.has(a) || a.nodeType !== Node.TEXT_NODE && !jx.has(zw(a.nodeName))));
        const b = this.a;
        this.a = a;
        return b;
      }
    },
    jx = new Set(["tt", "head", "layout", _.we, _.be, mw, "styling", _.Rf, "body", _.Ed, "p", "span", "br"]),
    kx = new Set([_.Rf, _.be]);
  var Ww = class {
    constructor(a, b, c, d, e, f) {
      this.h = a;
      this.a = b;
      this.j = c;
      this.l = d;
      this.f = e;
      this.attributes = f;
    }
    getId() {
      return this.attributes.id;
    }
  };
  var mx = class {
    constructor() {
      this.a = new Map();
    }
  };
  var nx = class {
    constructor(a) {
      this.a = a;
      this.f = new DOMParser();
    }
    parseInit() {}
    parseMedia(a, b, c) {
      c && c.length || (c = [a.byteLength]);
      var d = _.Pg(this.a.ttml || {}),
        e = _.J(a, 0, c[0]);
      e = _.Ei(e);
      if ("" === e) return {
        cues: [],
        styles: []
      };
      var f = null;
      try {
        f = this.f.parseFromString(e, _.Vf);
      } catch (m) {
        throw new _.G(_.E, 2, 2005, "Failed to parse TTML.");
      }
      d = new ix(d);
      e = new mx();
      f = new lx(f);
      b = b.periodStart;
      typeof b === _.r && (d.A = b);
      b = c[0];
      for (var g = 1; g < c.length; ++g) {
        var h = c[g],
          k = _.J(a, b, h);
        k = _.Si(k);
        const m = `urn:mpeg:14496-30:subs:${g}`;
        d.h.set(m, new $w(m, k, {
          imageType: "PNG",
          encoding: "Base64"
        }));
        b += h;
      }
      for (; f.Vc();) a = f.next(), a = Xw(e, a), c = d, b = a, "tt" === Uw(b) && (c.l = new fx(c.B, Object.assign({}, b.attributes))), c = d, b = a, "tt" === Uw(b) && (c.w = new dx(b.attributes.frameRate, b.attributes.subFrameRate, b.attributes.frameRateMultiplier, b.attributes.tickRate)), c = d, b = a, Uw(b) !== _.be || b.attributes.begin || b.attributes.end || (g = `#${b.getId()}`, c.h.set(g, new $w(g, b.h.textContent, b.attributes))), c = d, g = a, Uw(g) === _.be && g.attributes.begin && g.attributes.end ? (b = `#${Pw()}`, g = new $w(b, g.h.textContent, g.attributes), c.h.set(b, g), c = g) : c = null, c && (a.j = c.getId()), c = d, b = a, Uw(b) === _.Rf && (g = b.getId() || Pw(), h = new hx(g, b.f, Object.assign({}, b.attributes)), c.a.set(g, h), b.a && c.j.get(b.a).f.push(g)), c = d, g = a, Uw(g) !== _.Rf && Rw(g) ? (b = Pw(), g = new hx(b, [], Object.assign({}, g.attributes)), c.a.set(b, g), c = g) : c = null, c && a.f.push(c.getId()), c = d, g = a, Uw(g) !== _.Ed || !g.attributes.extent && !g.attributes.origin ? c = null : (b = Pw(), g = new ex(b, Sw(c, g.f), Object.assign({}, g.attributes)), c.j.set(b, g), c = g), c && (a.a = c.getId()), c = d, g = a, Uw(g) !== mw ? c = null : (b = g.getId(), g = new ex(b, Sw(c, g.f), Object.assign({}, g.attributes)), c.j.set(b, g), c = g), c && (a.a = c.getId()), c = d, g = a, b = g.l, -1 === ["#text", "span", "br"].indexOf(Uw(g)) || 0 === b.length ? c = null : (h = b[b.length - 1], c.f.has(h) ? (b = Pw(), g = new cx(b, "#text" === g.h.nodeName ? g.h.textContent : null, "br" === Uw(g), g.a, g.j, Sw(c, g.f), g.attributes), c.f.set(b, g), (c = c.f.get(h)) && c.j.push(b), c = g) : c = null), c && a.l.push(c.getId()), c = d, g = a, "p" === Uw(g) || g.j ? (b = Pw(), g = new cx(b, null, !1, g.a, g.j, Sw(c, g.f), g.attributes), c.f.set(b, g), c.o.push(g), c = g) : c = null, c && a.l.push(c.getId());
      e = Tw(d);
      f = [];
      for (l of d.a.values()) f.push(...l.build(d.l));
      var l = f;
      return {
        cues: e,
        styles: l
      };
    }
  };
  var ox = class {
      constructor(a) {
        this.f = a;
      }
      a(a) {
        var b = new nx(this.f);
        ({
          cues: a
        } = b.parseMedia(a, {
          periodStart: 0,
          segmentStart: null,
          segmentEnd: 0
        }));
        b = "WEBVTT\n\n";
        for (const d of a) {
          a = b;
          b = Yw(d.startTime) + " --\x3e " + Yw(d.endTime);
          {
            var c = d;
            const e = [];
            switch (c.textAlign) {
              case _.ee:
                e.push("align:left");
                break;
              case _.yf:
                e.push("align:right");
                break;
              case _.Us:
                e.push("align:middle");
                break;
              case _.ee:
                e.push("align:start");
                break;
              case _.yf:
                e.push("align:end");
            }
            switch (c.writingMode) {
              case _.ig:
                e.push("vertical:lr");
                break;
              case _.jg:
                e.push("vertical:rl");
            }
            c = e.length ? " " + e.join(" ") : "";
          }
          b = a + (b + c + "\n");
          b += d.payload + "\n\n";
        }
        return _.Ii(b);
      }
    },
    px = class {
      probe(a, b) {
        return a === _.Ec && b === _.Uf;
      }
      create(a) {
        return new ox(a);
      }
    };
  var qx = class {
    constructor(a) {
      this.a = new nx(a);
      this.g = new _.R("clpp.ttml.Mp4TtmlParser");
    }
    parseInit(a) {
      let b = !1;
      new _.em().N(_.ze, _.Yl).N(_.Xf, _.Yl).N(_.ue, _.Yl).N(_.ye, _.Yl).N(_.Of, _.Yl).ca(_.Qf, _.Zl).N("stpp", c => {
        b = !0;
        c.parser.stop();
      }).parse(a);
      if (!b) throw new _.G(_.E, 2, 2007);
    }
    parseMedia(a, b) {
      let c = !1,
        d = [],
        e = [];
      const f = [];
      new _.em().N("moof", _.Yl).N("traf", _.Yl).ca("subs", g => {
        const h = _.O(g.reader);
        for (let k = 0; k < h; ++k) {
          g.reader.skip(4);
          const l = _.Rl(g.reader);
          for (let m = 0; m < l; ++m) {
            const n = 1 === g.version ? _.O(g.reader) : _.Rl(g.reader);
            f.push(n);
            g.reader.skip(6);
          }
        }
      }).N(_.te, _.$l(g => {
        c = !0;
        g = this.a.parseMedia(g, b, f);
        d = d.concat(g.cues);
        e = e.concat(g.styles);
      })).parse(a);
      c || this.g.warn("Couldn't find the mdat box");
      return {
        cues: d,
        styles: e
      };
    }
  };
  _.x("clpp.ttml.TtmlComponent", class extends _.qu {
    constructor() {
      super();
      this.j = new px();
    }
    f() {
      _.Es(_.Ec, nx);
      _.Es(_.Cc, qx);
      _.Es(gw, qx);
      _.Es(iw, qx);
      _.Es(hw, qx);
      _.Wj.push(this.j);
    }
    a() {
      _.Gs(_.Ec);
      _.Gs(_.Cc);
      _.Gs(gw);
      _.Gs(iw);
      _.Gs(hw);
      _.Yj(this.j);
    }
    id() {
      return "subtitles-ttml";
    }
  });
};
f.call(g, window);