<div class="cl-main " *ngIf="policies()">
  <router-outlet></router-outlet>
</div>
<div class="cl-main " *ngIf="!policies()">
  <app-logo-area [breadcrumbs]="[]"></app-logo-area>
  <main>

    <app-headline-section [title]="''"></app-headline-section>

    <div class="col-12" style="height: 20vh"></div>
    <div style="width: 100%; display: flex;  align-items: center; justify-content: center;">
      <div class="cl-spinner">Loading Policies & Licensed Services...<i class="fas fa-spinner fa-spin"></i></div>
    </div>
  </main>
</div>
