import {Component, forwardRef, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {Subscription} from 'rxjs';
import {LanguageSelectComponent} from './language-select/language-select.component';


@Component({
    selector: 'app-track-selection',
    templateUrl: './track-selection.component.html',
    styleUrls: ['./track-selection.component.scss'],
    providers: [{
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => TrackSelectionComponent),
        }],
  imports: [LanguageSelectComponent, ReactiveFormsModule]
})
export class TrackSelectionComponent implements OnInit, ControlValueAccessor, OnDestroy {
  static DEFAULTS = {
    audio: [],
    subtitles: [],
    cc: [],
  };
  form: FormGroup;

  private changed;
  private touched;

  private sub: Subscription;

  protected readonly environment = environment;

  writeValue(value) {
    this.form.patchValue(value, {emitEvent: false})
  }

  registerOnChange(fn) {
    this.changed = fn;
  }

  registerOnTouched(fn) {
    this.touched = fn;
  }

  constructor(private formBuilder: FormBuilder) {
    console.log('constructing TrackSelectionComponent')
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      audio: ['', [Validators.nullValidator]],
      subtitles: ['', [Validators.nullValidator]],
      cc: ['', [Validators.nullValidator]],
      images: ['', [Validators.nullValidator]],
    })
    this.sub = this.form.valueChanges.subscribe((val) => {
      this.touched();
      this.changed(val)
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
