<ng-template #modalFFProbeJSON>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">FFProbe JSON</div>
      <div class="modal-body">
        <pre>{{ ffprobe| json }}</pre>
      </div>
      <div class="modal-footer">
              <span class="modal-footer-left">
                <div style="display: flex; flex-direction: row; gap: 16px; align-content: space-between">
                  <app-do-button
                    *ngIf="'contentplatform:repository:Mutation/RefreshFFProbe'| isAllowed: 'contentplatform:file:input.file_id/' + (file()?.id || ''): organization()"
                    [messageField]="ffprobe_message"
                    (actionTriggered)="refresh_ffprobe($event)" buttonClass="btn btn-sm cl-btn-outline-text it-cancel">
                     <span>Force refresh</span>
                  </app-do-button>
                  <app-do-button
                    [messageField]="ffprobe_message"
                    (actionTriggered)="copy_ffprobe($event)" buttonClass="btn btn-sm cl-btn-outline-text it-cancel">
                     <span>Copy to clipboard</span>
                  </app-do-button>
                </div>
              </span>

        <span class="modal-footer-center">
          <div style="margin-top: -8px">
            <app-message-field #ffprobe_message/>
          </div>
        </span>
        <span class="modal-footer-right">
                    <button type="button" class="btn btn-sm cl-btn-outline-primary it-ok"
                            (click)="dialog.closeAll()">
                        <span>Close</span>
                    </button></span>

      </div>
    </div>
  </div>
</ng-template>


<ng-template #modalWarnings>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">Warnings</div>
      <div class="modal-body">
        <pre>{{ warnings | json }}</pre>
      </div>
      <div class="modal-footer">
        <div class="modal-footer-left">
          <div style="display: flex; flex-direction: row; gap: 16px; align-content: space-between">
            <app-do-button [messageField]="basicqc_message" buttonClass="btn btn-sm cl-btn-outline-text it-cancel"
                           (actionTriggered)="refresh_basic_qc($event)"
                           *ngIf="'contentplatform:repository:Mutation/RefreshBasicQc'| isAllowed: 'contentplatform:file:input.file_id/' + (file()?.id || '') : organization()">
              <span>Refresh basic QC</span>
            </app-do-button>
            <app-do-button [messageField]="basicqc_message" buttonClass="btn btn-sm cl-btn-outline-text it-cancel"
                           (actionTriggered)="copy_warnings($event)">
              <span>Copy to clipboard</span>
            </app-do-button>
          </div>
        </div>
        <span class="modal-footer-center ">
          <div style="margin-top: -8px">
            <app-message-field #basicqc_message/>
          </div>

        </span>
        <span class="modal-footer-right ">
                    <button type="button" class="btn cl-btn-outline-primary btn-sm"
                            (click)="dialog.closeAll()">
                        <span>Close</span>
                    </button></span>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #modalExtraInfo>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">Extra info</div>
      <div class="modal-body">
        <pre>{{ extra_info | json }}</pre>
      </div>
      <div class="modal-footer">
              <span class="modal-footer-left">
                <div style="display: flex; flex-direction: row; gap: 16px; align-content: space-between">
                    <app-do-button buttonClass="btn btn-sm cl-btn-outline-text it-cancel"
                                   [messageField]="extrainfo_message"
                                   (actionTriggered)="refresh_extra_info($event)"
                                   *ngIf="'contentplatform:repository:Mutation/RefreshExtra'| isAllowed: 'contentplatform:file:input.file_id/' + (file()?.id || ''): organization()">
                        <span>Refresh extra info</span>
                    </app-do-button>
                    <app-do-button buttonClass="btn btn-sm cl-btn-outline-text it-cancel"
                                   [messageField]="extrainfo_message"
                                   (actionTriggered)="copy_extra_info($event)">
                        <span>Copy to clipboard</span>
                    </app-do-button>
                  </div>
              </span>
        <span class="modal-footer-center">
          <div style="margin-top: -8px">
          <app-message-field #extrainfo_message/>
            </div>
        </span>
        <span class="modal-footer-right">
                    <button type="button" class="btn cl-btn-outline-primary btn-sm"
                            (click)="dialog.closeAll()">
                        <span>Close</span>
                    </button></span>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #modalMediaInfo>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">MediaInfo JSON</div>
      <div class="modal-body">
        <pre>{{ mediainfo_json | json }}</pre>
      </div>
      <div class="modal-footer">
              <span class="modal-footer-left">
                <div style="display: flex; flex-direction: row; gap: 16px; align-content: space-between">
                    <app-do-button [messageField]="mediainfo_json_message" buttonClass="btn btn-sm cl-btn-outline-text"
                                   (actionTriggered)="refresh_mediainfo($event)"
                                   *ngIf="'contentplatform:repository:Mutation/RefreshMediaInfo'| isAllowed: 'contentplatform:file:input.file_id/' + (file()?.id || '') : organization()">
                        <span>Refresh mediainfo</span>
                    </app-do-button>
                    <app-do-button [messageField]="mediainfo_json_message" buttonClass="btn btn-sm cl-btn-outline-text"
                                   (actionTriggered)="copy_mediainfo_json($event)">
                        <span>Copy to clipboard</span>
                    </app-do-button>
                  </div>
              </span>
        <span class="modal-footer-center">
          <div style="margin-top: -8px">
          <app-message-field #mediainfo_json_message/>
            </div>
        </span>
        <span class="modal-footer-right">
                    <button type="button" class="btn btn-sm cl-btn-outline-primary it-ok"
                            (click)="dialog.closeAll()">
                        <span>Close</span>
                    </button></span>
      </div>
    </div>
  </div>

</ng-template>


<ng-template #modalMediaInfoTxt>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">MediaInfo text</div>
      <div class="modal-body">
        <pre>{{ mediainfo }}</pre>
      </div>
      <div class="modal-footer">
              <span class="modal-footer-left">
                <div style="display: flex; flex-direction: row; gap: 16px; align-content: space-between">
                    <app-do-button
                      (actionTriggered)="refresh_mediainfo($event)" [messageField]="mediainfo_message"
                      buttonClass="btn btn-sm cl-btn-outline-text"
                      *ngIf="'contentplatform:repository:Mutation/RefreshMediaInfo'| isAllowed: 'contentplatform:file:input.file_id/' + (file()?.id || ''): organization()">
                        <span>Refresh mediainfo</span>
                    </app-do-button>
                    <app-do-button [messageField]="mediainfo_message"
                                   buttonClass="btn btn-sm cl-btn-outline-text"
                                   (actionTriggered)="copy_mediainfo($event)">
                        <span>Copy to clipboard</span>
                    </app-do-button>
                  </div>
              </span>
        <span class="modal-footer-center">
          <div style="margin-top: -8px">
          <app-message-field #mediainfo_message/>
            </div>
        </span>
        <span class="modal-footer-right">
                    <button type="button" class="btn btn-sm cl-btn-outline-primary"
                            (click)="dialog.closeAll()">
                        <span>Close</span>
                    </button></span>
      </div>
    </div>
  </div>
</ng-template>

<app-logo-area [breadcrumbs]="breadcrumps()"></app-logo-area>
<main>
  <app-headline-section [title]="'Assets'"></app-headline-section>
  <section class="cl-section-regular">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2>
            <app-asset-icon class="cl-faded" [thing]="file()"></app-asset-icon>
            {{ this.file()?.name }}
            <a class="cl-a-quickaction" [routerLink]="folder() | link"
               style="white-space: nowrap;">..&nbsp;(up)</a>
          </h2>
        </div>
        <div class="col-12">
          <app-s3-link [fof]="file()"></app-s3-link>
        </div>
      </div>
    </div>

  </section>
  <section class="cl-section-regular">
    <div class="container">
      <div class="row" *ngIf="loading()">
        <div class="col-12" style="text-align: center;">
          <div class="cl-spinner">Loading File Details...<i class="fas fa-spinner fa-spin"></i></div>
        </div>
      </div>
      <div class="row" *ngIf="!loading()">
        <div class="col-12" style="  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end; ">
          <div class="cl-h3">
            Tracks
          </div>
          <div style="display: flex; flex-direction: row; gap: 16px;  align-items: end; ">
            <app-message-field #trackUpdateMessage/>
            <div
              *ngIf="'contentplatform:repository:Mutation/UpdateFile'| isAllowed: 'contentplatform:file:input.file_id/' + (file()?.id || ''): organization()"
            >
              <app-do-button [messageField]="trackUpdateMessage" (actionTriggered)="refreshTracks($event)"><span>Reset tracks</span><i
                class="fas fa-angle-right"></i>
              </app-do-button>
            </div>
          </div>
        </div>
        <div class="col-12">
          <form role="form" class="castlabs-ui" #form="ngForm">

            <table class="cl-table-data" style="table-layout: fixed">
              <colgroup>
                <col span="1" style="width: 60px">
                <col span="1">
                <col span="1">
                <col span="1" style="width: 70px;">
              </colgroup>
              <thead>
              <tr>
                <th>index</th>
                <th>track&nbsp;type</th>
                <th>language</th>
                <th>enabled</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngIf="tracks.length === 0">
                <td colspan="4" style="text-align: center;">No tracks available.</td>
              </tr>
              <tr *ngFor="let track of tracks; let i = index;">
                <td>
                  <div [style.color]="track['enabled']?'unset':'rgba(64, 64, 64, 0.38)'">{{ track['index'] }}</div>
                </td>
                <td>
                  <ng-container
                    *ngIf="track['track_type'] == TRACKTYPES.Audio ||  track['track_type'] == TRACKTYPES.Video||  track['track_type'] == TRACKTYPES.Image ||
               track['track_type'] == TRACKTYPES.Data || !('contentplatform:repository:Mutation/UpdateFile'| isAllowed: 'contentplatform:file:input.file_id/' + (file()?.id || '') :organization()); else selecttype">
                    <div [style.color]="track['enabled']?'unset':'rgba(64, 64, 64, 0.38)'"
                         style="width: 100%; overflow: visible"> {{ track['track_type'] }}
                    </div>
                  </ng-container>
                  <ng-template #selecttype>
                    <div class="cl-form">


                      <select class="form-select" name="track_type{{i}}" [(ngModel)]="track['track_type']"
                              [disabled]="!track['enabled']"
                              *ngIf="track['track_type'] == TRACKTYPES.Subtitle ||  track['track_type'] == TRACKTYPES.Closedcaption">
                        <option *ngFor="let tt of [TRACKTYPES.Subtitle, TRACKTYPES.Closedcaption]" [value]="tt">
                          {{ tt }}
                        </option>
                      </select>
                    </div>
                  </ng-template>
                </td>
                <td>

                  <ng-container
                    *ngIf="!('contentplatform:repository:Mutation/UpdateFile'| isAllowed: 'contentplatform:file:input.file_id/' + (file()?.id || ''): organization()); else selectlang">
                    <ng-container *ngFor="let lang of languages">
                      <div [style.color]="track['enabled']?'unset':'rgba(64, 64, 64, 0.38)'"
                           *ngIf="lang.id === track['language']">{{ lang.name }}
                      </div>
                    </ng-container>

                  </ng-container>
                  <ng-template #selectlang>
                    <div style="" class="cl-form">
                      <select
                        [(ngModel)]="track['language']"
                        name="language{{i}}"
                        [disabled]="!track['enabled']"
                        class="form-select">
                        <option *ngFor="let lang of languages" [value]="lang.id">
                          {{ lang.name }}
                        </option>
                      </select>

                    </div>
                  </ng-template>

                </td>
                <td>
                  <div class="form-check" style="margin-top: 0">
                    <input type="checkbox" class="form-check-input" title="{{ track['index'] }} enabled"
                           [(ngModel)]="track['enabled']" name="enabled{{i}}"
                           [disabled]="!('contentplatform:repository:Mutation/UpdateFile'| isAllowed: 'contentplatform:file:input.file_id/' + (file()?.id || ''): organization())">
                  </div>

                </td>
              </tr>
              </tbody>
            </table>

          </form>
        </div>

        <div class="col-12">
          <h3>Tags</h3>
        </div>

        <div class="col-12">
          <table
            class="cl-table-data cl-form">
            <thead>
            <tr>
              <th style="width: 33%">key</th>
              <th style="width: 66%">value</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="!tags || tags.length === 0" class="cl-tr-no-data">
              <td colspan="100">
                <div style="display: flex; flex-direction: row; width: 100%; justify-content: center">
                  <div style="display: flex; flex-direction: row">
                    <div style="padding-top: 7px">No tags available.
                      <a role="button"
                         *ngIf="'contentplatform:repository:Mutation/UpdateFile'| isAllowed: 'contentplatform:file:input.file_id/' + (file()?.id || '') : organization()"
                         class="cl-a-quickaction"
                         (click)="onAddTag($event)">
                        Add tag
                      </a></div>
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngFor="let tag of tags; let i = index;let last=last; let first= first">
              <td style="width: 33%">

                <input
                  *ngIf="'contentplatform:repository:Mutation/UpdateFile'| isAllowed: 'contentplatform:file:input.file_id/' + (file()?.id || ''): organization(); else keyelse"
                  type="text" [(ngModel)]="tags[i].key">

                <ng-template #keyelse>{{ tags[i].key }}</ng-template>
              </td>
              <td style="width: 66%">
                <div style="display: flex; flex-direction: row"
                     *ngIf="'contentplatform:repository:Mutation/UpdateFile'| isAllowed: 'contentplatform:file:input.file_id/' + (file()?.id || ''): organization(); else valueelse">

                  <input type="text" [(ngModel)]="tags[i].value">
                  <a role="button" *ngIf="last" class="cl-a-quickaction" style="white-space:nowrap;"
                     (click)="onAddTag($event)">
                    Add&nbsp;tag
                  </a>
                </div>
                <ng-template #valueelse>{{ tags[i].value }}</ng-template>
              </td>
            </tr>
            </tbody>
          </table>
        </div>


        <div class="col-12 " style="display: flex; flex-direction: row"
             *ngIf="'contentplatform:repository:Mutation/UpdateFile'| isAllowed: 'contentplatform:file:input.file_id/' + (file()?.id || '') : organization()">
          <div style="margin-left: auto; display: flex; flex-direction: row; gap: 16px;  align-items: end; ">
            <app-message-field #saveMessage/>
            <app-do-button (actionTriggered)="save($event)" [messageField]="saveMessage"><span>Save tags & tracks</span><i
              class="fas fa-angle-right"></i>
            </app-do-button>
          </div>
        </div>


        <div class="cl-h3">System-Generated Metadata</div>
        <div class="col-12" style="display: flex; flex-direction: row">
          <div style="margin-left: auto"></div>
          <div style="flex-direction: row; display: flex; justify-content:  space-between; gap: 16px">
            <div>
              <button (click)="openDialog(modalFFProbeJSON)" class="btn btn-sm cl-btn-outline-text">Show FFProbe JSON
              </button>
            </div>
            <div>
              <button (click)="openDialog(modalMediaInfo)" class="btn btn-sm cl-btn-outline-text">Show MediaInfo JSON
              </button>
            </div>

            <button (click)="openDialog(modalMediaInfoTxt)" class="btn btn-sm cl-btn-outline-text">Show MediaInfo text
            </button>
          </div>
        </div>

        <div class="cl-h3">QC and extra information</div>
        <div style="display: flex; flex-direction: row;">
          <div style="margin-left: auto"></div>
          <div style="flex-direction: row; display: flex; justify-content:  space-between; gap: 16px">
            <button (click)="openDialog(modalWarnings)" class="btn btn-sm cl-btn-outline-text">Show basic QC
              <i style="color: #2a80d5" *ngIf="warnings.length > 0" class="fa fa-times-circle"></i>
            </button>
            <button (click)="openDialog(modalExtraInfo)" class="btn btn-sm cl-btn-outline-text">Show extra info</button>

          </div>
        </div>

        <ng-container *ngIf="has_video">
          <div class="cl-h3">Stills</div>
          <ng-container *ngIf="(this.stills) as stills">
            <ng-container *ngIf="stills.length > 0; else nostills">
              <ngx-gallery [options]="galleryOptions" [images]="stills" class="ngx-gallery"></ngx-gallery>
            </ng-container>
            <ng-template #nostills>
              <div style="display: flex; flex-direction: row;align-items: center">
                <div style="flex-grow: 1; display: flex; flex-direction: column; align-self: center">
                  <div style="text-align: center">
                    <img width="125" src="../../../../assets/emptystates/NoImages.png"/>
                  </div>
                  <div class="cl-h3" style="text-align: center">There are no stills.</div>
                </div>
                <div style="align-self: flex-end">
                  <app-do-button (actionTriggered)="generate_stills($event)">
                    <span>Generate stills</span><i
                    class="fas fa-angle-right"></i>
                  </app-do-button>

                </div>
              </div>
            </ng-template>

          </ng-container>
        </ng-container>
      </div>
    </div>
  </section>
</main>
