<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">Restore From Archive</div>
    <div class="modal-body cl-form">

      <p>You are about to restore or change the restore parameter of the following files:</p>

      <table class="cl-table-data cl-table-data-light">
        <thead>
        <tr>
          <th class="cl-table-head cl-p-tiny">Name</th>
          <th class="cl-table-head cl-p-tiny">Size</th>
          <th class="cl-table-head cl-p-tiny">Restoration Plan</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let file of data.files">
          <td class="cl-font-condensed">{{ file.name }}</td>
          <td class="cl-font-condensed" style="width: 70px">{{ file.size | fileSize:0 }}</td>
          <td class="cl-font-condensed">{{ file | fileRestorePlan:selectedTier }}</td>
        </tbody>
      </table>


      <label class="form-label" for="days">Days to keep restored</label>
      <input id="days" style="width: 100%" type="number" [(ngModel)]="days" min="1">

      <label class="form-label">Please select a restore tier (affects the cost and restoration time).</label>
      <div class="form-check">
        <input [value]="RestoreTier.RestoreTier_1"
               [(ngModel)]="selectedTier" class="form-check-input" type="radio" name="tier" id="radio1">
        <label class="form-check-label" for="radio1">Express Restore</label>
        <div class="desc" *ngIf="selectedTier == RestoreTier.RestoreTier_1">In a hurry? Select our Express Restore for
          the fastest possible restoration. Please note that this option incurs a fee and is suitable for urgent
          requirements. Estimated time for completion: 3-5 minutes.
        </div>
      </div>
      <div class="form-check">
        <input [value]="RestoreTier.RestoreTier_2"
               [(ngModel)]="selectedTier" class="form-check-input" type="radio" name="tier" id="radio2">
        <label class="form-check-label" for="radio2">Standard Restore</label>
        <div class="desc" *ngIf="selectedTier == RestoreTier.RestoreTier_2">Need it a bit faster? Choose our Standard
          Restore for a quicker process. We cover the cost, but recommend this option only if it's necessary. Estimated
          time for completion: 3-5 hours.
        </div>
      </div>
      <div class="form-check">
        <input [value]="RestoreTier.RestoreTier_3"
               [(ngModel)]="selectedTier" class="form-check-input" type="radio" name="tier" id="radio3">
        <label class="form-check-label" for="radio3">Economical Restore</label>
        <div class="desc" *ngIf="selectedTier ==RestoreTier.RestoreTier_3">Take advantage of our free restore option.
          Perfect for non-urgent situations, this option allows you to save costs while we efficiently restore your
          data. Estimated time for completion: 5-12 hours.
        </div>
      </div>

      <ng-container *ngIf="selectedTier === RestoreTier.RestoreTier_1">
        <label for="confirmfield" class="form-label">Type <i>confirm</i> to confirm</label>
        <input id="confirmfield" type="text" [(ngModel)]="confirmText" style="width: 100%;">
      </ng-container>

    </div>
    <div class="modal-footer">
      <span class="modal-footer-left "><button class="btn btn-sm cl-btn-outline-text"
                                                                             (click)="dialogRef.close()"><i
        class="fas fa-angle-left"></i><span>Cancel</span></button></span>
      <span class="modal-footer-right ">
      <button class="btn btn-sm cl-btn-outline-primary" [class.cl-btn-spinner]="startedrestoring"
              (click)="startRestore()"
              [disabled]="selectedTier === RestoreTier.RestoreTier_1 && confirmText !== 'confirm'"><span>Restore</span><i
        class="fas fa-angle-right"></i></button>
      </span>
    </div>


  </div>
</div>
