import {Pipe, PipeTransform} from '@angular/core';

/*
 *
 */
@Pipe({name: 'contains', pure: true})
export class ContainsPipe implements PipeTransform {

    transform(array: string[], value: string): boolean {
      return array.includes(value);
    }
}
