import {Component, Inject, OnInit, Pipe, PipeTransform, ViewChild} from '@angular/core';
import {File, Folder, Mutation, ProcessStateEnum, Query, RestoreState, RestoreTier} from '../../types';
import {RemainingTimePipe} from '../../pipes/remaining-time-pipe';
import {FILE_FOLDER_LIST_WITH_ARCHIVE, RESTORE} from '../../queries';
import {Apollo} from 'apollo-angular';
import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {switchMap} from 'rxjs';
import {skipWhile} from 'rxjs/operators';
import {ProcessService} from '../../services/process.service';
import {FormsModule} from '@angular/forms';
import {FileSizePipe} from '../../pipes/file-size-pipe';
import {CommonModule} from '@angular/common';

@Pipe({
  name: 'fileRestorePlan',
  pure: false
})
export class FileRestorePlanPipe implements PipeTransform {
  tiers = [
    {
      tier: RestoreTier.RestoreTier_1, label: 'Express Restore',
    },
    {
      tier: RestoreTier.RestoreTier_2, label: 'Standard Restore',
    },
    {
      tier: RestoreTier.RestoreTier_3, label: 'Economical Restore',
    }
  ];

  transform(file: File, tier: RestoreTier): string {
    if (!file.archive || ![RestoreState.Archived, RestoreState.Restored, RestoreState.Restoring].includes(file.archive.restore_state)) {
      return '';  // not glacier or unexpected state
    }
    const selectedName = this.tiers.find(t => t.tier === tier).label
    switch (file.archive.restore_state) {
      case RestoreState.Archived:
        return 'Restore with ' + selectedName + '.';
      case RestoreState.Restoring:
        if (tier < file.archive.extra.restore_tier) {
          return 'Upgrade to ' + selectedName + '.';
        }
        const eta = new RemainingTimePipe().transform(file.archive.extra.restore_eta, 'almost done');
        return this.tiers.find(t => t.tier === file.archive.extra.restore_tier).label +
          ' is in progress (max eta ' + eta + ')';
      case RestoreState.Restored:
        return 'Restored. Extend availability.';
      default:
        return 'unexpected restore state. please contact castLabs support';
    }
  }
}

@Component({
  selector: 'app-archive-restore-dialog',
  templateUrl: './archive-restore-dialog.component.html',
  styleUrls: ['archive-restore-dialog.component.scss'],
  imports: [
    FormsModule,
    FileSizePipe,
    FileRestorePlanPipe,
    CommonModule
  ]
})
export class ArchiveRestoreDialogComponent implements OnInit {


  readonly RestoreTier = RestoreTier;
  selectedTier: RestoreTier = RestoreTier.RestoreTier_3;
  confirmText: String = '';
  totalHTML = '';
  days = 14;

  startedrestoring = false;

  files: File[];
  folder: Folder;
  totalSize = 0;


  constructor(
    public dialogRef: DialogRef<ArchiveRestoreDialogComponent>,
    @Inject(DIALOG_DATA) public data: {
      files: File[],
      folder: Folder
    },
    private apollo: Apollo,
    private processService: ProcessService,
  ) {
    this.files = this.data.files;
    this.folder = this.data.folder;
    this.totalSize = this.files.reduce((sum, current) => sum + current.size, 0);
  }

  ngOnInit(): void {
  }

  startRestore() {
    this.startedrestoring = true;
    const file_ids = this.files.map(file => file.id);
    const files_str = file_ids.join(', ');
    console.log(`Restore tier: ${this.selectedTier} for ${files_str}`);

    const wait = (ms) => new Promise((res) => setTimeout(res, ms));
    const delayRefetchedQuery = async (observableQuery) => {
      await wait(10000);
      observableQuery.refetch();
      this.startedrestoring = false;
      this.dialogRef.close()
    }
    this.apollo.mutate<Mutation>({
      mutation: RESTORE,
      variables: {
        files: file_ids,
        tier: this.selectedTier,
        days: this.days,
      },
      fetchPolicy: 'network-only',
      onQueryUpdated: delayRefetchedQuery,
      refetchQueries: [{
        query: FILE_FOLDER_LIST_WITH_ARCHIVE,
        variables: {
          id: this.folder.id
        },
      }]
    }).pipe(
      switchMap(r => this.processService.observeProcess(r.data.restore,
        {
          successMessage: `Successfully restored ${files_str}.`,
          failureMessage: `Failed to restore ${files_str}.`,
          link: window.location.href
        }
      )),
      skipWhile(process => {
        return process.state === ProcessStateEnum.InProgress
      })
    ).subscribe(() => this.apollo.query<Query>({
      query: FILE_FOLDER_LIST_WITH_ARCHIVE,
      variables: {
        id: this.folder.id
      }
    }));
  }
}
