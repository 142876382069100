import {Component, Inject, OnInit} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {Router} from '@angular/router';
import {BannerService} from '../../../banner/banner.service';
import {File, Folder, Mutation, RepositoryProcessPayload} from '../../../types';
import gql from 'graphql-tag';
import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DoButtonComponent} from '../../do-button/do-button.component';
import {MessageFieldComponent} from '../../do-button/message-field.component';
import {ProcessService} from '../../../services/process.service';
import {switchMap, timer} from 'rxjs';
import {FILE_FOLDER_LIST} from '../../../queries';

@Component({
  selector: 'app-rename-file-dialog',
  templateUrl: './rename-file-dialog.component.html',
  styleUrls: ['./rename-file-dialog.component.scss'],
  imports: [FormsModule, ReactiveFormsModule, DoButtonComponent, MessageFieldComponent]
})
export class RenameFileDialogComponent implements OnInit {
  dest_name: string;
  response: RepositoryProcessPayload

  constructor(
    public dialogRef: DialogRef<RenameFileDialogComponent>,
    @Inject(DIALOG_DATA) public data: { file: File, folder: Folder },
    private apollo: Apollo,
    public router: Router,
    public processService: ProcessService,
    public banner: BannerService
  ) {
    this.dest_name = data.file.name;
  }

  ngOnInit() {

  }

  public rename(button: DoButtonComponent) {
    const mutation = `mutation MyMutation($src: String!, $dest: String!) {
      move_file(input: {src: $src, dest: $dest}) {
        state
        id
      }
    }
    `

    this.apollo.mutate<Mutation>({
      mutation: gql(mutation),
      variables: {
        src: this.data.file.id,
        dest: `${this.data.folder.id}${this.dest_name}`
      }
    }).subscribe(response => {
      this.response = response.data.move_file
      this.processService.observeProcess(response.data.move_file, {
        failureMessage: `Failed to rename ${this.data.file.name}`,
      }).pipe(
        switchMap(move => this.apollo.query({
          query: FILE_FOLDER_LIST,
          fetchPolicy: 'network-only',
          variables: {
            id: this.data.file.id,
            show_deleted: true
          }
        })
      )).subscribe(() => {
          if (!this.data.file.archived) {
            button.done('Files moved successfully')
            timer(1000).subscribe(() => this.dialogRef.close())
          }
        }
      );
    })
    if (!this.data.file.archived) {
      button.info('Renaming ...', {duration: -1})
    } else {
      button.done('Restoring. Rename will performed after restore is complete...')
      timer(5000).subscribe(() => this.close())
    }

  }


  public close() {
    console.log('closing ')

    this.processService.observeProcess(this.response, {
      failureMessage: `Failed to move ${this.data.file.name}`,
      successMessage: `Successfully moved ${this.data.file.name}`,
      link: window.location.href
    })
    this.dialogRef.close()
  }
}

