import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

export class Preview {
  folder_id: string;
  dash_url: string;
  merchant: string;
  hls_url: string;
  asset_id: string;
  timestamp?: number; // To track expiration
}

const STORAGE_KEY = 'preview_list';
const EXPIRATION_TIME = 12 * 60 * 60 * 1000; // 12 hours in milliseconds
const CLEANUP_INTERVAL = 15 * 60 * 1000; // 15 minutes in milliseconds

@Injectable({
  providedIn: 'root'
})
export class PreviewRegistrationService {

  private previews = new BehaviorSubject<Preview[]>([]);

  constructor() {
    this.loadPreviewsFromStorage();
    this.startCleanupTimer();
  }

  public addPreview(preview: Preview) {
    preview.timestamp = Date.now(); // Set timestamp
    const previews = [...this.previews.value, preview];

    this.previews.next(previews);
    this.savePreviewsToStorage(previews);
  }

  public getPreviews(): Observable<Preview[]> {
    return this.previews.asObservable();
  }

  public removePreview(preview: Preview) {
    const updatedPreviews = this.previews.value.filter(p => p.asset_id !== preview.asset_id);

    this.previews.next(updatedPreviews);
    this.savePreviewsToStorage(updatedPreviews);
  }

  private savePreviewsToStorage(previews: Preview[]) {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(previews));
  }

  private loadPreviewsFromStorage() {
    const storedData = localStorage.getItem(STORAGE_KEY);
    if (storedData) {
      let previews: Preview[] = JSON.parse(storedData);
      previews = this.removeExpiredPreviews(previews);
      this.previews.next(previews);
      this.savePreviewsToStorage(previews); // Update storage after cleanup
    }
  }

  private removeExpiredPreviews(previews: Preview[]): Preview[] {
    const now = Date.now();
    return previews.filter(p => p.timestamp && now - p.timestamp < EXPIRATION_TIME);
  }

  private startCleanupTimer() {
    setInterval(() => {
      const updatedPreviews = this.removeExpiredPreviews(this.previews.value);
      if (updatedPreviews.length !== this.previews.value.length) {
        this.previews.next(updatedPreviews);
        this.savePreviewsToStorage(updatedPreviews);
      }
    }, CLEANUP_INTERVAL);
  }
}
