import {Component, OnInit} from '@angular/core';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [DatePipe]
})
export class FooterComponent implements OnInit {
  protected buildDate: Date = new Date(0);
  protected gitBranch = 'local';
  protected gitCommit = 'local';

  constructor() {
  }


  ngOnInit(): void {
    this.getCurrentAppData()
  }

  private async getCurrentAppData() {
    if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
      const registration = await navigator.serviceWorker.getRegistration();
      if (registration?.active?.state === 'activated') {
        const response = await fetch('/ngsw.json?ngsw-cache-bust=' + new Date().getTime());
        const data = await response.json();
        if (data.appData) {
          this.extractAppData(data.appData);
        }
      }
    }
  }

  private extractAppData(appData: any) {
    this.buildDate = appData.buildDate ? new Date(Date.parse(appData.buildDate)) : new Date();
    this.gitBranch = appData.gitBranch || 'Unknown';
    this.gitCommit = appData.gitCommit || 'Unknown';
  }
}
