import {Component, Inject, OnInit} from '@angular/core';
import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {File, Folder, Mutation} from '../types';
import {Apollo, gql} from 'apollo-angular';
import {FILE_FOLDER_LIST_WITH_ARCHIVE} from '../queries';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
  imports: [DatePipe]
})
export class DeleteDialogComponent implements OnInit {
  protected basedir: Folder;
  protected files: File[];
  deleting = false

  constructor(public dialogRef: DialogRef<string>,
              @Inject(DIALOG_DATA) public data: { basedir: Folder, files: File[] },
              private apollo: Apollo) {
    this.basedir = data.basedir;
    this.files = data.files;
  }

  ngOnInit(): void {
  }

  performDelete() {
    this.deleting = true;
    const delete_mutations = this.files.map((f, index) => `del${index}: delete(input: {id: "${f.id}"}) {
  file {
     id
   }
}`).join('\n')

    const mut = gql`
      mutation BatchDelete {
        ${delete_mutations}
      }`
    this.apollo.mutate<Mutation>({
      mutation: mut,
      refetchQueries: [{
        query: FILE_FOLDER_LIST_WITH_ARCHIVE,
        variables: {
          show_deleted: true,
          id: this.basedir.id
        },
      }]
    }).subscribe(() => {
      this.deleting = false;
      this.dialogRef.close();
    })
  }
}
