import {animate, style, transition, trigger} from '@angular/animations';


// Start at nothing and grow in height until reaching normal size
export const GROW_DOWN_ANIMATION = trigger('growDown', [
  transition(':enter', [
    style({height: '0', 'z-index': '1'}),
    animate(200, style({height: '*', 'z-index': '3'})),
  ]),
  transition(':leave', [
    style({height: '*'}),
    animate(200, style({height: '0'})),
  ])
]);
