import { clpp } from "./cl.core.js";
let g = {};
const _ = clpp._;
var f = function (window) {
  'use strict';

  var qR = "Unsupported unit",
    rR = "clpp-text-plain",
    sR = "clpp-text-ttml",
    tR = "clpp.htmlcue.HtmlTextDisplayer",
    uR = "initial",
    vR = function (a) {
      return _.Uh(a, {
        bf: (b, c) => b.yc(c)
      });
    },
    wR = function (a, b) {
      if (0 === b.length) return a;
      let c = a;
      a = a.findIndex(d => d.startTime >= b[0].startTime);
      0 <= a && (c = c.slice(0, a));
      return c.concat(b);
    },
    xR = function (a, b) {
      let c = 0,
        d = a.length - 1;
      for (; c <= d;) {
        const e = c + d >> 1,
          f = b(a[e]);
        if (0 === f) return e;
        0 < f ? c = e + 1 : d = e - 1;
      }
      return -1;
    },
    yR = function (a) {
      if (!a.input) return null;
      var b = a.input.match(/^([^<]*)(<[^>]+>?)?/);
      b = b[1] ? b[1] : b[2];
      a.input = a.input.substr(b.length);
      return b;
    },
    AR = function (a) {
      let b = null;
      for (; b = a.match(/&(amp|lt|gt|lrm|rlm|nbsp);/);) a = a.replace(b[0], c => zR[c]);
      return a;
    },
    CR = function (a, b) {
      BR(a, b);
      Array.from(b.getElementsByTagName("*")).forEach(c => {
        BR(a, c);
      });
    },
    BR = function (a, b) {
      for (const c in a) null != a[c] && (b.style[c] = a[c]);
    },
    DR = function (a, b) {
      return 0 === a ? "0" : a + b;
    },
    ER = function (a, b) {
      return 1 > Math.abs(a - b) || a < b;
    },
    FR = function (a, b) {
      return a < b && 1 < Math.abs(a - b);
    },
    GR = function (a, b) {
      return a > b && 1 < Math.abs(a - b);
    },
    HR = function (a, b) {
      return 1 > Math.abs(a - b) || a > b;
    },
    JR = function (a) {
      const b = a.getBoundingClientRect();
      var c = getComputedStyle(a, null);
      const d = parseFloat(c.getPropertyValue("border-left-width")) || 0,
        e = parseFloat(c.getPropertyValue("border-right-width")) || 0;
      var f = parseFloat(c.getPropertyValue("border-top-width")) || 0;
      c = parseFloat(c.getPropertyValue("border-bottom-width")) || 0;
      const g = (b.top || a.offsetTop) + f;
      f = (b.height || a.offsetHeight) - f - c;
      return new IR(b.left + d, b.right + e, g, (b.bottom || g + f) + c, f, (b.width || a.offsetWidth) - d - e);
    },
    KR = function (a) {
      return new IR(a.left, a.f, a.top, a.a, a.height, a.width);
    },
    LR = function (a, b) {
      return b.some(c => FR(a.left, c.f) && GR(a.f, c.left) && FR(a.top, c.a) && GR(a.a, c.top));
    },
    MR = function (a, b) {
      return HR(a.top, b.top) && ER(a.a, b.a) && HR(a.left, b.left) && ER(a.f, b.f);
    },
    NR = function (a, b, c) {
      switch (c) {
        case "+x":
          return FR(a.left, b.left);
        case "-x":
          return GR(a.f, b.f);
        case "+y":
          return FR(a.top, b.top);
        case "-y":
          return GR(a.a, b.a);
        default:
          throw Error("Unknown axis");
      }
    },
    OR = function (a) {
      return a.f.writingMode === _.Vs;
    },
    PR = function (a) {
      var b = a.a.childNodes[0],
        c = a.a.getBoundingClientRect();
      const d = b.getClientRects();
      b = c.height / d.length;
      c = c.width / d.length;
      return OR(a) ? b : c;
    },
    QR = function (a) {
      switch (a.f.writingMode) {
        case _.Vs:
          return "horizontal-tb";
        case _.ig:
          return _.ig;
        case _.jg:
          return _.jg;
        default:
          throw Error("Unknown writing mode");
      }
    },
    RR = function (a) {
      switch (a.f.positionAlign) {
        case _.he:
          return _.ee;
        case "auto":
        case _.bd:
          return _.Us;
        case _.ie:
          return _.yf;
      }
      return a.f.textAlign;
    },
    SR = function (a, b) {
      if (typeof a.f.position === _.r && typeof a.f.size === _.r) switch (b) {
        case _.he:
          return a.f.position;
        case "auto":
        case _.bd:
          return a.f.position - a.f.size / 2;
        case _.ie:
          return a.f.position - a.f.size;
      }
      return 0;
    },
    TR = function (a) {
      if (!a.f.positionAlign) switch (a.f.textAlign) {
        case _.ee:
        case _.ee:
          return _.he;
        case _.Us:
          return _.bd;
        case _.yf:
        case _.yf:
          return _.ie;
      }
      return a.f.positionAlign;
    },
    WR = function (a, b, c) {
      return (UR(a) || VR(a)) && _.Wg(c) ? DR(c, _.rf) : UR(a) && _.z(c) ? DR(0, _.rf) : OR(a) && _.Wg(b) ? DR(b, "%") : uR;
    },
    YR = function (a, b, c) {
      return OR(a) && _.Wg(c) ? DR(c, _.rf) : (UR(a) || VR(a)) && _.Wg(b) ? DR(b, "%") : uR;
    },
    VR = function (a) {
      return a.f.writingMode === _.jg;
    },
    UR = function (a) {
      return a.f.writingMode === _.ig;
    },
    ZR = function (a, b) {
      if (a.j) switch (b.writingMode) {
        case _.Vs:
          return ["-y"];
        case _.ig:
          return ["+x"];
        case _.jg:
          return ["-x"];
      }
      a = [];
      switch (b.writingMode) {
        case _.Vs:
          a = ["+y", "-y"];
          break;
        case _.ig:
          a = ["+x", "-x"];
          break;
        case _.jg:
          a = ["-x", "+x"];
      }
      0 > b.line && (a = a.reverse());
      return a;
    },
    $R = function (a) {
      for (a.h = []; 0 < a.f.childNodes.length;) a.f.removeChild(a.f.childNodes[0]);
      a.a = JR(a.l.a);
    },
    aS = function (a, b) {
      $R(a.h);
      a.l.unshift(b);
      for (const c of a.l) a.h.append(c);
    },
    bS = function (a, b, c) {
      a = a.f;
      const d = a.viewportAnchorUnits;
      if (d === _.bt) return a.viewportAnchorY / 100 * c - a.regionAnchorY / 100 * b;
      if (0 === d) return a.viewportAnchorY - a.regionAnchorY;
      throw Error(qR);
    },
    dS = function (a, b, c, d) {
      const e = document.createElement(_.Rf),
        f = e.dataset;
      f.name = b;
      f.for = c;
      d && (f.name = `${b} (sandbox)`, f.sandbox = !0);
      c = document.head;
      c.appendChild(e);
      cS(a, c, b);
      a.g.debug(`Created HTML style element #${b}.`);
      return e.sheet;
    },
    eS = function (a, b) {
      a = `.${a.j} .clpp-text-container`;
      const c = `${a} .clpp-text-cue`;
      return b = b.replace(/::cue\((#[^)]+)\)/g, `${a} $1`).replace(/::cue\(([^)]+)\)/g, `${c} $1`).replace(/::cue-region\(([^)]+)\)/g, `${a} $1 .clpp-text-cue`).replace(/::cue/g, c).replace(/::cue-region/g, `${a} .clpp-text-cue-region .clpp-text-cue`).replace(/::ttml/g, a);
    },
    fS = function (a, b) {
      let c = !1;
      try {
        a.f.insertRule(b, 0);
        const d = a.f.cssRules[0];
        d instanceof CSSStyleRule && (c = d.selectorText.split(",").every(e => 0 === e.trim().indexOf(`.${a.j} `)));
      } catch (d) {}
      0 < a.f.cssRules.length && a.f.deleteRule(0);
      return c;
    },
    gS = function (a) {
      if (null != a.a) {
        for (; 0 < a.a.cssRules.length;) a.a.deleteRule(0);
        a.o = [];
      }
    },
    cS = function (a, b, c) {
      const d = new MutationObserver(e => {
        e.forEach(f => {
          f.removedNodes.forEach(g => {
            g.id === c && a.g.error(`PRESTOplay HTML style element #${c} has ` + "been unexpectedly removed! Some styles will not work!");
          });
        });
      });
      d.observe(b, {
        subtree: !1,
        childList: !0
      });
      a.l.push(() => d.disconnect());
    },
    hS = function (a) {
      a = _.Pg(a.htmlcue || {});
      _.z(a.stretchSmpteImage) && (a.stretchSmpteImage = !1);
      _.z(a.enableResizeObserver) && (a.enableResizeObserver = !0);
      return a;
    },
    nS = function (a, b) {
      if ((b || a.l) && a.F) {
        var c = a.m.getPosition();
        if (b || a.J !== c && a.m.getState() === _.so) if (a.J = c, c = iS(a, c), b || (b = a.o, b = !(null === b && null === c || null !== b && null !== c && b.start === c.start && b.end === c.end)), b && (a.o = c, jS(a), c)) {
          b = {
            color: a.getFontColor() || void 0,
            backgroundColor: a.getBackgroundColor() || void 0,
            fontFamily: a.getFontFamily() || void 0,
            textShadow: _.Ls(a) || void 0
          };
          c = a.f.slice(c.start, c.end + 1);
          for (const k of c) {
            c = a;
            var d = k,
              e = b,
              f = c.a.a,
              g = null;
            switch (d.payloadType) {
              case _.tg:
                g = "clpp-text-vtt";
                break;
              case _.Yf:
                g = sR;
                break;
              case _.Ts:
                g = rR;
            }
            g && !f.classList.contains(g) && _.No(f, g);
            f = JR(c.a.a);
            if (0 === f.width || 0 === f.height) c.g.warn("The height or width of the `.clpp-text-container` element is zero. Captions will not be displayed. Please check if the `clpp.styles.css` file has been included.");else {
              BR({
                fontSize: kS(c, d.containerRows)
              }, c.a.a);
              f = null;
              g = d.payloadType === _.tg;
              var h = d.payloadType === _.Yf;
              d.region && (g || h || d.backgroundImage) && (c.B.has(d.region.id) ? f = c.B.get(d.region.id) : (f = new lS(d.region, {}), c.B.set(d.region.id, f), aS(c.a, f)));
              d = new mS(d, c.L, e);
              f ? aS(f, d) : aS(c.a, d);
            }
          }
        }
      }
    },
    oS = function (a) {
      jS(a);
      a.J = null;
      a.o = null;
      a.f = [];
    },
    pS = function (a, b) {
      return a.startTime <= b && a.endTime > b;
    },
    iS = function (a, b) {
      var c = xR(a.f, d => pS(d, b) ? 0 : b !== d.startTime ? b - d.startTime : b - d.endTime);
      if (-1 === c) return null;
      {
        let d = c;
        for (; 0 <= d - 1 && pS(a.f[d - 1], b);) d--;
        for (; c + 1 < a.f.length && pS(a.f[c + 1], b);) c++;
        return {
          start: d,
          end: c
        };
      }
    },
    jS = function (a) {
      a.B.clear();
      if (a.a) {
        const b = a.a.a;
        b.classList.remove("clpp-text-vtt");
        b.classList.remove(sR);
        b.classList.remove(rR);
        a = a.a;
        $R(a.h);
        a.l = [];
      }
    },
    kS = function (a, b) {
      const c = JR(a.a.a).height;
      if (null !== a.I) return a.getFontSize();
      if (null !== a.C) return a = a.getFontSizePercent(), `${Math.round(c * a)}px`;
      if (typeof b === _.r && 0 < b) return `${Math.round(c / b)}px`;
      a = a.getFontSizePercent();
      return `${Math.round(c * a)}px`;
    },
    tS = class {
      parse(a) {
        a = {
          input: a
        };
        const b = _.Po(_.Ed),
          c = [];
        let d = b;
        for (var e; null !== (e = yR(a));) if ("\x3c" === e[0]) {
          if ("/" === e[1]) c.length && c[c.length - 1] === e.substr(2).replace("\x3e", "") && (c.pop(), d = d.parentNode);else {
            if (e = e.match(/^<([^.\s/0-9>]+)(\.[^\s\\>]+)?([^>\\]+)?(\\?)>?$/)) {
              var f = e[1];
              var g = e[3];
              var h = qS[f];
              h ? (h = _.Po(h), (f = rS[f]) && g && h.setAttribute(f, g.trim()), g = h) : g = null;
              !g || sS[g.localName] && sS[g.localName] !== d.localName || (e[2] && (g.className = e[2].substr(1).replace(/\./g, " ")), c.push(e[1]), d.appendChild(g), d = g);
            }
          }
        } else d.appendChild(document.createTextNode(AR(e)));
        return b;
      }
    },
    qS = {
      c: "c",
      i: "i",
      b: "b",
      u: "u",
      ruby: "ruby",
      rt: "rt",
      v: "v",
      lang: "lang"
    },
    rS = {
      v: "voice",
      lang: "lang"
    },
    sS = {
      rt: "ruby"
    },
    zR = {
      "\x26amp;": "\x26",
      "\x26lt;": "\x3c",
      "\x26gt;": "\x3e",
      "\x26lrm;": "\u200e",
      "\x26rlm;": "\u200f",
      "\x26nbsp;": "\u00a0"
    };
  var IR = class {
    constructor(a, b, c, d, e, f) {
      this.left = a;
      this.f = b;
      this.top = c;
      this.a = d;
      this.height = e;
      this.width = f;
    }
  };
  var uS = class {
    constructor(a, b, c) {
      this.id = a;
      this.w = b;
      this.B = c;
      this.a = this.o();
      this.j = !1;
    }
    init() {
      this.j = !0;
    }
    A() {
      return !1;
    }
  };
  var mS = class extends uS {
    constructor(a, b, c) {
      super(a.id, "clpp-text-cue", {});
      this.l = b;
      this.f = a;
      this.h = !!this.f.backgroundImage;
      this.C = c;
    }
    A() {
      return !this.h && !(this.f.region && this.f.region.displayAlign);
    }
    init(a) {
      super.init(a);
      if (this.h) _.No(this.a, "clpp-text-cue-image"), this.a.style.backgroundImage = `url("${this.f.backgroundImage}")`, this.l.stretchSmpteImage && (this.a.style.backgroundSize = "contain");else if (a) {
        var b = this.f;
        if (b.payloadType === _.Yf) {
          var c = _.Po(_.Ed);
          c.innerHTML = b.payload;
          b = c;
        } else b.payloadType === _.tg ? b = new tS().parse(b.payload) : (c = _.Po(_.Ed), c.textContent = b.payload, b = c);
        _.No(b, this.w);
        this.id && (b.id = this.id);
        for (d of this.f.cssClassList) _.No(b, d);
        BR({
          writingMode: QR(this)
        }, b);
        var d = b;
        this.a.appendChild(d);
        _.No(this.a, "clpp-text-wrapper");
        for (var e of this.f.cssClassList) _.No(this.a, `clpp-text-wrapper__${e}`);
        _.z(this.f.line) && (this.f.lineInterpretation = _.Ws, this.f.line = OR(this) ? -1 : 0);
        e = QR(this);
        BR({
          writingMode: e,
          webkitWritingMode: e,
          direction: uR,
          textAlign: RR(this)
        }, this.a);
        if (!this.f.region || !this.f.region.displayAlign) {
          e = SR(this, TR(this));
          BR({
            position: "absolute",
            left: WR(this, e, null),
            right: VR(this) && _.z(null) ? DR(0, _.rf) : uR,
            top: YR(this, e, null),
            width: OR(this) ? DR(this.f.size, "%") : uR,
            height: UR(this) || VR(this) ? DR(this.f.size, "%") : uR
          }, this.a);
          b = this.f;
          a = OR(this) ? a.height : a.width;
          c = PR(this);
          if (b.lineInterpretation === _.Ws) {
            var f = c * Math.round(b.line);
            Math.abs(f) > a && (f = c * (0 > b.line ? 0 : -1));
            0 > f && (f += a);
          } else {
            f = c / a * 100;
            let g = b.line;
            switch (b.lineAlign) {
              case _.bd:
                g -= f / 2;
                break;
              case _.Sd:
                g -= f;
            }
            f = Math.round(g / 100 * a);
          }
          VR(this) && (f = a - f - c);
          a = f;
          BR({
            left: WR(this, e, a),
            right: VR(this) && _.z(a) ? DR(0, _.rf) : uR,
            top: YR(this, e, a)
          }, this.a);
        }
        CR(this.C, d);
      }
    }
    o() {
      return _.Po(_.Ed);
    }
  };
  var vS = class {
    constructor(a, b) {
      this.l = a;
      this.f = a.a;
      this.j = void 0 === b ? !1 : b;
      this.a = JR(a.a);
      this.h = [];
    }
    append(a) {
      const b = a.a;
      b.style.visibility = _.$d;
      this.f.appendChild(b);
      a.j || a.init(this.a);
      if (a.A()) {
        a: {
          var c = JR(a.a);
          var d = ZR(this, a.f),
            e = PR(a);
          const g = KR(c);
          let h = null,
            k = 0;
          for (const l of d) {
            for (; NR(c, this.a, l) || MR(c, this.a) && LR(c, this.h);) {
              d = c;
              var f = e;
              switch (l) {
                case "+x":
                  d.left += f;
                  d.f += f;
                  break;
                case "-x":
                  d.left -= f;
                  d.f -= f;
                  break;
                case "+y":
                  d.top += f;
                  d.a += f;
                  break;
                case "-y":
                  d.top -= f, d.a -= f;
              }
            }
            if (this.j || MR(c, this.a)) break a;
            d = this.a;
            d = Math.max(0, Math.min(c.f, d.f) - Math.max(c.left, d.left)) * Math.max(0, Math.min(c.a, d.a) - Math.max(c.top, d.top)) / (c.height * c.width);
            d > k && (h = KR(c), k = d);
            c = KR(g);
          }
          c = h || g;
        }
        BR({
          top: DR(c.top - this.a.top, _.rf),
          bottom: DR(this.a.a - c.a, _.rf),
          left: DR(c.left - this.a.left, _.rf),
          right: DR(this.a.f - c.f, _.rf),
          height: DR(c.height, _.rf),
          width: DR(c.width, _.rf)
        }, a.a);
      }
      b.style.visibility = "visible";
      this.h.push(JR(a.a));
    }
  };
  var wS = class extends uS {
    constructor(a, b, c) {
      super(a, b, c);
      this.h = null;
      this.l = [];
    }
    init() {
      this.h = new vS(this);
      this.j = !0;
    }
    o() {
      const a = _.Po(_.Ed);
      _.No(a, this.w);
      this.id && (a.id = this.id);
      return a;
    }
  };
  var lS = class extends wS {
    constructor(a, b) {
      super(a.id, "clpp-text-cue-region", b);
      this.f = a;
    }
    init(a) {
      a: {
        var b = this.f;
        switch (b.widthUnits) {
          case 0:
            b = b.width;
            break a;
          case _.bt:
            b = b.width / 100 * a.width;
            break a;
          default:
            throw Error(qR);
        }
      }
      var c = this.f;
      var d = c.viewportAnchorUnits;
      if (d === _.bt) c = c.viewportAnchorX / 100 * a.width - c.regionAnchorX / 100 * b;else if (0 === d) c = c.viewportAnchorX - c.regionAnchorX;else throw Error(qR);
      d = a.height;
      const e = bS(this, d, a.height);
      BR(this.B, this.a);
      BR({
        left: DR(c, _.rf),
        top: DR(e, _.rf),
        width: DR(b, _.rf),
        height: DR(d, _.rf)
      }, this.a);
      this.f.writingMode && BR({
        display: "flex",
        "flex-direction": this.f.writingMode
      }, this.a);
      this.f.displayAlign && BR({
        "justify-content": this.f.displayAlign
      }, this.a);
      for (var f of this.f.cssClassList) _.No(this.a, f);
      a: switch (f = this.f, f.heightUnits) {
        case 0:
          f = f.height;
          break a;
        case _.bt:
          f = f.height / 100 * a.height;
          break a;
        case 2:
          f = f.height;
          c = new mS(new _.Zs(0, 1, "Lorem ipsum", _.Ts), {}, {});
          b = c.a;
          this.a.appendChild(b);
          c.init(JR(this.a));
          c = b.getBoundingClientRect().height;
          this.a.removeChild(b);
          b = c;
          f *= b;
          break a;
        default:
          throw Error(qR);
      }
      a = bS(this, f, a.height);
      BR({
        top: DR(a, _.rf),
        height: DR(f, _.rf)
      }, this.a);
      this.h = new vS(this, !0);
      this.j = !0;
    }
    getId() {
      return this.f.id;
    }
  };
  var xS = class {
    constructor(a, b) {
      this.g = new _.R("clpp.htmlcue.StyleManager");
      this.l = [];
      this.j = a;
      this.h = b;
      this.o = [];
      this.a = dS(this, this.h, this.j);
      this.f = dS(this, this.h, this.j, !0);
      this.f.disabled = !0;
      this.g.debug(`Stylesheet ${this.h} has been created.`);
    }
    appendRule(a) {
      if (null != this.a && !this.o.some(b => b === a)) {
        const b = eS(this, a);
        if (fS(this, b)) {
          try {
            this.a.insertRule(b, this.a.cssRules.length), this.g.debug(`Added CSS rule ${b}`);
          } catch (c) {
            this.g.error(`An error occurred when adding a CSS rule: ${b}`);
          }
          this.o.push(a);
        } else this.g.warn(`Stylesheet ${this.h} ignoring the CSS rule ` + `because it's not safe: ${b}`);
      }
    }
    destroy() {
      const a = this;
      return _.w(function* () {
        null != a.a && (a.l.forEach(b => b()), a.l = [], gS(a), document.head.removeChild(a.a.ownerNode), document.head.removeChild(a.f.ownerNode), a.a = null, a.f = null, a.g.debug(`Stylesheet ${a.h} has been destroyed.`));
      });
    }
  };
  var yS = class extends _.Ou {
    constructor(a) {
      super(a);
      this.g = new _.R(tR);
      this.m = a;
      this.L = hS(a.getConfiguration());
      this.l = !1;
      this.f = [];
      this.F = a.getSurface().getContainer();
      this.j = new xS(a.getSurface().getUniqueCssClass(), "CLPP Subtitle Styles");
      this.a = new wS(null, "clpp-text-container", {});
      this.A = new _.Hu(a.getSurface(), this.S.bind(this));
      this.J = null;
      this.F ? (this.F.appendChild(this.a.a), this.a.init(null)) : this.g.warn("No container element! Subtitles will not appear. Please check your player surface configuration or switch to NativeTextDisplayer.");
      this.G = new _.mk(() => {
        nS(this);
      });
      this.B = new Map();
      this.o = null;
    }
    append(a, b) {
      if (null != this.j && 0 !== a.length) {
        for (const c of b) this.j.appendRule(c);
        a.sort((c, d) => c.startTime !== d.startTime ? c.startTime - d.startTime : c.endTime - d.endTime);
        this.f = wR(this.f, vR(a));
        this.o = null;
        nS(this, !1);
      }
    }
    h() {
      null == this.j || nS(this, !0);
    }
    destroy() {
      const a = this;
      return _.w(function* () {
        null != a.j && (oS(a), a.G.stop(), yield a.j.destroy(), a.j = null, a.F.removeChild(a.a.a), a.a = null, a.A.stop(), a.A = null, a.l = !1);
      });
    }
    remove(a, b) {
      if (null == this.j) return !1;
      this.f = this.f.filter(c => c.startTime < a || c.endTime >= b);
      this.o = null;
      nS(this, !0);
      return !0;
    }
    isTextVisible() {
      return this.l;
    }
    setTextVisibility(a) {
      null != this.j && this.l !== a && ((this.l = a) ? (this.G.vb(.25), this.L.enableResizeObserver && this.A.start(), nS(this, !0)) : (this.G.stop(), this.A.stop(), oS(this)));
    }
    S() {
      nS(this, !0);
    }
  };
  _.x(tR, yS);
  yS.prototype.setTextVisibility = yS.prototype.setTextVisibility;
  yS.prototype.isTextVisible = yS.prototype.isTextVisible;
  yS.prototype.remove = yS.prototype.remove;
  yS.prototype.destroy = yS.prototype.destroy;
  yS.prototype.append = yS.prototype.append;
  var zS = class {
    a(a) {
      return !!a.enableHtmlCue && !_.Ck();
    }
    create(a) {
      return new yS(a);
    }
  };
  _.x("clpp.htmlcue.HtmlCueComponent", class extends _.qu {
    f() {
      _.Wn(_.ae, new zS());
    }
    a() {
      _.Un.has(_.ae) && (_.Un.delete(_.ae), _.Vn.info("Removed factory: 'html'"));
    }
    id() {
      return _.Sf;
    }
  });
};
f.call(g, window);