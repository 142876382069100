import {Component, Input, OnInit} from '@angular/core';
import {debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';
import {Hit, Organization, Query} from '../../types';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {LogoAreaComponent} from '../logo-area/logo-area.component';
import {HeadlineSectionComponent} from '../headline-section/headline-section.component';
import {FilterSectionComponent} from '../filter-section/filter-section.component';
import {FileIconComponent} from '../file-icon/file-icon.component';
import {RouterModule} from '@angular/router';
import {CommonModule, DatePipe} from '@angular/common';
import {AddBreakersPipe} from '../../pipes/remove-last-segment-pipe';


@Component({
    selector: 'app-search-page',
    templateUrl: 'search-page.component.html',
    styleUrls: ['search-page.component.scss'],
    imports: [
      LogoAreaComponent,
      HeadlineSectionComponent,
      FilterSectionComponent,
      ReactiveFormsModule,
      FileIconComponent,
      RouterModule,
      DatePipe,
      AddBreakersPipe,
      CommonModule,
    ]
})
export class SearchPageComponent implements OnInit {

  @Input()
  organization: Organization;

  items: Array<string>;
  search_ongoing = false;
  hits: Hit[] = [];


  filters: FormGroup = new FormGroup({
    t: new FormControl<string>(''),
  });


  searchHits = (term: string) => this.apollo.query<Query>({
    query: gql`
      query SearchMetadata($searchTerm: String!) {
        search_metadata(search_term: $searchTerm) {
          hits {
            key
            brefix
            ffprobe
            warnings
            extra_info
            mediainfo
            SequenceNumber
            timestamp
          }
        }
      }
    `,
    variables: { searchTerm: term } // Pass the search term dynamically
  }).pipe(
    map(res => res.data.search_metadata.hits)
  )

  ngOnInit() {
    this.filters.valueChanges.pipe(
      tap(() => console.log('value changed')),
      debounceTime(300), // Wait for 300ms after the last emission
      distinctUntilChanged(), // Ensure only new values are emitted
      tap(() => this.search_ongoing = true),
      tap(() => console.log('going to search')),
      switchMap(this.searchHits)
    ).subscribe(res => {
        this.hits = res;
        this.search_ongoing = false;
      }
    );
  }


  constructor(private apollo: Apollo) {

  }
}
