import {Component, forwardRef, OnInit} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-basic-poitem-vod-info',
  templateUrl: './basic-poitem-vod-info.component.html',
  styleUrls: ['./basic-poitem-vod-info.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => BasicPoitemVodInfoComponent),
  },
    {
      provide: NG_VALIDATORS,
      useExisting: BasicPoitemVodInfoComponent,
      multi: true
    }],
  imports: [
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
  ]
})
export class BasicPoitemVodInfoComponent implements OnInit, ControlValueAccessor {
  static DEFAULTS = {
    input_brefix: '',
    po_item_id: '',
  };
  form: FormGroup;


  private changed;
  private touched;

  constructor(private formBuilder: FormBuilder) {
  }


  writeValue(value) {
    this.form.patchValue(value, {emitEvent: false})
  }

  registerOnChange(fn) {
    this.changed = fn;
  }

  registerOnTouched(fn) {
    this.touched = fn;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      input_brefix: ['', [Validators.required]],
      po_item_id: ['', [Validators.required]],
    })
    this.form.valueChanges.subscribe((val) => {
      this.touched();
      this.changed(val)
    });

  }

  validate({value}: FormControl) {
    const isNotValid = !this.form.valid
    return isNotValid && {
      invalid: true
    }
  }

}
