import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup, FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {IfeFormat} from '../../../target_systems';
import {CommonModule} from '@angular/common';

@Component({
    selector: 'app-above-default-specific',
    templateUrl: './above-default-specific.component.html',
    styleUrls: ['./above-default-specific.component.scss'],
    providers: [{
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AboveDefaultSpecificComponent),
        },
        {
            provide: NG_VALIDATORS,
            useExisting: AboveDefaultSpecificComponent,
            multi: true
        }],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
  ]
})
export class AboveDefaultSpecificComponent implements OnInit, ControlValueAccessor, OnChanges {

  @Input()
  ife_format: IfeFormat;

  form: FormGroup;
  private changed;
  private touched;

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('aaaa')
    if (this.ife_format && this.form) {
      if (!this.ife_format.watermark) {
        this.form.controls['watermark'].setValue(false)
        this.form.controls['watermark'].disable()
      } else {
        this.form.controls['watermark'].enable()
      }
      if (this.ife_format.require_metadata_reference) {
        if (!this.form.controls['metadata_reference']) {
          this.form.addControl('metadata_reference', new FormControl('', [Validators.required]))
        }
      } else {
        this.form.removeControl('metadata_reference')
      }
      this.form.controls['watermark'].updateValueAndValidity();
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable()
    } else {
      this.form.enable()
    }
  }


  writeValue(value) {
    this.form.patchValue(value, {emitEvent: false})
  }

  registerOnChange(fn) {
    this.changed = fn;
  }

  registerOnTouched(fn) {
    this.touched = fn;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      watermark: [false, [Validators.nullValidator]],
      aspect_ratio: ['pass-through', [Validators.required]],
    })
    this.form.valueChanges.subscribe((val) => {
      if (this.touched) {
        this.touched();
      }
      if (this.changed) {
        this.changed(val);
      }
    });

  }

  validate({value}: FormControl) {
    const isNotValid = !this.form.valid
    return isNotValid && {
      invalid: true
    }
  }


}
