import {Component, OnInit} from '@angular/core';
import {
  ChangePasswordCommand,
  CognitoIdentityProviderClient,
  GetUserCommand,
  UpdateUserAttributesCommand
} from '@aws-sdk/client-cognito-identity-provider';
import {environment} from '../../../environments/environment';
import {OAuthService} from 'angular-oauth2-oidc';
import {MFASetupComponent} from './mfasetup/mfasetup.component';
import {Dialog} from '@angular/cdk/dialog';
import {DoButtonComponent} from '../do-button/do-button.component';
import {HeadlineSectionComponent} from '../headline-section/headline-section.component';
import {LogoAreaComponent} from '../logo-area/logo-area.component';
import {FormsModule} from '@angular/forms';
import {MessageFieldComponent} from '../do-button/message-field.component';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  imports: [
    HeadlineSectionComponent,
    LogoAreaComponent,
    FormsModule,
    MessageFieldComponent,
    DoButtonComponent,
    CommonModule
  ]
})
export class UserProfileComponent implements OnInit {
  family_name = '';
  given_name = '';
  oldpw: string;
  newpw: string;
  sub = '';
  email = '';
  hasmfa: boolean;
  client = new CognitoIdentityProviderClient({region: environment.region});

  constructor(
    private oauth: OAuthService,
    private dialog: Dialog
  ) {


  }

  updateData(): void {

    const command = new GetUserCommand({
      AccessToken: this.oauth.getAccessToken()
    });
    const response = this.client.send(command).then(value => {
      for (const userAttribute of value.UserAttributes) {
        if (userAttribute.Name === 'given_name') {
          this.given_name = userAttribute.Value
        }
        if (userAttribute.Name === 'family_name') {
          this.family_name = userAttribute.Value
        }
        if (userAttribute.Name === 'sub') {
          this.sub = userAttribute.Value
        }
        if (userAttribute.Name === 'email') {
          this.email = userAttribute.Value
        }
      }
      console.log(value.UserAttributes)
      console.log(value.Username)
      console.log(value.UserMFASettingList)
      this.hasmfa = value.UserMFASettingList !== undefined && value.UserMFASettingList.find((v) => v === 'SOFTWARE_TOKEN_MFA') === 'SOFTWARE_TOKEN_MFA'
    });

  }

  save(button: DoButtonComponent): void {
    const command = new UpdateUserAttributesCommand({
      AccessToken: this.oauth.getAccessToken(),
      UserAttributes: [
        {
          Name: 'family_name',
          Value: this.family_name
        },
        {
          Name: 'given_name',
          Value: this.given_name
        },
      ]
    })
    this.client.send(command).then(v1 => {
      if (!(this.newpw === undefined || this.newpw === '' || this.oldpw === '' || this.oldpw === undefined)) {
        const command2 = new ChangePasswordCommand({
          AccessToken: this.oauth.getAccessToken(),
          PreviousPassword: this.oldpw,
          ProposedPassword: this.newpw
        })
        this.client.send(command2).then(v2 => {
          button.done('Name and password changed successfully.')
        }).catch(reason => {
          button.done('Name changed successfully. Password not changed. ' + reason, {severity: 'cl-alert-danger'})
        })
      } else {
        button.done('Name changed successfully.')
      }
    }).catch(reason => {
      button.done('Name not changed. ' + reason, {severity: 'cl-alert-danger'})
    })

  }

  ngOnInit(): void {
    this.updateData()
  }

  mfa(button: DoButtonComponent): void {
    this.dialog.open(MFASetupComponent, {
      data: {
        access_token: this.oauth.getAccessToken(),
        email: this.email
      }
    }).closed.subscribe(result => {
      const {message, severity} = result as { message?: string; severity?: 'cl-alert-danger' | 'cl-alert-warning' | 'cl-alert-info' } || {};
      this.updateData();
      if (message) {
        button.done(message, {severity});
      } else {
        button.done()
      }
    });

  }

}
