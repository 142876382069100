<app-logo-area [breadcrumbs]="breadCrumbs()"></app-logo-area>
<main>
  <app-headline-section
    [title]="'Previews'"></app-headline-section>

  <app-filter-section [all]="poItems$ | async" [filtered]="poItemsSortedFiltered$ | async" [formGroup]="filters">
    <form [formGroup]="filters" class="cl-form">
      <input type="text" style="width: 100%" formControlName="t"
             placeholder="Filter by ID and name">
      <p class="valid-feedback">Filter terms are case-insensitive.</p>
    </form>
  </app-filter-section>
  <section class="cl-section-regular">

    <div *ngIf="loading">
      <div class="col-12" style="height: 30vh"></div>
      <div style="width: 100%; display: flex;  align-items: center; justify-content: center;">
        <div class="cl-spinner">Loading ...<i class="fas fa-spinner fa-spin"></i></div>
      </div>
    </div>
      <div class="container" *ngIf="poItemsSortedFiltered$ | async as poItemsFiltered">
        <div class="row">
          <div class="col-12">
            <table style="table-layout: fixed;" class="cl-table-data">
              <colgroup>
                <col span="1" style="width: 50%">
                <col span="1" style="width: 50%">
                <col span="1" style="width: 180px">
                <col span="1" style="width: 90px;">
              </colgroup>

              <thead>
              <tr>
                <th> Project Name</th>
                <th> Title</th>
                <th> Date</th>
                <th> Actions</th>
              </tr>
              </thead>
              <tbody *ngIf="poItemsFiltered.length === 0; else bodyWithData">
              <tr class="cl-tr-no-data">
                <td colspan="100" style="text-align: center">No previews available.</td>
              </tr>
              </tbody>
              <ng-template #bodyWithData>
                <tbody>
                <tr *ngFor="let poitem of poItemsFiltered">
                  <td>{{ poitem.po.po_name }}</td>
                  <td>
                    {{ poitem.po_item_id }}
                  </td>
                  <td>{{ poitem.preview.dash_manifest_last_modified |date:'d/MMM/y, HH:mm' }}</td>
                  <td><a class="cl-a-btn cl-a-primary"
                         [routerLink]="['/o',this.organization()?.id , 'previews', poitem.po.airline, poitem.po.po_name, poitem.po_item_id, 'preview']">Preview</a>
                  </td>
                </tr>
                </tbody>
              </ng-template>
            </table>
          </div>
        </div>
      </div>
  </section>
</main>
