import {Component} from '@angular/core';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-message-field',
  imports: [
    NgIf
  ],
  templateUrl: './message-field.component.html',
  standalone: true
})
export class MessageFieldComponent {
  message: string = null
  severity = 'cl-alert-info';

  public setMessage(message: string,
                    severity: 'cl-alert-danger' | 'cl-alert-warning' | 'cl-alert-info',
                    duration: number) {
    this.message = message;
    this.severity = severity
    if (duration >= 0) {
      setTimeout(() => {
        this.message = null;
      }, duration)
    }
  }
}
