import { clpp } from "./cl.core.js";
let g = {};
const _ = clpp._;
var f = function (window) {
  'use strict';

  var Vz, Wz, Xz, Yz, Zz, $z, aA, bA, cA, dA, eA, fA, gA, hA, iA, jA, kA, lA, mA, nA, oA, pA, qA, rA, sA, tA, uA, vA, wA, zA, AA, BA, CA, DA, EA, FA, GA, HA, IA, JA, LA, MA, NA, OA, QA, RA, SA, VA, WA, YA, ZA, $A, aB, bB, cB, dB, gB, hB, iB, nB, oB, pB, qB, rB, sB, tB, vB, yB, zB, CB, FB, GB, HB, IB, KB, LB, MB, NB, OB, PB, QB, RB, SB, TB, UB, VB, XB, cC, fC, kC, mC, bC, nC, oC, pC, qC, rC, sC, yC, zC, AC, BC, CC, EC, FC, GC, HC, DC, KC, NC, MC, OC, LC, PC, QC, RC, SC, WC, XC, YC, ZC, $C, aD, bD, cD, dD, fD, gD, hD, jD, iD, kD, lD, mD, nD, oD, qD, pD, rD, zD, yD, AD, BD, DD, ED, FD, GD, ID, JD, LD, KD, HD, RD, MD, ND, OD, PD, QD, SD, TD, UD, XD, VD, WD, YD, ZD, $D, aE, cE, dE, eE, gE, hE, kE, iE, jE, lE, mE, nE, oE, qE, rE, sE, tE, vE, wE, yE, zE, BE, AE, uE, xE, CE, DE, GE, EE, FE, IE, HE, KE, LE, ME, NE, OE, PE, aF, bF, cF, eF, fF, YE, QE, ZE, $E, gF, hF, qF, XE, rF, jF, sF, tF, uF, vF, oF, wF, xF, kF, lF, mF, nF, yF, BF, CF, FF, GF, HF, DF, EF, iF, zF, AF, IF, JF, KF, LF, MF, NF, OF, PF, QF, RF, SF, TF, UF, sD, tD, uD, vD, wD, xD, fG, hG, iG, nG, oG, lG, jG, qG, rG, vG, yG, CG, PG, QG, SG, TG, UG, VG, WG, kG, mG, cH, RG, sG, uG, wG, xG, YG, LG, BG, DG, EG, WF, dG, GG, HG, KG, MG, NG, OG, AG, cG, jH, gH, vH, aG, bG, xH, XF, YF, ZF, $F, dH, zH, hH, wH, fH, yH, AH, lH, mH, kH, BH, eH, CH, DH, EH, FH, GH, HH, jB, KH, tG, rH, xC, IC, JC, NH, OH, PH, nH, oH, qH, RH;
  Vz = " buffer";
  Wz = " buffer. Range:";
  Xz = " range:";
  Yz = " with element state: ";
  Zz = "\x3c/DATA\x3e\x3c/WRMHEADER\x3e";
  $z = "Aborting operations for ";
  aA = "Appended audio segment ";
  bA = "Appended video segment ";
  cA = "Appending data to ";
  dA = "Buffer:";
  eA = "Cannot get segment, period ID unknown";
  fA = "Choosing new streams after key status change";
  gA = "Done Aborting operations for ";
  hA = "Done Appending data to ";
  iA = "Done Flushing buffer for ";
  jA = "Done Removing data from ";
  kA = "Done Setting append window for ";
  lA = "Done Setting timestamp offset for ";
  mA = "Failed to abort operations for ";
  nA = "Failed to append data for ";
  oA = "Failed to flush buffer for ";
  pA = "Failed to remove data from ";
  qA = "Failed to set append window for ";
  rA = "Failed to set timestamp offset for ";
  sA = "Flushing buffer for ";
  tA = "IterateSegmentReference";
  uA = "Jumping forward";
  vA = "MediaSource threw QuotaExceededError:";
  wA = "No more scheduled operations for ";
  _.xA = "No stream with id";
  _.yA = "Player is in ERROR state and can not switch to ";
  zA = "PrestoPlay TextTrack";
  AA = "QuotaExceededError";
  BA = "Removing audio from variant - audio not compatible with platform";
  CA = "Removing data from ";
  DA = "Reset transmuxers.";
  EA = "Setting append window for ";
  FA = "Setting playback rate to\x3d";
  GA = "Setting timestamp offset for ";
  HA = "Skip buffer flush for ";
  IA = "Starting next operation for ";
  JA = "Update-End received for ";
  _.KA = "Updating manifest...";
  LA = "bufferBehind\x3d";
  MA = "bufferedBehind\x3d";
  NA = "calling onCanSwitch()...";
  OA = "cannot find segment";
  _.PA = "caption";
  QA = "clpp.mse.Player";
  RA = "currentPeriod.startTime\x3d";
  SA = "failed to get buffered range for ";
  _.TA = "getSegmentNextReference";
  _.UA = "iterateSegmentReference";
  VA = "key-restriction";
  WA = "manifest.start";
  _.XA = "onChooseStreams_";
  YA = "presentationTime:";
  ZA = "presentationTime\x3d";
  $A = "reference.endTime\x3d";
  aB = "reference.position\x3d";
  bB = "reference.startTime\x3d";
  cB = "sourceopen";
  dB = "startstreaming";
  _.eB = "subtitle";
  _.fB = "subtitles";
  gB = "switch: Stream ";
  hB = function (a, b) {
    for (const c in a.a) b(c, a.a[c]);
  };
  iB = function (a) {
    const b = a.a + a.f.byteOffset;
    let c = void 0;
    for (; _.Ol(a);) if (0 === _.Ql(a)) {
      c = a.a + a.f.byteOffset - 1;
      break;
    }
    a = a.f.buffer.slice(b, c);
    return _.Ei(a);
  };
  _.kB = function (a) {
    const b = new Uint8Array(jB),
      c = 4 + b.byteLength + a.j.byteLength + 4 + a.f.byteLength,
      d = new _.gm(c, !1);
    _.N(d, c);
    _.Nl(d, b);
    _.Nl(d, a.j);
    _.N(d, a.f.byteLength);
    _.Nl(d, a.f);
    return _.J(d.buffer);
  };
  _.lB = function (a, b, c, d) {
    var e = _.Vi(b);
    if (8 !== e.byteLength) e = "";else {
      var f = _.Tm(e);
      e = _.Si(f);
      b: switch (f = f.byteLength, _.ih("Trying to create PlayReady header in version", a), a) {
        case "4.0.0.0":
          e = [`<WRMHEADER xmlns="${"http://schemas.microsoft.com/DRM/2007/03/PlayReadyHeader"}" version="${a}">`, "\x3cDATA\x3e\x3cPROTECTINFO\x3e", `<KEYLEN>${f}</KEYLEN>`, "\x3cALGID\x3eAESCTR\x3c/ALGID\x3e\x3c/PROTECTINFO\x3e", `<KID>${e}</KID>`, c ? `<CHECKSUM>${c}</CHECKSUM>` : "", d ? `<LA_URL>${d}</LA_URL>` : "", Zz].join("");
          break b;
        case "4.1.0.0":
          e = [`<WRMHEADER xmlns="${"http://schemas.microsoft.com/DRM/2007/03/PlayReadyHeader"}" version="${a}">`, '\x3cDATA\x3e\x3cPROTECTINFO\x3e\x3cKID ALGID\x3d"AESCTR" ', c ? `CHECKSUM="${c}" ` : "", `VALUE="${e}"`, "\x3e\x3c/KID\x3e\x3c/PROTECTINFO\x3e", d ? `<LA_URL>${d}</LA_URL>` : "", Zz].join("");
          break b;
        case "4.2.0.0":
        case "4.3.0.0":
          e = [`<WRMHEADER xmlns="${"http://schemas.microsoft.com/DRM/2007/03/PlayReadyHeader"}" version="${a}">`, '\x3cDATA\x3e\x3cPROTECTINFO\x3e\x3cKIDS\x3e\x3cKID ALGID\x3d"AESCTR" ', c ? `CHECKSUM="${c}" ` : "", `VALUE="${e}"`, "\x3e\x3c/KID\x3e\x3c/KIDS\x3e\x3c/PROTECTINFO\x3e", d ? `<LA_URL>${d}</LA_URL>` : "", Zz].join("");
          break b;
        default:
          _.B("Cannot create PlayReady header in version", a), e = "";
      }
    }
    if (!e) return null;
    _.ih("New payload", e);
    e = _.J(_.Ji(e, !0));
    return new _.Wm(_.Xm, b, d || null, e, a, c);
  };
  _.mB = function (a, b) {
    return b === a.version ? a : _.lB(b, a.a, a.l || void 0, a.h || void 0);
  };
  nB = function (a, b) {
    a: {
      if (b) for (const c of a.f.keys()) {
        const d = a.f.get(c);
        for (let e = 0; e < d.fb.length; e++) if (d.fb[e] === b) {
          a = d;
          break a;
        }
      }
      a = null;
    }
    return a ? a.Na : null;
  };
  oB = function (a, b) {
    if (_.pk("Edge/")) return !0;
    b = b.toLowerCase();
    if (_.qk() && b.includes('codecs\x3d"ac-3"')) {
      const c = b.replace("ac-3", _.Qd);
      return a.H.has(b) || a.H.has(c);
    }
    return a.H.has(b);
  };
  pB = function (a, b) {
    a = a.l.get(b);
    return !(!a || a !== _.Me && a !== _.ce);
  };
  qB = function (a, b) {
    var c = b.audio,
      d = b.video;
    if (c && c.encrypted && (c = _.gn(c), !oB(a, c)) || d && d.encrypted && (d = _.gn(d), !oB(a, d))) return !1;
    const e = a.keySystem();
    return 0 === b.drmInfos.length || b.drmInfos.some(f => f.keySystem === e);
  };
  rB = function (a, b) {
    return null == a.a ? null : (a = a.a[a.a.length - 1]) ? a[b] : null;
  };
  sB = function (a) {
    a = rB(a, _.ju);
    return null == a ? null : a;
  };
  tB = function (a, b) {
    if (a.a.length) {
      if (0 > b) a.f = 0;else {
        for (var c = 0, d = a.a[c] || null; d && b > d.Mb() + d.getDuration();) c += 1, d = a.a[c] || null;
        a.f = d ? c : a.a.length - 1;
      }
    } else a.f = -1;
  };
  _.uB = function (a, b) {
    return a.concat(b);
  };
  vB = function () {};
  _.wB = function (a, b) {
    if (0 === b.length) return a;
    const c = b.map(d => new _.ci(d));
    return a.map(d => new _.ci(d)).map(d => c.map(d.resolve.bind(d))).reduce(_.uB, []).map(d => d.toString());
  };
  _.xB = function (a, b, c) {
    return {
      keySystem: a,
      licenseServerUri: "",
      distinctiveIdentifierRequired: !1,
      persistentStateRequired: !1,
      audioRobustness: "",
      videoRobustness: "",
      serverCertificate: null,
      initData: b || [],
      keyIds: [],
      periodId: c ? c : null
    };
  };
  yB = function (a, b) {
    a = _.qi(a);
    b = _.qi(b);
    a = a.split("-");
    b = b.split("-");
    return a[0] === b[0] && 1 === a.length && 2 === b.length;
  };
  zB = function (a) {
    return a.language ? _.qi(a.language) : a.audio && a.audio.language ? _.qi(a.audio.language) : a.video && a.video.language ? _.qi(a.video.language) : _.$f;
  };
  _.AB = function (a, b) {
    a = _.qi(a);
    const c = new Set();
    for (var d of b) c.add(_.qi(d));
    for (const e of c) if (e === a) return e;
    for (const e of c) if (yB(e, a)) return e;
    for (const e of c) if (d = e, b = a, d = _.qi(d), b = _.qi(b), d = d.split("-"), b = b.split("-"), b = 2 === d.length && 2 === b.length && d[0] === b[0]) return e;
    for (const e of c) if (yB(a, e)) return e;
    return null;
  };
  _.BB = function (a) {
    if (typeof a !== _.Pf) return null;
    var b = a.trim().match(/^(\d+)x(\d+)$/);
    if (!b) return null;
    a = parseInt(b[1], 10);
    b = parseInt(b[2], 10);
    return 0 >= a || 0 >= b ? null : {
      Uc: a,
      kd: b,
      Fh: a * b
    };
  };
  CB = function (a, b) {
    for (const c in b) if (b[c] === a) return c;
    return "";
  };
  _.DB = function (a, b) {
    if (!a.length) return b;
    if (!b.length) return a;
    const c = [];
    for (let e = 0; e < a.length; e++) for (var d = 0; d < b.length; d++) if (a[e].keySystem === b[d].keySystem && a[e].periodId === b[d].periodId) {
      const f = a[e];
      d = b[d];
      let g = [];
      g = g.concat(f.initData || []);
      g = g.concat(d.initData || []);
      const h = [...(f.keyIds || [])];
      d.keyIds && d.keyIds.length && d.keyIds.forEach(k => {
        h.includes(k) || h.push(k);
      });
      c.push({
        keySystem: f.keySystem,
        licenseServerUri: f.licenseServerUri || d.licenseServerUri,
        distinctiveIdentifierRequired: f.distinctiveIdentifierRequired || d.distinctiveIdentifierRequired,
        persistentStateRequired: f.persistentStateRequired || d.persistentStateRequired,
        videoRobustness: f.videoRobustness || d.videoRobustness,
        audioRobustness: f.audioRobustness || d.audioRobustness,
        serverCertificate: f.serverCertificate || d.serverCertificate,
        initData: g,
        keyIds: h,
        periodId: f.periodId
      });
      break;
    }
    return c;
  };
  _.EB = function (a, b) {
    return a.length && b.length ? 0 < _.DB(a, b).length : !0;
  };
  FB = function (a, b) {
    let c = 0;
    for (const d of a) c += b(d) ? 1 : 0;
    return c;
  };
  GB = function (a, b) {
    var c = _.fk(b, a);
    if (!c) return !1;
    c = c();
    a = c.isMuxed(a, b);
    c.destroy();
    return a;
  };
  HB = function (a) {
    const b = [a.mimeType];
    for (const c of _.yt) {
      const [d, e] = c;
      {
        const f = a[d];
        null != f && b.push(e.trim() + '\x3d"' + String(f).trim() + '"');
      }
    }
    return b.join(";").replace(/\s/g, "");
  };
  IB = function (a) {
    return a.split(",").map(b => b.trim()).filter(b => 0 < b.length);
  };
  _.JB = function (a) {
    const b = [];
    for (const c of a.split(/\s*,\s*/)) {
      {
        a = c.split(".");
        const d = a[0];
        a.shift();
        a = [d, a.join(".")];
      }
      b.push(a[0]);
    }
    return b.sort().join(",");
  };
  KB = function (a, b = "total") {
    b = new _.Ct(void 0, b);
    for (const c of a) b.a.push(c);
    b.length = a.length;
    return b;
  };
  LB = function () {
    return !!window.SourceBuffer && !!SourceBuffer.prototype && !!SourceBuffer.prototype.changeType;
  };
  MB = function (a, b) {
    return a.start - b.start;
  };
  NB = function (a) {
    return a.U.map(b => ({
      start: b.start,
      end: b.end,
      width: b.width,
      height: b.height,
      bandwidth: b.bandwidth,
      rendition: b.rendition
    }));
  };
  OB = function (a) {
    a.player && (a.player.off(_.xf, a.a), a.f && a.player.off(_.Wf, a.a));
    a.player = null;
  };
  PB = function (a) {
    a.player && a.player.trigger(new _.C(_.Mc));
  };
  QB = function (a) {
    a.player && a.player.trigger(new _.C(_.Mc));
  };
  RB = function (a) {
    if (a.player) {
      var b = !1,
        c = {};
      for (let d = 0; d < a.U.length; d++) {
        const e = a.U[d],
          f = e.width + "x" + e.height,
          g = c[f];
        if (g && g !== e.bandwidth) {
          b = !0;
          break;
        }
        c[f] = e.bandwidth;
      }
      b && !a.f ? (a.log.debug("Tracking timeupdates"), a.player.on(_.Wf, a.a, a), a.f = !0) : !b && a.f && (a.log.debug("Stop tracking timeupdates"), a.player.off(_.Wf, a.a), a.f = !1);
    }
  };
  SB = function (a, b, c) {
    a.U = a.U.filter(d => {
      if (d.end <= b || d.start >= c) return !1;
      d.start = Math.max(d.start, b);
      d.end = Math.min(d.end, c);
      return !0;
    });
    a.player && a.player.trigger(new _.C(_.mg));
  };
  TB = function (a, b) {
    let c = null;
    for (const d of a) b >= d.startTime && (c = d);
    return c;
  };
  UB = function (a) {
    const b = new Set();
    a.variants.forEach(c => {
      c.audio && b.add(c.audio);
      c.video && (b.add(c.video), c.video.trickModeVideo && b.add(c.video.trickModeVideo));
    });
    a.textStreams.forEach(c => b.add(c));
    a.thumbStreams.forEach(c => b.add(c));
    return b;
  };
  VB = function (a, b) {
    for (let c = 0; c < a.length; c++) if (UB(a[c]).has(b)) return c;
    return -1;
  };
  XB = function (a, b) {
    Array.from(UB(a)).forEach(c => {
      c.segmentIndex && _.WB(c.segmentIndex, b);
    });
  };
  _.YB = function (a, b, c) {
    const d = Math.pow(a.h, b);
    c = c * (1 - d) + d * a.a;
    isNaN(c) || (a.a = c, a.f += b);
  };
  _.ZB = function (a) {
    return a.a / (1 - Math.pow(a.h, a.f));
  };
  _.$B = function (a, b, c, d, e, f, g, h) {
    a = _.ij(a);
    a.timeout = d.timeout;
    a.maxAttempts = d.maxAttempts;
    a.baseDelay = d.baseDelay;
    a.fuzzFactor = d.fuzzFactor;
    a.backoffFactor = d.backoffFactor;
    if (0 !== b || null != c) a.headers.Range = c ? "bytes\x3d" + b + "-" + c : "bytes\x3d" + b + "-";
    e && (a.startTime = e);
    f && (a.endTime = f);
    g && (a.contentType = g);
    h && (a.timescale = h);
    return a;
  };
  _.aC = function (a, b, c) {
    a.h = b;
    a.a = c;
  };
  cC = function (a) {
    return 2 === a.j.version ? a.a.Mb() : bC(a, 0);
  };
  _.dC = function (a, b) {
    a.K = {
      offsetDateTime: b - cC(a),
      seekRangeStartDateTime: b
    };
  };
  _.eC = function (a, b) {
    a.l = b;
    a.C = b;
    a.a.f = b;
  };
  fC = function (a) {
    return 2 === a.j.version ? a.a.f ? a.a.a : null : a.l ? a.f : a.C ? a.o : null;
  };
  _.gC = function (a, b) {
    a.w = null == a.w ? b : Math.min(a.w, b);
  };
  _.hC = function (a, b) {
    a.o = Math.max(a.o || 0, b);
  };
  _.iC = function (a, b, c) {
    if (0 !== b.length) {
      var d = b[b.length - 1].endTime + c;
      _.gC(a, b[0].startTime + c);
      a.h = b.reduce((e, f) => Math.max(e, f.endTime - f.startTime), a.h);
      _.hC(a, d);
      null != a.F && a.L && (a.F = (Date.now() + a.H) / 1E3 - (a.o || 0) - a.h);
      a.g.debug("notifySegments: maxSegmentDuration\x3d" + a.h);
    }
  };
  _.jC = function (a, b) {
    a.h = Math.max(a.h, b);
    a.g.debug("notifyNewSegmentDuration: maxSegmentDuration\x3d" + a.h);
  };
  kC = function (a) {
    if (2 === a.j.version) a = a.a.a;else if (a.l) a = a.f;else if (a.C) a = a.o || 0;else {
      if ("startover" === a.B) var b = a.I || 0;else b = (Date.now() + a.H) / 1E3, b = a.M ? Math.max(0, b - (a.h - a.G) - a.F) : Math.max(0, b - a.h - a.F);
      a = Math.min(b, a.f);
    }
    return a;
  };
  _.lC = function (a) {
    return 2 === a.j.version ? a.a.Mb() : Infinity === a.A ? 0 : Math.max(0, kC(a) - a.A);
  };
  mC = function (a) {
    const b = a.Yc() && !a.C ? a.J : 0;
    return 2 === a.j.version ? a.a.a - b : Math.max(0, kC(a) - b);
  };
  bC = function (a, b) {
    if (2 === a.j.version) return a.a.Mb();
    const c = Math.max(a.w, 0);
    return Infinity === a.A ? Math.ceil(1E3 * c) / 1E3 : Math.max(c, Math.min(kC(a) - a.A + b, mC(a)));
  };
  nC = function (a, b = !1) {
    b = b ? a.offset : 0;
    return {
      schemeIdUri: a.schemeIdUri,
      configurationId: a.configurationId,
      value: a.value,
      startTime: a.startTime + b,
      endTime: a.endTime + b,
      id: a.id,
      eventElement: a.eventElement,
      type: a.type,
      customAttributes: a.customAttributes
    };
  };
  oC = function (a, b, c) {
    a.f = b;
    a.h = c;
  };
  pC = function (a, b) {
    const c = [];
    let d = !1;
    for (const f of b) if (!a.a.has(f)) {
      c.push(f);
      var e = Array.from(a.a.values()).some(g => g.id === f.id);
      f.id && e && (d = !0);
    }
    e = a.a.size;
    a.a.clear();
    b.forEach(f => a.a.add(f));
    b = a.a.size;
    c.forEach(f => a.f(f));
    (d || 0 < c.length || e !== b) && a.h();
  };
  qC = function (a, b = !1) {
    return Array.from(a.a.values()).map(c => nC(c, b)).sort((c, d) => c.startTime - d.startTime);
  };
  rC = function (a) {
    const b = a.a.kf(),
      c = a.h,
      d = b ? b.id : null;
    (c ? c.id : null) !== d && b && a.f(new _.C(_.Af, {
      id: d
    }));
    a.h = b;
    "basic" === a.o.timeline && (a.j.a = b);
  };
  sC = function (a) {
    let b = a.h.get(a.a);
    if (!b) return null;
    0 < b.a.length && b.Wa().length && a.f >= b.a.length && (a.a += 1, a.f = 0, b = a.h.get(a.a));
    return b && 0 < b.a.length ? b.a[a.f] || null : b;
  };
  _.WB = function (a, b) {
    for (; a.a.length;) if (0 >= a.a[0].endTime) a.a.shift();else break;
    0 === a.a.length || a.a[a.a.length - 1].startTime >= b || (a.a[a.a.length - 1].endTime = b);
  };
  _.tC = function (a) {
    return a.a.length ? a.a[a.a.length - 1].endTime : 0;
  };
  _.vC = function (a, b) {
    let c = [];
    const d = [];
    for (var e = 0, f = 0; e < a.a.length && f < b.length;) {
      var g = a.a[e];
      const h = b[f];
      g.startTime < h.startTime ? (c.push(g), e++) : (g.startTime > h.startTime ? 0 === e ? d.push(h) : a.g.info("Refusing to rewrite original references on update!") : (.1 < Math.abs(g.endTime - h.endTime) ? c.push(new _.uC(g.position, h.startTime, h.endTime, h.Wa, h.wa, h.ka, h.Ba, h.keyInfo, h.wc, h.timescale, h.horizontalTiles, h.verticalTiles, h.durationPerTile)) : c.push(g), e++), f++);
    }
    for (; e < a.a.length;) c.push(a.a[e++]);
    if (c.length) for (e = c[c.length - 1].position + 1; f < b.length;) g = b[f++], g = new _.uC(e++, g.startTime, g.endTime, g.Wa, g.wa, g.ka, g.Ba, g.keyInfo, g.wc, g.timescale, g.horizontalTiles, g.verticalTiles, g.durationPerTile), c.push(g);else c = b;
    b = c;
    if (d.length && c.length) {
      b = [];
      f = c[0].position;
      e = d.length;
      for (const h of d) b.push(new _.uC(f - e--, h.startTime, h.endTime, h.Wa, h.wa, h.ka, h.Ba, h.keyInfo, h.wc, h.timescale, h.horizontalTiles, h.verticalTiles, h.durationPerTile));
      for (const h of c) b.push(h);
    }
    a.a = b;
  };
  _.wC = function (a, b) {
    for (let c = 0; c < a.a.length; ++c) if (a.a[c].endTime > b) return a.a.splice(0, c);
    b = a.a;
    a.a = [];
    return b;
  };
  yC = function (a, b) {
    let c = a.f(b);
    c = null === c ? -1 : c - 1;
    const d = a.get(c + 1);
    let e = -1;
    if (d && 0 < d.a.length) for (let f = d.a.length - 1; 0 <= f; --f) {
      let g = d.a[f];
      if (b >= g.startTime && b < g.endTime) {
        for (; f && !g.h;) --f, g = d.a[f];
        if (!g.h) break;
        c += 1;
        e = f - 1;
        break;
      }
    }
    return new xC(a, c, e);
  };
  zC = function (a, b) {
    a.f = b;
  };
  AC = function (a) {
    if (a.a.paused || 0 === a.a.playbackRate || 0 === a.a.buffered.length) var b = !1;else a: {
      b = a.a.buffered;
      a = a.a.currentTime;
      for (let c = 0; c < b.length; c++) {
        const d = b.start(c),
          e = b.end(c);
        if (!(a < d - .1 || a > e - (c + 1 === b.length ? .5 : .1))) {
          b = !0;
          break a;
        }
      }
      b = !1;
    }
    return b;
  };
  BC = function (a) {
    if (0 !== a.a.readyState) {
      if (a.a.seeking) {
        if (!a.C) return;
      } else a.C = !1;
      if (!a.a.paused && !a.o) {
        a.a.readyState !== a.I && (a.j = !1, a.I = a.a.readyState);
        var b = a.l.smallGapLimit,
          c = a.a.currentTime,
          d = a.a.buffered;
        a: {
          var e = new _.Ct(d);
          var f = a.l.gapDetectionThreshold;
          if (e.length && !(1 === e.length && 1E-6 > e.end(0) - e.start(0))) for (var g = 0; g < e.length; g++) if (e.start(g) > c && (0 === g || e.end(g - 1) - c <= f)) {
            e = g;
            break a;
          }
          e = null;
        }
        if (null == e) {
          if (a.f) {
            a = a.f;
            d = a.h;
            c = AC(d);
            d = d.a.currentTime;
            b = Date.now() / 1E3;
            if (a.a !== d || a.l !== c) a.j = b, a.a = d, a.l = c;
            d = b - a.j;
            d >= a.o && c && a.f(a.a, d);
          }
        } else if ((0 !== e || a.A) && a.H && (f = d.start(e), !(f >= mC(a.G)))) {
          g = f - c;
          b = g <= b;
          var h = !1;
          if (!(.001 > g)) {
            if (!b && !a.j) {
              a.j = !0;
              const k = new _.C("largegap", {
                detail: {
                  currentTime: c,
                  gapSize: g
                }
              });
              k.cancelable = !0;
              a.F(k);
              a.l.jumpLargeGaps && !k.defaultPrevented ? h = !0 : _.hh("Ignoring large gap at", c, "size", g);
            }
            if (b || h) 0 === e ? _.hh(uA, g, "seconds because of gap before start time of", f) : _.hh(uA, g, "seconds because of gap starting at", d.end(e - 1), "and ending at", f), a.B = !0, a.a.currentTime = f;
          }
        }
      }
    }
  };
  CC = function (a, b) {
    a.h = a.f.currentTime;
    a.l = b;
    a.j = a.o;
    a.f.currentTime = b;
    a.a.vb(.1);
  };
  EC = function (a, b) {
    a.w || (a.w = !0, a.o && a.o());
    .001 > Math.abs(a.a.currentTime - b) ? DC(a) : (a.f.one(a.a, _.Ef, () => {
      DC(a);
    }), CC(a.h, 0 === a.a.currentTime ? b : a.a.currentTime));
  };
  FC = function (a, b) {
    a.A = b;
    a.f.off(a.a, _.qe);
    a.f.one(a.a, _.qe, () => {
      EC(a, b);
    });
  };
  GC = function (a) {
    return a.l ? a.a.currentTime : a.A;
  };
  HC = function (a, b) {
    0 < a.a.readyState ? CC(a.h, b) : FC(a, b);
  };
  DC = function (a) {
    _.vk() && !a.l && a.j();
    a.l = !0;
    a.f.on(a.a, _.Ef, () => a.j());
  };
  KC = function (a, b, c) {
    if (!c.stallEnabled) return null;
    const d = c.gapDetectionThreshold;
    let e = c.stallSkip;
    c = new IC(new JC(b), c.stallThreshold);
    zC(c, (f, g) => {
      a.g.warn(`Stall detected at ${f} for ${g} seconds.`);
      if (e) {
        g = new _.Ct(b.buffered);
        var h;
        if (h = _.pk("Edge/") || _.pk("Trident/") || _.qk() || _.vk()) a: {
          h = g;
          if (h.length && !(1 >= h.length)) for (let k = 0; k < h.length - 1; k++) if (f > h.start(k) && f < h.end(k)) {
            h = h.start(k + 1) - h.end(k) >= d;
            break a;
          }
          h = !1;
        }
        if (h) {
          a: {
            if (g.length && !(1 >= g.length)) for (h = 0; h < g.length - 1; h++) if (f > g.start(h) && f < g.end(h)) {
              f = g.start(h + 1) - f;
              break a;
            }
            f = null;
          }
          f && (e = Math.min(f + .1, e));
        }
        a.g.warn(`Seeking forward ${e} seconds to break stall.`);
        b.currentTime += e;
      } else a.g.warn("Pausing and unpausing to break stall."), b.pause(), b.play();
    });
    return c;
  };
  NC = function (a, b, c) {
    null == b ? b = Infinity > a.a.getDuration() || c ? cC(a.a) : mC(a.a) : 0 > b && (b = mC(a.a) + b);
    return LC(a, MC(a, b));
  };
  MC = function (a, b) {
    const c = cC(a.a);
    if (b < c) return c;
    a = mC(a.a);
    return b > a ? a : b;
  };
  OC = function (a) {
    const b = GC(a.f);
    return 0 < a.h.readyState && !a.h.paused ? MC(a, b) : b;
  };
  LC = function (a, b) {
    const c = a.a.getDuration();
    return b >= c ? c - a.l.durationBackoff : b;
  };
  PC = function (a, b) {
    if ("startover" === a.a.B) return b;
    var c = new _.Ct(a.h.buffered);
    c = c.Fb.bind(c);
    var d = Math.max(a.B, a.l.rebufferingGoal);
    const e = a.l.safeSeekOffset;
    let f = cC(a.a);
    const g = mC(a.a),
      h = a.a.getDuration();
    3 > g - f && (f = g - 3);
    const k = bC(a.a, d),
      l = bC(a.a, e);
    d = bC(a.a, d + e);
    if (b >= h) return a.g.debug("Playhead past duration."), LC(a, b);
    if (b > g) return a.g.debug("Playhead past end."), g;
    if (b < f) {
      if (c(l)) return a.g.debug("Playhead before start \x26 start is buffered"), l;
      a.g.debug("Playhead before start \x26 start is unbuffered");
      return d;
    }
    if (b >= k || c(b)) return a.g.debug("Playhead in safe region or in buffered region."), b;
    a.g.debug("Playhead outside safe region \x26 in unbuffered region.");
    return d;
  };
  QC = function (a, b, c = 0) {
    for (const d of a.a) d.l(a.f.currentTime, b, c);
  };
  RC = function (a, b) {
    const c = a.offset || 0;
    return b < a.startTime + c ? 1 : b > a.endTime + c ? 3 : 2;
  };
  SC = function (a, b, c, d) {
    a.a = b;
    a.f = c;
    a.h = d;
  };
  _.TC = function (a) {
    _.fs[a] && delete _.fs[a];
  };
  _.UC = function (a) {
    _.ds[a] && delete _.ds[a];
  };
  _.VC = function (a) {
    a = a.toLowerCase();
    return _.Ds[a] || window.muxjs && a === _.zc ? !0 : !1;
  };
  WC = function (a, b) {
    if (b !== _.zc) {
      var c = _.Ds[b];
      if (!c) throw new _.G(_.E, 2, 2012, "Text parser is unavailable for " + b);
      a.w = new c(a.C);
    }
  };
  XC = function (a, b, c, d) {
    return Promise.resolve().then(() => {
      if (a.w && a.j) if (null == c || null == d) a.w.parseInit(_.J(b));else {
        var e = {
          periodStart: a.A,
          segmentStart: c,
          segmentEnd: d
        };
        e = a.w.parseMedia(_.J(b), e);
        var f = e.cues.filter(g => g.startTime >= a.h && g.startTime < a.l);
        a.j.append(f, e.styles);
        null == a.a && (a.a = Math.max(c, a.h));
        a.f = Math.min(d, a.l);
      }
    });
  };
  YC = function (a, b, c) {
    a.B = b;
    if (b = a.o.get(b)) for (const d of b.keys()) {
      let e = b.get(d);
      e && (e = e.filter(f => f.endTime <= c), a.j.append(e, []));
    }
  };
  ZC = function (a, b, c, d, e) {
    const f = c + " " + d,
      g = new Map();
    for (const h of b) {
      b = h.stream;
      g.has(b) || g.set(b, new Map());
      g.get(b).has(f) || g.get(b).set(f, []);
      h.startTime += e;
      h.endTime += e;
      if (!(h.startTime >= a.h && h.startTime < a.l)) continue;
      const k = new _.Zs(h.startTime, h.endTime, h.text);
      g.get(b).get(f).push(k);
      b === a.B && a.j.append([k], []);
    }
    for (const h of g.keys()) {
      a.o.has(h) || a.o.set(h, new Map());
      for (const k of g.get(h).keys()) e = g.get(h).get(k), a.o.get(h).set(k, e);
    }
    a.a = null == a.a ? Math.max(c, a.h) : Math.min(a.a, Math.max(c, a.h));
    a.f = Math.max(a.f, Math.min(d, a.l));
  };
  $C = function (a, b) {
    var c = String(_.Bj++);
    c = new _.Cj(c, a);
    c.mimeType = _.yj(b.mimeType);
    if (a === _.K || a === _.L) c.label = _.yj(b.label), c.language = _.yj(b.language), c.originalLanguage = _.yj(b.originalLanguage);
    a === _.M ? (c.frameRate = b.frameRate, c.roles = b.roles, c.trickPlay = b.trickPlay) : a === _.L ? (c.channelsCount = b.channelsCount, c.roles = b.audioRoles || [], c.mimeType = _.yj(b.audioMimeType)) : (c.kind = _.yj(b.kind), c.roles = b.roles);
    c.accessibility = a === _.M || a === _.L ? (b.accessibility || []).filter(d => d.type === a) : b.accessibility || [];
    return c;
  };
  aD = function (a, b, c) {
    const d = new _.Kj(a === _.M ? `${b.videoId}` : a === _.L ? `${b.audioId}` : `${b.id}`, c);
    switch (a) {
      case _.M:
        d.codec = _.yj(b.videoCodec);
        d.bandwidth = b.videoBandwidth || b.bandwidth;
        d.width = b.width;
        d.height = b.height;
        d.originalId = b.originalVideoId;
        break;
      case _.L:
        d.codec = _.yj(b.audioCodec);
        d.bandwidth = b.audioBandwidth || b.bandwidth;
        d.originalId = b.originalAudioId;
        break;
      default:
        d.codec = _.yj(b.codecs), d.originalId = b.originalTextId;
    }
    (a = c.renditions.find(e => _.Xg(e, d, null, ["id", "track"]))) || c.renditions.push(d);
    return a || d;
  };
  bD = function (a, b) {
    a = a.reduce((c, d) => {
      b === _.M ? null === d.videoId || c[d.videoId] && !d.active || (c[d.videoId] = d) : b === _.L && (null === d.audioId || c[d.audioId] && !d.active || (c[d.audioId] = d));
      return c;
    }, {});
    return Object.values(a);
  };
  cD = function (a, b) {
    if (void 0 !== b && null !== b) {
      const c = a.filter(d => d.audio && d.audio.id === b);
      if (0 < c.length) return c;
    }
    return a;
  };
  dD = function (a, b, c) {
    function d(e, f) {
      return null == e ? null == f : f.id === e;
    }
    for (let e = 0; e < c.length; e++) if (d(a, c[e].audio) && d(b, c[e].video)) return c[e];
    return null;
  };
  _.eD = function (a, b) {
    a.forEach(c => {
      c.startTime += b;
      c.endTime += b;
    });
  };
  fD = function (a) {
    const b = a.periods[0].startTime;
    a = a.periods[a.periods.length - 1].endTime;
    if (null == a) throw Error("Period end time must be known");
    return {
      start: b,
      end: a
    };
  };
  gD = function (a) {
    return Object.assign({}, a, {
      periods: a.periods.map(b => Object.assign({}, b))
    });
  };
  hD = function (a, b) {
    if (0 === a.length) throw Error("Cannot concatenate 0 manifests");
    if (1 === a.length) return a[0];
    a[0].cues.forEach(e => {
      e.offset = 0;
    });
    var c = [...a[0].periods];
    for (var d = 1; d < a.length; d++) {
      const e = fD(a[d - 1]).end;
      _.eD(a[d].periods, e);
      c.push(...a[d].periods);
      a[d].cues.forEach(f => {
        f.offset = e;
      });
    }
    d = a.flatMap(e => e.cues);
    c = {
      periods: c,
      presentationTimeline: b,
      minBufferTime: 0,
      cues: d
    };
    d = fD(c).end;
    _.aC(b.a, 0, d);
    (a = a[0].presentationTimeline.kc()) && _.dC(b, a.seekRangeStartDateTime);
    return c;
  };
  jD = function (a, b) {
    try {
      if (1 === a.length) return a[0];
      if (!a.every(iD)) throw new _.$h("Period durations must be finite");
      return hD(a, b);
    } catch (c) {
      throw new _.G(_.E, 7, 7003, "Failed to join manifests into a continuous playlist!", c);
    }
  };
  iD = function (a) {
    return a.periods.every(b => null != b.endTime && 0 <= b.endTime && isFinite(b.endTime));
  };
  kD = function (a, b) {
    const c = b.periods[0].configurationId;
    var d = [...a.periods].filter(e => e.configurationId !== c);
    a = a.presentationTimeline;
    d = {
      periods: d,
      presentationTimeline: a,
      minBufferTime: 0
    };
    _.eC(a, b.presentationTimeline.l);
    return jD([d, b], a);
  };
  lD = function (a) {
    return {
      id: a.id,
      bandwidth: a.bandwidth,
      trickPlay: a.video ? a.video.trickPlay : !1,
      width: a.video ? a.video.width : void 0,
      height: a.video ? a.video.height : void 0
    };
  };
  mD = function (a, b) {
    return b.find(c => c.id === a.id) || null;
  };
  nD = function (a, b) {
    return a.h.filter(c => c.type === b);
  };
  oD = function (a, b) {
    return (a = a.a.get(b) || null) && a.track;
  };
  qD = function (a, b, c) {
    if (!c || oD(a, c.type) !== c || a.m.pa()) switch (b) {
      case _.M:
        a.ab(c && c.renditions[0]);
        break;
      case _.L:
        a.sb(c && c.renditions[0]);
        break;
      case _.K:
        a.tb(c && c.renditions[0]);
        break;
      default:
        pD(a, b, c && c.renditions[0]);
    }
  };
  pD = function (a, b, c, d) {
    d = void 0 === d ? !1 : d;
    if (a.m && ((a.a.get(b) || null) !== c || a.m.pa())) {
      var e = a.m;
      if (c) {
        if (0 > a.h.indexOf(c.track)) throw Error(`Unknown rendition with ID ${c.id}`);
        if (b !== c.track.type) throw Error("Types mismatch");
        a.g.debug(`Activate rendition type
          ${b} with ID: ${c.id}`);
        if (b === _.K) {
          const g = e.ha().find(h => h.id === parseInt(c.id, 10));
          g && (a.f.set(b, c), rD(a, c), e.trigger(new _.C(_.dl)), sD(e, !0).then(() => {
            tD(e, g);
          }).catch(h => {
            a.g.warn("Error while activating track", h);
          }));
        } else if (b === _.L) a.f.set(b, c), uD(e, parseInt(c.id, 10));else if (b === _.M) {
          const g = a.a.get(_.L) || null;
          var f = null;
          if (g) {
            const h = vD(e).filter(k => k.videoId === parseInt(c.id, 10));
            f = h.find(k => k.audioId === parseInt(g.id, 10)) || null;
            if (!f) {
              a.g.debug("Unable to find exact variant. Searching for equivalent");
              const k = g.track.renditions.map(l => l.id).filter(l => l !== g.id);
              f = h.find(l => k.find(m => m === l.audioId)) || null;
            }
          } else f = vD(e).find(h => h.videoId === parseInt(c.id, 10)) || null;
          f ? (a.f.set(b, c), wD(e, f, d)) : a.g.warn("Cannot find matching variant");
        }
      } else a.g.debug(_.ya + b), (f = oD(a, b)) && (b === _.K ? sD(e, !1).then(() => {
        a.a.set(b, null);
        e.trigger(new _.C(_.dl));
      }).catch(g => {
        a.g.warn("Error while de-activating text tracks", g);
      }) : b === _.L ? a.g.info("Enabling ABR for audio is not supported") : b === _.M && f.trickPlay ? a.g.info("Enabling ABR for trick play tracks is not supported") : wD(e, null, d));
    }
  };
  rD = function (a, b) {
    a.a.set(b.track.type, b);
    b === (a.f.get(b.track.type) || null) && a.f.set(b.track.type, null);
  };
  zD = function (a) {
    if (a.m) {
      a.g.debug("Track list change detected, updating track list");
      var b = [];
      for (var c of Object.values(_.wt)) a.a.set(c, null), a.f.set(c, null);
      for (var d of a.m.ha()) {
        let e = $C(_.K, d);
        e = (c = b.find(g => e.yc(g))) || e;
        const f = aD(_.K, d, e);
        d.active && a.a.set(_.K, f);
        c || b.push(e);
      }
      xD(a.m) || a.a.set(_.K, null);
      d = vD(a.m);
      yD(a, b, d, _.M);
      yD(a, b, d, _.L);
      a.h = b;
    }
  };
  yD = function (a, b, c, d) {
    c = bD(c, d);
    for (const e of c) {
      let f = $C(d, e);
      f = (c = b.find(h => f.yc(h))) || f;
      const g = aD(d, e, f);
      e.active && a.a.set(d, g);
      c || b.push(f);
    }
  };
  AD = function (a, b, c) {
    if (b) {
      a.h.length || zD(a);
      var d = b.type,
        e = a.a.get(d) || null,
        f;
      for (const g of nD(a, d)) if (f = g.renditions.find(h => parseInt(h.id, 10) === b.id)) break;
      f && e !== f && (rD(a, f), d === _.L ? a.m.trigger(new _.C(_.bl)) : d === _.M && a.m.trigger(new _.C(_.ng, {
        details: {
          width: f.width || 0,
          height: f.height || 0,
          bandwidth: f.bandwidth,
          track: f.track,
          abrSelection: c
        }
      })));
    }
  };
  BD = function (a) {
    const b = _.gk(a.mimeType, a.codecs),
      c = HB(a);
    return _.VC(b) || _.Ar(c) || (_.fk(b, a.type) ? !0 : !1);
  };
  DD = function (a, b) {
    if (window.ManagedMediaSource) {
      a.j.disableRemotePlayback = !0;
      var c = new ManagedMediaSource();
      a.l.one(c, cB, () => {
        URL.revokeObjectURL(a.B);
        b.resolve();
      });
      a.l.on(c, dB, () => {
        a.S = !0;
      });
      a.l.on(c, "endstreaming", () => {
        a.S = !1;
      });
      a.B = CD(c);
      a.j.src = a.B;
      return c;
    }
    c = new MediaSource();
    a.B = CD(c);
    a.l.one(c, cB, () => {
      URL.revokeObjectURL(a.B);
      b.resolve();
    });
    a.j.src = a.B;
    return c;
  };
  ED = function (a, b) {
    b.forEach((c, d) => {
      c = a.o.addSourceBuffer(c);
      a.l.on(c, _.Ud, a.T.bind(a, d));
      a.l.on(c, "updateend", a.C.bind(a, d));
      a.f[d] = c;
      a.h[d] = [];
      a.H[d] = !1;
    });
    a.O = b.has(_.p) && b.has(_.t);
  };
  FD = function (a, b) {
    try {
      return new _.Ct(a.f[b].buffered, b);
    } catch (c) {
      return b in a.f && a.g.error(SA + b, c), null;
    }
  };
  GD = function (a, b) {
    return a.f[b] ? (a = FD(a, b)) ? a.toString() : "" : "";
  };
  ID = function (a, b, c) {
    if (a.J) return Promise.reject();
    c = {
      start: c,
      p: new _.xn()
    };
    a.h[b].push(c);
    1 === a.h[b].length && HD(a, b);
    return c.p;
  };
  JD = function (a, b, c, d, e) {
    return [ID(a, b, a.wb.bind(a, b)), ID(a, b, a.ia.bind(a, b, c)), ID(a, b, a.Kb.bind(a, b, d, e))];
  };
  LD = function (a, b) {
    return _.w(function* () {
      if (a.J) return Promise.reject();
      const c = [];
      for (const d in a.f) {
        const e = new _.xn(),
          f = {
            start: function (g) {
              g.resolve();
            }.bind(null, e),
            p: e
          };
        a.h[d].push(f);
        c.push(e);
        1 === a.h[d].length && f.start();
      }
      try {
        yield Promise.all(c);
      } catch (d) {
        for (const e in a.f) a.h[e].length && a.h[e].shift();
        return Promise.reject(d);
      }
      for (const d in a.f);
      try {
        yield b();
      } catch (d) {
        return Promise.reject(new _.G(_.E, 3, 3015, {
          mediaError: d
        }));
      } finally {
        for (const d in a.f) KD(a, d);
      }
    });
  };
  KD = function (a, b) {
    a.h[b].shift();
    HD(a, b);
  };
  HD = function (a, b) {
    const c = a.h[b][0];
    if (c) try {
      a.g.debug(IA + b), c.start();
    } catch (d) {
      d.name === AA ? c.p.reject(new _.G(_.E, 3, 3017, {
        type: b
      })) : c.p.reject(new _.G(_.E, 3, 3015, {
        mediaError: d
      })), KD(a, b);
    } else a.g.debug(wA + b);
  };
  RD = function (a, b, c, d) {
    var e = a.periods.flatMap(h => h.variants);
    if (b.length || c.length) e = MD(e, b, c);
    e = ND(e, d);
    if (1 < a.periods.length) {
      const h = l => {
        l = l.split(".");
        let m = l[0];
        1 < l.length && (m += "." + l[1]);
        return m.toLowerCase();
      };
      b = [];
      for (var f of a.periods) {
        c = new Set();
        for (const l of f.variants) null !== l.audio && c.add(h(l.audio.codecs));
        b.push(c);
      }
      let k = b[0];
      for (f = 1; f < b.length; f++) {
        const l = b[f];
        k = new Set([...k].filter(m => l.has(m)));
      }
      e = e.filter(l => 0 === k.size && null === l.audio ? !0 : 0 < k.size && null === l.audio ? !1 : k.has(h(l.audio.codecs)));
    }
    e = OD(e);
    const g = PD(e);
    for (const h of a.periods) h.variants = h.variants.filter(k => {
      if (QD(k) === g) return !0;
      _.ih("Dropping Variant (better codec available)", k);
      return !1;
    });
  };
  MD = function (a, b, c) {
    if (!b.length && !c.length) return a;
    const d = a.filter(g => (g = g.audio) && c.length && (g = _.JB(g.codecs), c.includes(g)) ? !0 : !1),
      e = a.filter(g => (g = g.video) && b.length && (g = _.JB(g.codecs), b.includes(g)) ? !0 : !1),
      f = a.filter(g => d.length && !e.length ? d.includes(g) : !d.length && e.length ? e.includes(g) : d.includes(g) && e.includes(g));
    return f.length ? f : a;
  };
  ND = function (a, b) {
    var c = a.filter(f => f.audio && f.audio.channelsCount);
    const d = new Map();
    for (var e of c) c = e.audio.channelsCount, d.has(c) || d.set(c, []), d.get(c).push(e);
    e = Array.from(d.keys());
    if (0 === e.length) return a;
    a = e.filter(f => f <= b);
    return a.length ? d.get(Math.max.apply(null, a)) : d.get(Math.min.apply(null, e));
  };
  OD = function (a) {
    const b = new _.Ns();
    for (const c of a) a = QD(c), b.push(a, c);
    return b;
  };
  PD = function (a) {
    let b = "",
      c = Infinity;
    hB(a, (d, e) => {
      e = e.reduce((f, g) => f + g.bandwidth || 0, 0) / e.length;
      _.ih(_.pd, d, "avg bandwidth", e);
      e < c && (b = d, c = e);
    });
    return b;
  };
  QD = function (a) {
    let b = "";
    a.video && (b = _.JB(a.video.codecs));
    let c = "";
    a.audio && (c = _.JB(a.audio.codecs));
    return b + "-" + c;
  };
  SD = function (a, b, c, d) {
    let e = a.periods.flatMap(f => f.variants);
    if (b.length || c.length) e = MD(e, b, c);
    e = ND(e, d);
    for (const f of a.periods) f.variants = f.variants.filter(g => e.includes(g));
  };
  TD = function (a, b) {
    var c;
    if (!(c = !a.width || !a.height)) {
      c = a.width;
      if (c = c >= b.minWidth && c <= b.maxWidth) c = a.height, c = c >= b.minHeight && c <= b.maxHeight;
      c && (c = a.width * a.height, c = c >= b.minPixels && c <= b.maxPixels);
    }
    c && (a = a.bandwidth, c = a >= b.minBandwidth && a <= b.maxBandwidth);
    return c ? !0 : !1;
  };
  UD = function (a, b) {
    let c = !1;
    for (const d of a) a = d.allowedByApplication, d.allowedByApplication = TD(d, b), a !== d.allowedByApplication && (c = !0);
    return c;
  };
  XD = function (a, b, c, d, e) {
    e = void 0 === e ? !0 : e;
    let f = !1;
    d.variants = d.variants.filter(g => {
      if (a && a.O && !qB(a, g)) return _.ih("Dropping variant - not compatible with key system", g), !1;
      let h = g.audio;
      const k = g.video;
      if (h && !BD(h)) {
        const l = h.codecs;
        0 <= h.codecs.indexOf(_.Qd) ? (_.ih("Trying to use AC-3 for EC-3"), h.codecs = h.codecs.replace(_.Qd, "ac-3"), BD(h) ? _.hh("Using AC-3 instead of EC-3 for", VD(h)) : (h.codecs = l, _.hh(BA, VD(h)), g.audio = null, f = !0, h = null)) : (_.hh(BA, VD(h)), g.audio = null, f = !0, h = null);
      }
      return k && !BD(k) ? (_.hh("Dropping variant - video not compatible with platform", VD(k)), !1) : h && b && !WD(h, b, e) ? (_.hh("Dropping variant - not compatible with active audio", "active audio", VD(b), "variant.audio", VD(h)), !1) : k && c && !WD(k, c, e) ? (_.hh("Dropping variant - not compatible with active video", "active video", VD(c), "variant.video", VD(k)), !1) : !0;
    });
    if (f) {
      let g = !1,
        h = !1;
      for (const k of d.variants) !g && k.video && k.audio && (g = !0), h || k.audio || !k.video || (h = !0);
      g && h && (d.variants = d.variants.filter(k => k.video && !k.audio ? (_.hh("Dropping variant - not compatible with video + audio", "variant.video", VD(k.video)), !1) : !0));
    }
    d.textStreams = d.textStreams.filter(g => {
      const h = _.VC(_.gk(g.mimeType, g.codecs));
      h || _.ih("Dropping text stream. Is not supported by the platform.", g);
      return h;
    });
  };
  VD = function (a) {
    const b = HB(a);
    return a.type === _.p ? "type\x3daudio mimeType\x3d" + a.mimeType + " codecs\x3d" + a.codecs + " codecInfo\x3d" + b + " bandwidth\x3d" + a.bandwidth + " channelsCount\x3d" + a.channelsCount : a.type === _.t ? "type\x3dvideo mimeType\x3d" + a.mimeType + " codecs\x3d" + a.codecs + " codecInfo\x3d" + b + " bandwidth\x3d" + a.bandwidth + " frameRate\x3d" + a.frameRate + " width\x3d" + a.width + " height\x3d" + a.height : "unexpected stream type";
  };
  WD = function (a, b, c) {
    return a.mimeType !== b.mimeType || (void 0 === c || c) && a.codecs.split(".")[0] !== b.codecs.split(".")[0] ? !1 : !0;
  };
  YD = function (a) {
    const b = a.audio,
      c = a.video,
      d = b ? b.codecs : null,
      e = c ? c.codecs : null,
      f = [];
    e && f.push(e);
    d && f.push(d);
    const g = c && c.mimeType || null,
      h = b && b.mimeType || null;
    var k = [];
    b && k.push(b.kind);
    c && k.push(c.kind);
    k = k[0] || null;
    const l = new Set(),
      m = new Set();
    if (b) for (var n of b.roles) m.add(n);
    if (c) for (const q of c.roles) l.add(q);
    n = [c, b].map(q => q ? q.accessibility || [] : []).reduce((q, u) => q.concat(u), []);
    a = {
      id: a.id,
      active: !1,
      type: "variant",
      bandwidth: a.bandwidth,
      language: a.language,
      originalLanguage: a.language,
      label: null,
      kind: k,
      width: null,
      height: null,
      frameRate: null,
      trickPlay: !1,
      mimeType: g,
      audioMimeType: h,
      codecs: f.join(", "),
      audioCodec: d,
      videoCodec: e,
      primary: a.primary,
      roles: Array.from(l),
      audioRoles: Array.from(m),
      videoId: null,
      audioId: null,
      channelsCount: null,
      spatialAudio: !1,
      audioBandwidth: null,
      videoBandwidth: null,
      originalVideoId: null,
      originalAudioId: null,
      originalTextId: null,
      accessibility: n
    };
    c && (a.videoId = c.id, a.originalVideoId = c.originalId, a.width = c.width || null, a.height = c.height || null, a.frameRate = c.frameRate || null, a.trickPlay = !!c.trickPlay, a.videoBandwidth = c.bandwidth || null);
    b && (a.audioId = b.id, a.originalAudioId = b.originalId, a.originalLanguage = b.originalLanguage, a.channelsCount = b.channelsCount, a.audioBandwidth = b.bandwidth || null, a.label = b.label, a.audioRoles = b.roles);
    return a;
  };
  ZD = function (a) {
    return {
      id: a.id,
      active: !1,
      type: _.F,
      bandwidth: 0,
      language: a.language,
      originalLanguage: a.originalLanguage,
      label: a.label,
      kind: a.kind || null,
      width: null,
      height: null,
      frameRate: null,
      trickPlay: !1,
      mimeType: a.mimeType,
      audioMimeType: null,
      codecs: a.codecs || null,
      audioCodec: null,
      videoCodec: null,
      primary: a.primary,
      roles: a.roles,
      audioRoles: null,
      videoId: null,
      audioId: null,
      channelsCount: null,
      spatialAudio: !1,
      audioBandwidth: null,
      videoBandwidth: null,
      originalVideoId: null,
      originalAudioId: null,
      originalTextId: a.originalId,
      accessibility: a.accessibility,
      keyId: null
    };
  };
  $D = function (a) {
    return a.allowedByApplication && a.allowedByKeySystem;
  };
  _.bE = function (a, b, c, d) {
    b = _.Qh(b);
    let e = a;
    const f = a.filter(h => h.primary);
    f.length && (e = f);
    const g = e.length ? e[0].language : "";
    e = e.filter(h => h.language === g);
    b.some(h => {
      const k = _.AB(_.qi(h), a.map(l => l.language));
      return k ? (e = a.filter(l => _.qi(l.language) === k), !0) : !1;
    });
    d && e.some(h => h.kind === _.Wd) && (e = e.filter(h => h.kind === _.Wd));
    if (c) {
      b = aE(e, c);
      if (b.length) return b;
      _.B("No exact match for the text role could be found.");
    } else if (b = e.filter(h => 0 === h.roles.length), b.length) return b;
    b = e.map(h => h.roles).reduce(_.uB, []);
    return b.length ? aE(e, b[0]) : e;
  };
  aE = function (a, b) {
    return a.filter(c => c.roles.includes(b));
  };
  cE = function (a, b, c) {
    if (a.j && a.a.useCmsd) {
      const d = sB(a.j);
      null !== d && (a.g.debug("Using CMSD maxSuggestedBitrateKbps:", d), c = c.filter(e => e.bandwidth <= 1E3 * d));
    }
    a = c.filter(d => !d.trickPlay);
    b && (a = a.filter(d => TD(d, b)));
    return a.sort((d, e) => d.bandwidth - e.bandwidth);
  };
  dE = function (a) {
    return new _.G(_.E, 7, 7E3, a.a ? "Another load operation was started while a load operation was in progress." : "Player was released while a load operation was in progress.");
  };
  eE = function (a, b) {
    if (b && (null == a.a || a.a !== b)) throw dE(a);
  };
  gE = function (a, b) {
    a.a.has(b) || a.a.set(b, new fE());
    return a.a.get(b);
  };
  hE = function (a, b, c) {
    gE(a, b).text = c;
  };
  kE = function (a, b, c) {
    c = void 0 === c ? !0 : c;
    var d;
    if (!(d = !!a.audio !== !!b.audio || !!a.video !== !!b.video || a.language !== b.language) && (d = a.audio && b.audio)) {
      d = a.audio;
      var e = b.audio;
      d = !(d.channelsCount === e.channelsCount && (!c || iE(d, e)) && jE(d.roles, e.roles));
    }
    !d && (d = a.video && b.video) && (a = a.video, b = b.video, d = !((!c || iE(a, b)) && jE(a.roles, b.roles)));
    return d ? !1 : !0;
  };
  iE = function (a, b) {
    if (a.mimeType !== b.mimeType) return !1;
    a = IB(a.codecs).map(c => _.JB(c));
    b = IB(b.codecs).map(c => _.JB(c));
    if (a.length !== b.length) return !1;
    a.sort();
    b.sort();
    for (let c = 0; c < a.length; c++) if (a[c] !== b[c]) return !1;
    return !0;
  };
  jE = function (a, b) {
    a = new Set(a);
    b = new Set(b);
    a.delete(_.se);
    b.delete(_.se);
    if (a.size !== b.size) return !1;
    for (const c of a) if (!b.has(c)) return !1;
    return !0;
  };
  lE = function (a, b) {
    let c = null;
    b.some(d => {
      c = _.AB(_.qi(d), a.map(e => zB(e)));
      return !!c;
    });
    return c ? a.filter(d => c === zB(d)) : [];
  };
  mE = function (a, b) {
    return a.filter(c => {
      const d = c.audio;
      c = c.video;
      return d && 0 <= d.roles.indexOf(b) || c && 0 <= c.roles.indexOf(b);
    });
  };
  nE = function (a, b) {
    return a.filter(c => c.audio && c.audio.spatialAudio !== b ? !1 : !0);
  };
  oE = function (a) {
    return 1 >= a.a.chasingRate ? (a.g.warn("chasingRate must be greater than 1."), !1) : a.a.chaseJumpDistance <= a.a.startChasingAt ? (a.g.warn("chaseJumpDistance must be greater than startChasingAt."), !1) : a.a.startChasingAt <= a.a.stopChasingAt ? (a.g.warn("startChasingAt must be greater than stopChasingAt."), !1) : 0 > a.a.stopChasingAt ? (a.g.warn("stopChasingAt must be greater than or equal to 0."), !1) : !0;
  };
  qE = function (a, b) {
    if (window.ManagedMediaSource) {
      a.j.disableRemotePlayback = !0;
      var c = new ManagedMediaSource();
      a.l.one(c, cB, () => {
        URL.revokeObjectURL(a.B);
        b.resolve();
      });
      a.l.on(c, dB, () => {
        a.V = !0;
      });
      a.l.on(c, "endstreaming", () => {
        a.V = !1;
      });
      a.B = pE(c);
      a.j.src = a.B;
      return c;
    }
    c = new MediaSource();
    a.B = pE(c);
    a.l.one(c, cB, () => {
      URL.revokeObjectURL(a.B);
      b.resolve();
    });
    a.j.src = a.B;
    return c;
  };
  rE = function (a, b) {
    b.forEach((c, d) => {
      const e = a.o.addSourceBuffer(c);
      a.l.on(e, _.Ud, a.T.bind(a, d));
      a.l.on(e, "updateend", a.C.bind(a, d));
      a.a[d] = e;
      a.H[d] = c;
      a.h[d] = [];
      a.I[d] = !1;
    });
    a.S = b.has(_.p) && b.has(_.t);
  };
  sE = function (a, b) {
    try {
      return new _.Ct(a.a[b].buffered, b);
    } catch (c) {
      return b in a.a && a.g.error(SA + b, c), null;
    }
  };
  tE = function (a, b) {
    return a.a[b] ? (a = sE(a, b)) ? a.toString() : "" : "";
  };
  vE = function (a, b, c) {
    if (a.O) return Promise.reject();
    c = {
      start: c,
      p: new _.xn()
    };
    a.h[b].push(c);
    1 === a.h[b].length && uE(a, b);
    return c.p;
  };
  wE = function (a, b, c) {
    0 > c && (c += .001);
    try {
      a.g.debug(GA + b, c), a.a[b].timestampOffset = c;
    } catch (d) {
      throw a.g.warn(rA + b, c, d), d;
    }
    a.g.debug(lA + b, c);
    a.C(b);
  };
  yE = function (a, b, c, d) {
    return _.w(function* () {
      const e = _.JB(_.ik(a.H[b])),
        f = _.hk(a.H[b]),
        g = _.gk(c, d),
        h = _.JB(_.ik(g)),
        k = _.hk(g);
      if (e === h && f === k) return !1;
      a.K.mediaSource.codecSwitchingStrategy === _.Jf && LB() && (yield xE(a, b, g));
      return !0;
    });
  };
  zE = function (a, b, c, d, e, f) {
    return [f ? Promise.resolve() : vE(a, b, () => {
      try {
        a.g.debug($z + b + Vz);
        const g = a.a[b].appendWindowStart,
          h = a.a[b].appendWindowEnd;
        a.a[b].abort();
        a.a[b].appendWindowStart = g;
        a.a[b].appendWindowEnd = h;
      } catch (g) {
        throw a.g.warn(mA + b + Vz, g), g;
      }
      a.g.debug(gA + b + Vz);
      a.C(b);
    }), vE(a, b, () => wE(a, b, c)), vE(a, b, () => {
      try {
        a.g.debug(EA + b, d, e), a.a[b].appendWindowStart = 0, a.a[b].appendWindowEnd = e, a.a[b].appendWindowStart = d;
      } catch (g) {
        throw a.g.warn(qA + b, d, e, g), g;
      }
      a.g.debug(kA + b, d, e);
      a.C(b);
    })];
  };
  BE = function (a, b) {
    return _.w(function* () {
      if (a.O) return Promise.reject();
      const c = [];
      for (const d in a.a) {
        const e = new _.xn(),
          f = {
            start: function (g) {
              g.resolve();
            }.bind(null, e),
            p: e
          };
        a.h[d].push(f);
        c.push(e);
        1 === a.h[d].length && f.start();
      }
      try {
        yield Promise.all(c);
      } catch (d) {
        for (const e in a.a) a.h[e].length && a.h[e].shift();
        return Promise.reject(d);
      }
      for (const d in a.a);
      try {
        yield b();
      } catch (d) {
        return Promise.reject(new _.G(_.E, 3, 3015, {
          mediaError: d
        }));
      } finally {
        for (const d in a.a) AE(a, d);
      }
    });
  };
  AE = function (a, b) {
    a.h[b].shift();
    uE(a, b);
  };
  uE = function (a, b) {
    const c = a.h[b][0];
    if (c) try {
      a.g.debug(IA + b), c.start();
    } catch (d) {
      d.name === AA ? c.p.reject(new _.G(_.E, 3, 3017, {
        type: b
      })) : c.p.reject(new _.G(_.E, 3, 3015, {
        mediaError: d
      })), AE(a, b);
    } else a.g.debug(wA + b);
  };
  xE = function (a, b, c) {
    return vE(a, b, () => {
      b === _.F ? a.g.debug(`Change not supported for ${b}`) : (a.g.debug(`Change Type: ${a.H[b]} -> ${c}`), LB() ? (a.a[b].changeType(c), a.H[b] = c) : a.g.debug("Change Type not supported"), a.C(b));
    });
  };
  CE = function (a, b) {
    a.f = b;
  };
  DE = function (a, b) {
    a.j = b;
    a.j.speedUpMode && (a.w = a.j.speedUpMode);
  };
  GE = function (a, b) {
    a.a.stop();
    a.l || (a.o = !1);
    const c = a.l ? 0 : a.h;
    if ((b ? b : a.w) === _.Bd || 0 === c || 1 === c) try {
      EE(a, c);
    } catch (d) {
      a.g.warn('Set playbackRate using "decoder" mode fails, due to unsupported range by platform. Fallback to "seek" mode.', d), FE(a);
    } else FE(a);
  };
  EE = function (a, b) {
    if (a.f.pf() !== b) {
      a.g.debug(FA, b, ' using native playbackRate "decoder" mode.');
      try {
        a.f.zh(b);
      } catch (c) {
        throw c;
      }
    }
  };
  FE = function (a) {
    a.g.debug(FA, a.h, ' using "seek" mode.');
    EE(a, 0);
    a.o = !0;
    a.a.vb(.25);
  };
  IE = function (a) {
    a = _.J(a);
    let b = !1,
      c;
    const d = [],
      e = h => {
        d.push(h);
        _.Yl(h);
      },
      f = () => {
        b = !0;
      },
      g = [];
    new _.em().N(_.ze, e).N(_.Xf, e).N(_.ue, e).N(_.ye, e).N(_.Of, e).ca(_.Qf, h => {
      c = h;
      d.push(h);
      _.Zl(h);
    }).ca("encv", f).ca("enca", f).ca("avc1", h => {
      g.push({
        N: h,
        Hc: 1701733238
      });
    }).ca("avc3", h => {
      g.push({
        N: h,
        Hc: 1701733238
      });
    }).ca("ac-3", h => {
      g.push({
        N: h,
        Hc: 1701733217
      });
    }).ca(_.Qd, h => {
      g.push({
        N: h,
        Hc: 1701733217
      });
    }).ca("mp4a", h => {
      g.push({
        N: h,
        Hc: 1701733217
      });
    }).parse(a);
    if (b) return _.hh("Init segment already indicates encryption."), a;
    if (0 === g.length || !c) throw _.jh("Failed to find boxes needed to fake encryption!"), _.jh("Failed init segment (hex):", _.Wi(a)), new _.G(_.E, 3, 14E3);
    g.reverse();
    for (const h of g) _.hh(`Inserting "${_.Xl(h.Hc)}" box into init segment.`), a = HE(a, c, h.N, d, h.Hc);
    return a;
  };
  HE = function (a, b, c, d, e) {
    {
      var f = JE.value(),
        g = a.subarray(c.start, c.start + c.size);
      const k = _.Bi(g);
      var h = new Uint8Array(c.size + f.byteLength);
      h.set(g, 0);
      g = _.Bi(h);
      g.setUint32(4, e);
      h.set(f, c.size);
      e = k.getUint32(4);
      g.setUint32(c.size + 16, e);
      KE(h, 0, h.byteLength);
      e = h;
    }
    h = new Uint8Array(a.byteLength + e.byteLength);
    c = _.pk("Xbox One") || _.ok() ? c.start : c.start + c.size;
    f = a.subarray(c);
    h.set(a.subarray(0, c));
    h.set(e, c);
    h.set(f, c + e.byteLength);
    for (const k of d) KE(h, k.start, k.size + e.byteLength);
    a = _.Bi(h, b.start);
    b = _.am(b);
    d = a.getUint32(b);
    a.setUint32(b, d + 1);
    return h;
  };
  KE = function (a, b, c) {
    a = _.Bi(a, b);
    b = a.getUint32(0);
    0 !== b && (1 === b ? (a.setUint32(8, c >> 32), a.setUint32(12, c & 4294967295)) : a.setUint32(0, c));
  };
  LE = function (a) {
    return "(" + a.type + ":" + a.stream.id + ")";
  };
  ME = function (a) {
    return !a.qb && null == a.eb && !a.Ub && !a.dc;
  };
  NE = function (a) {
    a.periods.every(b => typeof b.id === _.Pf);
  };
  OE = function (a) {
    null != a.eb && (a.eb.stop(), a.eb = null);
  };
  PE = function (a) {
    return _.w(function* () {
      a.Qb && (yield a.Qb.abort());
    });
  };
  aF = function (a, b, c, d, e) {
    return _.w(function* () {
      var f = a.D.Lb();
      const g = QE(a, f);
      if (null == g) a.g.warn("Cannot initialize streams, period not found at", f);else {
        f = new Map();
        var h = new Set();
        b && (f.set(_.p, b), h.add(b));
        c && (f.set(_.t, c), h.add(c));
        d && (f.set(_.F, d), h.add(d));
        yield a.D.$.init(f, a.h.forceNativeTS, a.h.disableAudioTracks);
        a.f || (XE(a), yield YE(a, h), a.f || f.forEach((k, l) => {
          a.a.has(l) || (k = ZE(k, g, e), a.a.set(l, k), $E(a, k, 0));
        }));
      }
    });
  };
  bF = function (a, b) {
    a = a.j.periods.find(c => c.id === b) || null;
    if (!a) throw new _.G(_.E, 5, 5006, `Period ${b} not found`);
    return a;
  };
  cF = function (a) {
    var b = a.a.get(_.t);
    return b ? bF(a, b.Ob) : (b = a.a.get(_.p)) ? bF(a, b.Ob) : null;
  };
  _.dF = function (a, b) {
    return (a = a.a.get(b)) ? a.Zb || a.stream : null;
  };
  eF = function (a) {
    return _.dF(a, _.p);
  };
  fF = function (a) {
    return _.dF(a, _.t);
  };
  YE = function (a, b) {
    return _.w(function* () {
      const c = [];
      for (const f of b) {
        const g = a.w.get(f.id);
        g ? (a.g.debug("(all) Stream " + f.id + " is being or has been set up"), c.push(g)) : (a.g.debug("(all) setting up Stream " + f.id), a.w.set(f.id, new _.xn()), c.push(f.createSegmentIndex(a.D.Hb)));
      }
      try {
        if (yield Promise.all(c), a.f) return;
      } catch (f) {
        if (a.f) return;
        for (var d of b) {
          var e = a.w.get(d.id);
          e && (e.catch(() => {}), e.reject(), a.w.delete(d.id));
        }
        throw f;
      }
      for (e of b) (d = a.w.get(e.id)) && !d.done && (d.resolve(), a.g.debug("(all) setup Stream " + e.id));
    });
  };
  QE = function (a, b) {
    return (a = TB(a.j.periods, b + _.jt)) ? a.id : null;
  };
  ZE = function (a, b, c) {
    return {
      stream: a,
      type: a.type,
      Xb: null,
      segmentIterator: null,
      na: null,
      Zb: null,
      Dd: !0,
      Ob: b,
      endOfStream: !1,
      qb: !1,
      eb: null,
      Ub: !1,
      od: 0,
      Md: !1,
      dc: !1,
      ye: !1,
      Cc: !1,
      Nf: c || 0,
      Qb: null,
      qd: !1,
      zf: 0
    };
  };
  $E = function (a, b, c) {
    a.g.debug(LE(b), "updating in " + c + " seconds");
    b.eb = new _.zt(() => _.w(function* () {
      try {
        yield a.Pb(b);
      } catch (d) {
        if (a.D) a.D.onError(d);
      }
    })).fa(c);
  };
  gF = function (a, b) {
    return _.w(function* () {
      yield a.D.$.clear(_.F);
      a.G++;
      a.H = !1;
      var c = a.G,
        d = a.D.$;
      const e = new Map(),
        f = new Set();
      e.set(_.F, b);
      f.add(b);
      yield d.init(e, !1, a.h.disableAudioTracks);
      a.f || (yield YE(a, f), a.f || (d = d.getTextDisplayer().isTextVisible() || a.h.alwaysStreamText, a.G !== c || a.a.has(_.F) || a.H || !d || (c = a.D.Lb(), d = QE(a, c), null == d ? a.g.warn("Cannot set up text media state, period not found at", c) : (c = ZE(b, d, 0), a.a.set(_.F, c), $E(a, c, 0)))));
    });
  };
  hF = function (a) {
    a.H = !0;
    const b = a.a.get(_.F);
    b && (OE(b), PE(b).catch(() => {}), a.a.delete(_.F));
  };
  _.pF = function (a, b, c, d) {
    const e = a.a.get(b.type);
    if (!e && b.type === _.F && a.h.ignoreTextStreamFailures) gF(a, b);else if (e) {
      var f = iF(a, b).id;
      if (c && f !== e.Ob) {
        a.g.debug("switch: switching to stream in another Period; clearing buffer and changing Periods");
        for (var g of a.a.values()) jF(a, g);
      } else if (e.Zb && (a.g.debug("switch during trick play mode", b), b.trickModeVideo ? (e.Zb = b, b = b.trickModeVideo, a.g.debug("switch found trick play stream", b)) : (e.Zb = null, a.g.debug("switch found no special trick play stream"))), kF(a, f) && lF(a, b.id)) if (e.stream !== b) {
        g = a.j.presentationTimeline.getDuration();
        var h = a.D.Lb();
        h = mF(a, e, h);
        !c && (e.endOfStream || h >= g) ? a.g.debug(gB + LE(e) + " already buffered to the end. Not switching.") : (b.type === _.F && a.D.$.A(_.gk(b.mimeType, b.codecs)), e.stream = b, e.stream.segmentIterator = null, e.Dd = !0, a.g.debug("switch: switching to Stream " + LE(e)), nF(a, e, f) && (a.g.info("Aborting current segment request to switch."), e.Qb.abort()), c && (e.dc ? e.Md = !0 : e.qb ? (e.Ub = !0, e.od = d, e.Md = !0) : (OE(e), oF(a, e, !0, d).catch(k => {
          if (a.D) a.D.onError(k);
        }))));
      } else a.g.debug(gB + LE(e) + " already active");
    }
  };
  qF = function (a, b, c, d) {
    b.video && _.pF(a, b.video, c, d);
    b.audio && _.pF(a, b.audio, c, d);
  };
  XE = function (a) {
    const b = a.j.presentationTimeline.getDuration();
    Infinity > b ? a.D.$.Y(b) : a.D.$.Y(Math.pow(2, 32));
  };
  rF = function (a, b) {
    const c = a.j.periods.map(d => d.id);
    a.g.debug(`Manifest updated, periods (${c.length}): ` + `${c.join(", ")}`);
    a.j.periods = b.periods;
    NE(a.j);
    XE(a);
  };
  jF = function (a, b) {
    const c = LE(b);
    b.dc ? a.g.debug(c, "clear: already clearing the buffer") : b.Ub ? a.g.debug(c, "clear: already waiting") : b.qb ? (a.g.debug(c, "clear: currently updating"), b.Ub = !0, b.od = 0) : null == a.D.$.Ja(b.type) ? (a.g.debug(c, "clear: nothing buffered"), null == b.eb && $E(a, b, 0)) : (a.g.debug(c, "clear: handling right now"), OE(b), oF(a, b, !1, 0).catch(d => {
      if (a.D) a.D.onError(d);
    }));
  };
  sF = function (a) {
    const b = a.D.Lb(),
      c = g => a.D.$.Fb(g, b, 0);
    let d = !1;
    const e = QE(a, b);
    if (_.ti(a.a.values(), g => g.Ob === e)) for (const g of a.a.keys()) {
      var f = a.a.get(g);
      f.stream.segmentIterator = null;
      c(g) || (jF(a, f), d = !0);
    } else if (!_.ti(a.a.keys(), c)) {
      a.g.debug("(all): seeked: unbuffered seek: clearing all buffers");
      for (f of a.a.values()) jF(a, f);
      d = !0;
    }
    d || a.g.debug("(all): seeked: buffered seek: presentationTime\x3d" + b);
  };
  tF = function (a, b) {
    b = void 0 === b ? !1 : b;
    if (a.f) a.g.error("Unable to retry after StreamingEngine is destroyed!");else if (a.o) a.g.error("Unable to retry after StreamingEngine encountered a fatal error!");else for (const c of a.a.values()) {
      const d = LE(c);
      if (c.Cc || b && ME(c)) a.g.info(d, "Retrying after failure..."), c.Cc = !1, $E(a, c, .1);
    }
  };
  uF = function (a) {
    return Array.from(a.a.values()).some(b => null !== b.eb);
  };
  vF = function (a) {
    const b = Array.from(a.a.values());
    return a.B && b.every(c => c.endOfStream);
  };
  oF = function (a, b, c, d) {
    return _.w(function* () {
      const e = LE(b);
      b.Ub = !1;
      b.Md = !1;
      b.od = 0;
      b.dc = !0;
      b.stream.segmentIterator = null;
      a.g.debug(e, "clearing buffer");
      if (d) {
        var f = a.D.Lb();
        const g = a.D.$.getDuration();
        f = a.D.$.remove(b.type, f + d, g);
      } else f = a.D.$.clear(b.type).then(() => !a.f && c ? a.D.$.flush(b.type) : null);
      yield f;
      a.f || (a.g.debug(e, "cleared buffer"), b.Xb = null, b.na = null, b.dc = !1, b.endOfStream = !1, b.qd = !1, $E(a, b, 0));
    });
  };
  wF = function (a) {
    return _.w(function* () {
      const b = [];
      for (const c of a.a.values()) OE(c), c.qb && c.Qb && (c.qb = !1, b.push(c.Qb.abort())), c.Ub && b.push(oF(a, c, !0, 0));
      yield Promise.all(b);
    });
  };
  xF = function (a, b, c) {
    (a = a.a.get(b)) && c && (OE(a), a.stream = c, a.Xb = c, a.na && (a.na = c.getSegmentReference(a.na.position)), a.na || (a.Xb = null));
  };
  kF = function (a, b) {
    a = a.A.get(b);
    return !(!a || !a.Jd);
  };
  lF = function (a, b) {
    a = a.w.get(b);
    return !(!a || !a.Jd);
  };
  mF = function (a, b, c) {
    return b.Xb && b.na ? iF(a, b.Xb).startTime + b.na.endTime : Math.max(c, b.Nf);
  };
  nF = function (a, b, c) {
    if (!b.Qb) return !1;
    const d = a.D.Lb(),
      e = a.D.$.ga(b.type);
    c = (c = yF(a, b, d, e, c)) ? c.Tc() : null;
    if (null == c) return !1;
    var f = b.stream.initSegmentReference;
    f && (c += f.Tc() || 0);
    f = a.D.td().getBandwidthEstimate();
    if (8 * c / f < (null === e ? 0 : e - d) - Math.max(a.j.minBufferTime || 0, a.h.rebufferingGoal)) return !0;
    a = b.Qb;
    a = a.j ? a.j.bytesRemaining : -1;
    return 0 > a || a > c ? !0 : !1;
  };
  yF = function (a, b, c, d, e) {
    if (null == e) return a.g.warn(eA), null;
    if (b.stream.segmentLookupStrategy === _.UA) b.stream.segmentIterator ? a = sC(b.stream.segmentIterator) : b.na || d ? (c = b.na ? b.na.endTime : d, a.g.info(tA, "looking up segment from new stream endTime:", c), b.stream.segmentIterator = yC(b.stream.segmentIndex, c), e = b.stream.segmentIterator.next().value, null === e && (b.stream.segmentIterator = null, a.g.warn(tA, OA, "endTime:", c, b.na)), a = e) : (e = bF(a, e), e = Math.max(0, c - e.startTime), a.g.info(tA, "looking up segment", "lookupTime:", e, YA, c), d = null, d || (b.stream.segmentIterator = yC(b.stream.segmentIndex, e), d = b.stream.segmentIterator.next().value), null === d && a.g.warn(tA, OA, "lookupTime:", e, YA, c), a = d);else {
      var f = LE(b);
      if (b.na && b.stream === b.Xb) c = b.na.position + 1, a.g.debug(f, "next position known:", "position\x3d" + c), a = zF(a, b, e, c);else {
        if (b.na) {
          a.g.debug(f, "next position unknown: another Stream buffered");
          f = iF(a, b.Xb);
          var g = AF(a, b, f.startTime + b.na.endTime, e);
        } else a.g.debug(f, "next position unknown: nothing buffered"), g = AF(a, b, d || c, e);
        if (null == g) a = null;else if (f = zF(a, b, e, g), null == f) a = null;else {
          var h = null == a.h ? !1 : a.h.ignoreDrift;
          null == d && !h && f.Ba + f.startTime + 2 > c && (a = zF(a, b, e, Math.max(0, g - 1))) && (f = a);
          a = f;
        }
      }
    }
    return a;
  };
  BF = function (a, b) {
    var c = a.A.get(b);
    if (c) return a.g.debug(`(all) Period ${b} is being or has been set up`), c;
    a.g.debug(`(all) setting up Period ${b}`);
    const d = new _.xn();
    a.A.set(b, d);
    const e = new Set();
    c = bF(a, b);
    for (const f of c.variants) f.video && e.add(f.video), f.video && f.video.trickModeVideo && e.add(f.video.trickModeVideo), f.audio && e.add(f.audio);
    for (const f of c.textStreams) e.add(f);
    a.F = a.F.then(() => a.f ? null : YE(a, e)).then(() => {
      a.f || (d.resolve(), a.g.debug(`(all) setup Period ${b}`));
    }).catch(f => {
      a.f || (d.catch(() => {}), d.reject(), a.A.delete(b), a.g.warn(`(all) failed to setup Period ${b}`), a.D.onError(f));
    });
    return d;
  };
  CF = function (a) {
    a.g.debug("(all) setupAllPeriods_");
    a.j.periods.map(b => b.id).forEach(b => {
      BF(a, b).catch(vB);
    });
  };
  FF = function (a, b) {
    if (b.type === _.F && b.stream.mimeType === _.zc) return a.D.$.Pa(b.stream.originalId || ""), null;
    b.type === _.F && a.D.$.ba();
    if (!a.D.$.Qa() && b.type !== _.F) return a.h.bufferLimitUpdateInterval ? a.h.bufferLimitUpdateInterval / 2 : .5;
    var c = LE(b);
    const d = a.D.Lb(),
      e = mF(a, b, d);
    a.g.debug(c, "timeNeeded\x3d" + e);
    var f = a.D.$.xa(b.type, d);
    a.g.debug(c, "update_:", ZA + d, "bufferedAhead\x3d" + f);
    var g = Math.max(a.j.minBufferTime || 0, a.h.rebufferingGoal, a.h.bufferingGoal) * a.l,
      h = fC(a.j.presentationTimeline);
    if (null != h && e >= h && !b.qd) return DF(a, b), null;
    b.endOfStream = !1;
    h = iF(a, b.stream).id;
    const k = QE(a, e);
    null != k && (b.Ob = k);
    if (k !== h) return a.g.debug(c, "need Period " + k, ZA + d, "timeNeeded\x3d" + e, "currentPeriodId\x3d" + h), null;
    if (f >= g) return a.g.debug(c, "buffering goal met"), a.h.bufferLimitUpdateInterval || .5;
    f = a.D.$.ga(b.type);
    f = yF(a, b, d, f, h);
    if (!f) return a.j.presentationTimeline.isLive() || b.type === _.F ? a.h.bufferLimitUpdateInterval || 1 : (a.g.debug("Media state " + c + ": no next segment found. Assuming EOS."), DF(a, b), null);
    c = Infinity;
    for (const l of a.a.values()) if (l.type !== _.F || l.stream.mimeType !== _.zc) g = mF(a, l, d), c = Math.min(c, g);
    if (e >= c + a.j.presentationTimeline.h) return a.h.bufferLimitUpdateInterval || 1;
    b.Nf = 0;
    EF(a, b, d, h, f).catch(() => {});
    return null;
  };
  GF = function (a, b) {
    return _.w(function* () {
      if (!a.f) {
        if (a.D.Of) try {
          yield a.D.Of(b);
        } catch (c) {
          c.cause = b, b = c;
        }
        if (!b.f) a.D.onError(b);
      }
    });
  };
  HF = function (a, b) {
    const c = LE(b),
      d = iF(a, b.stream).id;
    if (b.Ob !== d) {
      var e = b.Ob,
        f = Array.from(a.a.values());
      f.every(g => g.Ob === e) ? f.every(ME) ? (a.g.debug(c, "all need Period " + e), BF(a, e).then(() => {
        if (!a.f) if (a.D.$.Ra(), f.every(l => {
          const m = ME(l),
            n = iF(a, l.stream).id;
          return m && l.Ob === e && n !== e;
        })) {
          var g = bF(a, e);
          a.g.debug(c, "Streaming period", e, g);
          a.g.debug(c, "calling onChooseStreams()...");
          var h = a.D.me(g);
          a.C = !h.variant && !!h.text;
          var k = new Map();
          h.variant && h.variant.video && k.set(_.t, h.variant.video);
          h.variant && h.variant.audio && k.set(_.p, h.variant.audio);
          h.text && k.set(_.F, h.text);
          for (const l of a.a.keys()) if (!k.has(l) && l !== _.F) {
            a.g.error(c, "invalid Streams chosen: missing " + l + " Stream");
            a.D.onError(new _.G(_.E, 5, 5005));
            return;
          }
          for (const l of Array.from(k.keys())) if (!a.a.has(l)) if (l === _.F) aF(a, null, null, k.get(_.F), g.startTime), k.delete(l);else {
            a.g.error(c, "invalid Streams chosen: unusable " + l + " Stream");
            a.D.onError(new _.G(_.E, 5, 5005));
            return;
          }
          for (const l of Array.from(a.a.keys())) (g = k.get(l)) ? (_.pF(a, g, !1, 0), $E(a, a.a.get(l), 0)) : a.a.delete(l);
          a.g.debug(c, NA);
          a.D.le();
        } else a.g.debug(c, "ignoring transition to Period", e, "since another is happening");
      }).catch(vB)) : a.g.debug(c, "all MediaStates need Period " + e + ", but not all MediaStates are idle") : a.g.debug(c, "not all MediaStates need Period " + e);
    }
  };
  DF = function (a, b) {
    a.g.debug(LE(b), "buffered to end of presentation");
    b.endOfStream = !0;
    b.type === _.t && (a = a.a.get(_.F)) && a.stream.mimeType === _.zc && (a.endOfStream = !0);
  };
  EF = function (a, b, c, d, e) {
    return _.w(function* () {
      const f = LE(b),
        g = bF(a, d);
      a.g.debug(f, "fetchAndAppend_:", ZA + c, RA + g.startTime, aB + e.position, bB + e.startTime, $A + e.endTime);
      const h = b.stream,
        k = b.stream.segmentIterator;
      var l = a.j.presentationTimeline.getDuration();
      const m = IF(a, d);
      l = JF(a, b, g, Math.max(0, g.startTime - .1), m ? m.startTime + .01 : l, e);
      b.qb = !0;
      b.Dd = !1;
      a.g.debug(f, "fetching segment");
      let n = !1;
      const q = (v, y) => {
          if (a.f || a.o) return null;
          n = !0;
          return KF(a, b, c, g, h, e, v, y);
        },
        u = a.D.$.ga(b.type) || 0;
      l.then(() => a.f || a.o ? null : LF(a, b, e, !1, v => q(v.data, !0))).then(v => a.f || a.o ? null : n || null === v ? (MF(a, b, u, e, h), null) : q(v, !1)).then(() => {
        if (!a.f && !a.o) {
          MF(a, b, u, e, h);
          b.qb = !1;
          b.ye = !1;
          b.Ub || a.D.Fd();
          if (k) {
            const v = k.next().value;
            v && a.g.debug("New iterated segment", v);
          }
          $E(a, b, 0);
          NF(a, b, h);
          a.g.debug(f, "finished fetch and append");
        }
      }).catch(v => _.w(function* () {
        a.f || a.o || (b.qb = !1, b.type === _.F && a.h.ignoreTextStreamFailures ? (1001 === v.code ? a.g.warn(f, "Text stream failed to download. Proceeding without it.") : a.g.warn(f, "Text stream failed to parse. Proceeding without it."), a.a.delete(_.F)) : 7001 === v.code ? (b.qb = !1, b.eb = null, $E(a, b, 0)) : 3017 === v.code ? OF(a, b, v) : 1001 === v.code && v.data && 404 === v.data[1] ? (a.g.warn(f, "request for segment failed", "segment not available on server", aB + e.position, bB + e.startTime, $A + e.endTime, "error\x3d" + v), b.qb = !1, b.eb = null, $E(a, b, 1)) : b.type === _.F ? (v instanceof _.G && v.a(1), OE(b), a.D.onError(new _.G(1, 2, 2010, v))) : 1001 <= v.code && 1003 >= v.code ? yield PF(a, b, g, v) : (a.g.error(f, "failed fetch and append: code\x3d" + v.code), b.Cc = !0, v instanceof _.G && v.a(_.E), yield GF(a, v)));
      }));
    });
  };
  iF = function (a, b) {
    a = a.j.periods;
    const c = VB(a, b);
    a = a[c];
    if (!a) throw new _.G(_.E, 5, 5006, `No period found for stream with ID ${b.id}.`);
    return a;
  };
  zF = function (a, b, c, d) {
    const e = LE(b);
    if (null == c) return a.g.warn(e, eA), null;
    c = bF(a, c);
    const f = b.stream.getSegmentReference(d);
    if (!f) return a.g.debug(e, "segment does not exist:", RA + c.startTime, "position\x3d" + d), null;
    var g = a.j.presentationTimeline;
    d = _.lC(g);
    g = kC(g);
    return !b.qd && (c.startTime + f.endTime < d || c.startTime + f.startTime > g) ? (a.g.debug(e, "segment is not available:", RA + c.startTime, bB + f.startTime, $A + f.endTime, "availabilityStart\x3d" + d, "availabilityEnd\x3d" + g), null) : f;
  };
  AF = function (a, b, c, d) {
    const e = LE(b);
    d = bF(a, d);
    const f = Math.max(0, c - d.startTime);
    b = b.stream.findSegmentPosition(f);
    a.g.debug(e, "Looked up segment:", ZA + c, "lookupTime\x3d" + f, RA + d.startTime, "Result: segment position\x3d" + b);
    null == b && a.g.warn(e, "cannot find segment:", RA + d.startTime, "lookupTime\x3d" + f);
    return b;
  };
  IF = function (a, b) {
    const c = a.j.periods.findIndex(d => d.id === b);
    return 0 > c ? null : a.j.periods[c + 1] || null;
  };
  JF = function (a, b, c, d, e, f) {
    return _.w(function* () {
      var g = c.startTime - f.Ba,
        h = b.Dd || f.initSegment !== (b.na && b.na.initSegment);
      if (!h && g === b.zf) return Promise.resolve();
      b.zf = g;
      const k = LE(b);
      a.g.debug(k, "setting timestamp offset to " + g);
      a.g.debug(k, "setting append window start to " + d);
      a.g.debug(k, "setting append window end to " + e);
      g = a.D.$.Sa(b.type, g, d, e, b.stream.mimeType, b.stream.codecs);
      if (!b.stream.initSegmentReference || !h) return g;
      a.g.debug(k, "fetching init segment");
      h = LF(a, b, f && f.initSegment || b.stream.initSegmentReference, !0).then(l => {
        if (a.f) return null;
        a.g.debug(k, "appending init segment");
        return QF(a, l, b).then(() => a.D.$.L(b.type, l, null, null, !!b.stream.closedCaptions && 0 < b.stream.closedCaptions.size));
      }).catch(l => {
        b.Dd = !0;
        return Promise.reject(l);
      });
      return Promise.all([g, h]);
    });
  };
  KF = function (a, b, c, d, e, f, g, h) {
    h = void 0 === h ? !1 : h;
    return _.w(function* () {
      const k = LE(b);
      if (e && e.keyId && a.D.Pc) {
        var l = nB(a.D.Pc, e.keyId);
        if (l) try {
          yield l;
        } catch (n) {
          a.g.warn("DRM session load failed!", n);
        } else a.g.warn(`Unable to find DRM session for ${e.keyId}`);
      }
      if (e.encrypted && e.keyId && a.D.Pc && pB(a.D.Pc, e.keyId)) return OE(b), l = iF(a, e).id, (l = RF(a, l)) && (yield l), l = a.D.Qd(e, d, VA), a.g.warn(k, `Blocked append for ${f.startTime + d.startTime} - ${f.endTime + d.startTime}: ${l}. ` + `Stream with Key ID ${e.keyId} has restrictions.`), Promise.resolve();
      const m = e.closedCaptions && 0 < e.closedCaptions.size;
      null != e.emsgSchemeIdUris && 0 < e.emsgSchemeIdUris.length && new _.em().ca("emsg", a.I.bind(a, d, f, e.emsgSchemeIdUris)).parse(g);
      return SF(a, b, c).then(() => {
        if (a.f) return null;
        const n = f.startTime + d.startTime,
          q = f.endTime + d.startTime;
        a.g.debug(k, "Appending segment " + `with ${n.toFixed(3)} - ${q.toFixed(3)} ` + `KeyID: ${e.keyId} ` + `Width: ${e.width} Height: ${e.height} ` + `Bitrate: ${_.Pi(e.bandwidth)} ` + `Fragment: ${h}`);
        return a.D.$.L(b.type, g, n, q, m, e.id, h);
      }).then(() => {
        a.f || (a.g.debug(k, "appended media segment"), b.Xb = e, b.na = f);
      });
    });
  };
  LF = function (a, b, c, d, e) {
    d = void 0 === d ? !1 : d;
    e = void 0 === e ? null : e;
    const f = _.$B(c.Wa(), c.wa, c.ka, a.h.attemptParameters, c.startTime, c.endTime, b.type, c.timescale);
    f.type = 2;
    f.onFragment = c.wc ? null : e;
    f.disableFragmentSupport = c.wc || a.h.disableFragmentSupport;
    f.disableMergedFragments = a.h.disableMergedFragments;
    if (e = c.f()) f.keyInfo = e, f.onFragment = null;
    d && (f.responseModifiers.push(g => {
      TF(a, b, g.data);
    }), b.type === _.t && a.h.overridePasp && f.responseModifiers.push(a.J.bind(a)));
    a.g.debug("fetching: reference\x3d", c);
    c = a.D.Hb.fetch(f);
    b.Qb = c;
    return c.W.then(g => {
      const h = a.D.td(),
        k = g.timeMs,
        l = g.data.byteLength;
      k && l && !d && h.segmentDownloaded(k, l);
      b.Qb = null;
      return g.data;
    });
  };
  MF = function (a, b, c, d, e) {
    if (!a.j.presentationTimeline.isLive() && b.type !== _.F) {
      var f = LE(b);
      const g = (a.D.$.ga(b.type) || 0) - c,
        h = d.endTime - d.startTime;
      c = g - h;
      if (Math.abs(c) >= Math.min(.01, h) && Math.abs(c) <= Math.min(1, h)) for (a.g.debug(f, "Adjusted segment", d.position, "with real duration", g, "and accounted for", c, "delta between segment duration and real duration"), b.qd = !0, a = a.j.periods, b = c, e.adjustSegmentDurations(d.position, b), d = VB(a, e), f = a[d], e = e.getDuration(), XB(f, e), e = d + 1; e < a.length; e++) a[e].startTime += b;
    }
  };
  NF = function (a, b, c) {
    if (!a.B) {
      b = LE(b);
      var d = Array.from(a.a.values());
      1 !== d.length || d[0].type !== _.F ? a.B = d.every(e => e.type === _.F || a.C ? !0 : !e.Ub && !e.dc && e.na) : a.C && (a.B = !0);
      a.B && (a.g.debug(b, "startup complete"), c = iF(a, c).id, IF(a, c), RF(a, c));
    }
  };
  OF = function (a, b, c) {
    const d = LE(b);
    if (Array.from(a.a.values()).some(e => e !== b && e.ye)) a.g.debug(d, vA, "waiting for another stream to recover...");else {
      const e = Math.round(100 * a.l);
      if (20 < e) a.l -= .2;else if (4 < e) a.l -= .04;else {
        a.g.error(d, "MediaSource threw QuotaExceededError too many times");
        b.Cc = !0;
        a.o = !0;
        a.D.onError(c);
        return;
      }
      a.g.warn(d, vA, "reducing buffering goals by " + (100 - Math.round(100 * a.l)) + "%");
      b.ye = !0;
    }
    $E(a, b, 4);
  };
  PF = function (a, b, c, d) {
    return _.w(function* () {
      let e = !0;
      1001 === d.code && 500 <= d.data.status || (e = !a.D.Qd(b.stream, c));
      e && (b.Cc = !0, d.a(_.E), yield GF(a, d));
    });
  };
  QF = function (a, b, c) {
    return _.w(function* () {
      if (a.h.patchEdgeWhenMixedContent && _.ok()) {
        const d = IE(b);
        yield a.D.$.L(c.type, d.buffer, null, null, !!c.stream.closedCaptions && 0 < c.stream.closedCaptions.size);
      }
    });
  };
  RF = function (a, b) {
    let c = null;
    a.A.has(b) || (c = BF(a, b).then(() => {
      a.f || (a.g.debug(NA), a.D.le());
    }).catch(vB));
    CF(a);
    a.D.gh && (a.g.debug("calling onStartupComplete()..."), a.D.gh());
    return c;
  };
  SF = function (a, b, c) {
    if (a.f) return Promise.resolve();
    const d = LE(b);
    a.g.debug(d, "checking buffer length");
    const e = Math.max(a.h.bufferBehind, a.j.presentationTimeline.h),
      f = a.D.$.Ja(b.type);
    if (null == f) return a.g.debug(d, "buffer behind okay because nothing buffered:", ZA + c, LA + e), Promise.resolve();
    const g = c - f,
      h = g - e;
    if (.01 >= h) return a.g.debug(d, "buffer behind okay:", ZA + c, MA + g, LA + e, "underflow\x3d" + Math.abs(h)), Promise.resolve();
    a.g.debug(d, "buffer behind too large:", ZA + c, MA + g, LA + e, "overflow\x3d" + h);
    return a.D.$.remove(b.type, f, f + h).then(() => {
      a.f || a.g.debug(d, "evicted " + h + " seconds");
    });
  };
  TF = function (a, b, c) {
    new _.em().N(_.ze, _.Yl).N(_.Xf, _.Yl).N(_.ue, _.Yl).N(_.ye, _.Yl).N(_.Of, _.Yl).ca(_.Qf, _.Zl).N("encv", d => {
      d.reader.skip(78);
      _.Yl(d);
    }).N(_.If, _.Yl).N("schi", _.Yl).N("tenc", d => {
      d.reader.skip(6);
      if (_.Ql(d.reader)) {
        d.reader.skip(1);
        const e = _.Wi(_.Vl(d.reader, 16));
        e !== b.stream.keyId && (a.g.debug(LE(b), "Key id from tenc mismatches with key id from manifest. Switching from " + `${b.stream.keyId} to ${e}.`), b.stream.keyId = e);
      }
      d.parser.stop();
    }).parse(c);
  };
  UF = function (a) {
    const b = c => c.video && c.audio || c.video && c.video.codecs.includes(",");
    if (a.some(c => c.variants.some(b))) {
      _.ih("Found variant with audio and video content, so filtering out audio-only content in all periods.");
      for (const c of a) c.variants = c.variants.filter(b);
    }
  };
  sD = function (a, b) {
    return _.w(function* () {
      if (a.we !== b && (a.we = b, a.F === VF && (a.K.getTextDisplayer().setTextVisibility(b), !a.configuration.streaming.alwaysStreamText))) if (b) {
        var c = WF(a);
        c = _.bE(c.textStreams, a.cc, a.Dc, a.bc);
        0 < c.length && (yield gF(a.a, c[0]));
      } else hF(a.a);
    });
  };
  tD = function (a, b) {
    if (a.f && a.a) {
      const c = WF(a),
        d = c.textStreams.find(e => e.id === b.id);
      d ? (XF(a, c, d, !1), YF(a, d), a.cc = d.language, a.bc = d.kind === _.Wd) : a.log.error(_.xA, b.id);
    }
  };
  uD = function (a, b) {
    if (a.configuration.streaming.disableAudioTracks) Promise.reject(Error("Audio tracks are disabled by config."));else {
      if (a.a && (a.f || a.B)) {
        var c = fF(a.a),
          d = eF(a.a),
          e = TB(a.f.periods, OC(a.B)),
          f = a.f.periods[e ? a.f.periods.indexOf(e) : 0].variants.filter(h => h.audio.id === b && $D(h));
        e = !1;
        var g = void 0;
        if (c) {
          if (g = f.find(h => h.video.id === c.id), !g) {
            a.log.debug("Cannot find exact variant, looking for anything else that matches with given audioId");
            e = !0;
            const h = a.getTrackManager().Ma();
            if (h) {
              const k = h.renditions.map(l => l.id);
              g = f.find(l => k.find(m => m === String(l.video.id)));
            }
          }
        } else g = f[0];
        if (g) {
          if (d.codecs.split(".")[0] === g.audio.codecs.split(".")[0]) {
            d = g.audio;
            a.Ra = d.id;
            f = f.map(lD);
            a.h.setTracks(f);
            f = WF(a);
            ZF(a, f, g, !1);
            e || a.V ? $F(a, g, !0, 0) : _.pF(a.a, d, !0, 0);
            Promise.resolve();
            return;
          }
          a.log.warn("Target audio codecs not compatible with active audio codecs.");
        }
      }
      Promise.reject();
    }
  };
  vD = function (a) {
    if (a.f && a.B) {
      const b = aG(a),
        c = [];
      for (const d of bG(a)) a = YD(d), a.active = d === b, c.push(a);
      return c;
    }
    return [];
  };
  wD = function (a, b, c) {
    var d = void 0 === d ? 0 : d;
    if (a.f && a.a) {
      const f = WF(a);
      if (a.configuration.abr.enabled && b) a.log.info("Disable ABR and enable manual track selection to " + `select ${b.width}x${b.height}@${b.bandwidth}`), a.configuration.abr.enabled = !1, a.h.disable();else if (!a.configuration.abr.enabled && !b) {
        a.log.info("Enable ABR Selection");
        a.configuration.abr.enabled = !0;
        a.h.enable();
        a.R = null;
        c = cG(a, f.variants);
        null != a.ba && (a.Qa = a.ba, a.ba = null);
        null == c || a.V || dG(a, c);
        return;
      }
      if (b) {
        a.R = b.id;
        var e = f.variants.find(g => g.id === b.id);
        e ? (a.R = e.video.id, $D(e) ? (ZF(a, f, e, !1), $F(a, e, c, d), null == a.ba && (a.ba = a.Qa), a.Qa = new eG(e), cG(a, f.variants)) : a.log.error("Unable to switch to restricted track", b.id)) : a.log.error("No variant with id", b.id);
      } else a.R = null, null != a.ba && (a.Qa = a.ba, a.ba = null), c = cG(a, f.variants), null == c || a.V || dG(a, c);
    }
  };
  xD = function (a) {
    const b = a.we;
    return a.f ? a.K.getTextDisplayer().isTextVisible() : b;
  };
  fG = function (a, b) {
    a.j || (a.o.on(b, _.Ud, () => {
      if (a.j.error) {
        var c = a.j.error.code;
        if (1 === c) c = null;else {
          var d = a.j.error.msExtendedCode;
          d && (0 > d && (d += Math.pow(2, 32)), d = d.toString(16));
          c = new _.G(_.E, 3, 3016, {
            code: c,
            extendedError: d,
            message: a.j.error.message
          });
        }
      } else c = null;
      if (c) a.onError(c);
    }), a.j = b);
  };
  hG = function (a) {
    return new _.gG(b => {
      const c = a.getTrackManager(),
        d = [];
      if (c) for (const e of c.getVideoTracks()) d.push(...e.renditions);
      return d.find(e => e.id === b.toString()) || null;
    });
  };
  iG = function (a, b) {
    if (a.ia !== b) if (a.ia === _.oo && b !== _.po) b = CB(b, _.no), a.log.info(_.yA + `${b}`);else {
      var c = a.Ae;
      c = 0 === c ? 0 : Date.now() - c;
      var d = a.ia;
      a.ia = b;
      a.Ae = Date.now();
      null !== a.H && (a.H.A = b === _.ro);
      a.G && _.Yn(a.G.a, a.ia);
      b === _.ko && (a.Rf = a.re ? 1 : 2);
      var e = a.Rf,
        f = {
          currentState: a.ia,
          previousState: d,
          timeSinceLastStateChangeMS: c,
          reason: b === _.ko || d === _.ko ? e : void 0
        },
        g = CB(d, _.no),
        h = CB(b, _.no),
        k = b !== _.ko ? "" : `[${CB(e, _.mo)}]`;
      a.log.info(`Player state change: ${g} -> ` + `${h}${k}`);
      a.trigger(new _.C(_.Mf, {
        detail: f
      }));
      (b === _.ho || b === _.oo) && a.Pa && a.Pa.stop();
      b === _.ko ? a.trigger(new _.C(_.Sc, {
        detail: {
          bufferedTimeMS: -1,
          reason: e
        }
      })) : d === _.ko && a.trigger(new _.C(_.Rc, {
        detail: {
          bufferedTimeMS: c,
          reason: e
        }
      }));
    }
  };
  nG = function (a) {
    return _.w(function* () {
      jG(a);
      yield kG(a);
      lG(a);
      if (_.qk(4) || _.qk(5)) {
        const b = a.getSurface().getMedia();
        fG(a, b);
        yield mG(a, b);
      }
      a.configuration = a.da;
    });
  };
  oG = function (a) {
    return _.w(function* () {
      const b = a.l.a;
      a.l.a = null;
      b && (yield b.catch(() => {}));
      yield Object.getPrototypeOf(Object.getPrototypeOf(a)).release.call(a);
      yield a.unload();
      iG(a, _.po);
    });
  };
  lG = function (a) {
    a.j && (a.o.off(a.j, _.Ud), a.j = null);
  };
  jG = function (a) {
    if (a.F === pG) throw new _.G(_.E, 7, 7E3, "Player is destroyed.");
  };
  qG = function (a) {
    return a.Y ? a.Y.catch(() => {}) : Promise.resolve();
  };
  rG = function (a) {
    const b = new _.Mu();
    (a = a.playlist) && a.cropTimeline && (a.startTime || a.endTime) && (b.h = {
      start: a.startTime || null,
      end: a.endTime || null
    });
    return b;
  };
  vG = function (a, b) {
    return _.w(function* () {
      a.G = new _.Wt();
      iG(a, _.qo);
      const c = sG(a, a.configuration);
      yield mG(a, c);
      eE(a.l, b);
      a.J = new tG();
      oC(a.J, d => {
        a.log.info("Timeline cue found", d);
        uG(a, _.Ph, d);
      }, () => {
        a.log.info("Timeline cues changed");
        a.trigger(new _.C(_.Fh));
      });
    });
  };
  yG = function (a, b, c, d, e) {
    return _.w(function* () {
      var f = _.vo(b)[c];
      const g = yield wG(a, f);
      eE(a.l, d);
      _.$m(WA);
      f = yield xG(a, g, f, b.id || "");
      eE(a.l, d);
      e && f.presentationTimeline.l && (yield g.stop(), eE(a.l, d));
      _.$m("manifest.end");
      _.an("Fetch and parse manifest", WA, "manifest.end");
      return {
        parser: g,
        manifest: f
      };
    });
  };
  CG = function (a, b, c) {
    return _.w(function* () {
      _.$m("initDrm.start");
      const d = new _.Bo({
        Hb: a.getNetworkEngine(),
        onError: h => a.onError(h),
        pe: h => {
          if (a.a) {
            var k = WF(a),
              l = !1,
              m = Object.keys(h);
            0 === m.length && a.log.warn("Got a key status event without any key statuses, so we don't know the real key statuses. If we don't have all the keys, you'll need to set restrictions so we don't select those tracks.");
            var n = 1 === m.length && "00" === m[0];
            n && a.log.warn("Got a synthetic key status event, so we don't know the real key statuses. If we don't have all the keys, you'll need to set restrictions so we don't select those tracks.");
            if (m.length) {
              m = a.getPeriods();
              for (let A = 0; A < m.length; A++) {
                const D = m[A];
                for (var q of D.variants) {
                  var u = q;
                  var v = [];
                  u.audio && v.push(u.audio);
                  u.video && v.push(u.video);
                  u = v;
                  for (var y of u) u = q.allowedByKeySystem, y.keyId && (v = h[n ? "00" : y.keyId], v = !!v && !zG.includes(v), q.allowedByKeySystem = v), u !== q.allowedByKeySystem && (v = y.type, y.type === _.t && (v = `video ${y.width}x${y.height}@` + `${_.Pi(y.bandwidth)}`), a.log.warn("DRM Key not available for " + `KID: '${y.keyId}'. Applying restrictions to ${v}`)), D === k && u !== q.allowedByKeySystem && (l = !0);
                }
              }
            }
            if (h = cF(a.a)) {
              q = eF(a.a);
              y = fF(a.a);
              a: {
                n = h.variants;
                for (m = 0; m < n.length; m++) if (n[m].audio === q && n[m].video === y) {
                  n = n[m];
                  break a;
                }
                n = null;
              }
              if (!n || n.allowedByKeySystem) a.log.info(fA), AG(a), cG(a, h.variants);
            }
            l && h !== k && (a.log.info(fA), AG(a), cG(a, k.variants));
          }
        },
        oe: (h, k) => {
          a.trigger(new _.C(_.Kd, {
            expirationTime: k
          }));
        },
        onEvent: h => a.xe(h),
        getConfiguration: () => a.getConfiguration(),
        Ac: () => a.Ac()
      });
      var e = _.Jl(c);
      d.h = e;
      e = c ? c.offlineId || "" : "";
      let f = [];
      const g = _.as(b.periods);
      if (e) {
        const h = _.sl(_.Oe);
        h && (yield h.l(a, g), f = yield h.j(e));
      }
      yield _.wn(d, g, f);
      a.w = d;
      BG(a, b.periods);
      _.$m("initDrm.end");
      _.an("Init DRM", "initDrm.start", "initDrm.end");
      return d;
    });
  };
  PG = function (a, b) {
    return _.w(function* () {
      const c = a.j;
      DG(a, c);
      EG(a, a.f.periods);
      a.Qa = new FG(a.configuration.preferredAudioLanguage || [], a.configuration.preferredAudioRole, a.configuration.preferredAudioChannelCount, a.configuration.preferSpatialAudio, a.configuration.mediaSource.codecSwitchingStrategy);
      a.cc = a.configuration.preferredTextLanguage;
      a.Dc = a.configuration.preferredTextRole;
      a.bc = a.configuration.preferForcedSubtitles;
      yield _.Mn(a.w, c);
      eE(a.l, b);
      a.h.configure(a.configuration.abr);
      a.ge && a.h.setCmsdManager(a.ge);
      a.h.init((f, g, h) => {
        if (!a.V) {
          var k = WF(a);
          (f = mD(f, k.variants)) ? dG(a, f, g, h) : a.log.warn("No matching variant found for track switch");
        }
      });
      a.B = GG(a, c, a.configuration.startTime);
      a.Sa = HG(a);
      a.H = new IG({
        pf: () => c.playbackRate,
        zh: f => {
          c.playbackRate = f;
        },
        dh: f => {
          c.currentTime += f;
        }
      });
      DE(a.H, a.configuration.trickPlay);
      a.isLive() && a.configuration.streaming.enableLiveEdgeChasing && (a.yd = new JG(a));
      KG(a, Math.max(1E-4, a.configuration.streaming.rebufferingGoal));
      LG(a) ? SD(a.f, _.Qh(a.configuration.preferredVideoCodec), _.Qh(a.configuration.preferredAudioCodec), a.configuration.preferredAudioChannelCount) : RD(a.f, _.Qh(a.configuration.preferredVideoCodec), _.Qh(a.configuration.preferredAudioCodec), a.configuration.preferredAudioChannelCount);
      var d = MG(a);
      d.h = a.configuration.streaming;
      a.a = d;
      a.F = VF;
      yield d.start();
      eE(a.l, b);
      a.configuration.streaming.startAtSegmentBoundary && (d = NG(a, OC(a.B)), HC(a.B.f, d));
      for (var e of a.f.periods) OG(a, e);
      AG(a);
      e = WF(a) || a.f.periods[0];
      d = e.variants.some(f => f.primary);
      a.configuration.preferredAudioLanguage || d || a.log.warn("No preferred audio language set.  We will choose an arbitrary language initially");
      cG(a, e.variants);
    });
  };
  QG = function (a, b) {
    var c = a.getMediaPosition(),
      d = a.ec,
      e = b.periods,
      f = d.a;
    d.a = [];
    var g = null,
      h = null;
    for (var k of e) e = k.endTime - k.startTime, k.configurationId === g ? h.a += e : (g = k.configurationId || "", h = new _.Ku(g || "", k.startTime, e, !1), d.a.push(h));
    d.h && (h = d.h.start, g = d.h.end, k = d.a[0], null != h && k && h >= k.f && h <= k.f + k.a && (e = h - k.f, e < k.a && (k.f = h, k.a -= e)), h = d.a[d.a.length - 1], null != g && h && g >= h.f && g <= h.f + h.a && (h.a = g - h.f));
    tB(d, c);
    a: if (c = f, d = d.a, c.length !== d.length) d = !1;else {
      for (f = 0; f < c.length; f++) if (!c[f].yc(d[f])) {
        d = !1;
        break a;
      }
      d = !0;
    }
    !d && a.trigger(new _.C(_.Cf));
    rC(a.Bd);
    pC(a.J, b.cues);
  };
  SG = function (a, b, c) {
    b = RG(a, b, c);
    c.catch(() => {});
    c.reject(b);
    a.onError(b);
    throw b;
  };
  TG = function (a, b) {
    return _.w(function* () {
      const c = yield yG(a, b, 0, null, !0);
      try {
        const d = [a.f, gD(c.manifest)],
          e = jD(d, a.f.presentationTimeline);
        return {
          parser: c.parser,
          manifest: e
        };
      } catch (d) {
        throw new _.G(_.E, 7, 7003, "Failed to append load manifest");
      }
    });
  };
  UG = function (a, b) {
    a.configuration.ads.hls.mockCuesFn ? (b = a.configuration.ads.hls.mockCuesFn(a), pC(a.J, b)) : pC(a.J, b);
  };
  VG = function (a, b) {
    return _.w(function* () {
      if (a.S === _.la) UG(a, b.cues);else if (a.S === _.sb) {
        a.f = kD(a.f, gD(b));
        const c = a.configuration.playlist.endTime,
          d = a.f.presentationTimeline.a.a;
        c && d > c && (a.log.info("Reached end of playlist. Turning timeline static."), a.A.requestStop(), a.A = null, _.eC(a.f.presentationTimeline, !0), a.trigger(new _.C(_.ke)));
        QG(a, a.f);
      } else rF(a.a, b);
    });
  };
  WG = function (a) {
    return _.w(function* () {
      a.A.requestStop();
      a.A = null;
      a.S === _.sb ? a.trigger(new _.C(_.Bf)) : a.trigger(new _.C(_.ke));
    });
  };
  kG = function (a) {
    return _.w(function* () {
      a.F !== pG && (a.F = XG);
      const b = a.j;
      b && (a.o.off(b, _.pe), a.o.off(b, _.bf), a.o.off(b, _.Ne), a.o.off(b, _.Td), a.o.off(b, _.sf), a.o.off(b, _.qf));
      a.J && (a.J.release(), a.J = null);
      a.Sa && (a.Sa.release(), a.Sa = null);
      a.Pa && (a.Pa.stop(), a.Pa = null);
      a.A && (yield a.A.stop(), a.A = null);
      a.h && (yield a.h.stop());
      a.a && (yield a.a.destroy(), a.a = null);
      a.B && (a.B.release(), a.B = null);
      a.H && (a.H.release(), a.H = null);
      a.K && (yield a.K.destroy(), a.K = null);
      a.wb && (a.wb.release(), a.wb = null);
      a.yd && (a.yd.release(), a.yd = null);
      b && b.src && (b.removeAttribute("src"), b.load());
      a.w && (yield a.w.destroy(), a.w = null);
      a.M.clear();
      a.I = null;
      a.zd.clear();
      a.f = null;
      a.G = null;
      a.V = !0;
      a.Ha.clear();
      a.fc = !1;
      YG(a);
    });
  };
  mG = function (a, b) {
    return _.w(function* () {
      const c = window.muxjs ? new ZG() : new _.$G(),
        d = _.Xn(a.configuration, [_.ae, "single-native"]).create(a);
      a.O = d;
      const e = hG(a);
      e.track(a);
      a.wb = e;
      let f = null;
      LG(a) ? f = new aH(b, c, d, e, a.configuration) : f = new _.bH(b, c, d, e, a.configuration);
      yield f.open();
      a.K = f;
    });
  };
  cH = function (a, b, c) {
    c = void 0 === c ? !0 : c;
    return _.w(function* () {
      const d = Date.now() / 1E3,
        e = new _.xn();
      a.l.a = e;
      try {
        jG(a);
        yield qG(a);
        eE(a.l, e);
        a.ea = _.vo(b);
        a.configuration = _.pl(a.da, b);
        a.log.info(_.db, a.configuration);
        a.log.info(_.xb, a.ea);
        yield vG(a, e);
        eE(a.l, e);
        const f = yield yG(a, b, a.getSourceIndex(), e, !1);
        eE(a.l, e);
        a.A = f.parser;
        a.f = f.manifest;
        UG(a, f.manifest.cues);
        a.w = yield CG(a, a.f, a.configuration.drm);
        eE(a.l, e);
        c && (yield PG(a, e), eE(a.l, e), a.o.one(a.j, _.pe, () => {
          const g = Date.now() / 1E3 - d;
          a.G && (a.G.A = g);
        }));
        e.resolve();
      } catch (f) {
        SG(a, f, e);
      }
    });
  };
  RG = function (a, b, c) {
    var d = a.l;
    return null != d.a && d.a === c || b instanceof _.G && 7E3 === b.code ? b instanceof _.G ? b : new _.G(_.E, 7, 7003, "Unexpected error.", b) : dE(a.l);
  };
  sG = function (a, b) {
    const c = a.getSurface().getMedia();
    c.loop = !!b.loop;
    _.z(b.volume) || a.setVolume(b.volume);
    _.z(b.muted) || a.setMuted(b.muted);
    fG(a, c);
    return c;
  };
  uG = function (a, b, c) {
    a.trigger(new _.C(b, {
      detail: nC(c, dH(a))
    }));
  };
  wG = function (a, b) {
    return _.w(function* () {
      var c = a.getNetworkEngine();
      c = yield _.cs(b.url, c, a.configuration.manifest.attemptParameters, b.type || null);
      c.configure(a.configuration.manifest);
      return c;
    });
  };
  xG = function (a, b, c, d) {
    return new _.Eo(Promise.resolve().then(() => _.w(function* () {
      let e = null;
      for (; !e;) try {
        {
          var f = b,
            g = c.url;
          const k = eH(a, d);
          var h = f.start(g, k);
        }
        e = yield h;
      } catch (k) {
        if (a.uc) {
          if (f = yield a.uc(c.url, k)) c.url = f;else throw k;
        } else throw k;
      }
      if (0 === e.periods.length) throw new _.G(_.E, 4, 4014);
      UF(e.periods);
      return e;
    })), () => {
      a.log.info("Aborting parser step...");
      return b.stop();
    }).W;
  };
  YG = function (a) {
    if (a.G && a.I && a.B) {
      var b = fH(a) === _.ko,
        c = a.H;
      c.l = b;
      GE(c, c.o ? "seek" : void 0);
      a.B.j.o = b;
      gH(a);
      a.Bd && (c = 1 > Math.abs(a.getMediaDuration() - a.getMediaPosition()), a = a.Bd, b && c && (b = a.a, b.a[b.a.length - 1] && a.f(new _.C(_.zf))));
    }
  };
  LG = function (a) {
    return a.configuration.mediaSource.codecSwitchingStrategy === _.Jf && (_.qk(2) || _.qk(3) || _.qk(4) || _.qk(5) || _.qk(6) || _.tk() && 38 === _.uk().major || _.tk() && 53 === _.uk().major || _.tk() && 68 === _.uk().major ? !1 : !0);
  };
  BG = function (a, b) {
    var c = a.a ? eF(a.a) : null;
    const d = a.a ? fF(a.a) : null,
      e = !LG(a);
    for (const g of b) XD(a.w, c, d, g, e);
    c = FB(b, g => g.variants.some($D));
    if (a.configuration.streaming.disableAudioTracks) for (var f of b) for (const g of f.variants) delete g.audio;
    if (a.configuration.streaming.disableVideoTracks) for (const g of b) for (const h of g.variants) (f = h.video) && !GB(f.type, _.gk(f.mimeType, f.codecs)) && delete h.video;
    if (0 === c) throw new _.G(_.E, 4, 4032);
    if (c < b.length) throw new _.G(_.E, 4, 4011);
    for (const g of b) UD(g.variants, a.configuration.restrictions) && a.a && WF(a) === g && AG(a), hH(a, g.variants);
  };
  DG = function (a, b) {
    const c = d => {
      a.j && a.I && (d.type === _.Ef && a.jc ? (a.I.a = iH, YG(a)) : d.type === _.ug && a.getState() === _.so && .1 > a.getBufferInfo().xa(a.getMediaPosition()) ? (a.I.a = iH, YG(a)) : a.I && jH(a));
      gH(a);
    };
    a.o.on(b, _.bf, c);
    a.o.on(b, _.Ne, c);
    a.o.on(b, _.Td, c);
    a.o.on(b, _.Ef, c);
    a.o.on(b, _.Df, c);
    a.o.on(b, _.qf, c);
    a.o.on(b, _.ug, c);
    a.o.on(b, _.sf, () => {
      if (a.j) {
        var d = a.j.playbackRate;
        0 !== d && a.H.set(d);
      }
    });
  };
  EG = function (a, b) {
    for (let c = 0; c < b.length; c++) {
      const d = b[c],
        e = new Map();
      for (const f of d.variants) if (f.video && f.video.closedCaptions) {
        const g = f.video;
        for (const h of g.closedCaptions.keys()) if (!e.has(h)) {
          const k = {
            id: a.Wf++,
            originalId: h,
            createSegmentIndex: Promise.resolve.bind(Promise),
            findSegmentPosition: () => null,
            getSegmentReference: () => null,
            initSegmentReference: null,
            Ba: 0,
            mimeType: _.zc,
            codecs: "",
            kind: _.PA,
            encrypted: !1,
            keyId: null,
            language: g.closedCaptions.get(h),
            label: null,
            type: _.F,
            primary: !1,
            trickModeVideo: null,
            emsgSchemeIdUris: null,
            roles: g.roles,
            channelsCount: null,
            spatialAudio: !1,
            closedCaptions: null
          };
          e.set(h, k);
        }
      }
      for (const f of e.values()) d.textStreams.push(f);
    }
  };
  WF = function (a) {
    const b = OC(a.B);
    let c = null;
    for (const d of a.f.periods) d.startTime <= b && (c = d);
    return c;
  };
  dG = function (a, b, c, d) {
    c = void 0 === c ? !1 : c;
    d = void 0 === d ? 0 : d;
    if (a.configuration.abr.enabled) {
      a.log.debug("switch_");
      var e = kH(a, b);
      lH(a, e, () => ZF(a, e, b, !0));
      e === WF(a) && mH(a);
      a.a && qF(a.a, b, c, d);
    }
  };
  GG = function (a, b, c) {
    return new nH(b, a.f, a.configuration.streaming, c, d => {
      a.Sa && QC(a.Sa, !0, d);
      a.a && sF(a.a);
    }, d => a.dispatchEvent(d), a.S === _.sb);
  };
  HG = function (a) {
    const b = new oH(a.j);
    var c = new pH(a.f);
    CE(c, () => {
      AG(a);
    });
    b.a.add(c);
    a.J && (c = new qH(a.J), SC(c, d => {
      d.Ed && d.Ed();
      uG(a, _.Gh, d);
    }, d => {
      d.ne && d.ne();
      uG(a, _.Hh, d);
    }, (d, e) => {
      e || (d.Ed && d.Ed(), uG(a, _.Gh, d), d.ne && d.ne(), uG(a, _.Hh, d));
    }), b.a.add(c));
    a.S === _.sb && (c = new rH(a.ec, a.hc, d => a.trigger(d), a.configuration.playlist), b.a.add(c), a.Bd = c);
    return b;
  };
  KG = function (a, b) {
    a.I = new sH(b, Math.min(.5, b / 2));
    a.I.a = iH;
    YG(a);
    a.Pa = new _.mk(() => {
      jH(a);
    }).vb(.25);
  };
  MG = function (a) {
    const b = {
      configuration: {
        Xg: a.S === _.sb
      },
      Lb: () => OC(a.B),
      $: a.K,
      Hb: a.getNetworkEngine(),
      me: a.Zf.bind(a),
      le: a.Yf.bind(a),
      onError: a.onError.bind(a),
      Bf: a.$f.bind(a),
      onEvent: a.xe.bind(a),
      Ff: a.dg.bind(a),
      Fd: a.ag.bind(a),
      td: () => a.h,
      Qd: a.Xf.bind(a),
      Of: a.cg.bind(a),
      Pc: a.w
    };
    return new _.tH(a.f, b);
  };
  NG = function (a, b) {
    function c(g, h) {
      if (!g) return null;
      h = g.findSegmentPosition(h - f.startTime);
      return null == h ? null : (g = g.getSegmentReference(h)) ? g.startTime + f.startTime : null;
    }
    const d = eF(a.a);
    var e = fF(a.a);
    const f = WF(a);
    a = c(d, b);
    e = c(e, b);
    return null != e && null != a ? Math.max(e, a) : null != e ? e : null != a ? a : b;
  };
  OG = function (a, b) {
    var c = a.a ? eF(a.a) : null;
    const d = a.a ? fF(a.a) : null,
      e = !LG(a);
    XD(a.w, c, d, b, e);
    c = b.variants;
    if (!c.some($D)) throw new _.G(_.E, 4, 4011);
    hH(a, b.variants);
    UD(c, a.configuration.restrictions) && a.a && WF(a) === b && AG(a);
    if (!_.qk(2) && (b = a.w ? a.w.getDrmInfo() : null)) for (const f of c) for (const g of f.drmInfos) if (g.keySystem === b.keySystem) for (const h of g.initData || []) _.An(a.w, h.keyId, h.initData) || _.Cn(a.w, h.initDataType, h.initData, h.keyId);
  };
  AG = function (a) {
    const b = a.getTrackManager();
    b && a.F !== pG && (b.m && (zD(b), b.m.trigger(new _.C(_.Bt))), mH(a));
  };
  cG = function (a, b) {
    try {
      hH(a, b);
    } catch (e) {
      return a.onError(e), null;
    }
    b = b.filter(e => $D(e));
    var c = cD(b, a.Ra);
    b = a.Qa.create(c);
    b = Array.from(b.values());
    var d = b.map(lD);
    a.h.setTracks(d);
    if (null !== a.R && null !== a.Ra && (d = b.find(e => e.video.id === a.R && e.audio.id === a.Ra), a.R = null, d)) return d;
    if (a.configuration.abr.enabled || null === a.R) return c = a.h.chooseTrack(), mD(c, b);
    c = c.find(e => null !== e.video && e.video.id === a.R);
    return c ? c : (a.log.warn("Selected video track not found. Falling back to ABR selection"), c = a.h.chooseTrack(), mD(c, b));
  };
  jH = function (a) {
    switch (a.F) {
      case VF:
        a: if (a.j.ended || a.K.M()) var b = !0;else {
          if (a.isLive()) {
            b = kC(a.f.presentationTimeline);
            var c = a.getBufferInfo();
            if (null !== c && (c = c.ga(), null !== c && c >= b)) {
              b = !0;
              break a;
            }
          }
          b = !1;
        }
        break;
      default:
        b = !1;
    }
    if (a.K) {
      var d = a.K.Z();
      c = d.video;
      d = d.audio;
      var e = a.j.currentTime;
      c && d ? (c = c.xa(e), d = d.xa(e), c = Math.min(c, d)) : c = c ? c.xa(e) : d ? d.xa(e) : 0;
    } else c = 0;
    if (d = a.I) {
      {
        d = a.I;
        var f = b;
        const g = d.f.get(d.a);
        e = d.a;
        c = f || c >= g ? uH : iH;
        d.a = c;
        d = e !== c;
      }
    }
    c = d;
    d = vH(a);
    c || a.getState() === _.ko && !d ? (a.log.debug("Buffering state changed. Is buffering", d, "buffered to end", b, "state", a.getState()), YG(a)) : a.fc && gH(a);
  };
  gH = function (a) {
    iG(a, fH(a));
  };
  vH = function (a) {
    return a.w && a.w.B ? !0 : a.I ? a.I.getState() === iH : !1;
  };
  aG = function (a) {
    const b = WF(a);
    return b && gE(a.M, b).variant;
  };
  bG = function (a) {
    const b = WF(a);
    if (null == b) return [];
    wH(a);
    return b.variants.filter(c => $D(c));
  };
  xH = function (a) {
    const b = WF(a);
    if (null == b) return [];
    wH(a);
    return b.textStreams.filter(c => !a.zd.has(c));
  };
  XF = function (a, b, c, d) {
    hE(a.M, b, c);
    if (b = a.getTrackManager()) AD(b, c, d), c = b.La(), a = a.G.B, a.h !== c && (a.h = c, a.a.push({
      timestamp: Date.now() / 1E3,
      id: c.id,
      trackId: c.track.id,
      type: c.track.type,
      fromAdaptation: d,
      width: null,
      height: null,
      bandwidth: null
    }));
  };
  YF = function (a, b) {
    a.V ? a.X = b : _.pF(a.a, b, !0, 0);
  };
  ZF = function (a, b, c, d) {
    var e = a.getTrackManager();
    a.A && a.A.setCurrentVariant(c);
    if (e) {
      var f = a.G.B;
      if (c.video) {
        AD(e, c.video, d);
        var g = e.ma(),
          h = f;
        h.j !== g && (h.j = g, h.a.push({
          timestamp: Date.now() / 1E3,
          id: g.id,
          trackId: g.track.id,
          type: g.track.type,
          fromAdaptation: d,
          width: g.width,
          height: g.height,
          bandwidth: g.bandwidth
        }));
      }
      c.audio && (AD(e, c.audio, d), e = e.Ua(), f.f !== e && (f.f = e, f.a.push({
        timestamp: Date.now() / 1E3,
        id: e.id,
        trackId: e.track.id,
        type: e.track.type,
        fromAdaptation: d,
        width: null,
        height: null,
        bandwidth: e.bandwidth
      })));
    }
    gE(a.M, b).variant = c;
  };
  $F = function (a, b, c, d) {
    c = void 0 === c ? !1 : c;
    d = void 0 === d ? 0 : d;
    a.V ? (a.Z = b, a.Vf = c, a.Uf = d) : qF(a.a, b, c, d);
  };
  dH = function (a) {
    return a.S === _.sb && "flat" === a.configuration.playlist.timeline;
  };
  zH = function (a, b, c) {
    c = void 0 === c ? !0 : c;
    const d = cG(a, b.variants);
    a.Ra = d && d.audio && d.audio.id;
    d && (ZF(a, b, d, !0), $F(a, d, c));
    (c = yH(a, b.textStreams)) && (a.configuration.streaming.alwaysStreamText || xD(a)) && (XF(a, b, c, !0), YF(a, c));
  };
  hH = function (a, b) {
    a = a.w ? _.$g(a.w.V) : {};
    var c = Object.keys(a);
    c = c.length && "00" === c[0];
    let d = !1,
      e = !1;
    const f = [],
      g = [];
    for (const h of b) {
      b = [];
      h.audio && b.push(h.audio);
      h.video && b.push(h.video);
      for (const k of b) k.keyId && ((b = a[c ? "00" : k.keyId]) ? zG.includes(b) && (g.includes(b) || g.push(b)) : f.includes(k.keyId) || f.push(k.keyId));
      h.allowedByApplication ? h.allowedByKeySystem && (d = !0) : e = !0;
    }
    if (!d) throw new _.G(_.E, 4, 4012, {
      hasAppRestrictions: e,
      missingKeys: f,
      restrictedKeyStatuses: g
    });
  };
  wH = function (a) {
    if (a.a && a.f) {
      var b = cF(a.a),
        c = WF(a);
      null != b && b === c && (eF(a.a), fF(a.a), _.dF(a.a, _.F), gE(a.M, c), gE(a.M, c));
    }
  };
  fH = function (a) {
    if (a.jc) var b = !1;else if (a.j && a.j.ended) b = !0;else {
      b = a.getMediaDuration();
      var c = a.getMediaPosition();
      b = a.fc && 0 <= c && b <= c ? !0 : !1;
    }
    return b ? _.ho : vH(a) || a.jc ? _.ko : a.j && a.j.paused ? _.ro : _.so;
  };
  yH = function (a, b) {
    return _.bE(b, a.cc, a.Dc, a.bc)[0] || null;
  };
  AH = function (a, b) {
    a.V = !0;
    a.h.disable();
    a.log.debug("Choosing new streams after period changed");
    let c = cG(a, b.variants),
      d = yH(a, b.textStreams);
    a.Z && (b.variants.includes(a.Z) && (c = a.Z), a.Z = null);
    a.X && (b.textStreams.includes(a.X) && (d = a.X), a.X = null);
    c && lH(a, b, () => ZF(a, b, c, !0));
    d && (a.configuration.streaming.alwaysStreamText || xD(a)) && lH(a, b, () => XF(a, b, d, !0));
    b === WF(a) && mH(a);
    return a.configuration.streaming.alwaysStreamText || xD(a) ? {
      variant: c,
      text: d
    } : {
      variant: c,
      text: null
    };
  };
  lH = function (a, b, c) {
    a.Ha.has(b) || a.Ha.set(b, []);
    a.Ha.get(b).push(c);
  };
  mH = function (a) {
    const b = WF(a);
    if (b) {
      const c = a.Ha.get(b) || [];
      for (a.Ha.delete(b); c.length;) c.shift()();
    }
  };
  kH = function (a, b) {
    for (const c of a.f.periods) if (c.variants.includes(b)) return c;
    return null;
  };
  BH = function (a, b, c) {
    return _.w(function* () {
      let d;
      try {
        d = yield _.cs(b.url, a.getNetworkEngine(), a.configuration.manifest.attemptParameters, b.type || null);
        d.configure(a.configuration.manifest);
        {
          var e = d,
            f = b.url;
          const D = eH(a, a.configuration.id || "");
          var g = e.start(f, D);
        }
        const h = yield g;
        UF(h.periods);
        if (!h.periods.length) throw new _.G(_.E, 4, 4014);
        yield a.A.stop();
        a.A = d;
        const k = c.audio && c.audio.id,
          l = c.video && c.video.id;
        a.f.periods = h.periods;
        const m = TB(h.periods, OC(a.B)) || h.periods[0];
        LG(a) ? SD(a.f, _.Qh(a.configuration.preferredVideoCodec), _.Qh(a.configuration.preferredAudioCodec), a.configuration.preferredAudioChannelCount) : RD(a.f, _.Qh(a.configuration.preferredVideoCodec), _.Qh(a.configuration.preferredAudioCodec), a.configuration.preferredAudioChannelCount);
        const n = dD(k, l, m.variants),
          q = yH(a, m.textStreams);
        cG(a, m.variants);
        const u = n && n.video,
          v = n && n.audio;
        g = [];
        const y = new Set();
        for (const D of m.variants) D.video && y.add(D.video), D.audio && y.add(D.audio);
        const A = a.getNetworkEngine();
        for (const D of [...y, ...m.textStreams]) g.push(D.createSegmentIndex(A));
        yield Promise.all(g);
        xF(a.a, _.p, v);
        xF(a.a, _.t, u);
        xF(a.a, _.F, q);
        n && ZF(a, m, n, !1);
        a.log.info("Switch CDN successful.");
      } catch (h) {
        throw d && (yield d.stop()), h;
      }
    });
  };
  eH = function (a, b) {
    return {
      networkingEngine: a.getNetworkEngine(),
      configurationId: b,
      filterNewPeriod: c => OG(a, c),
      filterAllPeriods: c => BG(a, c),
      onEvent: c => a.xe(c),
      onError: c => a.onError(c),
      onUpdate: c => VG(a, c),
      onTurnedStatic: () => WG(a)
    };
  };
  CH = function () {
    const a = MediaSource.isTypeSupported,
      b = /^dv(?:h[e1]|a[v1])\./;
    MediaSource.isTypeSupported = c => {
      var d = c.split(/ *; */),
        e = d.shift();
      const f = {};
      for (var g of d) d = g.split("\x3d"), f[d[0]] = d[1].replace(/"(.*)"/, "$1");
      g = f.codecs;
      if (!g) return a(c);
      let h = !1,
        k = !1;
      c = g.split(",").filter(l => {
        b.test(l) && (k = !0);
        /^(hev|hvc)1\.2/.test(l) && (h = !0);
        return !0;
      });
      k && (h = !1);
      f.codecs = c.join(",");
      h && (f.eotf = "smpte2084");
      for (const l in f) e += "; " + l + '\x3d"' + f[l] + '"';
      return cast.__platform__.canDisplayType(e);
    };
  };
  DH = function () {
    const a = MediaSource.isTypeSupported;
    MediaSource.isTypeSupported = b => "mp2t" === b.split(/ *; */)[0].split("/")[1].toLowerCase() ? !1 : a(b);
  };
  EH = function () {
    const a = MediaSource.prototype.addSourceBuffer;
    MediaSource.prototype.addSourceBuffer = function (...b) {
      b = a.apply(this, b);
      b.abort = function () {};
      return b;
    };
  };
  FH = function () {
    const a = SourceBuffer.prototype.remove;
    SourceBuffer.prototype.remove = function (b, c) {
      return a.call(this, b, c - .001);
    };
  };
  GH = function () {
    const a = MediaSource.isTypeSupported;
    MediaSource.isTypeSupported = b => "opus" !== _.JB(b) && a(b);
  };
  HH = function (a, b) {
    const c = a.mode;
    a.mode = c === _.Ff ? _.Ff : _.$d;
    const d = a.cues;
    for (let e = d.length - 1; 0 <= e; e--) {
      const f = d[e];
      f && b(f) && a.removeCue(f);
    }
    a.mode = c;
  };
  jB = [112, 115, 115, 104, 0, 0, 0, 0];
  _.IH = class {
    constructor(a, b, c, d, e) {
      this.Wa = a;
      this.wa = b;
      this.ka = c;
      this.keyInfo = d || null;
      this.wc = _.z(e) ? !1 : !!e;
    }
    Tc() {
      return this.ka ? this.ka - this.wa + 1 : null;
    }
    f() {
      return this.keyInfo;
    }
  };
  _.uC = class {
    constructor(a, b, c, d, e, f, g, h = null, k = !1, l = null, m = null, n = null, q = null, u = [], v = null) {
      this.position = a;
      this.startTime = b;
      this.endTime = c;
      this.Ba = g;
      this.Wa = d;
      this.wa = e;
      this.ka = f;
      this.keyInfo = h || null;
      this.wc = _.z(k) ? !1 : !!k;
      this.timescale = l;
      this.horizontalTiles = m;
      this.verticalTiles = n;
      this.durationPerTile = q;
      this.a = u || [];
      this.h = !1;
      this.initSegment = v;
    }
    getPosition() {
      return this.position;
    }
    Tc() {
      return this.ka ? this.ka - this.wa + 1 : null;
    }
    f() {
      return this.keyInfo;
    }
  };
  _.gG = class {
    constructor(a = null) {
      this.j = a;
      this.log = new _.R("clpp.renditions");
      this.U = [];
      this.h = this.player = null;
      this.f = !1;
    }
    track(a) {
      null != this.player && OB(this);
      a && (this.player = a, this.player.on(_.xf, this.a, this));
    }
    release() {
      OB(this);
      this.U = [];
      this.h = this.j = null;
    }
    append(a, b, c = null) {
      const d = this.j && null !== c ? this.j(c) : null;
      d || this.log.debug("Unable to resolve rendition for ID", c);
      c = {
        start: a,
        end: b,
        rendition: d,
        width: d ? d.width : 0,
        height: d ? d.height : 0,
        bandwidth: d ? d.bandwidth : 0
      };
      const e = this.U.find(f => f.start >= a && f.end <= b);
      if (e) e.rendition = d, e.width = c.width, e.height = c.height, e.bandwidth = c.bandwidth, e.start = c.start, e.end = c.end;else {
        for (const f of this.U) a >= f.start && a < f.end ? f.end = a : b >= f.start && b < f.end && (f.start = b);
        this.U.push(c);
        this.U.sort(MB);
      }
      RB(this);
      this.a();
      this.player && this.player.trigger(new _.C(_.mg));
    }
    clear() {
      this.U = [];
      RB(this);
      this.player && (this.player.trigger(new _.C(_.mg)), this.player.trigger(new _.C(_.Mc)));
    }
    a() {
      if (this.player) {
        var a = this.player.getMediaPosition();
        if (0 <= a) {
          a: {
            var b = this.U.length,
              c = Number.MAX_SAFE_INTEGER;
            for (var d = 0; d < b; d++) {
              const e = this.U[d];
              c = Math.min(e.start, c);
              if (e.start <= a && e.end > a) {
                a = .1 > e.end - a && d < b - 1 ? this.U[d + 1].rendition : e.rendition;
                break a;
              }
              if (e.start > a) break;
            }
            a = 0 < b && c > a && .25 > c - a ? this.U[0].rendition : null;
          }
          if (a && this.h !== a && (this.h = a, this.player)) {
            c = b = -1;
            if (a) b = a.width || -1, c = a.height || -1;else if (d = this.player.getSurface().getMedia()) b = d.videoWidth, c = d.videoHeight;
            this.player.trigger(new _.C(_.Oc, {
              detail: {
                width: b,
                height: c,
                bandwidth: a ? a.bandwidth : null,
                rendition: a
              }
            }));
          }
        }
      }
    }
  };
  _.JH = class {
    constructor(a) {
      this.h = Math.exp(Math.log(.5) / a);
      this.f = this.a = 0;
    }
  };
  KH = class {
    constructor(a) {
      this.a = this.h = 0;
      this.f = a;
    }
    Mb() {
      return this.h;
    }
    getDuration() {
      return this.f ? this.a - this.h : Infinity;
    }
  };
  _.LH = class {
    constructor(a, b, c, d, e, f) {
      f = void 0 === f ? !1 : f;
      this.j = a;
      this.F = b;
      this.J = c;
      this.A = this.f = Infinity;
      this.h = 1;
      this.G = 0;
      this.o = this.w = null;
      this.H = 0;
      this.C = this.l = d;
      this.L = void 0 === e ? !0 : e;
      this.g = new _.R("clpp.media.PresentationTimeline");
      this.M = f;
      this.K = null;
      this.a = new KH(d);
      this.I = null;
      this.B = this.j.type || null;
    }
    kc() {
      return this.K;
    }
    getDuration() {
      return 2 === this.j.version ? this.a.getDuration() : this.f;
    }
    getPresentationStartTime() {
      return this.F;
    }
    offset(a) {
      null != this.w && (this.w += a);
      null != this.o && (this.o += a);
    }
    isLive() {
      return 2 === this.j.version ? !this.a.f : Infinity === this.f && !this.l;
    }
    Yc() {
      return !this.l;
    }
  };
  tG = class {
    constructor() {
      this.f = () => {};
      this.h = () => {};
      this.a = new Set();
    }
    release() {
      this.f = () => {};
      this.h = () => {};
      this.a.clear();
    }
    cues() {
      return this.a;
    }
  };
  rH = class {
    constructor(a, b, c, d) {
      this.a = a;
      this.h = null;
      this.j = b;
      this.f = c;
      this.o = d;
      "flat" === d.timeline && d.cropTimeline && (a = d.startTime || 0, this.j.a = new _.Ku("crop", a, d.endTime ? d.endTime - a : Infinity, !1));
    }
    release() {
      this.f = this.j = this.h = this.a = null;
    }
    l(a) {
      tB(this.a, a);
      rC(this);
    }
  };
  xC = class {
    constructor(a, b, c) {
      this.h = a;
      this.a = b;
      this.f = c;
      this.g = new _.R("clpp.media.SegmentIterator");
    }
    next() {
      var a = this.h.get(this.a);
      a && 0 < a.a.length ? (this.f += 1, a.Wa().length && this.f === a.a.length && (this.a += 1, this.f = 0)) : (this.a += 1, this.f = 0);
      a = sC(this);
      return {
        value: a,
        done: !a
      };
    }
  };
  _.MH = class {
    constructor(a) {
      this.a = a;
      this.g = new _.R("clpp.media.SegmentIndex");
    }
    destroy() {
      this.a = [];
      return Promise.resolve();
    }
    f(a) {
      for (var b = this.a.length - 1; 0 <= b; --b) {
        const c = this.a[b];
        if (a >= c.startTime && a < c.endTime) return c.position;
      }
      if (this.a.length && a < this.a[0].startTime) return this.a[0].position;
      this.g.warn("No Segment found for " + a + ". We have " + this.a.length + " references");
      for (a = 0; a < this.a.length; a++) b = this.a[a], this.g.debug("SegmentIndex Ref " + a + " start: " + b.startTime + " end: " + b.endTime + " pos: " + b.position);
      return null;
    }
    get(a) {
      if (0 === this.a.length) return null;
      a -= this.a[0].position;
      return 0 > a || a >= this.a.length ? null : this.a[a];
    }
    offset(a) {
      this.g.info("Apply offset" + a + " to segment index");
      for (const b of this.a) b.startTime += a, b.endTime += a, b.Ba -= a;
    }
    h(a, b) {
      a -= this.a[0].position;
      if (0 <= a && a < this.a.length) for (this.g.warn("Accounting for segment duration drift", b, "at", a), this.a[a].endTime += b, a += 1; a < this.a.length; ++a) this.a[a].startTime += b, this.a[a].endTime += b;
    }
    getDuration() {
      return this.a[this.a.length - 1].endTime - this.a[0].startTime;
    }
    replace(a) {
      this.a = a;
    }
  };
  IC = class {
    constructor(a, b) {
      this.h = a;
      this.l = AC(a);
      this.a = a.a.currentTime;
      this.j = Date.now() / 1E3;
      this.o = b;
      this.f = () => {};
    }
    release() {
      this.h = null;
      this.f = () => {};
    }
  };
  JC = class {
    constructor(a) {
      this.a = a;
    }
  };
  NH = class {
    constructor(a, b, c, d, e) {
      this.a = a;
      this.G = b;
      this.l = c;
      this.F = e;
      this.h = new _.Rs();
      this.C = !1;
      this.I = a.readyState;
      this.j = !1;
      this.f = d;
      this.B = this.o = this.H = this.A = !1;
      this.h.on(a, _.ug, () => BC(this));
      this.w = new _.mk(() => {
        BC(this);
      }).vb(.25);
    }
    release() {
      this.h && (this.h.release(), this.h = null);
      null != this.w && (this.w.stop(), this.w = null);
      this.f && (this.f.release(), this.f = null);
      this.a = this.G = this.F = null;
      this.o = !1;
    }
    Fd() {
      this.A = !0;
      BC(this);
    }
  };
  OH = class {
    constructor(a, b) {
      this.f = a;
      this.o = b;
      this.l = this.h = this.j = 0;
      this.a = new _.mk(() => {
        0 >= this.j ? (_.B(["Failed to move playhead from", this.h, "to", this.l].join(" ")), this.a.stop()) : this.f.currentTime !== this.h ? this.a.stop() : (this.f.currentTime = this.l, this.j--);
      });
    }
    release() {
      this.a && (this.a.stop(), this.a = null);
      this.f = null;
    }
  };
  PH = class {
    constructor(a, b, c, d) {
      this.a = a;
      this.j = b;
      this.o = d;
      this.A = c;
      this.l = !1;
      this.f = new _.Rs();
      this.h = new OH(a, 10);
      this.w = !1;
      0 < a.readyState ? EC(this, c) : FC(this, c);
    }
    release() {
      this.f && (this.f.release(), this.f = null);
      null != this.h && (this.h.release(), this.h = null);
      this.j = () => {};
      this.a = null;
      this.o = () => {};
      this.w = !1;
    }
  };
  nH = class {
    constructor(a, b, c, d, e, f, g) {
      this.h = a;
      this.a = b.presentationTimeline;
      this.B = b.minBufferTime || 0;
      this.l = c;
      this.A = e;
      this.w = null;
      this.j = new NH(a, b.presentationTimeline, c, KC(this, a, c), f);
      this.f = new PH(a, () => {
        a: {
          var h = this.j;
          var k = h.B;
          h.B = !1;
          h.C = !0;
          h.A = !1;
          h.j = !1;
          h = k;
          k = GC(this.f);
          const l = PC(this, k);
          if (.001 < Math.abs(l - k)) {
            const m = new Date().getTime() / 1E3;
            if (!this.w || this.w < m - 1) {
              this.w = m;
              HC(this.f, l);
              h = void 0;
              break a;
            }
          }
          this.g.debug("Seek to " + k);
          this.A(h ? 1 : 0);
          h = void 0;
        }
        return h;
      }, NC(this, d, g), () => {
        this.j.H = !0;
      });
      this.o = new _.mk(() => {
        if (0 !== this.h.readyState && !this.h.paused) {
          var h = GC(this.f),
            k = cC(this.a),
            l = mC(this.a);
          3 > l - k && (k = l - 3);
          h < k && (k = PC(this, h), this.g.info("Jumping forward " + (k - h) + " seconds to catch up with the seek range."), this.h.currentTime = k);
        }
      }).vb(.25);
      this.g = new _.R("clpp.mse.Playhead");
    }
    release() {
      this.f && (this.f.release(), this.f = null);
      this.j && (this.j.release(), this.j = null);
      this.o && (this.o.stop(), this.o = null);
      this.h = this.f = this.a = this.l = null;
      this.A = () => {};
    }
  };
  oH = class {
    constructor(a) {
      this.f = a;
      this.a = new Set();
      this.h = new _.mk(() => {
        QC(this, !1);
      }).vb(.25);
    }
    release() {
      this.h.stop();
      for (const a of this.a) a.release();
      this.a.clear();
    }
  };
  qH = class {
    constructor(a) {
      this.j = a;
      this.o = new Map();
      this.a = () => {};
      this.f = () => {};
      this.h = () => {};
      this.w = [{
        rc: null,
        qc: 2,
        lc: (b, c) => this.a(b, c)
      }, {
        rc: 1,
        qc: 2,
        lc: (b, c) => this.a(b, c)
      }, {
        rc: 3,
        qc: 2,
        lc: (b, c) => this.a(b, c)
      }, {
        rc: 2,
        qc: 1,
        lc: (b, c) => this.f(b, c)
      }, {
        rc: 2,
        qc: 3,
        lc: (b, c) => this.f(b, c)
      }, {
        rc: 1,
        qc: 3,
        lc: (b, c) => this.h(b, c)
      }, {
        rc: 3,
        qc: 1,
        lc: (b, c) => this.h(b, c)
      }];
    }
    release() {
      this.j = null;
      this.o.clear();
      this.a = () => {};
      this.f = () => {};
      this.h = () => {};
    }
    l(a, b, c) {
      if (this.j) for (const d of this.j.cues()) {
        const e = this.o.get(d) || null,
          f = RC(d, a);
        this.o.set(d, f);
        const g = this.w.find(h => h.rc === e && h.qc === f);
        g && g.lc(d, b && 0 === c);
      }
    }
  };
  _.QH = 0;
  RH = class {
    constructor(a, b) {
      this.w = null;
      this.C = a;
      this.j = b;
      this.h = this.A = 0;
      this.l = Infinity;
      this.f = this.a = null;
      this.B = "";
      this.o = new Map();
    }
    destroy() {
      this.j = this.w = null;
      this.o.clear();
      return Promise.resolve();
    }
    remove(a, b) {
      return Promise.resolve().then(() => {
        !this.j || !this.j.remove(a, b) || null == this.a || b <= this.a || a >= this.f || (a <= this.a && b >= this.f ? this.a = this.f = null : a <= this.a && b < this.f ? this.a = b : a > this.a && b >= this.f && (this.f = a));
      });
    }
    Ja() {
      return this.a;
    }
    ga() {
      return this.f;
    }
    Fb(a) {
      return null == this.a || null == this.f ? !1 : a >= this.a && a < this.f;
    }
    xa(a) {
      return null == this.f || this.f < a ? 0 : this.f - Math.max(a, this.a);
    }
  };
  var V = class extends _.S {
    constructor() {
      super();
      this.g = new _.R("cl.api.mse.TrackManager");
      this.m = null;
      this.h = [];
      this.a = new Map();
      this.f = new Map();
    }
    initialize(a) {
      this.m = a;
    }
    release() {
      this.h = [];
      this.a.clear();
      this.f.clear();
    }
    load() {}
    destroy() {
      this.release();
      this.m = null;
    }
    getVideoTracks() {
      return nD(this, _.M);
    }
    getAudioTracks() {
      return nD(this, _.L);
    }
    ha() {
      return nD(this, _.K);
    }
    ma() {
      return this.a.get(_.M) || null;
    }
    Ua() {
      return this.a.get(_.L) || null;
    }
    La() {
      return this.a.get(_.K) || null;
    }
    jb() {
      return this.f.get(_.M) || null;
    }
    zb() {
      return this.f.get(_.L) || null;
    }
    Ab() {
      return this.f.get(_.K) || null;
    }
    Ma() {
      return oD(this, _.M);
    }
    la() {
      return oD(this, _.L);
    }
    ta() {
      return oD(this, _.K);
    }
    ub(a) {
      qD(this, _.M, a);
    }
    va(a) {
      qD(this, _.L, a);
    }
    Oa(a) {
      qD(this, _.K, a);
    }
    ab(a, b) {
      pD(this, _.M, a, void 0 === b ? !1 : b);
    }
    sb(a, b) {
      pD(this, _.L, a, void 0 === b ? !1 : b);
    }
    tb(a, b) {
      b = void 0 === b ? !1 : b;
      let c = a;
      if (!a) {
        const d = this.La();
        if (d && d.track.kind !== _.Wd) {
          a = this.ha().find(e => e.kind === _.Wd && e.language === d.track.language);
          if (!a) {
            const e = this.la();
            e && (a = this.ha().find(f => f.kind === _.Wd && f.language === e.language));
          }
          a && (c = a.renditions[0]);
        }
      }
      pD(this, _.K, c, b);
    }
    addTextTrack(a) {
      const b = this;
      return _.w(function* () {
        b.m && (yield b.m.addTextTrack(a.url, a.language, a.kind, a.mimeType, a.codec, a.label));
      });
    }
    pa() {
      return !!this.m && this.m.pa();
    }
  };
  V.prototype.isAbrEnabled = V.prototype.pa;
  V.prototype.addTextTrack = V.prototype.addTextTrack;
  V.prototype.setTextRendition = V.prototype.tb;
  V.prototype.setAudioRendition = V.prototype.sb;
  V.prototype.setVideoRendition = V.prototype.ab;
  V.prototype.setTextTrack = V.prototype.Oa;
  V.prototype.setAudioTrack = V.prototype.va;
  V.prototype.setVideoTrack = V.prototype.ub;
  V.prototype.getTextTrack = V.prototype.ta;
  V.prototype.getAudioTrack = V.prototype.la;
  V.prototype.getVideoTrack = V.prototype.Ma;
  V.prototype.getLoadingTextRendition = V.prototype.Ab;
  V.prototype.getLoadingAudioRendition = V.prototype.zb;
  V.prototype.getLoadingVideoRendition = V.prototype.jb;
  V.prototype.getTextRendition = V.prototype.La;
  V.prototype.getAudioRendition = V.prototype.Ua;
  V.prototype.getVideoRendition = V.prototype.ma;
  V.prototype.getTextTracks = V.prototype.ha;
  V.prototype.getAudioTracks = V.prototype.getAudioTracks;
  V.prototype.getVideoTracks = V.prototype.getVideoTracks;
  var SH = class {
    constructor() {
      this.f = new _.JH(2);
      this.h = new _.JH(5);
      this.a = 0;
    }
  };
  var ZG;
  ZG = class {
    constructor() {
      this.a = new muxjs.mp4.CaptionParser();
      this.l = [];
      this.j = {};
    }
    init(a) {
      const b = muxjs.mp4.probe;
      a = _.J(a);
      this.l = b.videoTrackIds(a);
      this.j = b.timescale(a) || {};
      this.a.init();
    }
    h(a, b) {
      a = _.J(a);
      (a = this.a.parse(a, this.l, this.j)) && a.captions && b(a.captions);
      this.a.clearParsedCaptions();
    }
    f() {
      this.a.resetCaptionStream();
    }
  };
  _.$G = class {
    init() {}
    h() {}
    f() {}
  };
  var CD;
  _.bH = class {
    constructor(a, b, c, d, e) {
      this.j = a;
      this.G = c;
      this.f = {};
      this.O = !1;
      this.a = null;
      this.h = {};
      this.l = new _.Rs();
      this.J = !1;
      this.w = {};
      this.I = b;
      this.F = d;
      this.R = e;
      this.Ha = this.R.streaming.maxSegmentToMediaOffset || 1;
      this.K = new _.xn();
      this.B = "";
      this.H = {};
      this.o = DD(this, this.K);
      this.S = !0;
      this.g = new _.R("clpp.mse.MediaSourceEngine");
    }
    destroy() {
      this.J = !0;
      const a = [];
      for (const c in this.h) {
        const d = this.h[c];
        var b = d[0];
        this.h[c] = d.slice(0, 1);
        b && a.push(b.p.catch(vB));
        for (b = 1; b < d.length; ++b) d[b].p.catch(vB), d[b].p.reject();
      }
      this.a && a.push(this.a.destroy());
      this.G && a.push(this.G.destroy());
      for (const c in this.w) this.w[c].destroy(), delete this.w[c];
      return Promise.all(a).then(() => {
        this.l && (this.l.release(), this.l = null);
        this.j && (this.j.removeAttribute("src"), this.j.load(), this.j = null);
        this.B = "";
        this.G = this.a = this.o = null;
        this.f = {};
        this.w = {};
        this.H = {};
        this.I = null;
        this.O = !1;
        for (const c in this.h);
        this.h = {};
      });
    }
    open() {
      return this.K;
    }
    init(a, b, c) {
      c = void 0 === c ? !1 : c;
      const d = this;
      return _.w(function* () {
        yield d.K;
        a.forEach((e, f) => {
          const g = _.gk(e.mimeType, e.codecs);
          if (f === _.F) d.A(g);else {
            var h = new Map();
            h.set(f, g);
            _.Ar(g) && b && !e.keyInfo || !_.fk(g, f) || !(e = _.fk(g)) || (h = e(), d.w[f] = h, h = h.demuxMimeTypes(f, g), c && h.delete(_.p));
            ED(d, h);
          }
        });
      });
    }
    Ra() {
      this.g.debug(DA);
      Object.values(this.w).forEach(a => a.reset());
    }
    Qa() {
      return this.R.streaming.ignoreManagedMediaSourceStreamingHints ? !0 : this.S;
    }
    A(a) {
      this.a || (this.a = new RH(this.R, this.G));
      WC(this.a, a);
    }
    M() {
      return this.o ? this.o.readyState === _.Td : !0;
    }
    Ja(a) {
      return a === _.F ? this.a.Ja() : (a = FD(this, a)) ? a.Ja() : null;
    }
    ga(a) {
      return a === _.F ? this.a.ga() : (a = FD(this, a)) ? a.ga() : null;
    }
    Fb(a, b, c) {
      return a === _.F ? this.a.Fb(b) : (a = FD(this, a)) ? a.Fb(b, !1, c) : !1;
    }
    xa(a, b) {
      return a === _.F ? this.a.xa(b) : (a = FD(this, a)) ? a.xa(b) : 0;
    }
    Z() {
      const a = {};
      a.total = new _.Ct(this.j.buffered, "total");
      a.audio = FD(this, _.p);
      a.video = FD(this, _.t);
      let b = [];
      this.a && (b = [{
        start: this.a.Ja(),
        end: this.a.ga()
      }]);
      a.text = KB(b, _.F);
      return a;
    }
    L(a, b, c, d, e, f, g) {
      g = void 0 === g ? !1 : g;
      const h = this.f[a];
      let k = -1,
        l = 0;
      h && h.buffered && (l = h.buffered.length);
      a === _.t && null !== c && null !== d && g && (k = 0 < l ? h.buffered.end(l - 1) : -1);
      a === _.F ? b = XC(this.a, b, c, d) : this.w[a] ? (b = this.w[a].transmux(b), b.captions && 0 < b.captions.length && (this.a || this.A(_.Uf), ZC(this.a, b.captions, c, d, this.f.video.timestampOffset)), e = [], 0 < b.audio.byteLength && this.f.audio && e.push(ID(this, _.p, this.V.bind(this, _.p, b.audio.buffer))), 0 < b.video.byteLength && this.f.video && e.push(ID(this, _.t, this.V.bind(this, _.t, b.video.buffer))), b = Promise.all(e)) : (e && window.muxjs && (this.a || this.A(_.Uf), null == c && null == d ? this.I.init(b) : this.I.h(b, m => {
        m.length && ZC(this.a, m, c, d, this.f.video.timestampOffset);
      })), b = ID(this, a, this.V.bind(this, a, b)));
      return b.then(() => {
        if (a === _.t && null !== c && null !== d && !g) this.F.append(c, d, f), null !== c && null !== d && this.g.debug(bA, c, d, dA, GD(this, a));else if (a === _.p && null !== c && null !== d) PB(this.F), null !== c && null !== d && this.g.debug(aA, c, d, dA, GD(this, a));else if (a === _.t && null !== c && null !== d && g) {
          var m = h.buffered.length,
            n = 0 < m ? h.buffered.end(m - 1) : -1;
          0 > k && 0 < m && (k = h.buffered.start(0));
          0 <= k && 0 < n && this.F.append(k, n, f);
        }
        if (!this.H[a] && h && 1 === h.buffered.length) {
          m = h.buffered.start(0);
          const q = c - m;
          if (Math.abs(q) > this.Ha && 0 === h.timestampOffset) return this.g.warn(`Detected a delta of ${q} between ` + `media times and segment times for ${a}. ` + `The segment start time is ${c} and the media start time ` + `is ${m}. Adjusting timestampOffset.`), m = ID(this, a, () => {
            this.ia(a, q);
          }), n = ID(this, a, this.X.bind(this, a, !1)), Promise.all([m, n]);
        }
        null !== c && null !== d && (this.H[a] = !0);
      }).catch(m => {
        throw m;
      });
    }
    Pa(a) {
      const b = this.ga(_.t) || 0;
      YC(this.a, a, b);
    }
    ba() {
      this.a && YC(this.a, "", 0);
    }
    remove(a, b, c) {
      return a === _.F ? this.a.remove(b, c) : ID(this, a, this.da.bind(this, a, b, c)).then(() => {
        if (a === _.t) {
          const d = this.Ja(a),
            e = this.ga(a);
          null !== d && null !== e && SB(this.F, d, e);
        } else a === _.p && QB(this.F);
      });
    }
    clear(a) {
      if (a === _.F) {
        if (!this.a) return Promise.resolve();
        this.I.f();
        return this.a.remove(0, Infinity);
      }
      return ID(this, a, this.da.bind(this, a, 0, this.o.duration));
    }
    flush(a) {
      return a === _.F ? Promise.resolve() : ID(this, a, this.X.bind(this, a));
    }
    Sa(a, b, c, d) {
      const e = this;
      return _.w(function* () {
        if (a === _.F) {
          e.a.A = b;
          var f = e.a;
          f.h = c;
          f.l = d;
        } else f = JD(e, a, b, c, d), e.O && (f = f.concat(JD(e, _.p, b, c, d))), yield Promise.all(f);
      });
    }
    endOfStream(a) {
      return LD(this, () => {
        this.M() || (a ? this.o.endOfStream(a) : this.o.endOfStream());
      });
    }
    Y(a) {
      LD(this, () => {
        this.o.duration = a;
      });
    }
    getDuration() {
      return this.o.duration;
    }
    V(a, b) {
      this.g.debug(cA + a + Vz);
      try {
        this.f[a].appendBuffer(b);
      } catch (c) {
        throw this.g.warn(nA + a, c), c;
      }
      this.g.debug(hA + a + Vz);
    }
    da(a, b, c) {
      if (c <= b) this.C(a);else {
        this.g.debug(CA + a + Xz, b, c);
        try {
          this.f[a].remove(b, c);
        } catch (d) {
          throw this.g.warn(pA + a + Wz, b, c, d), d;
        }
        this.g.debug(jA + a + Xz, b, c);
      }
    }
    wb(a) {
      try {
        this.g.debug($z + a + Vz);
        const b = this.f[a].appendWindowStart,
          c = this.f[a].appendWindowEnd;
        this.f[a].abort();
        this.f[a].appendWindowStart = b;
        this.f[a].appendWindowEnd = c;
      } catch (b) {
        throw this.g.warn(mA + a + Vz, b), b;
      }
      this.g.debug(gA + a + Vz);
      this.C(a);
    }
    X(a) {
      try {
        this.j.readyState > HTMLMediaElement.HAVE_NOTHING ? (this.g.debug(sA + a), this.j.currentTime -= .001) : this.g.warn(HA + a + Yz + this.j.readyState);
      } catch (b) {
        throw this.g.warn(oA + a, b), b;
      }
      this.g.debug(iA + a);
      this.C(a);
    }
    ia(a, b) {
      0 > b && (b += .001);
      try {
        this.g.debug(GA + a, b), this.f[a].timestampOffset = b;
      } catch (c) {
        throw this.g.warn(rA + a, b, c), c;
      }
      this.g.debug(lA + a, b);
      this.C(a);
    }
    Kb(a, b, c) {
      try {
        this.g.debug(EA + a, b, c), this.f[a].appendWindowStart = 0, this.f[a].appendWindowEnd = c, this.f[a].appendWindowStart = b;
      } catch (d) {
        throw this.g.warn(qA + a, b, c, d), d;
      }
      this.g.debug(kA + a, b, c);
      this.C(a);
    }
    T(a) {
      this.h[a][0].p.reject(new _.G(_.E, 3, 3014, {
        mediaError: this.j.error ? this.j.error.code : 0
      }));
    }
    C(a) {
      const b = this.h[a][0];
      b && (b.p.resolve(), this.g.debug(JA + a), KD(this, a));
    }
    getTextDisplayer() {
      return this.G;
    }
  };
  CD = window.URL.createObjectURL;
  _.TH = class {
    constructor() {
      this.g = new _.R("clpp.abr");
      this.A = null;
      this.l = !1;
      this.w = new SH();
      this.f = [];
      this.o = 1;
      this.C = this.B = !1;
      this.j = this.a = this.h = null;
    }
    stop() {
      this.A = null;
      this.l = !1;
      this.f = [];
      this.o = 1;
      this.h = null;
      this.C = !1;
    }
    init(a) {
      this.A = a;
    }
    chooseTrack() {
      let a = cE(this, !this.C && this.a.initialRestrictions ? this.a.initialRestrictions : this.a.restrictions, this.f);
      var b = this.getBandwidthEstimate();
      this.f.length && !a.length && (this.g.warn("No tracks met the ABR restrictions. Choosing a track by lowest bandwidth."), a = cE(this, null, this.f), a = [a[0]]);
      this.h = Date.now();
      this.C = !0;
      {
        var c = a;
        let e = c[0] || null;
        for (let f = 0; f < c.length; f++) {
          const g = c[f];
          var d = c[f + 1];
          const h = isNaN(this.o) || 0 === this.o ? 1 : Math.abs(this.o),
            k = h * g.bandwidth,
            l = k / this.a.bandwidthDowngradeTarget;
          d = h * (d ? d.bandwidth : Infinity) / this.a.bandwidthUpgradeTarget;
          this.g.debug("Bandwidth ranges:", (k / 1E6).toFixed(3), (l / 1E6).toFixed(3), (d / 1E6).toFixed(3));
          b >= l && b <= d && (e = g);
        }
        b = e;
      }
      return b || a[0] || this.f[0];
    }
    enable() {
      this.l = !0;
    }
    disable() {
      this.l = !1;
    }
    segmentDownloaded(a, b) {
      this.g.debug("Segment downloaded:", "deltaTimeMs\x3d" + a, "numBytes\x3d" + b, "lastTimeChosenMs\x3d" + this.h, "enabled\x3d" + this.l);
      var c = this.w;
      if (!(16E3 > b)) {
        var d = 8E3 * b / a;
        a /= 1E3;
        c.a += b;
        _.YB(c.f, a, d);
        _.YB(c.h, a, d);
      }
      if (null != this.h && this.l) a: {
        this.g.debug("Suggesting Streams...");
        if (!this.B) {
          if (!(128E3 <= this.w.a)) {
            this.g.debug("Still waiting for a good estimate...");
            break a;
          }
          this.B = !0;
          if (this.a.useSwitchIntervalForInitialSwitch && Date.now() - this.h < 1E3 * this.a.switchInterval) {
            this.g.debug("Skipping initial estimation switch due to switch interval...");
            break a;
          }
        } else if (Date.now() - this.h < 1E3 * this.a.switchInterval) {
          this.g.debug("Still within switch interval...");
          break a;
        }
        b = this.chooseTrack();
        c = this.getBandwidthEstimate();
        this.g.debug("Calling switch_(), bandwidth\x3d" + Math.round(c / 1E3) + " kbps");
        b && this.A(b);
      }
    }
    playbackRateChanged(a) {
      this.o = a;
    }
    getBandwidthEstimate() {
      if (this.j && this.a.useCmsd) {
        var a = rB(this.j, _.Vd);
        a = null == a ? null : a;
        if (null !== a) return this.g.debug("Using CMSD estimatedThroughputKbps:", a), 1E3 * a;
      }
      a = this.w;
      return 128E3 > a.a ? this.a.defaultBandwidthEstimate : Math.min(_.ZB(a.f), _.ZB(a.h));
    }
    setTracks(a) {
      this.f = a;
    }
    setCmsdManager(a) {
      this.j = a;
    }
    configure(a) {
      this.a = a;
    }
    reset() {
      this.B = !1;
      this.w = new SH();
      const a = this.chooseTrack();
      a && this.A(a);
    }
  };
  var UH = class {
    constructor() {
      this.a = null;
    }
  };
  var VH = class {
      constructor() {
        this.a = new Map();
      }
      clear() {
        this.a.clear();
      }
    },
    fE = class {
      constructor() {
        this.text = this.variant = null;
      }
    };
  var WH = class {
    constructor(a, b) {
      this.f = a;
      this.a = new Set([a]);
      b = b || [];
      for (const c of b) this.add(c);
    }
    add(a, b) {
      b = void 0 === b ? !0 : b;
      if (kE(this.f, a, void 0 === b ? !0 : b)) return this.a.add(a), !0;
      _.B("Dropping variant due to incompatible codecs for the current adaptation set. This may be because CodecSwitchingStrategy is set to PREVENT or SourceBuffer.changeType() is not available on this platform. Variant dropped:", a, "because it is not compatible with", this.f);
      return !1;
    }
    values() {
      return this.a.values();
    }
  };
  var eG = class {
      constructor(a, b) {
        b = void 0 === b ? _.of : b;
        this.f = a;
        this.a = b;
        this.h = new FG(a.language, "", a.audio && a.audio.channelsCount ? a.audio.channelsCount : 0, !1, b);
      }
      create(a) {
        const b = this.a === _.Jf && LB(),
          c = a.filter(d => kE(this.f, d, !b));
        return c.length ? new WH(c[0], c) : this.h.create(a);
      }
    },
    FG = class {
      constructor(a, b, c, d, e) {
        this.j = _.Qh(a);
        this.a = b;
        this.f = c;
        this.l = void 0 === d ? !1 : d;
        this.h = void 0 === e ? _.of : e;
      }
      create(a) {
        var b = lE(a, this.j);
        0 < b.length && (a = b);
        b = !1;
        if (this.a) {
          var c = mE(a, this.a);
          0 < c.length ? (a = c, b = !0) : _.B("No exact match for preferred variant role could be " + `found. Role: ${this.a}`);
        }
        b || (b = a.filter(d => d.primary), 0 < b.length && (a = b));
        this.f && (b = ND(a, this.f), b.length ? a = b : _.B("No exact match for the channel count could be found."));
        b = nE(a, this.l);
        b.length ? a = b : _.B("No exact match for spatial audio could be found.");
        b = this.h === _.Jf && LB();
        c = new WH(a[0]);
        for (const d of a) c.add(d, !b);
        return c;
      }
    };
  var sH = class {
      constructor(a, b) {
        this.a = uH;
        this.f = new Map().set(uH, b).set(iH, a);
      }
      getState() {
        return this.a;
      }
    },
    iH = 0,
    uH = 1;
  var JG = class {
    constructor(a) {
      this.D = a;
      this.a = a.getConfiguration().streaming;
      this.f = new _.Rs();
      this.g = new _.R("clpp.mse.media.LiveEdgeObserver");
      oE(this) && (a = this.D.getSurface().getMedia(), this.f.on(a, _.Wf, () => {
        {
          var b = this.D.getPosition();
          const c = this.D.getSeekRange().end;
          b = c - b;
          const d = this.D.getPlaybackRate(),
            e = this.a.stopChasingAt;
          1 <= d && b >= this.a.chaseJumpDistance ? (this.g.debug("Too far from edge, jump to beginning."), this.D.setPlaybackRate(1), this.D.seek(c)) : 1 === d && b >= this.a.startChasingAt ? (this.g.debug("Not on live edge, increase playback rate."), this.D.setPlaybackRate(this.a.chasingRate, _.Bd)) : 1 < d && b <= e && (this.g.debug("On live edge, reduce playback rate."), this.D.setPlaybackRate(1));
        }
      }));
    }
    release() {
      this.f.release();
    }
  };
  var aH = class {
      constructor(a, b, c, d, e) {
        this.j = a;
        this.G = c;
        this.a = {};
        this.H = {};
        this.S = !1;
        this.f = null;
        this.h = {};
        this.l = new _.Rs();
        this.O = !1;
        this.w = {};
        this.J = b;
        this.F = d;
        this.K = e;
        this.Ha = this.K.streaming.maxSegmentToMediaOffset || 1;
        this.R = new _.xn();
        this.B = "";
        this.I = {};
        this.o = qE(this, this.R);
        this.V = !0;
        this.g = new _.R("clpp.mse.MediaSourceEngineSmooth");
      }
      destroy() {
        this.O = !0;
        const a = [];
        for (const c in this.h) {
          const d = this.h[c];
          var b = d[0];
          this.h[c] = d.slice(0, 1);
          b && a.push(b.p.catch(vB));
          for (b = 1; b < d.length; ++b) d[b].p.catch(vB), d[b].p.reject();
        }
        this.f && a.push(this.f.destroy());
        this.G && a.push(this.G.destroy());
        for (const c in this.w) this.w[c].destroy(), delete this.w[c];
        return Promise.all(a).then(() => {
          this.l && (this.l.release(), this.l = null);
          this.j && (this.j.removeAttribute("src"), this.j.load(), this.j = null);
          this.B = "";
          this.G = this.f = this.o = null;
          this.a = {};
          this.w = {};
          this.I = {};
          this.J = null;
          this.S = !1;
          for (const c in this.h);
          this.h = {};
        });
      }
      open() {
        return this.R;
      }
      init(a, b, c) {
        c = void 0 === c ? !1 : c;
        const d = this;
        return _.w(function* () {
          yield d.R;
          a.forEach((e, f) => {
            const g = _.gk(e.mimeType, e.codecs);
            if (f === _.F) d.A(g);else {
              var h = new Map();
              h.set(f, g);
              _.Ar(g) && b && !e.keyInfo || !_.fk(g, f) || !(e = _.fk(g)) || (h = e(), d.w[f] = h, h = h.demuxMimeTypes(f, g), c && h.delete(_.p));
              rE(d, h);
            }
            d.h[f] = [];
          });
        });
      }
      Ra() {
        this.g.debug(DA);
        Object.values(this.w).forEach(a => a.reset());
      }
      Qa() {
        return this.K.streaming.ignoreManagedMediaSourceStreamingHints ? !0 : this.V;
      }
      A(a) {
        this.f || (this.f = new RH(this.K, this.G));
        WC(this.f, a);
      }
      M() {
        return this.o ? this.o.readyState === _.Td : !0;
      }
      Ja(a) {
        return a === _.F ? this.f.Ja() : (a = sE(this, a)) ? a.Ja() : null;
      }
      ga(a) {
        return a === _.F ? this.f.ga() : (a = sE(this, a)) ? a.ga() : null;
      }
      Fb(a, b, c) {
        return a === _.F ? this.f.Fb(b) : (a = sE(this, a)) ? a.Fb(b, !1, c) : !1;
      }
      xa(a, b) {
        return a === _.F ? this.f.xa(b) : (a = sE(this, a)) ? a.xa(b) : 0;
      }
      Z() {
        const a = {};
        a.total = new _.Ct(this.j.buffered, "total");
        a.audio = sE(this, _.p);
        a.video = sE(this, _.t);
        let b = [];
        this.f && (b = [{
          start: this.f.Ja(),
          end: this.f.ga()
        }]);
        a.text = KB(b, _.F);
        return a;
      }
      L(a, b, c, d, e, f, g) {
        g = void 0 === g ? !1 : g;
        const h = this.a[a];
        let k = -1,
          l = 0;
        h && h.buffered && (l = h.buffered.length);
        a === _.t && null !== c && null !== d && g && (k = 0 < l ? h.buffered.end(l - 1) : -1);
        a === _.F ? b = XC(this.f, b, c, d) : this.w[a] ? (b = this.w[a].transmux(b), b.captions && 0 < b.captions.length && (this.f || this.A(_.Uf), ZC(this.f, b.captions, c, d, this.a.video.timestampOffset)), e = [], 0 < b.audio.byteLength && this.a.audio && e.push(vE(this, _.p, this.X.bind(this, _.p, b.audio.buffer))), 0 < b.video.byteLength && this.a.video && e.push(vE(this, _.t, this.X.bind(this, _.t, b.video.buffer))), b = Promise.all(e)) : (e && window.muxjs && (this.f || this.A(_.Uf), null == c && null == d ? this.J.init(b) : this.J.h(b, m => {
          m.length && ZC(this.f, m, c, d, this.a.video.timestampOffset);
        })), b = vE(this, a, this.X.bind(this, a, b)));
        return b.then(() => {
          if (a === _.t && null !== c && null !== d && !g) this.F.append(c, d, f), null !== c && null !== d && this.g.debug(bA, c, d, dA, tE(this, a));else if (a === _.p && null !== c && null !== d) PB(this.F), null !== c && null !== d && this.g.debug(aA, c, d, dA, tE(this, a));else if (a === _.t && null !== c && null !== d && g) {
            var m = h.buffered.length,
              n = 0 < m ? h.buffered.end(m - 1) : -1;
            0 > k && 0 < m && (k = h.buffered.start(0));
            0 <= k && 0 < n && this.F.append(k, n, f);
          }
          if (!this.I[a] && h && 1 === h.buffered.length) {
            m = h.buffered.start(0);
            const q = c - m;
            if (Math.abs(q) > this.Ha && 0 === h.timestampOffset) return this.g.warn(`Detected a delta of ${q} between ` + `media times and segment times for ${a}. ` + `The segment start time is ${c} and the media start time ` + `is ${m}. Adjusting timestampOffset.`), m = vE(this, a, () => {
              wE(this, a, q);
            }), n = vE(this, a, this.da.bind(this, a, !1)), Promise.all([m, n]);
          }
          null !== c && null !== d && (this.I[a] = !0);
        }).catch(m => {
          throw m;
        });
      }
      Pa(a) {
        const b = this.ga(_.t) || 0;
        YC(this.f, a, b);
      }
      ba() {
        this.f && YC(this.f, "", 0);
      }
      remove(a, b, c) {
        return a === _.F ? this.f.remove(b, c) : vE(this, a, this.ia.bind(this, a, b, c)).then(() => {
          if (a === _.t) {
            const d = this.Ja(a),
              e = this.ga(a);
            null !== d && null !== e && SB(this.F, d, e);
          } else a === _.p && QB(this.F);
        });
      }
      clear(a) {
        if (a === _.F) {
          if (!this.f) return Promise.resolve();
          this.J.f();
          return this.f.remove(0, Infinity);
        }
        return vE(this, a, this.ia.bind(this, a, 0, this.o.duration));
      }
      flush(a) {
        return a === _.F ? Promise.resolve() : vE(this, a, this.da.bind(this, a));
      }
      Sa(a, b, c, d, e, f) {
        const g = this;
        return _.w(function* () {
          if (a === _.F) {
            g.f.A = b;
            var h = g.f;
            h.h = c;
            h.l = d;
          } else {
            h = yield yE(g, a, e, f);
            var k = zE(g, a, b, c, d, h);
            g.S && (k = k.concat(zE(g, _.p, b, c, d, h)));
            yield Promise.all(k);
          }
        });
      }
      endOfStream(a) {
        return BE(this, () => {
          this.M() || (a ? this.o.endOfStream(a) : this.o.endOfStream());
        });
      }
      Y(a) {
        BE(this, () => {
          this.o.duration = a;
        });
      }
      getDuration() {
        return this.o.duration;
      }
      X(a, b) {
        this.g.debug(cA + a + Vz);
        try {
          this.a[a].appendBuffer(b);
        } catch (c) {
          throw this.g.warn(nA + a, c), c;
        }
        this.g.debug(hA + a + Vz);
      }
      ia(a, b, c) {
        if (c <= b) this.C(a);else {
          this.g.debug(CA + a + Xz, b, c);
          try {
            this.a[a].remove(b, c);
          } catch (d) {
            throw this.g.warn(pA + a + Wz, b, c, d), d;
          }
          this.g.debug(jA + a + Xz, b, c);
        }
      }
      da(a) {
        try {
          this.j.readyState > HTMLMediaElement.HAVE_NOTHING ? (this.g.debug(sA + a), this.j.currentTime -= .001) : this.g.warn(HA + a + Yz + this.j.readyState);
        } catch (b) {
          throw this.g.warn(oA + a, b), b;
        }
        this.g.debug(iA + a);
        this.C(a);
      }
      T(a) {
        this.h[a][0].p.reject(new _.G(_.E, 3, 3014, {
          mediaError: this.j.error ? this.j.error.code : 0
        }));
      }
      C(a) {
        const b = this.h[a][0];
        b && (b.p.resolve(), this.g.debug(JA + a), AE(this, a));
      }
      getTextDisplayer() {
        return this.G;
      }
    },
    pE = window.URL.createObjectURL;
  var pH = class {
    constructor(a) {
      this.h = a;
      this.a = null;
      this.f = () => {};
    }
    release() {
      this.a = this.h = null;
      this.f = () => {};
    }
    l(a) {
      const b = this.a;
      {
        const c = this.h.periods;
        a = TB(c, a) || c[0];
      }
      b !== a && this.f(a);
      this.a = a;
    }
  };
  var IG = class {
    constructor(a) {
      this.f = a;
      this.o = this.A = this.l = !1;
      this.h = this.f.pf();
      this.w = _.Bd;
      this.j = null;
      this.a = new _.mk(() => {
        this.A || this.f.dh(.25 * this.h);
      });
      this.g = new _.R("clpp.mse.media.PlayRateController");
    }
    getConfiguration() {
      return this.j;
    }
    release() {
      this.a && (this.a.stop(), this.a = null);
      this.f = null;
    }
    set(a, b) {
      this.h = a;
      GE(this, b);
    }
  };
  var JE = new _.Xt(() => new Uint8Array([0, 0, 0, 80, 115, 105, 110, 102, 0, 0, 0, 12, 102, 114, 109, 97, 0, 0, 0, 0, 0, 0, 0, 20, 115, 99, 104, 109, 0, 0, 0, 0, 99, 101, 110, 99, 0, 1, 0, 0, 0, 0, 0, 40, 115, 99, 104, 105, 0, 0, 0, 32, 116, 101, 110, 99, 0, 0, 0, 0, 0, 0, 1, 8, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]));
  _.tH = class {
    constructor(a, b) {
      this.D = b;
      this.j = a;
      NE(a);
      this.h = null;
      this.l = 1;
      this.F = Promise.resolve();
      this.A = new Map();
      this.w = new Map();
      this.a = new Map();
      this.H = this.f = this.o = this.B = !1;
      this.G = 0;
      this.g = new _.R("clpp.mse.Streaming");
      this.C = !1;
    }
    destroy() {
      const a = this;
      return _.w(function* () {
        const b = [];
        for (const c of a.a.values()) OE(c), b.push(PE(c));
        yield Promise.all(b);
        a.a.clear();
        a.w.clear();
        a.D = null;
        a.j = null;
        a.F = null;
        a.A.clear();
        a.h = null;
        a.f = !0;
      });
    }
    start() {
      const a = this;
      return _.w(function* () {
        const b = a.D.Lb();
        var c = TB(a.j.periods, b + _.jt);
        if (!c) throw new _.G(_.E, 5, 5006, "No period found.");
        c = a.D.me(c);
        if (!c.variant && !c.text) throw a.g.error("start(): No streams chosen"), new _.G(_.E, 5, 5005, "No streams chosen.");
        a.C = !c.variant && !!c.text;
        yield aF(a, c.variant ? c.variant.audio : null, c.variant ? c.variant.video : null, c.text, b);
        a.f || (a.g.debug("init: completed initial Stream setup"), a.D && a.D.fh && (a.g.debug("init: calling onInitialStreamsSetup()..."), a.D.fh()));
      });
    }
    resume() {
      if (!uF(this)) for (const a of this.a.values()) a.endOfStream = !1, $E(this, a, 0);
    }
    Pb(a) {
      const b = this;
      return _.w(function* () {
        if (!b.f) {
          var c = LE(a);
          if (!a.qb && null != a.eb && !a.dc) if (a.eb = null, a.Ub) b.g.debug(c, "skipping update and clearing the buffer"), yield oF(b, a, a.Md, a.od);else {
            try {
              const d = FF(b, a);
              null != d && ($E(b, a, d), a.Cc = !1);
            } catch (d) {
              yield GF(b, d);
              return;
            }
            HF(b, a);
            if (vF(b)) if (b.D.configuration.Xg) {
              b.g.debug("End of stream reached, keeping MediaSource open.");
              for (const d of b.a.values()) d.eb = null;
            } else b.g.debug("End of stream reached, calling ms.endOfStream()."), yield b.D.$.endOfStream(), b.f || (c = b.D.$.getDuration(), c < b.j.presentationTimeline.getDuration() && (b.j.presentationTimeline.f = c), b.D.Bf());
          }
        }
      });
    }
    I(a, b, c, d) {
      const e = iB(d.reader),
        f = iB(d.reader),
        g = _.O(d.reader),
        h = _.O(d.reader),
        k = _.O(d.reader),
        l = _.O(d.reader);
      d = _.Vl(d.reader, d.reader.Ka() - d.reader.getPosition());
      a = a.startTime + b.startTime + h / g;
      c.includes(e) && ("urn:mpeg:dash:event:2012" === e ? this.D.Ff() : (c = new _.C("emsg", {
        detail: {
          startTime: a,
          endTime: a + k / g,
          schemeIdUri: e,
          value: f,
          timescale: g,
          presentationTimeDelta: h,
          eventDuration: k,
          id: l,
          messageData: d
        }
      }), this.D.onEvent(c)));
    }
    J(a) {
      let b = !1,
        c = 0;
      new _.em().N(_.ze, _.Yl).N(_.Xf, _.Yl).N(_.ue, _.Yl).N(_.ye, _.Yl).N(_.Of, _.Yl).ca(_.Qf, _.Zl).N("encv", d => {
        d.reader.skip(78);
        _.Yl(d);
      }).N("pasp", d => {
        c = d.start + d.headerSize;
        const e = _.O(d.reader),
          f = _.O(d.reader);
        b = e !== f;
        this.g.debug(`hSpacing=${e}, vSpacing=${f}, updatePASP=${b}`);
        d.parser.stop();
      }).parse(a.data);
      b && (a = new _.gm(new DataView(a.data)), a.seek(c), _.N(a, 1), _.N(a, 1));
    }
  };
  var XH = class {
    constructor() {
      this.a = null;
    }
  };
  var YH = class extends _.Gt {
      constructor() {
        super(QA);
        this.F = XG;
        this.j = this.Y = null;
        this.Ae = 0;
        this.Rf = 2;
        this.wb = null;
        this.we = !1;
        this.o = new _.Rs();
        this.Pa = this.Ie = this.yd = this.H = this.Sa = this.B = this.K = this.w = null;
        this.fc = !1;
        this.f = this.A = this.a = this.J = this.I = null;
        this.h = new _.TH();
        this.Wf = 1E9;
        this.zd = new Set();
        this.V = !0;
        this.Z = null;
        this.Vf = !1;
        this.Uf = 0;
        this.X = null;
        this.M = new VH();
        this.G = null;
        this.ia = _.po;
        this.Qa = new FG(this.configuration.preferredAudioLanguage || [], this.configuration.preferredAudioRole, this.configuration.preferredAudioChannelCount, this.configuration.preferSpatialAudio, this.configuration.mediaSource.codecSwitchingStrategy);
        this.cc = this.configuration.preferredTextLanguage;
        this.l = new UH();
        this.R = this.Ra = null;
        this.Ha = new Map();
        this.ba = null;
        this.Dc = this.configuration.preferredTextRole;
        this.bc = this.configuration.preferForcedSubtitles;
        this.o.on(window, "online", () => {
          this.F === VF && tF(this.a);
        });
        this.Bd = this.ec = this.Cd = null;
        this.S = _.la;
        this.hc = new XH();
      }
      init(a, b) {
        const c = a.getMedia();
        super.init(a, b);
        fG(this, c);
      }
      namespace() {
        return QA;
      }
      setSeekInterceptor(a) {
        this.Ie = a || null;
      }
      Tf() {
        return new V();
      }
      getState() {
        return this.ia;
      }
      getDrmInfo() {
        return this.w ? this.w.getDrmInfo() : null;
      }
      unload() {
        const a = this;
        return _.w(function* () {
          sD(a, !1);
          a.fc = !1;
          if (a.ea) {
            a.log.debug("Unload player with source", a.ea);
            var b = a.getTrackManager();
            b && b.release();
            a.F !== pG && (yield nG(a), a.ea = null, a.Ae = 0, a.Ra = null, a.R = null);
          }
        });
      }
      release() {
        const a = this;
        return _.w(function* () {
          if (a.Y) return a.Y;
          a.Y = oG(a);
          a.Y.finally(() => {
            a.Y = null;
          });
          return a.Y;
        });
      }
      getBufferInfo(a) {
        if (this.K) {
          var b = this.K.Z();
          const c = new _.Ct(void 0, a);
          switch (a) {
            case _.M:
              return b.video ? b.video : c;
            case _.L:
              return b.audio ? b.audio : c;
            case _.K:
              return b.text ? b.text : c;
          }
          return b.total ? b.total : c;
        }
        return this.wb ? (b = NB(this.wb), KB(b, a)) : super.getBufferInfo(a);
      }
      getPosition() {
        var a = this.hc,
          b = this.getMediaPosition();
        a.a && (a = a.a, b -= a.f, b = 0 > b ? 0 : b > a.a ? a.a : b);
        return b;
      }
      getMediaPosition() {
        return this.B ? OC(this.B) : super.getMediaPosition();
      }
      seek(a) {
        if (this.Ie && !this.Ie(a)) return Promise.resolve();
        var b = this.hc;
        b = b.a ? a + b.a.Mb() : a;
        b !== a && this.log.info(`Seeking to ${a} s corresponding to ` + `media time of ${b} s.`);
        return super.seek(b);
      }
      getPresentationStartTime() {
        return this.f ? this.f.presentationTimeline.getPresentationStartTime() : null;
      }
      getDuration() {
        var a = this.hc,
          b = this.getMediaDuration();
        return a.a ? a.a.getDuration() : b;
      }
      getMediaDuration() {
        return this.f ? this.f.presentationTimeline.getDuration() : Infinity;
      }
      destroy() {
        const a = this,
          b = () => Object.getPrototypeOf(Object.getPrototypeOf(this)).destroy;
        return _.w(function* () {
          yield b().call(a);
          a.F !== pG && (a.log.debug("Destroying player..."), a.F = pG, lG(a), a.o && (a.o.release(), a.o = null), a.ec = null, a.h = null);
        });
      }
      getTimeline() {
        return this.ec;
      }
      setupPlaylist(a) {
        const b = this;
        return _.w(function* () {
          const c = new _.xn();
          b.l.a = c;
          try {
            jG(b);
            b.S = _.sb;
            yield qG(b);
            eE(b.l, c);
            const d = a[0];
            d.playlist && d.playlist.startTime && (d.startTime = d.playlist.startTime);
            b.ea = _.vo(d);
            b.configuration = _.pl(b.da, d);
            b.log.info("Loading playlist configurations", a);
            b.ec = rG(b.configuration);
            yield vG(b, c);
            eE(b.l, c);
            const e = a.map(n => yG(b, n, 0, null, !0)),
              f = yield Promise.all(e),
              g = f.filter(n => n.manifest.presentationTimeline.isLive()),
              h = g[0];
            h && (b.A = h.parser);
            if (1 < g.length) throw new _.G(_.E, 7, 7003, "Playlist of multiple live streams is not supported.");
            if (h) {
              const n = b.configuration.playlist.content;
              if (n === _.Tb) throw new _.G(_.E, 7, 7003, "Cannot play live content in VOD mode.");
              if ("IPR" === n && h !== f[f.length - 1]) throw new _.G(_.E, 7, 7003, "Wrong order of playlist assets.");
            }
            const k = new _.LH({
                version: 2
              }, null, 0, null == h),
              l = f.map(n => gD(n.manifest)),
              m = jD(l, k);
            b.f = m;
            b.w = yield CG(b, m, b.configuration.drm);
            eE(b.l, c);
            yield PG(b, c);
            eE(b.l, c);
            rF(b.a, m);
            QG(b, m);
            c.resolve();
          } catch (d) {
            b.A && (yield b.A.stop(), b.A = null), SG(b, d, c);
          }
        });
      }
      appendLoad(a) {
        const b = this;
        return _.w(function* () {
          jG(b);
          if (b.S !== _.sb) throw new _.G(_.E, 7, 7003, "Cannot append-load to non-playlist player.");
          const c = yield TG(b, a);
          b.f = c.manifest;
          b.A = c.parser;
          rF(b.a, c.manifest);
          vF(b.a) && b.a.resume();
          QG(b, c.manifest);
        });
      }
      seekWith(a) {
        return this.seek(this.getPosition() + a);
      }
      Sf() {
        const a = this;
        return _.w(function* () {
          jG(a);
          a.Cd ? (a.log.info("Loading preloaded asset", a.Cd), a.Cd = null, yield PG(a, null)) : a.log.warn("No preloaded asset found");
        });
      }
      preload(a) {
        const b = this;
        return _.w(function* () {
          jG(b);
          _.$m("preload.start");
          b.log.info("Preloading asset", a);
          yield cH(b, a, !1);
          b.Cd = a;
          b.log.info("Preloaded asset", a);
          _.$m("preload.end");
          _.an("preload", "preload.start", "preload.end");
        });
      }
      load(a) {
        return cH(this, a);
      }
      detach() {
        const a = this;
        return _.w(function* () {
          yield kG(a);
          a.configuration = a.da;
          lG(a);
        });
      }
      isLive() {
        var a;
        this.f ? (a = this.f.presentationTimeline.isLive()) || (a = this.f.presentationTimeline, a = Infinity !== a.f && !a.l) : a = !1;
        return a;
      }
      Pe() {
        this.log.debug("User seeking finished");
        this.I && jH(this);
        gH(this);
      }
      getSeekRange() {
        var a = {
          start: 0,
          end: 0
        };
        if (this.f) {
          var b = this.f.presentationTimeline;
          a = {
            start: cC(b),
            end: mC(b)
          };
        }
        b = this.hc;
        b.a ? (b = b.a.getDuration(), b > a.end && (b = a.end), b = {
          start: 0,
          end: b
        }) : b = a;
        return b;
      }
      kc() {
        return this.f ? this.f.presentationTimeline.kc() : null;
      }
      getPlaybackRate() {
        if (this.H) {
          var a = this.H;
          a = a.l ? 0 : a.h;
        } else a = 0;
        return a;
      }
      setPlaybackRate(a, b) {
        if (0 === a) this.log.warn("A trick play rate of 0 is unsupported!");else if (this.F === VF) {
          if (_.qk()) {
            this.log.warn("Pausing and unpausing to mitigate playbackRate platform issue.");
            var c = this.getSurface().getMedia();
            c.pause();
            this.H.set(a, b);
            c.play();
          } else this.H.set(a, b);
          if ((b = this.H.getConfiguration()) && b.preferTrickPlayTracks) {
            b = this.a;
            var d = 1 < Math.abs(a);
            if (c = b.a.get(_.t)) {
              var e = c.stream;
              if (e) if (b.g.debug("setTrickPlay", d), d) (d = e.trickModeVideo) && !c.Zb && (b.g.debug("Engaging trick mode stream", d), _.pF(b, d, !1, 0), c.Zb = e);else if (e = c.Zb) b.g.debug("Restoring non-trick-mode stream", e), c.Zb = null, _.pF(b, e, !0, 0);
            }
          }
          this.h.playbackRateChanged(a);
        }
      }
      ha() {
        if (this.f && this.B) {
          var a = WF(this);
          if (null == a) a = null;else {
            if (!gE(this.M, a).text) {
              var b = _.bE(a.textStreams, this.cc, this.Dc, this.bc);
              b.length && hE(this.M, a, b[0]);
            }
            a = gE(this.M, a).text;
          }
          b = [];
          for (const c of xH(this)) {
            const d = ZD(c);
            d.active = c === a;
            b.push(d);
          }
          return b;
        }
        return [];
      }
      getPeriods() {
        return this.f.periods;
      }
      getStats() {
        if (this.F !== VF) return _.bo();
        gH(this);
        var a = this.j;
        if (a.getVideoPlaybackQuality) {
          var b = a.getVideoPlaybackQuality();
          a = this.G;
          var c = Number(b.totalVideoFrames);
          a.l = Number(b.droppedVideoFrames);
          a.j = c;
        }
        if (this.F === VF) {
          if (b = aG(this)) this.G.o = b.bandwidth;
          b && b.video && (a = this.G, c = b.video.height || NaN, a.w = b.video.width || NaN, a.h = c);
          a = this.h.getBandwidthEstimate();
          this.G.f = a;
        }
        return this.G.getStats();
      }
      getCueTimeline() {
        return this.J;
      }
      getTimelineCues() {
        return this.J ? qC(this.J, dH(this)) : [];
      }
      resetAbr() {
        this.h && this.h.reset();
      }
      td() {
        return this.h;
      }
      addTextTrack(a, b, c, d, e, f) {
        const g = this;
        return _.w(function* () {
          if (g.F !== VF) throw g.log.error("Must call load() and wait for it to resolve before adding text tracks."), Error("State error!");
          const h = WF(g);
          var k = g.f.periods.indexOf(h) + 1;
          const l = (k >= g.f.periods.length ? g.f.presentationTimeline.getDuration() : g.f.periods[k].startTime) - h.startTime;
          if (Infinity === l) throw new _.G(1, 4, 4040);
          const m = new _.uC(1, 0, l, () => [a], 0, null, 0);
          k = {
            id: g.Wf++,
            originalId: null,
            createSegmentIndex: Promise.resolve.bind(Promise),
            findSegmentPosition: () => 1,
            getSegmentReference: q => 1 === q ? m : null,
            adjustSegmentDurations: () => {},
            getDuration: () => l,
            segmentLookupStrategy: _.TA,
            initSegmentReference: null,
            mimeType: d,
            codecs: e || "",
            kind: c,
            encrypted: !1,
            keyId: null,
            language: b,
            originalLanguage: b,
            label: f || null,
            type: _.F,
            primary: !1,
            trickModeVideo: null,
            emsgSchemeIdUris: null,
            roles: [],
            channelsCount: null,
            spatialAudio: !1,
            closedCaptions: null
          };
          g.zd.add(k);
          h.textStreams.push(k);
          yield gF(g.a, k);
          const n = _.dF(g.a, _.F);
          n && hE(g.M, h, n);
          g.zd.delete(k);
          g.log.debug("Choosing new streams after adding a text stream");
          zH(g, h);
          AG(g);
          return ZD(k);
        });
      }
      Zf(a) {
        this.log.debug(_.XA, a);
        try {
          this.log.debug("onChooseStreams_, choosing variant from ", a.variants);
          this.log.debug("onChooseStreams_, choosing text from ", a.textStreams);
          const b = AH(this, a);
          this.log.debug("onChooseStreams_, chose variant ", b.variant);
          this.log.debug("onChooseStreams_, chose text ", b.text);
          return b;
        } catch (b) {
          return this.onError(b), {
            variant: null,
            text: null
          };
        }
      }
      Yf() {
        this.log.debug("canSwitch_");
        this.V = !1;
        this.configuration.abr.enabled && this.h.enable();
        this.Z && (qF(this.a, this.Z, this.Vf, this.Uf), this.Z = null);
        this.X && (_.pF(this.a, this.X, !0, 0), this.X = null);
      }
      dg() {
        this.A && this.A.update();
      }
      ag() {
        this.B && this.B.j.Fd();
      }
      Xf(a, b, c) {
        if ((void 0 === c ? "" : c) === VA) return AG(this), this.pa() || (this.configuration.abr.enabled = !0, this.h.enable()), zH(this, b, !1), !0;
        if (c = b.variants.some(d => {
          let e = !1;
          const f = d[a.type];
          f && (e = d.allowedByApplication && f.id !== a.id, a.type === _.p && (e = e && f.language === a.language));
          return e;
        }) && this.pa()) {
          for (const d of b.variants) {
            const e = d[a.type];
            e && e.id === a.id && (d.allowedByApplication = !1);
          }
          AG(this);
          this.pa() || (this.configuration.abr.enabled = !0, this.h.enable());
          zH(this, b);
        }
        return c;
      }
      $f() {
        this.log.debug("endOfStream called by streaming engine");
        this.fc = !0;
      }
      onError(a) {
        if (this.F !== pG && 7001 !== a.code && 7E3 !== a.code) {
          var b = new _.C(_.Ud, {
            detail: a
          });
          a.severity === _.E && iG(this, _.oo);
          this.trigger(b);
          b.defaultPrevented && (b.a = !0);
        }
      }
      xe(a) {
        a.type === _.Nd ? this.dispatchEvent(a) : "largegap" !== a.type && (a.type === _.vg ? YG(this) : "emsg" !== a.type && (a instanceof _.C ? this.dispatchEvent(a) : this.log.debug(_.Ob, a.type)));
      }
      pa() {
        return this.configuration.abr.enabled;
      }
      cg(a) {
        const b = this;
        return _.w(function* () {
          if (b.uc) {
            b.log.info("Attempt to switch CDN.");
            var c = b.getLoadedSource(),
              d = b.pa();
            if (c = yield b.uc(c.url, a)) {
              c = _.Og(b.getLoadedSource(), {
                url: c
              });
              d && b.h.disable();
              yield wF(b.a);
              var e = aG(b);
              do {
                var f = !1;
                try {
                  b.log.info(`Switching CDN to ${c.url}.`), yield BH(b, c, e);
                } catch (g) {
                  if (b.log.info("Switch failed"), f = yield b.uc(c.url, a)) c.url = f, f = !0;else throw g;
                }
              } while (f);
              d && b.h.enable();
              tF(b.a, !0);
              b.ea[b.getSourceIndex()] = c;
              a.f = !0;
              b.trigger(new _.C(_.$c, {
                newManifest: c.url
              }));
            }
          }
        });
      }
    },
    pG = 0,
    XG = 1,
    VF = 2,
    zG = [_.Me, _.ce];
  _.mh(new class {
    a() {
      return QA;
    }
    canPlay(a, b) {
      if (_.zk()) return 0;
      const c = a.type || "",
        d = (window.ManagedMediaSource ? !0 : !1) || (window.MediaSource && MediaSource.isTypeSupported ? !0 : !1);
      a = window.ManagedMediaSource || window.MediaSource && MediaSource.isTypeSupported ? c in _.ds || _.es(a.url) in _.fs ? !0 : !1 : !1;
      if (d && a) {
        if (_.qk(2)) return 1;
        if (_.Ak() && c === _.hl) {
          if (b.streaming.preferNativeHlsOnSafari) return 1;
          _.B("Support for MSE + FairPlay on Apple is experimental. In case of issues, consider using native HLS.");
        }
        return 2;
      }
      return 0;
    }
    f(a) {
      return a === _.la || a === _.sb;
    }
    create() {
      return new YH();
    }
  }());
  _.gq(function () {
    _.ih("MediaSource.install");
    if (window.MediaSource) {
      if (window.cast && cast.__platform__ && cast.__platform__.canDisplayType) _.hh("Patching Chromecast MSE bugs."), CH();else if (_.Ak()) {
        var a = navigator.appVersion;
        a = 0 <= a.indexOf("Version") ? parseInt(a.match(/Version\/(\d+)/)[1], 10) : 13;
        DH();
        10 >= a ? (_.hh("Blacklisting MSE on Safari \x3c\x3d 10."), window.MediaSource = null) : 12 >= a ? (_.hh("Patching Safari 11 \x26 12 MSE bugs."), EH(), FH()) : (_.hh("Patching Safari 13 MSE bugs."), EH());
      } else _.qk(2) || _.qk(3) || _.qk(4) ? GH() : _.hh("Using native MSE as-is.");
    } else _.hh("No MSE implementation available.");
  });
  var ZH = class extends _.Qu {
    constructor(a) {
      super(a);
      a = a.getSurface().getMedia();
      this.a = null;
      for (let b = 0; b < a.textTracks.length; ++b) {
        const c = a.textTracks[b];
        c.mode = _.Dd;
        c.label === zA && (this.a = c);
      }
      this.a || (this.a = a.addTextTrack(_.fB, zA));
      this.a.mode = _.$d;
    }
    append(a) {
      const b = [],
        c = d => {
          if (d.startTime >= d.endTime) _.B("Invalid cue times: " + d.startTime + " - " + d.endTime), d = null;else {
            var e = d.payload || "";
            e = e.replace(/<br\s*\/?>/igm, "\n");
            e = _.Ri(e);
            e = new VTTCue(d.startTime, d.endTime, e);
            e.line = -1;
            try {
              e.align = _.bd;
            } catch (f) {
              e.align = "middle";
            }
            d.writingMode === _.ig ? e.vertical = "lr" : d.writingMode === _.jg && (e.vertical = "rl");
            d = e;
          }
          d && b.push(d);
        };
      for (const d of a) c(d);
      a = b.slice().sort((d, e) => d.startTime !== e.startTime ? d.startTime - e.startTime : d.endTime !== e.endTime ? d.endTime - e.startTime : b.indexOf(e) - b.indexOf(d));
      for (const d of a) this.a.addCue(d);
    }
    remove(a, b) {
      if (!this.a) return !1;
      HH(this.a, c => c.startTime < b && c.endTime > a);
      return !0;
    }
    isTextVisible() {
      return this.a.mode === _.Ff;
    }
    setTextVisibility(a) {
      null !== this.a && (this.a.mode = a ? _.Ff : _.$d);
    }
    destroy() {
      this.a && HH(this.a, () => !0);
      this.a = null;
      return Promise.resolve();
    }
  };
  _.Wn("single-native", new class {
    a() {
      return !0;
    }
    create(a) {
      return new ZH(a);
    }
  }());
};
f.call(g, window);