import {Component, EventEmitter, Input, OnInit, Output, signal, Signal} from '@angular/core';

@Component({
    selector: 'app-cl-tab',
    templateUrl: './cl-tab.component.html',
    styleUrls: ['./cl-tab.component.scss'],
  standalone: false
})
export class ClTabComponent implements OnInit {

  @Input() tab_title: string;

  active = signal<boolean>(false);

  @Output() activated = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

}
