<app-logo-area></app-logo-area>
<main>
  <app-headline-section [title]="'User profile'"></app-headline-section>
  <section class="cl-section-regular">
    <div class="container cl-form">
      <div class="row">
        <div class="col-8">
          <p class="cl-p-large">
            Edit your profile here.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <label for="givenname">First name</label>
          <input id="givenname" [(ngModel)]="given_name" type="text">
        </div>
        <div class="col-6">
          <label for="familiyname">Last name</label>
          <input id="familiyname" [(ngModel)]="family_name" type="text">

        </div>
        <div class="col-6">
          <label for="oldpw">Old password</label>
          <input id="oldpw" [(ngModel)]="oldpw" type="password">

        </div>
        <div class="col-6">
          <label for="newpw">New password</label>
          <input id="newpw" [(ngModel)]="newpw" type="password">
        </div>
      </div>
      <div class="row cl-spacing-medium">

        <div class="col-12 cl-spacing-large"
             style="display: flex;
             flex-direction: row;
             justify-content: flex-end;
             align-items: center;
             gap: 16px;">
          <app-message-field #saveMessage/>
          <app-do-button
            (actionTriggered)="save($event)"
            [messageField]="saveMessage"
          >
            <span>Save changes</span>
            <i class="fas fa-angle-right"></i>
          </app-do-button>

        </div>
      </div>
      <div class="row cl-spacing-medium">
        <div class="col-12" style="display: flex; flex-direction: row; justify-content: space-between;">
          <div *ngIf="hasmfa" style="display: flex; flex-direction: row; justify-content: space-between;">
            <div style="padding-top:8px"> MFA activated.</div>
            <i class="fa fa-check" style="padding-top: 12px; padding-left: 16px"></i>
          </div>
          <div *ngIf="!hasmfa" style="display: flex; flex-direction: row; justify-content: space-between;">
            <div style="padding-top: 8px">Please set up MFA.</div>
            <i class="fa fa-exclamation-triangle" style="padding-top: 12px; padding-left: 16px"></i>
          </div>
          <div style="margin-top: 4px">
          <app-message-field #mfaMessage/>
          </div>
          <app-do-button *ngIf="hasmfa" [messageField]="mfaMessage" (actionTriggered)="mfa($event)">
            <span>Redo MFA Setup</span>
            <i class="fas fa-angle-right"></i>
          </app-do-button>

          <app-do-button *ngIf="!hasmfa" [messageField]="mfaMessage" (actionTriggered)="mfa($event)">
            <span>Setup MFA</span>
            <i class="fas fa-angle-right"></i>
          </app-do-button>

        </div>
        <div class="col-md-2 col-6">

        </div>
      </div>
    </div>
  </section>

</main>
