import {AfterViewInit, Component, Input, Signal, signal} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {SideNavService} from '../../services/side-nav.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {Organization} from '../../types';
import {Observable} from 'rxjs';
import {PortalIdpService} from '../../services/portal-idp.service';
import {ContextService} from '../../services/context.service';
import {OAuthService} from 'angular-oauth2-oidc';
import {UserProfileService} from '../../services/user-profile.service';
import {environment} from '../../../environments/environment';
import {filter, map} from 'rxjs/operators';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {AutoLogoutComponent} from '../auto-logout/auto-logout.component';
import {FooterComponent} from '../footer/footer.component';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {IsAllowedAnywherePipe, IsAllowedPipe} from '../../pipes/has-action-pipe';
import {AsyncPipe, CommonModule, DatePipe} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {BannerOutlet} from '../../banner/banner.component';
import {ContainsPipe} from '../../pipes/contains-pipe';

@Component({
  selector: 'app-logged-in',
  templateUrl: './logged-in.component.html',
  styleUrls: ['./logged-in.component.scss'],
  animations: [trigger('flyInAnimation', [
    transition(':leave', [
      animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)'),
      style({height: 0})
    ]),
    transition(':enter', [
      style({height: 0}),
      animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)'),
    ])
  ])],
  imports: [
    CommonModule,
    AutoLogoutComponent,
    FooterComponent,
    RouterModule,
    IsAllowedPipe,
    IsAllowedAnywherePipe,
    AsyncPipe,
    FormsModule,
    BannerOutlet,
    DatePipe,
    ContainsPipe
  ]
})
export class LoggedInComponent implements AfterViewInit {
  organization: Signal<Organization>

  licensedProducts: Signal<String[]>;

  prod = environment.production

  navOpened = true;

  updateBuildDate: Date = null;

  constructor(public oauthService: OAuthService,
              protected sideNavService: SideNavService,
              protected contextService: ContextService,
              public userProfileService: UserProfileService,
              protected portalIdp: PortalIdpService,
              private router: Router,
              private swUpdate: SwUpdate) {
    this.organization = this.contextService.organization

    this.swUpdate.versionUpdates.pipe(
      filter((evt) => evt.type === 'VERSION_READY'),
      map((evt) => evt as VersionReadyEvent)
    ).subscribe(event => {

      if ('buildDate' in event.latestVersion.appData) {
        this.updateBuildDate = new Date(Date.parse(event.latestVersion.appData['buildDate'] as string));
      }
    });

  }

  ngAfterViewInit() {
    this.licensedProducts = this.contextService.licensedProducts;

    this.sideNavService.open()
    this.sideNavService.isOpen.subscribe(isOpen => this.navOpened = isOpen)

  }

  doLogout() {
    localStorage.setItem('post-logout-path', this.router.url);
    this.oauthService.logOut({
      client_id: this.oauthService.clientId,
      logout_uri: this.oauthService.redirectUri,
    });
  }


  reload() {
    window.location.reload()
  }
}
