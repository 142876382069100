import {AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2} from '@angular/core';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective implements AfterViewInit, OnDestroy {
  @Input('appTooltip') tooltipText!: string;
  @Input() tooltipPlacement: 'top' | 'bottom' | 'start' | 'end' = 'top';
  @Input() tooltipColor: 'primary' | 'secondary' | 'light' | 'dark'  = 'secondary';

  private tooltipElement!: HTMLElement;
  private tooltipInner!: HTMLElement;
  private tooltipArrow!: HTMLElement;

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngAfterViewInit(): void {
    this.createTooltip();
    this.renderer.listen(this.el.nativeElement, 'mouseenter', () => this.showTooltip());
    this.renderer.listen(this.el.nativeElement, 'mouseleave', () => this.hideTooltip());
  }

  private createTooltip(): void {
    this.tooltipElement = this.renderer.createElement('div');
    this.renderer.addClass(this.tooltipElement, 'tooltip');
    this.renderer.addClass(this.tooltipElement, `bs-tooltip-${this.tooltipPlacement}`);
    this.renderer.addClass(this.tooltipElement, `cl-tooltip`);
    this.renderer.addClass(this.tooltipElement, `cl-tooltip-${this.tooltipColor}`);
    this.renderer.setStyle(this.tooltipElement, 'position', 'absolute');
    this.renderer.setStyle(this.tooltipElement, 'visibility', 'hidden');

    this.tooltipInner = this.renderer.createElement('div');
    this.renderer.addClass(this.tooltipInner, 'tooltip-inner');
    this.renderer.setProperty(this.tooltipInner, 'textContent', this.tooltipText);

    this.tooltipArrow = this.renderer.createElement('div');
    this.renderer.addClass(this.tooltipArrow, 'tooltip-arrow');
    this.renderer.setStyle(this.tooltipArrow, 'position', 'absolute');

    this.renderer.appendChild(this.tooltipElement, this.tooltipArrow);
    this.renderer.appendChild(this.tooltipElement, this.tooltipInner);
    this.renderer.appendChild(document.body, this.tooltipElement);
  }

  private showTooltip(): void {
    const hostPos = this.el.nativeElement.getBoundingClientRect();
    const tooltipPos = this.tooltipElement.getBoundingClientRect();

    let top = 0, left = 0, arrowTop = '', arrowLeft = '';
    switch (this.tooltipPlacement) {
      case 'top':
        top = hostPos.top - tooltipPos.height - 5;
        left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
        arrowTop = 'calc(100% + 4px)';
        arrowLeft = '50%';
        break;
      case 'bottom':
        top = hostPos.bottom + 5;
        left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
        arrowTop = '-4px';
        arrowLeft = '50%';
        break;
      case 'start':
        top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
        left = hostPos.left - tooltipPos.width - 5;
        arrowTop = '50%';
        arrowLeft = 'calc(100% + 4px)';
        break;
      case 'end':
        top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
        left = hostPos.right + 5;
        arrowTop = '50%';
        arrowLeft = '-5px';
        break;
    }

    this.renderer.setStyle(this.tooltipElement, 'top', `${top}px`);
    this.renderer.setStyle(this.tooltipElement, 'left', `${left}px`);
    this.renderer.setStyle(this.tooltipElement, 'visibility', 'visible');
    this.renderer.setStyle(this.tooltipElement, 'opacity', '1');

    this.renderer.setStyle(this.tooltipArrow, 'top', arrowTop);
    this.renderer.setStyle(this.tooltipArrow, 'left', arrowLeft);
    this.renderer.setStyle(this.tooltipArrow, 'transform', 'translate(-50%, -50%)');
  }

  private hideTooltip(): void {
    this.renderer.setStyle(this.tooltipElement, 'visibility', 'hidden');
    this.renderer.setStyle(this.tooltipElement, 'opacity', '0');
  }

  ngOnDestroy(): void {
    if (this.tooltipElement) {
      this.renderer.removeChild(document.body, this.tooltipElement);
    }
  }
}
