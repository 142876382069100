import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SideNavService {

    private sourceSubject = new BehaviorSubject<boolean>(false);
    private sourceObservable$ = this.sourceSubject.pipe(
        shareReplay(1)
    );

    constructor() {
    }

    open() {
        this.sourceSubject.next(true);
    }

    close() {
        this.sourceSubject.next(false);
    }

    toggle() {
        this.sourceSubject.next(!this.sourceSubject.value);
    }

    get isOpen(): Observable<boolean> {
        return this.sourceObservable$;
    }
}
