import {Component, computed, Input, OnInit, Signal} from '@angular/core';
import {ActivatedRoute, RouterModule} from '@angular/router';
import {BehaviorSubject, combineLatest, Observable, of, switchMap} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {Airline, PoItem, Query} from '../../../types-workflow';
import gql from 'graphql-tag';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {BreadCrumb, LogoAreaComponent} from '../../logo-area/logo-area.component';
import {Organization} from '../../../types';
import {HeadlineSectionComponent} from '../../headline-section/headline-section.component';
import {FilterSectionComponent} from '../../filter-section/filter-section.component';
import {AsyncPipe, CommonModule, DatePipe} from '@angular/common';
import {ContextService} from '../../../services/context.service';
import {toObservable} from '@angular/core/rxjs-interop';

const query = gql`
  query GetPreviews($airline: String!, $date_created: AWSDateTime) {
    list_POs(input: {
      filter: {
        airline: { eq: $airline}
        date_created: {gt: $date_created}
      } }){
      pos {
        id
        airline
        po_name
        date_created
        poitems {
          filename
          id
          po_item_id
          preview {
            dash_manifest_last_modified
            dash_manifest_url
            hls_manifest_url
            hls_manifest_last_modified
          }
          po {
            po_name
            airline
          }
        }
      }
    }
  }
`

@Component({
  selector: 'app-preview',
  templateUrl: './list-previews.component.html',
  styleUrls: ['./list-previews.component.scss'],
  imports: [
    LogoAreaComponent,
    HeadlineSectionComponent,
    FilterSectionComponent,
    ReactiveFormsModule,
    AsyncPipe,
    RouterModule,
    DatePipe,
    CommonModule
  ]
})
export class ListPreviewsForAirlineComponent implements OnInit {

  title = new BehaviorSubject<string>(null);
  po$: Observable<string> = null;
  poItems$: Observable<PoItem[]> = null;
  poItemsSortedFiltered$: Observable<PoItem[]> = null;
  loading = true;

  filters: FormGroup = new FormGroup({
    t: new FormControl<string>(''),
  });
  breadCrumbs: Signal<BreadCrumb[]>;
  private airline: Signal<Airline | null>;
  organization: Signal<Organization | null>;

  ngOnInit() {
  }

  constructor(
    private route: ActivatedRoute,
    contextService: ContextService,
    private apollo: Apollo) {

    this.airline = contextService.airline;
    this.organization = contextService.organization;

    this.po$ = this.route.paramMap.pipe(
      map(paramMap => paramMap.get('po')),
    )

    this.breadCrumbs = computed(() =>
      [
        {
          display: 'Content Portal',
          commands: ['/'],
        },
        {
          display: this.organization()?.name,
          commands: ['/o', this.organization()?.id],
        },
        {
          display: 'Previews',
          commands: ['/o', this.organization()?.id, 'previews'],
        },
        {
          display: this.airline()?.airline_name,
          commands: ['/o', this.organization()?.id, 'previews', this.airline()?.iata_code],
        }
      ]);

    const sevenWeeksAgo = new Date();
    sevenWeeksAgo.setDate(sevenWeeksAgo.getDate() - 7 * 7);
    this.poItems$ = combineLatest(toObservable(this.airline), toObservable(this.organization)).pipe(
      tap(([airline, organization]) => console.log(airline?.iata_code, organization?.name )),
      switchMap(([airline, organization]) => {
          if (!airline || !organization) {
            this.loading = false
            return of([]); // Emit an empty array if either is missing
          }
          return this.apollo.use('workflow').query<Query>({
            query: query,
            errorPolicy: 'ignore',
            variables: {
              airline: airline.iata_code,
              date_created: sevenWeeksAgo.toISOString()
            }
          }).pipe(
            map(res => {

              return res.data.list_POs.pos
            }),
            map(pos => {
              pos = pos.map(po => Object.assign({}, po))
              for (const po of pos) {
                po.poitems = po.poitems.map(poitem => Object.assign({}, poitem))
                po.poitems = po.poitems.filter(poitem =>
                  poitem.preview !== null && (poitem.preview.hls_manifest_url !== null || poitem.preview.dash_manifest_url !== null))
              }
              pos = pos.filter(po => po.poitems.length > 0)
              return pos
            }),
            map(pos => {
              return pos.map(po => po.poitems).reduce((a, b) => [].concat(a, b), [])
            }),
            tap(poitems => this.loading = false),
          )
        }
      )
    )
    this.poItemsSortedFiltered$ = combineLatest(
      [
        this.poItems$,
        this.filters.valueChanges
      ]).pipe(
      map(([poitems, filters]) => {
        return poitems.filter(poitem => {
          return poitem.po_item_id.toLowerCase().includes((filters['t'] || '').toLowerCase()) ||
            poitem.po.po_name.toLowerCase().includes((filters['t'] || '').toLowerCase());
        })
      })
    )
  }
}
