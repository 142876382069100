import {ProcessStateEnum, Query} from '../types';
import {skipWhile, take} from 'rxjs/operators';
import {ApolloBase} from 'apollo-angular/apollo';
import {GET_PROCESS} from '../queries';

export function clean_datetime_string(input: string) {
  return input
    .replace(new RegExp(/:/g), '')
    .replace(new RegExp(/\.[0-9][0-9][0-9]Z/g), '');
}

export function clean_datetime(input: Date) {
  return clean_datetime_string(input.toISOString().split('T')[0])
}


export interface SourceEncoding {
  [key: string]: string | string[]
}

export function cleanLocalStorage() {
  localStorage.removeItem('nonce')
  localStorage.removeItem('access_token_stored_at')
  localStorage.removeItem('access_token')
  localStorage.removeItem('PKCE_verifier')
  localStorage.removeItem('session_state')
  localStorage.removeItem('id_token_claims_obj')
  localStorage.removeItem('refresh_token')
  localStorage.removeItem('expires_at')
  localStorage.removeItem('ng2Idle.main.idling')
  localStorage.removeItem('ng2Idle.main.expiry')
  localStorage.removeItem('id_token_stored_at')
  localStorage.removeItem('id_token_expires_at')
  localStorage.removeItem('id_token')
  window.location.reload();
}


export interface PoItem {
  id: string;
  input_brefix: string;
  po_item_id: string;
  encoding?: SourceEncoding;
}

export interface PORoot {
  po_number: string;
  airline: string;
  due_date: string;
  poItems: PoItem[];
  date_created: Date;
  email_notification?: string;
  ife: string;
  ife_format: string;
}

export function waitForProcess(apollo: ApolloBase, process_id: string) {
  return apollo.watchQuery<Query>({
    query: GET_PROCESS,
    pollInterval: 1000,
    variables: {
      'id': process_id
    }
  }).valueChanges.pipe(
    skipWhile((data) => {
      return data.data.process.state === ProcessStateEnum.InProgress
    }),
    take(1),
  )
}

export function shortenJanusOrg(org: string) {
  const regex = /urn:janus:organization:([a-f0-9]{32})/g;
  return org.replace(regex, (match, p1) => `${p1.substring(0, 8)}`);

}
