import {Injectable} from '@angular/core';
import {RepositoryProcessPayload} from '../types';
import {WorkflowProcessPayload} from '../types-workflow';
import {BehaviorSubject, Observable, timer} from 'rxjs';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';
import {filter, map} from 'rxjs/operators';
import {ApolloBase} from 'apollo-angular/apollo';


@Injectable({
  providedIn: 'root'
})
export class ProcessService {
  processObservers: { [key: string]: [BehaviorSubject<WorkflowProcessPayload | RepositoryProcessPayload>, string, string, string] } = {};

  constructor(private apollo: Apollo) {
    this.startMonitoring('WorkflowProcessPayload');
    this.startMonitoring('RepositoryProcessPayload');
  }

  private startMonitoring(processType: string) {
    timer(0, 3000).pipe(
      map(() => this.getInProgressObservers(processType)),
      filter(observers => Object.keys(observers).length > 0)
    ).subscribe((procs) => this.executeQuery(procs));
  }

  private getInProgressObservers(processType: string): {
    [key: string]: [BehaviorSubject<WorkflowProcessPayload | RepositoryProcessPayload>, string, string, string]
  } {
    return Object.entries(this.processObservers)
      .filter(([_, value]) =>
        value[0].value.state === 'IN_PROGRESS' &&
        value[0].value.__typename === processType
      )
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {} as { [key: string]: [BehaviorSubject<WorkflowProcessPayload | RepositoryProcessPayload>, string, string, string] });
  }

  private executeQuery(procs: {
    [key: string]: [BehaviorSubject<WorkflowProcessPayload | RepositoryProcessPayload>, string, string, string]
  }) {
    const timestamp = Date.now().toString();
    let queryText = `query GetProcess_${timestamp} {\n`;

    for (const processId of Object.keys(procs)) {
      queryText += this.getQueryFragment(processId);
    }
    queryText += `}`;
    let myApollo: ApolloBase = this.apollo;
    if (Object.values(procs)[0][0].value.__typename === 'WorkflowProcessPayload') {
      myApollo = this.apollo.use('workflow');
    }
    myApollo.query({
      query: gql(queryText),
      fetchPolicy: 'network-only'
    }).subscribe({
      next: res => this.handleResponse(res.data),
      error: err => console.error(err)
    });
  }

  private getQueryFragment(processId: string) {
    const formattedId = processId.replace(/-/g, '_');
    return `  process_${formattedId}: process(id: "${processId}") {\n    action\n    data\n    id\n    message\n    state\n    start_date\n    end_date\n  }\n`;
  }

  private handleResponse(data: any) {
    Object.keys(data).forEach(key => {
      const process = data[key];
      if (!process) {
        return;
      }

      const observer = this.processObservers[process.id][0];
      const currentProcess = observer.value;

      if (process.state === 'FAILED' || process.state === 'SUCCESS') {
        observer.next(process);
        observer.complete();
        const notifyTarget = process.state === 'FAILED' ? 2 : 1;
        if (this.processObservers[process.id][notifyTarget]) {
          this.notify(this.processObservers[process.id][notifyTarget],
            process.state === 'FAILED' ? 'circle-exclamation' : 'circle-check',
            this.processObservers[process.id][3]
          );
        }
      } else if (JSON.stringify(currentProcess) !== JSON.stringify(process)) {
        observer.next(process);
      }
    });
  }

  public observeProcess<T extends WorkflowProcessPayload | RepositoryProcessPayload>(
    process: T,
    options?: { successMessage?: string; failureMessage?: string; link?: string }): Observable<T> {
    console.log('observeProcess', process.id);
    if (this.processObservers[process.id] === undefined) {
      this.processObservers[process.id] = [
        new BehaviorSubject<T>(process),
        options?.successMessage,
        options?.failureMessage,
        options?.link];
    } else {
      this.processObservers[process.id][1] = options.successMessage
      this.processObservers[process.id][2] = options.failureMessage
      this.processObservers[process.id][3] = options.link
    }
    return this.processObservers[process.id][0] as BehaviorSubject<T>;
  }

  private notify(message: string, icon: string, link: string) {
    if (!('Notification' in window)) {
      alert('This browser does not support desktop notification');
      return;
    }

    const createNotification = () => {
      const notification = new Notification(message, {
        icon: `assets/icons/${icon}.png`
      });

      if (link) {
        notification.onclick = (event) => {
          event.preventDefault(); // Prevent focusing the Notification's tab
          const url = link.startsWith('http') ? link : `${window.location.origin}${link}`;
          window.open(url, '_blank');
        };
      }
    };

    if (Notification.permission === 'granted') {
      createNotification();
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          createNotification();
        }
      });
    }
  }
}
