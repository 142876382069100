<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">Delete Files</div>

    <div class="modal-body">
      <p>You are about to delete the following files</p>
      <table class="cl-table-data">
        <thead>
        <tr>
          <th class="cl-table-head cl-p-tiny ">
            Type
          </th>
          <th class="cl-table-head cl-p-tiny ">
            File or folder name
          </th>
          <th class="cl-table-head cl-p-tiny ">
            <div>
              Last Modified
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let file of files">
          <td>
            <i class="fas fa-file"></i>

          </td>
          <td>
            {{file.name}}
          </td>
          <td>
            {{file.last_modified | date: 'd MMM y,&nbsp;HH:mm'}}
          </td>
        </tbody>
      </table>

    </div>
    <div class="modal-footer">
      <span class="modal-footer-left ">
                    <button type="button" class="btn btn-sm cl-btn-outline-text it-cancel"
                            (click)="dialogRef.close()">
                        <i class="fas fa-angle-left"></i><span>Cancel</span>
                    </button></span>
      <span class="modal-footer-center "></span>
      <span class="modal-footer-right ">
                    <button type="button" class="btn btn-sm cl-btn-outline-primary it-ok" [class.cl-btn-spinner]="deleting"
                            (click)="performDelete()"
                    >
                        <span>Delete files</span><i class="fas fa-angle-right"></i>
                    </button></span>
    </div>
  </div>
</div>
