import {Component, effect, Inject, Signal, TrackByFunction} from '@angular/core';
import {map, take} from 'rxjs/operators';
import {FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {BannerService} from '../../../banner/banner.service';
import {Apollo} from 'apollo-angular';
import {Airline, Mutation} from '../../../types-workflow';

import {START_WORKFLOW_VOD_DEFAULT} from '../../../queries';
import {forkJoin} from 'rxjs';
import {Router} from '@angular/router';
import {VodDefaultFormatSpecificComponent} from '../vod-default-format-specific/vod-default-format-specific.component';
import {BasicPoitemVodInfoComponent} from '../basics-poitem-vod-info/basic-poitem-vod-info.component';
import {clean_datetime} from '../../utils';
import {PO_DESTINATIONS, PoDestination} from '../../../target_systems';
import {Organization} from '../../../types';
import {Po2024Service} from '../../../services/po-2024.service';
import {CommonModule} from '@angular/common';
import {MessageFieldComponent} from '../../do-button/message-field.component';
import {DoButtonComponent} from '../../do-button/do-button.component';
import {ContextService} from '../../../services/context.service';
import {ProcessService} from '../../../services/process.service';


@Component({
  selector: 'app-vod-default-po',
  templateUrl: './vod-default-po.component.html',
  styleUrls: ['./vod-default-po.component.css'],
  imports: [
    ReactiveFormsModule,
    BasicPoitemVodInfoComponent,
    VodDefaultFormatSpecificComponent,
    CommonModule,
    MessageFieldComponent,
    DoButtonComponent,
  ]
})
export class VodDefaultPoComponent  {

  organization: Signal<Organization>;
  airlines: Signal<Airline[]>;
  poDestinations: PoDestination[] = []
  poFormGroup: FormGroup;

  poItemTrackBy: TrackByFunction<FormGroup> = (index: number, poitem: FormGroup) => {
    return poitem.get('id').value;
  }

  constructor(
    private apollo: Apollo,
    @Inject('vod_default') protected poService: Po2024Service,
    private formBuilder: FormBuilder,
    private banner: BannerService,
    contextService: ContextService,
    private processService: ProcessService,
    private router: Router
  ) {
    this.organization = contextService.organization;
    this.airlines = contextService.airlines;

    this.poFormGroup = formBuilder.group({
      airline: [null, Validators.required],
      po_number: [clean_datetime(new Date()), Validators.required],
      po_destination: [null, Validators.required],
      auto_publish: [true, Validators.required],
      poItems: formBuilder.array([]),
    });


    this.poService.getFormState().pipe(take(1)).subscribe(savedForm => {
      if (savedForm) {
        savedForm.poItems.forEach(poItem => (this.poFormGroup.controls.poItems as FormArray).push(this.formBuilder.group(poItem)));
        this.poFormGroup.setValue(savedForm, {emitEvent: false})
      }
    });
    effect(() => {
      this.poFormGroup.controls['airline'].valueChanges.subscribe(airline => {
        this.poDestinations = PO_DESTINATIONS.hasOwnProperty(this.organization().legacy_name)
          ? [{display_name: this.organization().legacy_name, destination_id: this.organization().legacy_name}, {
            display_name: 'Default CDN',
            destination_id: 'vod'
          }]
          : [{display_name: 'Default CDN', destination_id: 'vod'}];
        this.poFormGroup.controls['po_destination'].setValue(this.poDestinations[0].destination_id)
      })
    });
    this.poService.poItemToAdd$.subscribe(data => {
      console.log('poItemToAdd$')
      if (data) {
        (this.poFormGroup.controls.poItems as FormArray).push(this.formBuilder.group(
          Object.assign(
            {},
            {
              basics: BasicPoitemVodInfoComponent.DEFAULTS,
              format_specific_data: VodDefaultFormatSpecificComponent.DEFAULTS
            },
            data)))
      }
    });
    this.poService.poItemToRemove$.subscribe(id => {
      if (id) {
        const index = (this.poFormGroup.controls.poItems as FormArray).controls.findIndex(poItem => poItem.get('id').value === id);
        (this.poFormGroup.controls.poItems as FormArray).removeAt(index);
      }
    });
    effect(() => {
      if (this.airlines().length === 1) {
        this.poFormGroup.controls['airline'].setValue(this.airlines()[0].iata_code);
      }
    })
  }



  compare_destination_id(o1: any, o2: any) {
    console.log(o1, o2)
    return o1?.destination_id === o2?.destination_id;
  }

  submit_po(button: DoButtonComponent) {
    const organization = this.organization()
    if (!this.poFormGroup.valid) {
      button.done('Form Invalid. Check for errors.', {
        severity: 'cl-alert-danger'
      })
      return
    }
    const workflows = []
    for (const poItem of this.poFormGroup.value.poItems) {
      workflows.push(this.apollo.use('workflow').mutate<Mutation>({
        mutation: START_WORKFLOW_VOD_DEFAULT,
        variables: {
          input_brefix: poItem.basics.input_brefix,
          po_item_id: poItem.basics.po_item_id.trim(),
          po_name: `${this.poFormGroup.value.airline}_${this.poFormGroup.value.po_number.trim()}`,
          po_destination: this.poFormGroup.value.po_destination,
          vtk_template: 'cmaf-abr',
          auto_publish: this.poFormGroup.value.auto_publish,
          format_specific_data: JSON.stringify(poItem.format_specific_data)
        }

      }).pipe(
        map(d => ({
          process: d.data.start_workflow_vod_default,
          po_item_id: poItem.basics.po_item_id.trim()
        }))))
    }

    forkJoin(workflows).subscribe(
      {
        next: wfs => {
          button.done('All encodes started successfully.');
          wfs.forEach(workflow => {
            this.processService.observeProcess(workflow.process, {
              successMessage: `${workflow.po_item_id} encoded.`,
              failureMessage: `Failed to encode ${workflow.po_item_id}.`,
              link: ['/#/o', organization, 'workflows',
                this.poFormGroup.value.airline,
                `${this.poFormGroup.value.airline}_${this.poFormGroup.value.po_number}`].join('/')
            })
          })
        },
        error: err => {
          button.done('Workflow submission failed: ' + err, {severity: 'cl-alert-danger'});
        }
      }
    )

  }

  get poItems() {
    return this.poFormGroup.get('poItems') as FormArray<FormGroup>;
  }

  ngOnDestroy(): void {
    this.poService.setFormState(this.poFormGroup.value)
  }

}
