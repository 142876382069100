<app-logo-area [breadcrumbs]="breadCrumbs()"></app-logo-area>
<main>

  <app-headline-section
    [title]="'Workflows'">
  </app-headline-section>
  <section class="cl-section-regular">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-8">
          <p class="cl-p-large">Efficiently manage and track your POs in one user-friendly interface. </p>
        </div>
      </div>
    </div>
  </section>
  <app-filter-section [all]="allpos$()" [filtered]="pos$()" [formGroup]="filters">
    <form [formGroup]="filters" class="cl-form">
      <input type="text" style="width: 100%" formControlName="t"
             placeholder="Search by name and path">
      <p class="valid-feedback">Search terms are case-insensitive.</p>
    </form>
  </app-filter-section>
  <section class="cl-section-regular">


    <div class="container">
      <div class="row">
        <div class="col-12">
          <table style="table-layout: fixed;" class="cl-table-data">
            <thead>
            <tr>
              <th class="cl-table-head cl-p-tiny " style="max-width: 50%; min-width: 100px">
                PO
              </th>
              <th class="cl-table-head cl-p-tiny " style="width: 120px">
                Date ordered
              </th>
              <th class="cl-table-head cl-p-tiny " style="width: 120px">
                Due date
              </th>
              <th class="cl-table-head cl-p-tiny " style="max-width: 50%">
                Target system
              </th>
            </tr>
            </thead>
            <tbody>



            <tr *ngIf="!loading()">
              <td>
                <a *ngIf="!(poprefix$ | async)" [routerLink]="['/o', this.organization()?.id, 'workflows']">.. (up)</a>
                <a *ngIf="poprefix$ | async"
                   [routerLink]="['/o', this.organization()?.id, 'workflows', this.airline()?.iata_code]">.. (up)</a>
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr class="cl-tr-no-data" *ngIf="loading()">
              <td colspan="4">
                <div class="cl-spinner">Loading ...<i class="fas fa-spinner fa-spin"></i>
                </div>
              </td>
            </tr>
            <tr *ngFor="let element of pos$()">
              <td>
                <a [routerLink]="['/o', this.organization().id, 'workflows', airline()?.iata_code, element.po_name ]"
                   [queryParams]="{ prefix: poprefix$ | async }">
                  {{ element.po_name  | orgShort }}</a>
              </td>
              <td>{{ element.date_created | date: 'd MMM y' }}</td>
              <td> {{ element.date_due }}</td>
              <td> {{ element.target_system }}</td>
            </tr>
            <tr *ngIf="!loading && allpos$().length == 0" class="cl-tr-no-data">
              <td colspan="4" style="text-align: center">No workflows available.</td>
            </tr>

            </tbody>

          </table>
        </div>
      </div>

    </div>

  </section>
</main>
